const ko = {
  // Header
  photos: '사진',
  Photos: '사진',
  vectors: '일러스트',
  Vectors: '일러스트',
  footageupper: '영상',
  footage: '영상',
  video: 'Video',
  audio: '음원',
  signin: '로그인',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Korean.png',
  language: '언어',
  // Footer
  contact: '연락처',
  contactUs: '고객문의',
  privacy: '개인정보보호정책',
  terms: '이용약관',
  cookie: '쿠키정책',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: '패스워드를 잊으셨나요',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: '정확한 이메일 주소를 입력하여 주시기 바랍니다.',
  emailCannotEmpty: '이메일 주소는 필수 입력 사항입니다.',
  returnToLogin: '로그인 페이지로 돌아가기',
  email: '이메일',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // Login
  username: '아이디',
  pw: '비밀번호',
  rememberMe: '나의 로그인 정보 기억하기',
  login: '로그인',
  forgotPassword: '비밀번호 찾기',
  loginAsGuest: '게스트 로그인',
  // ResetPassword
  resetPwdDesc: '새로운 패스워드 입력 후 바로 로그인하실 수 있습니다.',
  newPw: '신규 비밀번호',
  confirmPwd: '비밀번호 확인',
  resetPwdButton: '패스워드 재설정',
  resetPwdTitle: '패스워드를 재설정합니다.',
  enterYourNewPw: '새로운 패스워드를 입력하여 주시기 바랍니다.',
  pwMiniMumChars: '최소 8글자 이상',
  pwMaxChar20: '최대 20 문자 입력 가능',
  pwLowerCase: '최소 한 글자 이상의 소문자를 포함',
  pwUpperCase: '최소 한 글자 이상의 대문자를 포함',
  pwNumeral: '최소 한 개 이상의 숫자를 포함',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: '패스워드가 일치하지 않습니다.',
  resetPwdInvalidLink: '유효하지 않은 링크입니다.',
  pwHasBeenReset: '패스워드가 변경되었습니다. 다시 로그인하여 주시기 바랍니다.',
  // Admin index
  companyName: '상호',
  phone: '전화',
  fax: '팩스',
  address: '주소',
  idd: 'IDD',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: '전체 수량',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: '이전 비밀번호',
  confirmNewPassword: '비밀번호 확인',
  returnToHome: '메인 페이지로 돌아가기',
  // Cookie Policy
  langCookie01:
    '"%host%" 는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’와 같은 개인정보를 자동으로 수집하는 장치를 설치, 운용합니다. 쿠키란 "회사"의 웹 사이트 운영 시 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. "123RF"는 다음과 같은 목적을 위해 쿠키 등을 사용합니다.',
  langCookie02:
    '1. 쿠키 등 사용 목적 ①회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 횟수 파악 등을 통한 마케팅 및 라이크박스 등의 개인 맞춤 서비스 제공합니다.',
  langCookie03:
    '② 귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.',
  langCookie04:
    '2. 쿠키 설정 거부 방법 ①회원님이 사용하시는 웹 브라우저의 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다. 단, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용할 수 없습니다.',
  langCookie05:
    '② 설정방법 예(인터넷 익스플로러의 경우: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보)<BR><BR><BR>귀하께서 EU에 거주하는 경우, 해당 쿠키 정책이 적용되지 않으므로 반드시 별도로 제공되는 영문 쿠키 정책을 확인하여 주시기 바랍니다.',

  downloadModalTitle: '이미지 다운로드',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf: 'TIFF 이미지, 영상, 음원 파일은 지원하지 않습니다. 다음 콘텐츠 :',
  messageFileNotSupported2ndHalf: '일괄 다운로드 내역에서 삭제되었습니다.',
  licenseOnBehalf: '라이선스 대행 취득 ',
  customizedPortal: 'Customized portal',
  normalDownload: '기본 다운로드',
  myCredit: '내 크레딧',
  lowCreditsReminder: '크레딧 수량 낮음',
  downloadModalId: '이미지 ID:',
  downloadModalPrice: '크레딧:',
  clearAll: '선택 해제',
  myActivities: '최근 본 콘텐츠',
  info: '정보',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: '콘텐츠 다운로드 방법',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: '검색어를 입력하세요',
  toSearchAIKeyword: '검색어를 입력하세요.',
  allImages: '모든 콘텐츠',
  freeImages: 'Free Images',
  categoryImage: '카테고리',
  recentViewed: '최근 열람 콘텐츠',
  latestIMG: 'Latest Images',
  // Setting
  adminControlPanel: '관리자 모드',
  downloadHistory: '다운로드 내역',
  hruc: '고해상도 미리보기',
  changePassword: '비밀번호 변경',
  logOut: '로그아웃',
  hello: '안녕하세요,',
  viewAccount: 'View Account',
  myAccount: '마이페이지',
  myCollections: '마이컬렉션',
  personalCredits: '나의 크레딧',
  groupTotal: '전체 크레딧',
  toTalUnpaidLicense: '미지불 라이선스비용',
  highRUnwatermarkComp: '고해상도 시안용 콘텐츠 사용내역',
  monthSelection: 'Month selection',

  // Menu
  categories: '카테고리',
  myLikebox: '나의 라이트박스',
  settings: '시스템 설정',
  notification: '알림',
  clear: '삭제',
  likebox: '收藏夹',
  credits: '크레딧',
  // Credit summary at header
  downloadAndUsege: '크레딧 안내',
  totalDlCredit: '개인별 다운로드 크레딧',
  allocatedDlCredit: '할당된 크레딧',
  personalAvailableCre: '사용자별 사용 가능 크레딧',
  groupAvailableCre: '그룹별 사용 가능 크레딧',
  availableDlSlot: '다운로드 가능한 콘텐츠 수량',
  uNPLD: '후불제 선택',
  hiResHRUC: '고해상도 시안용 콘텐츠 사용내역',
  monthlyAllocation: '월별 할당량',
  remainingQuota: '잔여 크레딧',
  remainingHRUCQ: '고해상도 시안용 콘텐츠 다운로드 잔여 할당량',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: '그룹',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: '문의사항을 알려주세요. 또는 1544-4225 한국고객지원센터에 연락하세요.',
  liveChat: '라이브 채팅 (영문 지원)',
  whatsApp: 'WhatsApp',
  hiThere: '안녕하세요',
  // email response
  emailIsNotAvailable: '잘못된 이메일 주소입니다.',
  anErrorHaSoccured: '오류가 발생하였습니다. 다시 시도하여 주십시오.',
  emailHasBeenSent:
    '이메일이 성공적으로 발송 되었습니다. 안내 메일을 확인하여 주시기 바랍니다. 패스워드 재설정을 위한 안내 메일이 고객님의 이메일 주소 __email로 발송되었습니다. corporateplus@123rf.com로부터 패스워드 재설정 안내 메일이 발송되었습니다. 5분 이내 해당 메일이 확인되지 않으실 경우 귀하의 스팸 메일함을 확인하여 주시기 바랍니다.',
  tooManyAttempts: '시도 횟수가 너무 많습니다. 다시 시도해 주세요.',
  // INVALIDPASSWORD
  inValidPassword: '잘못된 패스워드입니다.<br>Caps Lock 상태를 확인하여 주시기 바랍니다.',
  userIsBlocked: '연속 비밀번호 오류로 인해 계정이 잠금처리되었습니다.',
  blockWarrings: '연속 10회 이상 오류 입력 시 잠금처리 됩니다. ',
  // Update Password
  resetPwdUpdated: '패스워드가 업데이트되었습니다.',
  updatePwDfailed: '비밀번호 변경에 실패하였습니다.',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: '분',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: '라이선스 약관',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: '사용자 ID가 입력되지 않았습니다.',
  passWordCannotEmpty: '패스워드가 입력되지 않았습니다.',
  oldPasswordCannotEmpty: '이전 패스워드를 입력하여 주시기 바랍니다',
  confirmPasswordCannotBeEmpty: '확인용 패스워드를 입력하여 주시기 바랍니다',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: '더 이상 이메일을 보낼 수 없습니다. 메일함을 확인해 주세요.',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: '오래된 버전의 브라우저를 사용하고 있는지 확인해주세요!',
  upgradeBrowser: '원활한 경험을 위해 최신 브라우저 버전으로 업그레이드 해주세요.',
  clickToDownload: '이용 중인 브라우저를 클릭하여 다운로드를 시작하세요 :',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: '전체보기',
  // Filter bar
  aiGenerate: 'AI 생성 이미지',
  standardModels: '표준 모델',
  fineTunedModels: '세부 조정 모델',
  aiContent: 'AI 생성 컨텐츠를 추가하세요',
  filter: '필터',
  safeSearch: '유해 정보 차단',
  aiAssist: 'AI 기능',
  freshness: '콘텐츠 등록일',
  gender: '성별',
  sortBy: '정렬방법',
  mediaType: '콘텐츠 타입',
  orientation: '콘텐츠 모양',
  modelPreferences: '사진모델',
  people: '인물',
  more: '더보기',
  numOfPeople: '사람 수',
  age: '연령',
  ethnicity: '민족성',
  collection: '콘텐츠 종류',
  uploaded: 'Uploaded',
  style: '스타일',
  cutOut: '단색/투명 백그라운드',
  doNotIncludeWords: '해당 검색어 제외',
  doNotIncludeWords_ex: '예. 치즈',
  color: '컬러',
  max1Color: '최대 색상 1개',
  license: '라이선스 유형',
  fps: 'FPS',
  resolution: '해상도',
  clipDuration: '음원길이 (분)',
  loop: '룹',
  category: '카테고리',
  genre: '장르',
  tempo: '템포 (bpm)',
  // Filter button text
  Relevance: '무작위순',
  New: '최신순',
  Georank: '국내 인기순',
  DownloadHistory: '다운로드 내역',
  Popularity: '인기순',
  Photography: '사진',
  VectorIllustration: '일러스트',
  Horizontal: '가로형',
  Landscape: '가로형',
  Portrait: '세로형',
  Square: '정사각형',
  Panorama: '파노라마형',
  numPpl: '사람 수',
  WithoutPeople: '인물사진제외',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: '제외',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: '최근 7일',
  LastMonth: 'Last month',
  PastThreeMonths: '최근 3개월',
  PastTwodays: '최근 2일',
  Male: '남성',
  Female: '여성',
  Standard: '스탠다드',
  extended: '특별 라이선스',
  Editorial: '에디토리얼',
  Commercial: '상업적 사용',
  Black: 'Black',
  Caucasian: '백인',
  AfricanAmerican: '아프리카계 미국인',
  Asian: '아시아인',
  EastAsian: 'East Asian',
  Hispanic: '라틴계',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: '어린이',
  Teenagers: '청소년',
  Adults: '성인',
  Seniors: '노인',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: '예',
  Nonloopable: '아니오',
  Music: '음악',
  SoundEffects: '효과음',
  LogoAndIdents: '로고&아이덴티티',
  Veryslow: '매우천천히',
  VeryslowSubText: '(0-79)',
  Slow: '천천히',
  SlowSubText: '(80-119)',
  Medium: '미디엄',
  MediumSubText: '(120-139)',
  Fast: '빠르게',
  FastSubText: '(140-159)',
  Veryfast: '매우빠르게',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: '인기순',
  MostDownload: '인기 다운로드',
  Credits: '크레딧',
  Freshness: '콘텐츠 등록일',
  pickAGenre: '장르 선택',
  // Paginations
  Of: 'of',
  Page: '페이지',
  // home
  recommended: '추천',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: '트렌드 키워드',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: '게임사운드',
  cartoonSounds: '만화사운드',
  soundPacks: '사운드팩',
  interfaceSounds: '디지털사운드',
  transitionsAndMovementsSounds: '움직임활동',
  natureSounds: '자연음',
  domesticSounds: '실내음',
  urbanSounds: '현대음',
  industrialSounds: '기계음',
  futuristicSounds: '미래상상',
  humanSounds: '휴먼사운드',
  othersSounds: '기타',
  ambientSounds: '환경음악',
  childrenSounds: '어린이음악',
  cinematicSounds: '영화음악',
  classicalSounds: '영화음악',
  corporateSounds: '기업음악',
  electronicaSounds: '일렉트로닉',
  soulSounds: '알앤비',
  jazzSounds: '재즈',
  popSounds: '팝',
  worldBeatSounds: '월드비트',

  countrySounds: '민속음악',
  drumSounds: '드럼베이스',

  folkSounds: '포크어쿠스틱',
  funkSounds: '펑크&그루브',

  hiphopSounds: '힙합',
  holidaySounds: '시즌음악',

  rockSounds: '락',

  individualSounds: '개성음악',
  introSounds: '전반부 & 후반부',
  ascSounds: '상승 & 하강',
  businessSounds: '비즈니스 & 미디어',
  orchestralSounds: '오케스트라',
  festiveSounds: '페스티벌',
  percussiveSounds: '타악기',
  miscellaneousSounds: '종합',
  packsSounds: '패키지',
  percussionSounds: '타악기 사운드',
  sampleLoopSounds: '샘플 루프',

  // Search images
  searchImage: '사진 & 일러스트',
  StockPhotosAndImages: '스톡 사진 & 이미지',
  StockVectorClipartAndIllustration: '스톡사진과 이미지',

  matches: '일치',
  editorial: '에디토리얼',
  downloaded: '해당 콘텐츠는 이미 다운로드 완료되었습니다.',
  to: 'to',
  relatedSearches: '관련 검색어',
  similarTo: '와 유사한',
  searchByImage: '이미지로 검색하기',
  options: '옵션',
  searchByImageMsg: '123RF 렌즈로 이미지 검색! 이곳에 이미지를 드래그하여 끌어넣으세요.',
  mustBeJPGorPNG: '검색 가능한 이미지 파일 : JPG / PNG',
  sizeLessThan5MB: '파일 크기 제한 : 5MB 미만',
  sizeLessThan4MB: '파일 크기 제한 : 4MB 미만',
  dragOrDropFile: '이미지 끌어 넣기 또는',
  browse: '검색',
  uploadedImage: '업로드된 이미지',
  similarStockPhotos: '유사한 콘텐츠',
  // Search footage
  searchFootage: '영상',
  StockFootage: '스톡 영상',
  businessFootage: 'Hacks to make the most of your business videos',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  footageProperties: '영상 정보',
  audioProperties: '음원 정보',
  modalDownloadTitle: '파일 다운로드 옵션',
  credit: '크레딧',
  saveAs: '파일 저장 형식',
  ownLicense: '기본 다운로드 (자체 라이선스)',
  downloadAs: '다운로드 형식',
  insufficientCredits: '크레딧이 부족합니다',
  custom: '협의 라이선스',
  personalAndCom: '개인 및 상업용의 홍보물, 프레젠테이션, SNS, 웹 용도에 필요한 라이선스입니다. ',
  licenseType: '라이선스 유형',
  requiredForResale:
    '공연, 구독 서비스, 스트리밍 영상, 게임 및 앱 등에 사용되는 유료 콘텐츠 제작/유통 용도에 필요한 라이선스입니다.',
  footageDetail: '영상 정보',
  stockAudioKeywords: '음원 키워드',
  stockFootageKeywords: '영상 키워드',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: '스테레오',
  fileSize: '파일 크기',
  footageId: '영상 ID',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  fileName: '파일 이름',
  // Search audio
  searchAudio: '오디오(음원 & 음향)',
  StockAudio: '음원',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: '링크 복사하기',
  preview: '미리보기',

  bestSellingFootage: '베스트 영상 검색하기',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: '유사 이미지',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: '최근에 본 이미지가 보여집니다.',
  rVVectors: '최근에 본 일러스트가 보여집니다.',
  rVFootage: '최근에 본 영상이 보여집니다.',
  rVAudio: '최근에 본 음원이 보여집니다.',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: '앗, 필터링된 결과가 존재하지 않습니다 :(',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle: '필터를 줄이거나 다른 키워드를 입력해보세요.',
  emptyReverseSearchTitle: '결과를 찾을 수 없습니다.',
  emptyReverseSearchSubTitle1:
    '업로드 한 이미지에 오류가 있습니다. 아래 검색 조건에 맞는지 확인 해 주시기 바랍니다',
  emptyReverseSearchSubTitle2: '키워드를 이용하여 상세검색을 해 보세요.',
  emptyReverseSearchSubTitle3: '이미지는 JPG/PNG 파일 형식으로만 업로드 가능합니다.',
  emptyReverseSearchSubTitle4: '이미지 사이즈는 5MB 이하일 경우 업로드 가능합니다.',
  emptySearchResultClearFilter: '필터 초기화',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: '검색어를 입력하세요.',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // collection search result page
  searchCollection: '컬랙션 검색',
  personal: '개인',
  groups: '그룹',
  createNew: '새로 만들기',
  createNewCollection: '뉴 컬렉션 만들기',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  titleItem: '이름',
  selectGroup: '그룹 선택',
  description: '설명 & 상세',
  charactersRemaining: '180자 입력 가능',
  cancel: '취소',
  create: '생성',
  or: '또는',
  defaultCollection: '기본 컬렉션',
  addToMyCollections: '라이크박스 등록',
  addedToCollection: '컬렉션에 담았어요.',
  removedFromCollection: '컬렉션에서 삭제되었습니다.',
  collectionModalPlaceholder: '예) 자연',
  groupNameShowsHere: '이곳에 그룹 이름 표시',
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: '나와 공유된 폴더',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: '소유자',
  images: '이미지',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: '개인',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: '공유하기',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: '현재 보유한 컬렉션이 없습니다.',
  btnUnDo: '실행 취소',
  // my collection
  dateAdded: '추가된 일자',
  collectionName: '컬렉션 이름',
  selectAll: '전체선택',
  delete: '삭제',
  seeAll: '전체보기',
  deleteCollection: '컬렉션 삭제',
  deleteWarning: '컬렉션에 포함된 콘텐츠 또한 모두 제거되며 이후 복구가 불가합니다.',
  createCollection: ' 이 생성되었어요.',
  shareCollection: '컬렉션 공유',
  shareWithTeam: 'Share in team',
  selectUsers: '대상 추가',
  searchUserName: '사용자 아이디 검색',
  copy: '복사',
  titleCopyModal: '선택 항목 복사',
  collectionUppercase: '컬렉션',
  groupCollection: '그룹',
  moveImage: '선택 항목 이동',
  defaultSelect: '컬렉션 이름을 입력하세요',
  moveLikebox: 'Move',
  messsageDeleteLikebox: '컬렉션에 포함된 콘텐츠 또한 모두 제거되며 이후 복구가 불가합니다.',
  areYouSure: '진행할까요?',
  editCollection: '컬렉션 편집하기',
  collectionNameUpper: '컬렉션 이름',
  deleteSuccessful: 'has been successfully deleted',
  edit: '수정',
  messageRemoveImage: '컬렉션에서 해당 콘텐츠를 제거할까요?',
  titleRemove: '선택 항목 제거',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: '저장',
  shareLikebox: '공유 받음',
  remove: '제거',
  moveTo: '옮기기',
  createNewPlaceholder: '예) 자연',
  shareViaEmail: '이메일로 공유하기',
  sharePublicly: 'Share publicly',
  receipnetEmail: '받는사람 이메일',
  message: '메시지',
  insertReceipmentEmail: '받는사람 이메일 삽입',
  messageText: '메시지',
  exportCollection: '컬렉션 추출하기',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: '새 컬랙션 추가',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: '파일형식',
  lowCredit: '크레딧 수량 낮음',
  downloadSuccess: '다운로드를 시작합니다. 감사합니다.',
  downloadFailed: '다운로드 오류: 오류가 발생하였습니다. 다시 시도하여 주십시오.',
  downloadedLabel: '다운로드 완료',
  any: '랜덤',
  resetFilter: '필터 초기화',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: '팔로우',
  name: '이름',
  location: '지역',
  since: '가입일자',

  // 마이페이지
  updated: 'Update',
  userName: '아이디',
  firstName: '이름',
  lastName: '성',
  myProfile: 'My Profile',
  changeProfiledetail: '프로필 정보 수정하기',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: '마이플랜',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: '후불제 선택',
  bodyUNPL:
    '후불제(UNPL)는 단기간동안 우선 서비스를 이용하고 추후 금액을 지불할 수 있는 결제방법입니다.',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',

  // audio player
  categoryGenre: '카테고리 / 장르',
  duration: '기간',
  seeMore: '더보기',
  seeLess: '간단히 보기',
  audioID: '음원 ID',
  // category
  landscape: '풍경',
  Babies: '아기',
  business: '비즈니스',
  food: '음식',
  animals: '동물',
  technology: '기술',
  healthcare: '의료',
  nature: '자연',
  romance: '로맨스',
  // category vector
  graphic: '그래픽',
  advertising: '광고',
  flower: '꽃',
  abstract: '추상적',
  Illustration: '일러스트',
  pop: '팝',
  bird: '새',
  pixel: '픽셀',
  threeD: '3D',

  // footage category
  education: '교육',
  communication: '통신',
  beauty: '아름다움',
  concept: '개념',
  fitness: '적합',
  lifestyle: '라이프 스타일',
  celebration: '축하',
  family: '가족',
  fashion: '유행',

  // Download-history modal
  reDownload: '재다운로드',
  chooseCustomTagOrCreate: '기존 태그를 사용 또는 새로운 태그 만들기',
  downloadDetails: '다운로드 정보',
  image: 'Image',
  downloadType: '다운로드 유형',
  groupName: '그룹 이름',
  size: '사이즈',
  dateDownloaded: '다운로드 일자',
  reLicense: '라이선스 재취득',
  customFields: '커스텀 필드',
  saveAndClose: '저장 후 닫기',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: '일자',
  export: '추출하기',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: '무료 콘텐츠',
  searchById: 'ID로 찾기',
  pleaseSelect: '선택하세요',
  totalOutstanding: '총 잔여 크레딧',
  viewInvoices: '인보이스 보기',
  // Image detail page - select download size sidebar
  standardSizes: '표준 사이즈',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: '자르기',
  // empty data
  opsNothingToSeeHere: '앗, 아무 것도 없습니다.',
  // invoice UNPL
  draft: 'draft',
  paid: 'paid',
  // search image
  authentic: '실사',
  // search audio
  mostDownloaded: '다운로드 순',
  download: '다운로드',
  // audio category
  soulAndRB: '알앤비',
  corporate: '기업',
  electronica: '일렉트로니카',
  classical: '고전',
  funkAndGrooveMusic: '펑크&그루브',
  worldBeat: '월드비트',
  cinematic: '영화',
  jazz: '재즈',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: '다음 페이지',
  reverseImageSearch: '이미지 검색',

  group: '그룹',
  needHelp: '도움이 필요하신가요?',
  copied: '복사 완료',
  copyPortfolioLinkSuccess: '기고자 포트폴리오 링크가 클립 보드에 복사되었습니다.',

  // my activities
  myActivity: '최근 본 콘텐츠',
  recentLike: '최근 좋아요 콘텐츠',
  recentView: '최근 조회한 콘텐츠',
  myActivityViewDesc: '최근 3개월의 내역만을 표시합니다.',
  myActivityLikeDesc: '최근 3개월의 내역을 표시합니다.',

  // dashboard
  profile: '프로필',

  // Pricing
  pricingCreditText: '크레딧',

  // Dashboard - Credit Usage
  user: 'User',
  creditUsage: 'Credit usage',
  searchPlaceholder: 'Search name, username, role, group',
  all: '전체',
  active: 'Active',
  deleted: 'Deleted',
  reset: '초기화',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: 'AI 검색',
  aiPoweredSearchOn: 'AI 검색 시작',
  aiSearchIntroduction: '무한 스크롤은 그만! 보다 정밀해진 AI 검색을 통해 작업시간을 단축하세요.',
  ok: '시작',
  // Filter bar ai
  bstract: '추상적',
  macroCloseUp: '접사 / 클로즈업',
  object: '오브젝트',
  watercolor: '수채화',
  flat: '플랫',
  cartoon: '만화',
  geometric: '기하학',
  gradient: '그라데이션',
  isometric: '아이소메트릭',
  '3d': '3D',
  handDrawn: '손그림',
  natural: '자연스러운',
  warm: '따뜻한',
  dramatic: '드라마틱',
  vividAndBold: '비비드 & 볼드',
  blackAndWhite: '블랙 & 화이트',
  // introduce Free image research
  imageResearchContentTitle:
    '123RF의 이미지 큐레이션 서비스 론칭 : 무료로 검색시간을 획기적으로 단축하세요.',
  imageResearchContentDesc:
    '기업 고객을 위해 고도화된 이미지 큐레이션 서비스를 시작합니다. 디자인 작업에 집중할 수 있도록 이미지 검색 단계는 123RF에게 의뢰하세요.',
  submitRequest: '요청하기',
  imageResearchContentInfoTitle1: '이미지 검색을 이제 의뢰하세요',
  imageResearchContentInfoDesc1:
    '최대한 상세하게 원하는 스타일, 분위기, 주요 색상 및 원하는 요소 등을 설명해주세요. 묘사가 자세할수록 디테일한 큐레이션이 제공됩니다.',
  imageResearchContentInfoTitle2: '큐레이션 진행방식',
  imageResearchContentInfoTitle3: '빠르고 정확한 서비스',
  imageResearchContentInfoTitle4: '이미지검색 비서',
  imageResearchContentInfoDesc4:
    '여러분은 디자인 작업에 집중하세요. 필요한 고퀄리티 디자인소스를 검색하는 업무는 맡겨 주세요.',
  requestForm: '요청 양식',
  whatDoYouWantThisImageFor: '이미지 사용 용도를 알려주세요.',
  websiteBanner: '웹사이트 배너',
  socialMedia: 'SNS 포스트',
  blogPost: '블로그 게시물',
  advertisement: '광고',
  print: '인쇄',
  others: '그 외',
  othersOutputPlaceholder: '그 외 사용 용도를 입력하세요.',
  whatIsPrimaryMessage: '이미지가 전달하고자 하는 주요 메시지 또는 주제는 알려주세요.',
  whatIsPrimaryMessagePlaceholder: '예: 공원에서 여자와 함께 달리는 털이 복슬한 강아지',
  areThereSubjectYouWantInThePicture: '이미지에 꼭 포함되어야 할 요소를 알려주세요.',
  egPeopleNatureTechnologyAbstract: '예: 사람, 자연, 기술, 추상적 아트',
  areThereImageOrStyleReference:
    '참고 가능한 123RF 이미지, 스타일 또는 기타 참고 레퍼런스 자료가 있나요?',
  ifPeopleIncludeWhatTheirDemographic: '인물 이미지의 경우 포함되어야 할 요소는 무엇인가요?',
  egAgeGenderEthnicityOccupation: '예: 나이, 성별, 인종, 직업',
  areThereAnyThingToAvoid: '이미지에서 포함되면 안되는 요소가 있나요?',
  whoisYourTargetAudience: '목표하는 타깃 고객을 알려주세요.',
  whoisYourTargetAudiencePlaceholder: '예: 독서를 좋아하는 청년',
  typeOfImage: '이미지 유형',
  whatMoodShouldImageEvoke: '담고 싶은 무드, 감정이 있나요?',
  happy: '행복',
  sad: '슬픔',
  calm: '평온',
  excited: '흥분',
  angry: '분노',
  enterCustomMood: '원하는 분위기를 입력해주세요.',
  areThereAnyOrientationForImage: '원하는 이미지 방향을 알려주세요.',
  enterCustomOrientation: '사용자 지정 입력',
  isThereLocationYouWantImageToBeIn: '꼭 들어가야 할 장소, 위치가 있나요?',
  no: '아니요',
  yes: '네',
  shareWithUsAReference: '레퍼런스 파일을 첨부해주세요.(JPG 또는 PDF / 최대 4MB)',
  replace: '변경하기',
  areThereAnySpecificRequirements: '다른 특정 요구 사항이 있나요?',
  enterOtherComments: '의견을 입력하세요.',
  submit: '제출하기',
  fieldCannotEmpty: '이 항목은 비워 둘 수 없습니다.',
  selectAtLeastOne: '하나 이상을 선택해 주세요.',
  fileMustBeJpgOrPdf: '파일은 JPG 또는 PDF형식을 지원합니다.',
  fileSizeLessThan4MB: '파일 크기는 4MB 미만이어야 합니다.',
  maximumFileNameAllow255: '허용되는 최대 글자 수는 255자입니다.',
  updateSuccessfully: '큐레이션 요청이 접수되었습니다.',
  freeImageResearch: '무료 이미지 큐레이션',
  newService: '새로운 서비스!',
  freeImageResearchIntroduction:
    '123RF의 이미지 큐레이션 서비스를 지금 바로 이용하여 디자인 절차의 효율을 높여보세요.',
  later: '나중에',
  letTryIt: '시작하기',
  viewPdfOfSampleOutput: '샘플 결과물 PDF 확인',
  imageResearchContentInfoDesc2: (
    <>
      AI 기술과 전문 큐레이터가 함께 여러분이 필요로 하는 이미지를 리스트업하여 제공합니다. 3억 컷의
      방대한 라이브러리에서 더 이상의 방황은 없도록 이미지 큐레이션 서비스를 바로 이용해보세요. AI
      검색에 능한 전문 큐레이터가 빠르게 여러분이 필요로 하는 디자인소스를 찾아드리겠습니다.
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      영업일 기준
      {' '}
      <strong>2일 이내</strong>
      {' '}
      큐레이션 링크를 제공하며, 이미지 선택에 대한 기준과
      설명을 같이 보내드립니다.
    </>
  ),
  characterMaxChar255: '허용되는 최대 글자 수는 255자입니다.',

  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: '태그를 입력하세요',

  relatedSearch: 'Related searches',
  showMore: '더보기',
  showLess: '간단히 표시',

  requiredWithVariable: '{{var}} 입력하세요',
  mood: '분위기',
  person: '인',
  doNotIncludeWords_ex2: '예: 녹색, 생머리',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: 'AI 이미지 생성기',
  imageGenerator: '이미지 생성기',
  aiImageGeneratorDesc: '상상하는 속도 그대로 이미지를 제작합니다.',
  generate: '생성하기',
  exploreVisualsCreatedWithThePowerOfAI: 'AI 기술로 생성된 콘텐츠를 둘러보세요',
  turnYourImaginationIntoRealityIn3SimpleSteps: '상상을 현실로 만드는 심플한 3단계',
  startWithGoodTextPrompt: (
    <>
      이미지의 묘사와
      <br />
      <span>함께 시작</span>
    </>
  ),
  startWithGoodTextPromptDesc:
    '상세한 묘사로 최고의 이미지를 만나보세요. 원하는 느낌의 특징을 담아 키워드의 개념으로 설명을 해주세요. ',
  reviewYourAIGeneratedImages: (
    <>
      생성된 AI
      {' '}
      <span>
        이미지를
        <br />
        검토하세요
      </span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    'AI 생성기가 입력하신 내용과 가장 유사한 3개의 미리보기 이미지를 생성하여 표시합니다.',
  downloadAndLicenseVisuals: (
    <>
      이미지를 다운로드 및
      <br />
      <span>라이선싱하세요</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    '최대 $25,000 콘텐츠 보증을 약속하는 123RF에서 안전하게 개인용/상업용으로 생성된 이미지를 다운로드하세요.',
  needSomeIdeas: '아이디어가 필요하신가요? 이 샘플에서 시작하여 사용의 도움을 받아보세요!',
  tryPrompt: '시작하기',
  creativityAtTheSpeedOfYourImagination: '상상력을 마음껏 발휘해보세요.',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    'Corporate+ AI 이미지 생성기를 통해  몇 초만에 텍스트를 놀라운 이미지로 변환하세요!',
  tryNow: '바로 시작',

  /// Random prompt
  prompt1: '자신감 넘치면서도 친근한 28세 한국 남성 가수를 묘사합니다. 매혹적인 무대 매너를 지닌 그를 표현력 있는 초상화를 통해 보여주세요. 역동적인 가창력과 마력적인 매력을 강조하여 그의 눈에 띄는 특징을 부각합니다. 그의 풍성한 검은 머리카락과 강렬하고 감정적인 눈을 생생하게 묘사하여 예술적 열정의 깊이를 전달하세요. 음악적 개성을 반영하는 세련되고 현대적인 의상을 입히되, 약간의 반항적인 요소나 세련됨을 더할 수 있습니다. 라이브 공연이나 녹음실을 연상시키는 배경에 그를 배치하여 그의 직업의 에너지와 흥분을 포착합니다. 극적인 조명과 구도를 사용하여 실제보다 더 큰 웅장한 느낌을 만들고, 떠오르는 스타로서의 그의 지위를 강조합니다. 마치 매혹적인 노래를 부르는 중인 것처럼 작품에 움직임과 역동성을 불어넣으세요. 보는 사람의 시선을 사로잡고 가수의 부인할 수 없는 무대 매너와 가창력을 보여주는 이미지를 만드는 것을 목표로 합니다.',
  prompt2: '아프리카 사바나의 광활한 배경 속 바위에 누워있는 위엄 있는 사자의 사진처럼 사실적인 이미지입니다. 배경을 흐릿하게 하고 사자에게 선명하게 초점을 맞추기 위해 F 2.8 조리개 설정으로 400mm 렌즈를 사용합니다. 조명은 자연스럽고 따뜻해야 하며, 일몰의 황금빛 색조를 포착합니다. 야생 동물의 아름다움과 위엄을 전달하는 생생하고 상세한 이미지를 만들기 위해 포토리얼리즘을 강조합니다.',
  prompt3: '네온 불빛과 홀로그램이 있는 사이버펑크 도시를 배경으로 빛나는 카타나를 휘두르는 액션 포즈의 미래적인 사무라이를 묘사한 혼합 매체 작품입니다. 시각적으로 풍부하고 역동적인 장면을 만들기 위해 금속 및 디지털 요소를 포함한 다양한 질감과 재료를 통합합니다.',
  prompt4: '흐르는 드레스를 입은 어린 공주가 마법의 생물들과 함께 마법의 숲에서 노는 밝은 디지털 일러스트레이션입니다. 생생하고 기발한 배경을 배경으로 다채로운 꽃, 나비, 반짝이는 요정 먼지로 그녀를 둘러쌉니다.',
  prompt5: '낡은 얼굴과 단호한 표정을 한 고대 전사가 칼을 든 세밀한 연필 그림입니다. 전사의 갑옷과 전투 상처를 강조하기 위해 정교한 음영과 질감을 사용하여 시대를 초월하고 강력한 이미지를 만듭니다.',
  prompt6: '시간 여행 탐험가가 다양한 역사 시대를 항해하는 초현실적인 혼합 매체 콜라주로, 빈티지 지도, 고대 유물 및 미래형 기기와 같은 요소를 사용합니다. 오래된 신문 조각, 사진 및 디지털 아트를 결합하여 유머러스하고 생각을 자극하는 작품을 만드세요.',
  prompt7: '우뚝 솟은 마천루, 하늘을 나는 자동차, 어둡고 불길한 구름이 있는 디스토피아 도시 풍경의 상세한 디지털 일러스트레이션입니다. 건축물의 복잡한 디테일과 극적인 조명을 사용하여 깊이와 규모감을 조성하고 분위기를 강화하세요.',
  prompt8: '현명한 노인이 마법 지팡이를 들고 있는 초상화로, 극적인 명암 대비 조명을 사용하여 노인의 얼굴 특징과 지팡이의 빛나는 룬을 강조합니다. 풍부한 질감과 그림자를 사용하여 신비로움과 지혜를 표현하세요.',
  prompt9: '갑옷을 입은 기수와 넓게 펼쳐진 용의 날개를 가진 용 기수가 하늘을 나는 활기찬 판타지 일러스트레이션입니다. 구름과 먼 산으로 둘러싸여 모험과 위엄을 더하세요.',
  prompt10: '아시아 도시의 번화한 시장 장면을 담은  candid 흑백 거리 사진으로, 상인들이 다채로운 과일과 채소를 판매하고 있습니다. 얕은 피사계 심도를 사용하여 상인의 표정과 상호 작용에 초점을 맞추고 활기찬 분위기를 포착하세요.',
  prompt11: '무지개 갈기를 가진 유니콘이 빛나는 버섯과 반짝이는 반딧불이로 둘러싸인 마법의 숲에 서 있는 기발한 수채화 그림입니다. 부드러운 파스텔 색상과 유려한 붓놀림을 사용하여 몽환적이고 매혹적인 장면을 연출하십시오.',
  prompt12: '매끄럽고 미니멀한 디자인, 스테인리스 스틸 가전제품, 그리고 중앙에 큰 아일랜드가 있는 현대식 주방의 3D 렌더링입니다. 사진처럼 사실적인 질감과 조명을 사용하여 깨끗한 선과 현대적인 스타일을 강조하고, 반사와 재료에 중점을 두십시오.',
  prompt13: '가장자리에서 폭포가 쏟아지고 무성한 초목이 표면을 덮고 있는 하늘에 떠 있는 섬의 판타지 풍경화입니다. 섬 주위에 솜털 같은 구름과 선명한 파란 하늘을 만들어 경이로움과 마법 같은 느낌을 연출하십시오.',
  prompt14: '땋은 머리와 사나운 표정을 한 바이킹 전사의 세밀한 초상화로, 전투 도끼를 들고 있습니다. 배경에는 폭풍우 치는 바다와 험준한 절벽을 묘사하고, 극적인 조명을 사용하여 전사의 특징과 갑옷을 강조하십시오.',
  prompt15: '톱니바퀴, 시계 장치, 증기 기관 장치로 가득 찬 어수선한 작업장에 있는 스팀펑크 발명가의 초현실적인 혼합 매체 콜라주입니다. 빈티지 사진, 오래된 청사진, 금속 질감을 사용하여 시각적으로 풍부하고 상상력이 풍부한 장면을 연출하십시오.',
  prompt16: '밤의 미래 도시 풍경을 디지털 일러스트로 표현합니다. 우뚝 솟은 마천루, 네온 사인, 그리고 비행하는 차량들을 담아 생생한 색감과 역동적인 조명을 사용하여 활기차고 몰입감 있는 분위기를 조성하고, 도시의 기술 발전과 활기찬 에너지를 강조합니다.',
  prompt17: '편안한 안락의자, 램프가 놓인 작은 테이블, 그리고 책으로 가득 찬 선반이 있는 아늑한 독서 공간을 사실적인 3D 렌더링으로 표현합니다. 따뜻하고 편안한 조명과 부드러운 질감을 사용하여 공간의 평온하고 편안한 분위기를 포착합니다.',
  prompt18: '투명한 유리 같은 소재의 패션 핸드백에 수족관이 완벽하게 설치된 디자인을 묘사합니다. 핸드백 안에서 우아하게 헤엄치는 물고기를 정교하게 표현하여 매혹적이고 독특한 액세서리를 만듭니다. 수족관이 핸드백 구조에 완벽하게 통합되어 두 요소 간의 매끄러운 전환을 보여줍니다. 고급 백화점 매장에 핸드백을 눈에 띄게 배치하고, 세련된 현대적인 선반, 조명 및 기타 고급 소매 진열대를 배경으로 합니다. 극적인 직접 조명을 사용하여 드라마틱한 느낌을 연출하고 시선을 수중 핸드백에 집중시킵니다. 투명하고 거의 초현실적인 품질을 보여주는 각도에서 핸드백을 촬영하여 물고기를 선명하게 보여주고 무중력 또는 유동성의 인상을 줍니다.',
  prompt19: '갈색과 올리브 그린 색조의 부드러운 색상으로 된 플러시 안락의자를 특징으로 하는 아늑하고 매력적인 장면을 포착합니다. 부드러운 섬유, 고급 목재 악센트, 그리고 평화로운 자연 풍경이 내다보이는 벽난로나 창문과 같은 편안함과 휴식을 불러일으키는 세부 사항으로 둘러싸인 따뜻하고 친밀한 분위기에서 의자를 선보입니다. 섬유의 고급스럽고 질감 있는 품질과 전반적인 아늑함과 휴식의 느낌을 강조합니다.',
  prompt20: '이끼 낀 나뭇가지 위에 앉아 있는 멋진 깃털을 가진 볏닭을 생생하고 활기차게 묘사합니다. 새의 멋진 깃털을 보완하는 푸르른 에메랄드색 배경을 사용합니다. 섬세한 명암의 미묘한 그라데이션을 사용하여 볏닭의 복잡한 깃털을 정교하게 표현하고, 볏닭의 풍부하고 어두운 녹색 볏, 따뜻한 갈색 부리, 그리고 밝고 호기심 많은 검은 눈을 강조하여 새의 자연스러운 아름다움을 보여주는 매력적인 초상화를 만듭니다. 전반적인 장면에 따뜻하고 평온한 분위기를 불어넣어 보는 사람이 평온한 자연 환경 속으로 들어가도록 초대합니다.',
  prompt21: '크리미한 프라젠 요거트 볼에 신선한 과일과 바삭한 견과류를 풍성하게 올린 시각적으로 매력적인 이미지를 디자인하십시오. 부드럽고 밝은 색상의 요거트와 과일 토핑의 선명한 색상 대비를 보여주면서 이 건강한 디저트의 상쾌하고 영양가 있는 특징을 포착하십시오. 재료를 예술적이고 식욕을 돋우는 방식으로 배열하여 보는 사람이 시각적 매력을 감상하고 상쾌하고 풍성한 경험을 예상할 수 있도록 하십시오. 전체 이미지에서 건강함과 즐거움을 느끼게 하여 보는 사람이 이 맛있는 간식의 즐겁고 영양가 있는 특징을 음미하도록 유도하십시오.',
  prompt22: '생생한 색상과 정교한 디테일로 가득 찬 초현실적인 꽃 정원을 탐험하며 시각적인 향연에 흠뻑 빠져보세요. 곱슬거리고 나선형의 꽃잎, 얼룩덜룩한 잎, 그리고 다른 매혹적인 색조를 가진 꽃들의 매력적인 본질을 포착하십시오. 각각은 그 자체로 하나의 예술 작품입니다. 수채화의 유동성과 잉크의 표현력을 활용하여 젖은 상태에서 젖은 상태의 기법을 사용하여 움직임과 깊이감을 표현하십시오. 풍부하고 채도 높은 색상의 상호 작용과 모든 꽃잎과 잎의 섬세하고 정교한 묘사를 통해 이 꽃이 만발한 이상향의 초현실적이고 몽환적인 특징을 전달하십시오.',
  prompt23: '고대 등대가 바위섬 위에 우뚝 서 있는 매혹적인 야경 일러스트를 제작하십시오. 여러 개의 창문으로 장식된 등대는 따뜻하고 친근한 빛을 발하며, 정교한 건축과 풍부한 역사를 강조합니다. 바위섬은 무성한 푸른 나무로 덮여 있으며, 그 반사는 고요한 달빛 바닷물 위에서 춤을 추며 보름달의 은은한 광채를 비춥니다. 천체는 하늘을 장식하며 구름과 바다에 신비로운 광채를 드리우고, 희미한 구름은 부분적으로 그 광채를 가려 장면의 신비로움을 더합니다. 반짝이는 별들이 먹물처럼 어두운 공간에 점점이 박혀 있으며, 달의 광채와 조화를 이루는 천상의 태피스트리를 만듭니다. 이 매혹적인 작품은 조명된 등대가 천상의 아름다움 속에서 굳건히 서서 보초를 서고 보는 사람에게 영화적이고 몽환적인 경험을 선사하는 고요하고 평화로운 밤을 포착합니다.',

  /// Tool list
  // ratio
  ratio: '비율',
  square1_1: '정사각형 (1:1)',
  square: '정사각형',
  portrait2_3: '세로 (2:3)',
  portrait: '세로',
  landscape3_2: '가로 (3:2)',
  wide16_9: '와이드 (16:9)',
  wide: '와이드 (16:9)',
  // style
  none: '없음',
  enhance: '향상',
  anime: '애니메이션',
  photographic: '사진',
  digitalArt: '디지털 아트',
  comicBook: '만화',
  fantasyArt: '판타지 아트',
  analogFilm: '아날로그',
  neonPunk: '네온 펑크',
  lowPoly: '로우 폴리아트',
  origami: '페이퍼 아트',
  lineArt: '라인 아트',
  tileTexture: '타일 텍스처',
  threeDModel: '3D 모델',
  pixelArt: '픽셀 아트',
  craftClay: '클레이아트',
  // color
  coolTone: '차가운 톤',
  mutedColors: '약한 색상',
  pastelColors: '파스텔 색상',
  vibrantColors: '생생한 색상',
  warmTone: '따뜻한 톤',
  // lighting
  lighting: '조명',
  backlight: '백라이트',
  crepuscularRays: '석양',
  dimlyLit: '희미한 조명',
  goldenHour: '일몰',
  lowLight: '저조도',
  rimLighting: '테두리 조명',
  studio: '스튜디오',
  sunlight: '햇빛',
  volumetric: '볼류메트릭',
  // composition
  composition: '컴포지션',
  blurryBackground: '흐릿한 배경',
  closeUp: '클로즈업',
  macrophotograph: '접사 촬영',
  narrowDepth: '초점',
  shotFromAbove: '위에서 촬영',
  shotFromBelow: '아래에서 촬영',
  wideAngle: '광각',

  /// FQAs
  allAIImageQuestionsAnswered: 'AI 생성 이미지 자주 묻는 질문',
  TTI_Q1: 'AI 생성 이미지는 무엇인가요?',
  TTI_A1:
    'AI 생성 이미지는 인공 지능(AI) 알고리즘을 사용하여 만든 이미지입니다. 이러한 알고리즘은 인간의 창의력과 독창성을 배우고 실현할 수 있도록 설계되었으며, 현업의 아티스트가 제작한 이미지와 유사하면서도 독특하고, 종종  환상적이거나 추상적인 요소가 포함된 이미지를 생성할 수 있습니다.',
  TTI_Q2: '하루에 생성할 수 있는 이미지 수량에 제한이 있나요?',
  TTI_A2:
    '네, 매일 생성할 수 있는 이미지 수량에 제한이 있습니다. AI 이미지 생성 서비스는 좋은 사용자 경험을 할 수 있도록 로그인을 필요로 하며 트래픽 과부하 방지를 위해 생성 수량에 제한을 둡니다.',
  TTI_Q3: 'Do you support text prompts in languages other than English?',
  TTI_A3: 'We support only the English language at the moment.',
  TTI_Q4: '이미지 생성과 라이선싱의 차이는 무엇인가요?',
  TTI_A4:
    '이미지를 생성 시 선택 가능한 AI 이미지 세트가 생성됩니다. Corporate+에서 라이선스를 취득 시 자체 사용 라이선스와 함께 고해상도 버전을 다운로드할 수 있는 권한이 부여됩니다.',
  TTI_Q5:
    '생성된 이미지에 알아볼 수 있는 특정 로고(상표), 랜드마크 또는 유명 인물이 포함되어 있습니다. 그래도 사용할 수 있나요?',
  TTI_A5:
    '이미지에 식별 가능한 사람이나 사물이 포함되어 있거나 주로 독특한 랜드마크 등이 콘텐츠의 메인 주제가 된다면 상업 사용 시 이미지에 묘사된 주체로부터 직접 허가를 받아야 합니다.',
  TTI_Q6: '생성된 이미지에 등장하는 인물은 누구인가요?',
  TTI_A6:
    'AI가 생성한 이미지에 등장하는 대부분의 인물은 실제 사람이 아니라 가상의 인물입니다. 하지만 AI 프로그램의 특성상 데이터를 기반으로 얻은 식별 가능한 특정 인물 또는 사물이 포함될 수 있습니다.',
  TTI_Q7: '생성한 콘텐츠는 어떻게 되나요? 다른 사용자가 사용할 수 있나요?',
  TTI_A7:
    '생성한 콘텐츠는 다른 사용자에게도 제공될 수 있습니다. 자세한 내용은 라이선스 규정을 확인해주세요!',
  TTI_Q8: '이미지 생성 시 시간이 오래 걸리는 이유는 무엇인가요?',
  TTI_A8:
    '페이지에서 이미지를 생성하는 데 평균 10초 내외로 소요됩니다. 하지만 묘사의 정확성, 하드웨어의 사양, AI가 동시에 처리해야 하는 요청의 양에 따라 달라질 수 있습니다.',
  TTI_Q9: '생성한 AI 이미지에 라이선스를 부여하려면 어떻게 해야 하나요?',
  TTI_A9:
    '이용자가 생성한 AI 이미지는 Corporate+ 계정에서 다운로드하여 라이선스를 부여할 수 있습니다. 다운로드할 때마다 생성된 이미지의 고해상도 버전과 25,000달러의 법적 보증을 받을 수 있습니다.',
  TTI_Q10: '이전에 생성했던 이미지를 다시 확인할 수 있나요?',
  TTI_A10:
    '가능합니다. AI로 생성된 결과 페이지의 URL링크를 북마크로 추가하거나 또는 개별적으로 저장해 주세요. 해당 링크는 타인에게도 공유 가능합니다.',
  TTI_Q11: 'AI를 사용하여 고품질 이미지를 생성하려면 어떻게 해야 하나요?',
  TTI_A11: `<ol>
              <li>"여행 블로그에 올릴 고품질 마운틴 뷰 이미지" 등 사용 목적을 상세히 해주세요.</li>
              <li>원하는 스타일과 분위기를 지정합니다 예시 : "밝고 생동감 있으며, 자연스러운 이미지"</li>
              <li>주제를 설정합니다: 배경, 조명 및 기타 주요 특징이나 요소와 같은 세부 사항을 포함하여 이미지의 주제를 구체적으로 설명해 주세요. 예시 : "구불구불한 강과 맑고 푸른 하늘이 있는 눈 덮인 산봉우리"</li>
              <li>예시를 제공하세요: 찾고 있는 이미지와 비슷한 스타일이나 분위기를 가진 이미지를 올려주세요. AI가 제공된 예시를 인식하여 유사한 이미지를 생성합니다.</li>
              <li>관련 키워드를 입력합니다 : "산 풍경", "눈 덮인 봉우리" 또는 "밝고 활기찬" 등의 키워드를 사용합니다.</li>
              <li>모호한 표현을 피해주세요 : 설명을 최대한 명확하고 구체적으로 작성하고, 추상적이거나 모호한 표현을 지양해 주세요.</li>
            </ol>`,

  /// Generating page
  bringingYourImagesToLife: '이미지에 생명 불어넣는 중...',
  cplusAiGenerator: 'AI이미지 생성기',
  generatedImages: '생성된 이미지',
  promptSettings: '프롬프트 설정',
  pastGeneration: '이전 버전',
  generateVariation: '변형 생성',
  iLikeThis: '이 이미지가 마음에 듭니다.',
  iDislikeThis: '마음에 들지 않습니다.',
  shareOptions: '공유 옵션',
  variations: '변형',
  copiedSharedUrl: '공유 URL을 클립보드에 복사',

  galleryPrompt1: '천상의 인어, 바닷속, 물고기로 둘러싸인 조개왕관, 초상화',
  galleryPrompt2: '음식과 촛불로 가득찬 식탁위에 푸짐한 선데이로스트',
  galleryPrompt3: '숲속에서 웃고 있는 귀여운 여우, 다른 숲속 동물들과 함께 축소되어 섬세하게 묘사된 모습',
  galleryPrompt4: '고품질, 8K 울트라 HD, 크리스탈 호박 안에 악기와 음표 모양. 높은 디테일, 마법의 호박 램프, 가을 느낌, 차분함',
  galleryPrompt5: '스코틀랜드의 파노라마 영화 같은 풍경, 밝고 아름다운 날',
  galleryPrompt6: '3D 스타일 소녀, 4K, 8K 해상도, 옥탄 렌더링, 포토리얼리스틱, HDR, 사진, 고해상도, 얼굴 대칭, 입체적 조명, 먼지로 덮인 안개, 사진, 옥탄 렌더, 24mm 렌즈, 4K, 24mm, DSLR, 고품질, 60 프레임 초고화질, 초 현실주의.',
  galleryPrompt7: '갈색 피부의 남자, 우주에서 목성을 펀치하는 영웅, 정면을 향한',
  galleryPrompt8: '따뜻한 커피안에 담긴 쿠키, 크리스마스 분위기',
  galleryPrompt9: '하얀 미니멀리스트 배경에 몬세라 잎을 곁들인 예술적인 물병',
  galleryPrompt10: '사라져가는 다이아몬드 빛깔의 환상적인 용',
  galleryPrompt11: '이슬 속의 행성들, 행성 이슬 방울, 일출, 클로즈업, 초고해상도, 선명한 초점, 스튜디오 사진, 정교한 디테일, 매우 자세한',
  galleryPrompt12: '황금 상자에 앉아있는 까치, 사실적인 판타지 삽화 스타일, 보석, 어두운 동화 분위기, 신비한',
  galleryPrompt13: '눈 덮인 산과 산꼭대기와 이어지는 빙판길이 반대 방향으로 펼쳐지는, 초고화질, 사실적인, 다채로움, 매우 정교한, UHD 드로잉, 펜과 잉크, 완벽한 구도, 아름다움과 정교함이 깃든 복잡한 상세 렌더링, 아트스테이션 유행하는 8K 예술적인 사진, 초현실 컨셉 아트, 부드럽고 자연스러운 볼륨감, 영화 같은 완벽한 빛',
  galleryPrompt14: '우주문명 시대, 신비로운 디지털 공간과 슈퍼 공상과학, 외계 도시 건물들을 내려다보며 우뚝 서 있는, 정교하고 아름다운 초고층 빌딩, 거리, 스카이 브릿지, 하늘에 떠다니는 공상과학 건축물, 전쟁 무기, 첨단 기술, 수 많은 전자 화면 불빛, 공상 과학소설 대축제 공간, 과학소설, 미스터리, 공상과학, 초현실적, 매우 선명한 사진, HD 픽셀, 울트라HD, 5D, 8K 해상도, 완벽한 픽셀, 완벽한 초미세 디테일, 선명한 초점, 스튜디오 사진, 정교한 디테일, 매우 자세한',
  galleryPrompt15: '밤에 플로리다 키스의 아름다운 해변, 바다가 배경으로 펼쳐지며, 네온색 빛깔, 검은 배경, 고요한 파도, 자연스러운 색조, 일몰, 평온함, 편안한 파도 속으로 확대되는 풍경, 해변, 직선으로 펼쳐진 수평선, 핑크, 파랑, 주황, 보라빛.',
  galleryPrompt16: '최상의 품질, 이중 노출, 메일 아트, 갈라진 종이 위에 예술, 아늑한 방 안에서 양털 공을 가지고 노는 예쁜 새끼고양이 클로즈업,  반짝이는, 빛나는 햇살, 햇빛, 역동적인 포즈, 동화책 일러스트, 2D, 평면적인, 귀여운, 사랑스러운, 빈티지, 동화같은, 패치워크, 스테인드 글라스, 동화책 세부 일러스트레이션, 영화적인, 초 고도의 디테일, 작은 세부사항까지 정교한 디테일, 아름다운 디테일, 신비로운, 화려한 색감, 복잡한 배경',
  galleryPrompt17: '불 , 산, 어두운 초현실주의, 분위기있는 초상화, 물 위의 피라미드 , 선명함',
  galleryPrompt18: '숲 속의 붉은 팬더, 영화 느낌',
  galleryPrompt19: '바다, 달, 물고기, 별이 빛나는 하늘, 보트, 화려한 분위기',
  galleryPrompt20:
    '화려한도시와 건물들, 추상적으로 표현된 활기찬 저녁,기하학적인 부분과 네온 조명 및 아크릴 퓨어링 방법으로 표현된 현대 팝아트 스타일.',
  galleryPrompt21:
    '빛나는 검을 든 여성의 뒷모습 실루엣, 북극 설산으로 이어지는 포탈 , 시네마틱 , 영웅적인 분위기',

  /// error page
  copyrightDetected: '초상권 및 저작권이 등록된 인물 또는 상표가 감지되었습니다.',
  copyrightDetectedDesc:
    '123RF는 책임있는 AI기술제공이 되도록 최선을 다하고 있으며, 타인의 권리를 침해하는 상황을 방지하기 위하여 상표,용어,브랜드 또는 특정 인격에 대한결과를 생성하지 않습니다. 설명을 다시 적어주세요.',
  errorMesTTI: '죄송합니다, 문제가 발생했습니다.',
  errorMesTTIDesc: '문제 해결을 위해 오류 내용을 자동 접수하였습니다. 잠시 후 다시 시도해 주세요.',
  tryAgain: '다시 시도',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',
  // download feature
  downloadQuotaFinished:
    '할당된 콘텐츠 다운로드 수량이 모두 소진되었습니다. 관리자에게 문의하여 주시기 바랍니다.',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: '키워드를 검색하여 멋진 이미지 를 찾아보세요.',
  searchBarPlaceholderAllImageTablet: '멋진 이미지 찾기.',
  searchBarPlaceholderAllImageMobile: '이미지 찾기.',
  searchBarPlaceholderPhotoDesktop: '키워드를 검색하여 멋진 사진 를 찾아보세요. ',
  searchBarPlaceholderPhotoTablet: '멋진 사진 찾기.',
  searchBarPlaceholderPhotoMobile: '사진 찾기.',
  searchBarPlaceholderVectorDesktop: '키워드를 검색하여 멋진 일러스트 를 찾아보세요.',
  searchBarPlaceholderVectorTablet: '멋진 일러스트 찾기.',
  searchBarPlaceholderVectorMobile: '일러스트 찾기.',
  searchBarPlaceholderFootageDesktop: '키워드를 검색하여 멋진 영상 를 찾아보세요.',
  searchBarPlaceholderFootageTablet: '멋진 영상 찾기.',
  searchBarPlaceholderFootageMobile: '영상 찾기.',
  searchBarPlaceholderAudioDesktop:
    '키워드를 검색하여 멋진 오디오 및 음원 파일 를 찾아보세요.',
  searchBarPlaceholderAudioTablet: '멋진 오디오 및 음원 파일 찾기.',
  searchBarPlaceholderAudioMobile: '오디오 및 음원 파일 찾기.',
  searchBarPlaceholderFreeDesktop: '키워드를 검색하여 멋진 무료이미지 를 찾아보세요.',
  searchBarPlaceholderFreeTablet: '멋진 무료이미지 찾기.',
  searchBarPlaceholderFreeMobile: '무료이미지 찾기.',
  // editorial warning
  forEditorialUseOnly: '에디토리얼 라이선스',
  forEditorialUseOnlyDesc:
    '초상권 및 재산권이 확보되지 않은 콘텐츠로 어떠한 경우에도 상업적 이용이 불가능합니다. 다만 사실을 전달하는 출판, 보도 용도로는 사용이 가능하며, 사용 시 잘라내기, 밝기 조정 등의 최소한의 편집만을 허용하고 있습니다. 또한 사용 시 예제(출처 예 : 저작권자명 ⓒ 123RF.COM)와 같은 출처를 반드시 표기하여 주시기 바랍니다.',

  // download comp
  downloadHiResComp: '고해상도 시안용 콘텐츠 다운로드',
  downloadComp: '미리보기 다운로드',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),

  textToImage: '텍스트를 이미지로',
  descTourTTI: '단순 텍스트를 입력하여 상상하던 이미지를 만들어보세요.',
  colorAndTone: '색상 및 톤',
  titleSuccessDownload: '필요한 이미지를 찾으셨네요. 축하해요!',
  hangInThere: '다운로드 준비 중, 잠시만 기다려주세요!',
  adultContentTitle: '잠재적인 성인 키워드 감지',
  adultContentDesc:
    '123RF는 AI기술을 통한 결과물이 윤리적이고 안전할 수 있도록, 특정 텍스트 또는 개념이 들어간 프롬프트는 처리하지 않습니다. 프롬프트를 다시 입력하세요.',
  reachedTheLimit: '앗, 오늘은 더 이상 AI 이미지 생성이 불가해요.',
  backTomorrow: '24시간 후에 다시 생성할 수 있어요. 내일 만나요!',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder: '시작하기 위해서는 상상하고 바라는 결과물을 텍스트로 입력하세요!',

  downloadAgain: 'Download again',
  btnClose: '닫기',

  fluxPro: 'Flux는 고급스럽고 고품질의 다양한 이미지 생성을 제공합니다.',
  flux: '현지 개발에 최적화된 가장 빠른 모델입니다.',
  ideogram: '텍스트 설명을 몇 초 만에 아름다운 이미지로 변환합니다.',
  realStockPhoto: '고급 AI 모델로 매력적인 스톡 사진을 손쉽게 제작하세요.',
  realCinematic: '와이드 화면 비율에서 뛰어난 영화적 결과물을 생성하는 데 특화된 모델입니다.',
  realFood: '음식 시각화 모델로 귀하의 브랜드를 강화하세요.',
  realMacro: '상세한 매크로 이미지를 생성하는 데 훌륭한 모델입니다.',
  realBokeh: '놀랍도록 생생한 보케 이미지를 만드는 데 적합한 포토리얼리스틱 스타일 모델입니다.',
  aiLicenseHeader: '이 파일은 인공지능 (AI)에 의해 생성되었습니다.',
  aiLicenseContent: '인공지능이 생성한 콘텐츠는 123RF의 표준 준수 검토 프로세스에 적용되지 않습니다. 생성자는 이러한 AI 생성 자료가 제3자의 지적 재산권이나 기타 권리를 침해하지 않도록 할 책임이 있습니다. 스팸 생성과 거짓, 오해의 소지가 있는, 유해한, 폭력적인, 음란한 또는 불법 자료의 생성은 엄격히 금지됩니다.',
  variousTitle: '다양한 모델 살펴보기',
  variousSDXL: '놀랍도록 사실적인 인물과 장면을 생생하게 표현해 보세요.',
  amazonNova: '최첨단 이미지 생성 모델입니다.',
  variousEimisAnime: '애니메이션 스타일의 이미지 생성에 특화된 AI 모델입니다.',
  variousDynaVisionXL: '3D 만화 캐릭터를 생성합니다.',
  variousJuggernautXL: '건축 요소와 풍경 및 배경을 중심으로 합니다.',
  variousDreamShaperXL: '고해상도 업스케일, 업그레이드 된 캐릭터 생성, 윤곽선 흐려짐 해소.',
  variousRealismEngineSDXL: '이 모델을 사용하면 섬세하고 생동감 넘치는 이미지를 만들 수 있습니다.',
  variousDALLE: 'DALL-E 3는 컨셉을 시각적이고 정밀하게 변환하는 데 탁월한 만능모델입니다.',
  aiEngine: 'AI 엔진',
  'DALL-E': 'DALL-E 3',

  langText: '텍스트',
  langElements: '소스',
  langAuto: '자동',
  langBgRemoval: '배경 제거',
  resetAll: '모두 초기화',
  facebookImage: '페이스북 이미지',
  facebookLink: '페이스북 링크',
  facebookCover: '페이스북 커버',
  facebookStory: '페이스북 스토리',
  instagramPost: '인스타그램 포스트',
  instagramStory: '인스타그램 스토리',
  twitterPost: '트위터 포스트',
  twitterHeader: '트위터 헤더',
  pinterestPost: '핀터레스트 포스트',
  emailHeader: '이메일 헤더',
  presentation: '프레젠테이션',
  presentationWide: '와이드 프레젠테이션',
  ebookCover: '전자책 표지',
  linkedinPost: '링크드인 포스트',
  brochureCover: '브로슈어 표지 (A4)',
  postcard: '엽서',
  photoPrint: '인쇄용 사진',
  youtubeProfileImage: '유튜브 프로필 사진',
  youtubeChannelCoverPhoto: '유튜브 채널아트',
  langApplyBgRemoval: '배경 제거하기',
  autoBnW: '자동 B&w',
  autoAdjust: '자동 조절',
  autoPop: '자동 팝',
  original: '오리지널',
  addHeader: '헤더 추가하기',
  addTitle: '제목 추가하기',
  addBody: '내용 텍스트 추가하기',
  addAssistive: '보조 텍스트 추가하기',
  langCompare: '비교',
  langEnableEditor: '이미지 편집',
  langSimilarStock: '유사한 스톡 이미지',
  langTextUndo: '실행 취소',
  langTextRedo: '재실행',
  langTextFont: '폰트',
  langTextFontSize: '폰트 크키',
  langTextColor: '텍스트 색상',
  langTextHighlight: '하이라이트',
  langTextAlignment: '정렬',
  langTextBold: '굵게',
  langTextItalic: '기울게',
  langTextUnderline: '밑줄',
  langTextLineSpacing: '줄 간격',
  langTextLetterSpacing: '글자 간격',
  langBringForward: '앞으로 가져오기',
  langBringBackward: '뒤로 보내기',
  langDuplicate: '복제',
  langOpacity: '투명도',
  langDelete: '삭제',
  imageProperties: '이미지 정보',
  noEditCompareMsg: '수정된 내용이 없어 전후 비교가 불가합니다.',
  stockPhoto: '스톡 콘텐츠',
  basicShape: '원형',
  chitChat: 'Chit Chat',
  shapes: '모양',
  elementChitChat: '소스의 모양',
  organicAbstractShapes: '추상적인 모양',
  elementShapes: '원형 소스',
  langApplyingEdit: '이미지 수정 중',
  langTextAllFonts: '전체 폰트',
  langSearchFontType: '폰트 검색하기',
  editorCompareImageBeforeTitle: '변경 전',
  editorCompareImageAfterTitle: '변경 후',
  langSizePx:
    '픽셀은 디지털 이미지의 기본 단위입니다. 픽셀 단위로 측정되는 이미지 크기는 세부 디테일 수준과 다운로드 크기를 결정합니다.',
  langSizeDpi:
    '인치당 도트는 인쇄 해상도를 측정합니다. dpi가 높을수록 디테일이 더 선명합니다. 인쇄 시 모든 이미지에 권장되는 해상도는 300dpi입니다.',
  cautionTTI:
    '주의: 프롬프트에서 상표, 저작권, 유명 랜드마크 및/또는 개인과 관련된 문구가 사용된 것을 감지했습니다.  콘텐츠가 관련 법률을 위반하거나 권리를 침해하지 않는지 여부를 포함하여 콘텐츠 사용에 대한 책임은 전적으로 사용자에게 있다는 점에 유의하세요.',

  addImageReference: '이미지 참조 추가(선택 사항)',
  uploadImageOnly: 'JPG 또는 PNG 형식으로만 업로드 할 수 있습니다. 최대 사이즈 4MB.',
  uploadJPGImageOnly: 'JPG 형식으로만 업로드 할 수 있습니다. 최대 사이즈 4MB.',
  dragAndDrop: '파일 끌어서 놓기 또는',
  errorFileSize: '파일이 너무 큽니다. 최대 4MB까지 허용됩니다.',
  adjustScale: '이 배율을 조정하여 업로드한 이미지와 매우 유사하거나 다른 결과를 생성할 수 있습니다. 권장 범위는 7~15입니다.',
  similar: '유사함',
  different: '다름',
  uploadNew: '새 항목 업로드',

  imageGuideGeneration: '생성하는 동안 이미지가 프롬프트의 안내가 됩니다.',
  notAvailableForEngine: '일부 AI 엔진에서는 사용할 수 없습니다.',

  imagePromptDescribe: 'AI 생성기로 생성하고 싶은, 마음속에 떠오르는 이미지를 묘사하여 원하시는 이미지를 찾아보세요.',
  fileTypeNotSp: '지원되지 않는 파일 형식입니다.',
  theFileSizeTooSmall: '파일 크기는 최소 512픽셀이상 부터 허용됩니다.',

  relatedSearchPhoto: '관련 이미지 검색결과',

  STOCK_PHOTO_PATH: '스톡사진',
  STOCK_FOOTAGE_PATH: '무비클립',
  STOCK_AUDIO_PATH: '음원',
  CLIPART_VECTOR_PATH: '일러스트',
  ALL_IMAGES: '모든 콘텐츠',
  PHOTO: 'Photo',
  PHOTOS: '사진',
  VECTORS: '일러스트',
  FOOTAGE: '무비클립',
  AUDIO: '음원',
  LANG_MORETHANMILLION: '컷의 평생 사용하는 사진·일러스트·영상·음원',
  LANG_SVG_OR_EPS: 'SVG 또는 EPS',
  LANG_OR: '또는',
  STANDARD_LICENSE: '스탠다드 라이선스',
  EXTENDED_LICENSE: '특별 라이선스',
  CREDIT_PACK: '크레딧팩',
  DOWNLOAD_PACK: '다운로드 팩',
  SUBSCRIPTION_PLAN: '정액제 서비스',
  LANG_DOWNLOAD: '다운로드',
  STOCKPHOTO: '스톡 콘텐츠',
  STOCK_PHOTO: '사진',
  STOCK_VECTOR: '일러스트',
  PREMIUM_STOCKPHOTO: 'PREMIUM 이미지',
  PLUS_STOCK_PHOTO: 'PLUS 사진·이미지',
  PLUS_STOCK_VECTOR: 'PLUS 벡터 일러스트',
  MOBILE_STOCKPHOTO: 'Mobile Stock Photo',
  STOCKFOOTAGE: '무비클립',
  IMAGEID: '콘텐츠 ID',
  FONTID: 'ID',
  MEDIATYPE: '콘텐츠 유형',
  COPYRIGHT_TEXT: '작가',
  KEYWORDS: '키워드',
  LANG_CANCEL: '취소',
  LANG_PURCHASE: '구매하기',
  LANG_STOCKVECTORSANDILLUS: '스톡사진과 이미지',
  LANG_STOCKPHOTOSANDIMAGES: '이미지, 사진',
  LANG_MATCHES: '',
  LANG_VECTORRESULTS: '{number}건의 {keyword} 평생 이용하는 벡터 일러스트를 확인하세요.',
  LANG_PHOTORESULTS: '{number}건의 {keyword} 평생 이용하는 사진·이미지를 확인하세요.',
  LANG_SIMILARIMGS: '유사 이미지',
  LANG_COLLECTIONS: '컬렉션',
  LANG_ALL: '전체',
  LANG_STD: '스탠다드',
  LANG_EXTD: '특별',
  LANG_COMMERCIAL: '상업적 사용',
  LANG_SUBSCRIPTION: '정액제',
  LANG_EDITORIAL: '에디토리얼',
  LANG_UPLOADED: '업로드 일자',
  LANG_FRESHNESS: '기간',
  LANG_ANYTIME: '모든 날짜',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: '2일 이내',
  LANG_PASTWEEK: '지난 주',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/1개월",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/1년",
  LANG_PASTMONTH: '지난 달',
  LANG_PAST3MONTHS: '3달 이내',
  LANG_NUMOFPPL: '사람 수',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: '인증 이메일을 받지 못하였나요?',
  LANG_WITHOUTPEOPLE: '없음',
  LANG_PERSON: '인',
  LANG_PEOPLE: '인',
  LANG_INFANTS: '유아',
  LANG_TODDLERS: '어린이',
  LANG_TEENS: '10대',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: '백인',
  LANG_BLACK: '흑인',
  LANG_HISPANIC: '히스패틱·라틴',
  LANG_EAST_ASIAN: '동아시아',
  LANG_SOUTHEAST_ASIAN: '동남아',
  LANG_INDIAN: '인도인',
  LANG_MIDDLE_EASTERN: '중동',
  LANG_AGE: '나이',
  LANG_ALL_SHAPE: '전체보기',
  LANG_ETHNICITY: '민족성',
  LANG_PEOPLEORMORE: '4인 이상',
  LANG_COLOR: '색상',
  LANG_OTHEROPTION: '기타 옵션',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: '단색/투명 백그라운드',
  LANG_FILTER_BY: '필터 기준',
  LANG_FILTER: '필터',
  LANG_PHOTOGRAPHY: '사진',
  LANG_VECTORILLUST: '벡터 일러스트',
  LANG_SORTBY: '정렬방법',
  LANG_MORE: '더 보기',
  LANG_RELEVANCE: '추천순',
  LANG_RESEND: '재발송',
  LANG_RESEND_VERIFICATION: '인증 이메일 재발송하기',
  LANG_NEWEST: '최신순',
  LANG_GEORANK: '국내 인기순',
  LANG_ORIENTATION: '모양',
  LANG_HORIZONTAL: '가로형',
  LANG_PORTRAIT: '세로형',
  LANG_SQUARE: '정사각형',
  LANG_PANORAMA: '파노라마',
  LANG_SELECTIVE_FOCUS: '아웃포커스',
  LANG_PATTERN: '패턴',
  LANG_VIBRANCE: '생동감',
  LANG_VERIFICATION_DESCRIPTION: '123RF 콘텐츠 다운로드 전 계정 인증이 필요합니다. 메일함에 도착한 인증메일을 확인 또는 아래에서 재발송을 클릭하세요.',
  LANG_VERIFICATION_TITLE: '이메일을 인증해주세요.',
  VERIFICATIONRESETUNABLE: '죄송합니다. 이메일을 보낼 수 없습니다. ',
  LANG_CHOOSEUPTO3COLORS: '최대 3개 선택 가능',
  LANG_ISOLATED: '배경없음',
  LANG_STYLE: '스타일',
  LANG_SAFESEARCH: '안전검색',
  LANG_ONLY_AUTHENTIC: '실사만',
  LANG_EXCLUDE_PLACEHOLDER: '예. 치즈',
  LANG_EXCLUDEWORDS: '제외 단어 입력',
  LANG_EXCLUDE: '제외',
  LANG_INCLUDE_AICONTENT: 'AI 생성 콘텐츠 포함',
  VISUAL_SEARCH: '이미지 상세 검색',
  VISUAL_SEARCH_TOOLTIP_TITLE: '간단한 방법으로 이미지 상세 검색을 시작해보세요!',
  VISUAL_SEARCH_TOOLTIP_INFO_1: '1. 이미지 내 상세 검색하고 싶으신 영역의 점을 클릭하세요.',
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2. 크롭 박스의 크기를 조절하여 상세 검색 영역 위치를 잡아주세요.',
  VISUAL_SEARCH_TOOLTIP_INFO_3: '3. 검색 이전 화면으로 돌아가려면  X 버튼을 클릭하세요.',
  VISUAL_SEARCH_NO_RESULTS: '검색 결과가 없습니다. ',
  LANG_RIS_TITLE: '이미지로 검색하기',
  LANG_RIS_NOTFOUND: '결과가 없습니다!',
  LANG_RIS_ERROR_HEADER: '이미지에 문제가 있습니다. 아래 조건에 부합한지 확인하세요. ',
  LANG_RIS_REFINE_SRCH_MSG: '키워드로 상세 검색',
  LANG_RIS_UPLOADED_IMAGE: '업로드된 이미지',
  LANG_TELL_ME_MORE: '자세히 읽기',
  LANG_ENTERKEYWORD: '검색어를 입력해 주세요.',
  LANG_SIZE: '사이즈',
  LANG_STANDARD_SIZE: '표준 사이즈',
  LANG_EXTENDED_SIZE: '확장된 사이즈',
  LANG_SIZE_PX: '픽셀은 디지털 이미지의 기본 단위입니다. 픽셀 단위로 측정되는 이미지 크기는 세부 디테일 수준과 다운로드 크기를 결정합니다.',
  LANG_SIZE_DPI: '인치당 도트는 인쇄 해상도를 측정합니다. dpi가 높을수록 디테일이 더 선명합니다. 인쇄 시 모든 이미지에 권장되는 해상도는 300dpi입니다.',
  LANG_SOCMED_SIZE: 'SNS 사이즈',
  LANG_COMPARE: '비교',
  LANG_AUTO_ENHANCE: '자동 보정',
  LANG_AUTO: '자동',
  LANG_BG_REMOVAL: '배경 제거',
  LANG_TEXT: '텍스트',
  LANG_ELEMENTS: '소스',
  LANG_IMAGE_INFO: '이미지 정보',
  LANG_FOLLOW: '팔로우',
  LANG_FOLLOWED: '팔로워',
  LANG_FOLLOWING: '팔로잉',
  FACEBOOK_IMAGE: '페이스북 이미지',
  FACEBOOK_LINK: '페이스북 링크',
  FACEBOOK_COVER: '페이스북 커버',
  FACEBOOK_STORY: '페이스북 스토리',
  INSTAGRAM_POST: '인스타그램 포스트',
  INSTAGRAM_STORY: '인스타그램 스토리',
  TWITTER_POST: '트위터 포스트',
  TWITTER_HEADER: '트위터 헤더',
  PINTEREST_POST: '핀터레스트 포스트',
  EMAIL_HEADER: '이메일 헤더',
  PRESENTATION: '프레젠테이션',
  PRESENTATION_WIDE: '와이드 프레젠테이션',
  EBOOK_COVER: '전자책 표지',
  LINKEDIN_POST: '링크드인 포스트',
  BROCHURE_COVER: '브로슈어 표지 (A4)',
  POSTCARD: '엽서',
  PHOTO_PRINT: '인쇄용 사진',
  YOUTUBE_PROFILE_IMAGE: '유튜브 프로필 사진',
  YOUTUBE_CHANNEL_COVER_PHOTO: '유튜브 채널아트',
  AUTO_COLOR: '자동 색보정',
  BALANCE: '밸런스',
  AUTO_LEVEL: '자동 레벨보정',
  AUTO_TONE: '자동 톤보정',
  ENRICH: '풍부함 더하기',
  RESET: '초기화',
  RESET_ALL: '모두 초기화',
  FILE_DOWNLOAD_OPTIONS: '다운로드 옵션',
  FILE_DOWNLOAD_INITIATED: '파일 다운로드',
  IMAGE_PROPERTIES: '<span style="font-size:12px">이미지 정보</span>',
  DOWNLOAD_DETAILS: '<span style="font-size:12px">다운로드 정보</span>',
  FILE_SIZE: '사이즈',
  LICENSE: '라이선스',
  FILEFORMAT: '파일 유형',
  CHANGE: '라이선스 변경',
  PRICING: '<span style="font-size:12px">가격</span>',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: '<span style="font-size:12px">다른 이름으로 저장</span>',
  IMAGE_USAGE: '<span style="font-size:12px">사용 용도</span>',
  PRINT: '인쇄',
  ELECTRONIC: '전자정보',
  COMPREHENSIVE: '통합',
  DOWNLOAD_CREDITS: '<b>{quota}</b> 크레딧 차감 : 크레딧 서비스',
  BASIC_SHAPE: '베이직 도형',
  CHIT_CHAT: '말풍선',
  SHAPES: '모양',
  ELEMENT_CHIT_CHAT: '말풍선 엘리먼트',
  ORGANIC_ABSTRACT_SHAPES: '추상적인 모양',
  ELEMENT_SHAPES: '도형 엘리먼트',
  BUY_SINGLE_IMAGE: '<b>1컷만 구매</b><br/>{quota} 크레딧 충전하기</b>',
  BUY_SINGLE_IMAGE_CREDIT: '크레딧팩 구매 <br/><b>{quota} 크레딧</b>',
  BUY_CREDIT_PACK: '<b>크레딧 팩 구매</b><br/>많이 충전할수록 할인돼요!',
  BUY_CREDIT_PACK_POINT_1: '{quota}크레딧 팩 <b>{price}</b>원',
  BUY_CREDIT_PACK_POINT_2: '크레딧의 다운로드 기간은 1년입니다.',
  BUY_CREDIT_PACK_POINT_3: '<b>1년내</b> 소진하지 않은 크레딧은 소멸됩니다.',
  BUY_SINGLE_IMAGE_PSUB: '싱글컷 구매 <br/><b>이미지 {quota} 컷 즉시 다운로드</b>',
  BUY_DOWNLOAD_PACK: '<b>다운로드팩 구매</b>',
  BUY_DOWNLOAD_PACK_POINT_1: '다운로드팩 {quota}컷 <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: '다운로드 기간 1년',
  BUY_DOWNLOAD_PACK_POINT_3: '',
  LOWEST_PRICE: '<b>정액제 구매</b><br/>사이즈 제한없이 저렴하게!',
  LOWEST_PRICE_POINT_1: '한 달 동안 <b>{price}</b>원에 이용해보세요',
  LOWEST_PRICE_POINT_2: '사진·일러스트 10컷 다운로드 가능',
  LOWEST_PRICE_POINT_3: '',
  PER_IMAGE_SIZE: '<b>{size}</b> 사이즈 1컷 당',
  PER_IMAGE_ANY: '<b>모든 사이즈</b> 1컷 기준',
  PER_IMAGE: '1컷 당',
  VIEW_PRICING: '서비스 및 가격 안내',
  DOWNLOAD_BEGIN: '파일 다운로드가 곧 시작됩니다.',
  DOWNLOAD_NOT_STARTING: '빠른 다운로드를 위해 고객 위치와 가장 가까운 서버를 선택하여 콘텐츠를 다운로드하여 주시기 바랍니다.',
  SELECT_MIRROR: '[콘텐츠 서버 선택]',
  SHOW_MORE: '더보기',
  SHOW_LESS: '간단히 표시',
  LICENSE_TYPE: '라이선스 유형',
  LICENSE_SUMMARY: '라이선스 요약',
  BACK: '뒤로가기',
  LANG_VECTOR: '벡터',
  PREMIUM_VECTOR: 'PREMIUM 벡터 일러스트',
  SCALE_ANY_SIZE: '크기 조정 가능',
  LANDINGPAGE_VECTOR_HEADLINE: '세계 유명 일러스트레이터가 함께하는 프리미엄 일러스트 · 아이콘 · 클립아트',
  LANDINGPAGE_PHOTO_HEADLINE: '세계 유명 브랜드, 작가가 함께하는 프리미엄 사진 · 이미지',
  CATEGORIES: '카테고리',
  LANDINGPAGE_PRICING_1: '크레딧 구매하기',
  LANDINGPAGE_PRICING_2: '정액제 구매하기',
  LANDINGPAGE_AS_LOW_AS: '최저 구매가',
  EMPTY_SEARCH_TITLE: '앗, 검색 결과가 없어요.',
  LANG_SMALL: 'S',
  LANG_MEDIUM: 'M',
  LANG_LARGE: 'L',
  LANG_EXTRA_LARGE: 'XL',
  LANG_LOW_CREDITS: '보유하신 크레딧이 부족해요!',
  LANG_TOPUP: '구매하기',
  LANG_TOPUP_NOW: '크레딧 충전하기',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description} 로열티 무료 사진, 그림, 이미지 그리고 스톡포토그래피. Image {mediaId}',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} 로열티 무료 사진, 그림, 이미지 그리고 스톡포토그래피. Image {mediaId}',
  SEO_DETAILS_METADESC_STOCKPHOTO: '{description} 그림, 스톡사진, 이미지 그리고 포토그래피. Image {mediaId}',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: '{description} 그림, 스톡사진, 이미지 그리고 포토그래피. Image {mediaId}',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} 무료 이미지·사진 {mediaId}.',
  SEO_DETAILS_METADESC_FREE_PHOTO: '{description}의 무료사진, 무료 이미지 {mediaId}.',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} 무료 벡터 일러스트·그래픽 {mediaId}.',
  SEO_DETAILS_METADESC_FREE_VECTOR: '{description}의 무료 벡터 일러스트. 무료 그래픽 {mediaId}.',
  SEO_DETAILS_OGDESCRIPTION: '123RF - 수천만 건의 사진, 일러스트, 무비클립과 음원을 만나다. 당신의 성공과 시선이 닿는 곳.',
  LIVECHAT_LOOKINGHELP: '추천 서비스 안내를 받아보시는 건 어떨까요?',
  LIVECHAT_CHATWITHUS: '지금 문의해보세요!',
  FREE_TRIAL: '<b>Get 10 Free Images</b> <br/>with Subscription Plan',
  LANG_RELATEDSEARCH: '관련검색',
  LANG_RELATED_STOCKPHOTO_SEARCH: '관련 이미지 검색결과',
  LANG_PROPERATTRIBUTE: '아래와 같이 출처 표기를 해주세요 :',
  LANG_ATTRIBUTEDESC: '에디토리얼 이미지를 이용한 제품, 웹 사이트 또는 자료에 아래 출처 텍스트를 넣으세요. 출처 표기는 필수이나 위치는 자유롭게 지정 가능합니다.',
  LANG_ATTRIBUTE_TITLE: '출처 표기는 옵션사항입니다.',
  LANG_ATTRIBUTE_CREDIT: '표기 없이 자유롭게 이용하실 수 있으나 저작권자 표기를 원하실 경우 아래를 이용해주세요.',
  LANG_ATTRIBUTE_COPYLINK: '링크 복사',
  LANG_ATTRIBUTE_COPYTEXT: '텍스트 복사',
  LANG_LICENSE_AGREEMENT: '라이선스 상세 <a href={url}><b>읽기</b></a>',
  LANG_NEXTPAGE: '다음페이지',
  LANG_IMAGE_NO_AVAILABLE: ' #{mediaId} 이미지는 판매 중지되었습니다.',
  LANG_PHOTOGRAPHY_METAIMG_DESC: '수천만개의 고해상도 RF 사진 및 각종 이미지를 검색, 다운로드 하실 수 있습니다.',
  LANG_VECTOR_METAIMG_DESC: '수천만개의 고해상도 RF 사진 및 각종 이미지를 검색, 다운로드 하실 수 있습니다.',
  LANG_ANOTHERMETAKWDEFAULT: '이미지, 이미지사진, 이미지사이트, 이미지검색, 이미지판매, 이미지구매, 사진, 편집사진, 일러스트, 아이콘, 클립아트, 템플릿, 디자인소스, 무비클립, 음원',
  LANG_ANOTHERMETADESCDEFAULT: '123RF는 사진, 일러스트, 아이콘, 템플릿, 디자인소스, 무비클립, 음원 등 다양한 디지털 콘텐츠를 제작 유통하는 전문 기업입니다. 당사에서는 모든 고객 여러분께서 디지털 콘텐츠를 구매하시는 것은 물론 판매까지 가능한 복합 서비스 환경을 제공합니다.',
  EMPTY_FILTERED_RESULT_TITLE: '앗, 필터링된 결과가 존재하지 않습니다 :(',
  EMPTY_FILTERED_RESULT_SUBTITLE: '필터를 줄이거나 다른 키워드를 입력해보세요.',
  CLEAR_ALL_FILTER: '필터 초기화',
  PROMOBAR_FREE10_TEXT: 'Get <b>10 FREE Images</b> when you get started on our 1 Month-Free Trial.',
  PROMOBAR_FREE10_BTNLABEL: 'START NOW',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: '모든 정액제 플랜 가격 인하',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: '플랜 보기',
  LANG_MODEL_RELEASED: '초상권 릴리즈',
  LANG_PROPERTY_RELEASED: '재산권 릴리즈',
  LANG_MODEL_RELEASE: '모델 릴리즈 포함',
  LANG_PROPERTY_RELEASE: '재산권 릴리즈 포함',
  LANG_YES: '예',
  LANG_NO: '아니요',
  LANG_MREXPLAIN: '초상권이 확보되어 있는 이미지로써, 본 이미지는 출판, 배포 등의 용도로 사용할 수 있습니다.',
  LANG_PREXPLAIN: '재산권이 확보되어 있는 이미지로써 본 이미지는 출판, 배포 등의 용도로 사용할 수 있습니다.',
  LANG_DOWNLOADS: '다운로드',
  LANG_OLDFILENAME: '파일명',
  LANG_DOWNLOAD_PREVIEW: '다운로드 미리보기',
  LANG_SHARE: 'SNS 공유',
  LANG_RESTRICTIONS: '허용범위',
  LANG_READ_MORE: '더 읽기',
  LANG_EDITORIAL_USE: '에디토리얼 라이선스',
  LANG_EDITORIALDESCP: '에디토리얼 콘텐츠: 본 콘텐츠는 초상권 및 재산권 등이 확보되지 않은 에디토리얼 콘텐츠로써 상업적 사용이 금지 되어 있습니다. 따라서 본 콘텐츠는 사실을 전달하는 출판, 보도 용도로만 사용 가능하며, 상세한 내용은 본 사이트 이용약관 및 각종 라이선스 규정을 참고하여 주시기 바랍니다. ※ 당사에서는 해당 콘텐츠의 초상권 및 재산권 해소를 위한 서비스를 제공하지 않습니다.',
  LANG_LOGO_OWNERSHIP: 'Logo Ownership Rights',
  LANG_PHOTO_SHOT_SMARTPHONE: 'This photo was shot with a smartphone.',
  LANG_VECTORNOLOGO: '해당 콘텐츠를 로고로 사용을 원하시는 고객님께서는 고객센터<b>{phonenum}</b>로 연락하여 주시기 바랍니다.',
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> 컷 차감 : 정액제 서비스',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> 컷 차감 : 다운로드 팩',
  LANG_LOGO: '로고',
  USING_OLDER_BROWSER: '오래된 버전의 브라우저를 사용하고 있는지 확인해주세요!',
  UPGRADE_NEWER_BROWSER: '원활한 경험을 위해 최신 브라우저 버전으로 업그레이드 해주세요.',
  CLICK_TO_DOWNLOAD: '이용 중인 브라우저를 클릭하여 다운로드를 시작하세요 : ',
  LANG_VECTOR_EDITABLE: '편집 가능한 SVG · EPS 형식',
  PROMOBAR_GOOD15_TEXT1: '평생 쓰는 123RF 이미지, ',
  PROMOBAR_GOOD15_TEXT2: '할인코드와 더 저렴하게!. 모든 정액제 플랜 15% 할인코드 : ',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: '평생 쓰는 123RF 이미지, ',
  PROMOBAR_SUMMER25_TEXT2: '모든 플랜 <b>25% 할인코드</b> : ',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: '모든 플랜 <b>30% 할인코드</b> : ',
  NO_EDIT_COMPARE_MSG: '수정된 내용이 없어 전후 비교가 불가합니다. ',
  SWITCH_TO_VECTOR_MSG: '벡터 이미지로 변경할 경우 편집한 내용이 사라져요! 사진 편집기 는 벡터 파일 유형에 적용되지 않습니다.',
  SWITCH_TO_XL_MSG: 'XL 사이즈 이미지로 변경할 경우 편집한 내용이 사라져요! 사진 편집기 는 최대 사이즈인 XL 유형에 적용되지 않습니다.',
  UNAVAILABLE_FOR_THIS_SIZE: '{size} 사이즈 미지원 기능',
  SWITCH: '변경하기',
  SWITCH_LICENSE_EXT_MSG: '{type} 형식으로 우선 다운로드하시겠어요? 특별 라이선스로 변경  시 편집 내용이 사라져요!',
  LANG_SESSION_EXPIRED: '세션이 만료되었습니다. <a href={url}>이곳에서 재로그인하세요.</a>',
  LANG_TEXT_COLOR: '텍스트 색상',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: '하이라이트',
  LANG_TEXT_ALIGNMENT: '정렬',
  LANG_TEXT_BOLD: '굵게',
  LANG_TEXT_ITALIC: '기울게',
  LANG_TEXT_UNDERLINE: '밑줄',
  LANG_TEXT_BULLET: '불렛',
  LANG_TEXT_LINE_SPACING: '줄 간격',
  LANG_TEXT_LETTER_SPACING: '글자 간격',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: '앞으로 가져오기',
  LANG_BRING_BACKWARD: '뒤로 보내기',
  LANG_DUPLICATE: '복제',
  LANG_OPACITY: '투명도',
  LANG_DELETE: '삭제',
  LANG_TEXT_ADD: '텍스트 추가',
  LANG_TEXT_EDIT: '텍스트 편집',
  LANG_TEXT_FONT_TYPE: '폰트 선택',
  LANG_TEXT_FONT_SIZE: '폰트 크키',
  LANG_TEXT_ALL_FONTS: '전체 폰트',
  LANG_TEXT_UNDO: '실행 취소',
  LANG_TEXT_REDO: '재실행',
  LANG_TEXT_FONT: '폰트',
  LANG_SEARCH_FONT_TYPE: '폰트 검색하기',
  LANG_SEARCH_SHAPE_TYPE: '엘리먼트 검색',
  LANG_DONE: '완료',
  ADD_HEADER: '헤더 추가하기',
  ADD_TITLE: '제목 추가하기',
  ADD_BODY: '내용 텍스트 추가하기',
  ADD_ASSISTIVE: '보조 텍스트 추가하기',
  STOCK_PHOTO_BANNER_TITLE: '수억 컷의 이미지로 상상하는 디자인을 완성하세요',
  STOCK_PHOTO_BANNER_DESC: '저작권 걱정 NO, 기간 걱정 NO! 전문 사진작가들의 작품을  자사 · 납품 · 지역 관계없이 넓게 사용하세요',
  STOCK_PHOTO_SUBSCRIBE_TITLE: '상상하는대로, 생각한대로',
  STOCK_PHOTO_SUBSCRIBE_DESC: '스토리에 힘을 더하는 디자인소스를 123RF에서 받아보세요. 기간제한 없이 평생 안전하게 이용하세요. 이미지는 언제나 123RF!',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: '다운로드팩',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: '최저',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: '구독 플랜',
  STOCK_PHOTO_CARD_IMAGE: 'image',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: '플랜 구독하기',
  STOCK_PHOTO_CARD_BUY_NOW: '구매하기',
  STOCK_PHOTO_POPULAR_ROYALTY: '인기 RF 사진 카테고리',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: '최저가 플랜으로 평생 사용하는 이미지를 받아보세요',
  STOCK_PHOTO_DISCOVER_NOW: '확인하세요',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: '풍경',
  STOCK_PHOTO_BABIES: '아기',
  STOCK_PHOTO_BUSINESS: '비즈니스',
  STOCK_PHOTO_FOOD: '음식',
  STOCK_PHOTO_ANIMALS: '동물',
  STOCK_PHOTO_TECHNOLOGY: '기술',
  STOCK_PHOTO_HEALTHCARE: '의료',
  STOCK_PHOTO_NATURE: '자연',
  STOCK_PHOTO_ROMANCE: '로맨스',
  CLIPART_VECTOR_EXPLORE_MILLIONS: '수억 컷의 일러스트로 생각하는 디자인을 그려보세요',
  CLIPART_VECTOR_NEED_AN_EYE: '평생 이용하는 벡터 일러스트, 인포그래픽, 클립아트, 아이콘과 배경까지. 전문 일러스트레이터의 작품을 123RF에서 받아보세요',
  CLIPART_VECTOR_FIND_VECTOR: '화질저하 없이 마음껏 이용',
  CLIPART_VECTOR_FIND_VECTOR_DESC: '평생 이용하는 벡터 일러스트, 카툰만화, 인포그래픽, 클립아트, 아이콘,패턴과 배경까지. 무한대로 늘릴 수 있는 벡터 일러스트로 여러분의 디자인 또한 무한대로 늘려보세요!',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: '인기 RF 일러스트 카테고리',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: '최저가 플랜으로 평생 사용하는 일러스트를 받아보세요',
  CLIPART_VECTOR_DISCOVER_NOW: 'Discover Now',
  SPEND_AND_WIN_SUBTITLE: 'Every {currency} spent = 1 Entry',
  SPEND_AND_WIN_IMGALT: 'Spend & Win an iPhone 13 Pro',
  CLIPART_VECTOR_GRAPHIC: '그래픽',
  CLIPART_VECTOR_ADVERTISING: '광고',
  CLIPART_VECTOR_FLOWER: '꽃',
  CLIPART_VECTOR_ABSTRACT: '추상적',
  CLIPART_VECTOR_POP: '팝',
  CLIPART_VECTOR_BIRD: '새',
  CLIPART_VECTOR_ILLUSTRATION: '일러스트',
  CLIPART_VECTOR_PIXEL: '픽셀',
  CLIPART_VECTOR_3D: '3D',
  LANG_VECTOR_SVG_EPS: '벡터, SVG 및 EPS',
  FREE_UPGRADE: '무료 <br> 사이즈UP',
  FREE_UPGRADE_MESSAGE: '정액제·다운로드팩으로 XL 사이즈 무료 업그레이드',
  LANG_FREE_IMAGE: '123RF 무료',
  LANG_IMAGE_FREE: '해당 이미지는 상업·개인 용도로 이용 가능합니다. 출처표기 필수',
  LANG_HOW_TO_ATTRIBUTE: '출처 표기는 어떻게 하나요?',
  LANG_FREE_DOWNLOAD: '무료 다운로드',
  LANG_FREE_DOWNLOAD_BUTTON: '무료 다운로드',
  LANG_GO_PLUS_BUTTON: 'PLUS 시작하기',
  LANG_ATTRIBUTION_IS_KEY: '콘텐츠를 무료로 제공하는 크리에이터가 더 많은 관심을 받을 수 있도록 출처를 꼭 넣어주세요. 이렇게 멋진 콘텐츠를 상업적으로 활용할 수 있게끔 한 크리에이터에게 받아 마땅한 박수와 관심을 보내주세요!',
  LANG_GET_ATTRIBUTION_LINK: '콘텐츠를 활용하는 곳(불가할 경우 기재 가능한 가장 가까운 곳) 출처 표기 링크를 기재해주세요.',
  LANG_CREDIT_OWNER: '크리에이터를 표기해주세요.',
  LANG_USE_ATTRIBUTION: '아래 출처 링크를 이용하세요.',
  LANG_ATTRIBUTION_LOCATION: '기재가 어려운 제작물의 경우,  웹사이트/블로그/뉴스레터 등의 하단 푸터에  콘텐츠 {media_type} 저작권자를 기재해주세요. ',
  LANG_GOT_A_QUESTION: "질문이 있으신가요? <a href={url} target='_blank'><u>문의하기</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: '무료이미지 이용조건 : 출처 표기 필수!',
  LANG_ATTR_FREE_DOWNLOADING: '잠시만 기다려주세요!',
  LANG_PLUS_CONTENT_1: '무제한으로 담으세요',
  LANG_PLUS_CONTENT_2: '100만 컷 이상의 사진 및 일러스트',
  LANG_PLUS_CONTENT_3: '123RF 독점 3D 그래픽 일러스트!',
  LANG_PLUS_CONTENT_4: '무제한 다운로드',
  LANG_PLUS_CONTENT_5: '무한한 크리에이티브',
  FREE_VECTOR: 'FREE 벡터 일러스트',
  FREE_STOCKPHOTO: 'FREE 사진·이미지',
  FREE_PUBLIC_DOMAIN_CC0: '공개 도메인 CC0',
  BUY_5_DOWNLOAD_PACK: "<b>특가 이벤트</b><br/>다운로드팩 3컷 + <b><span style='color:red'>무료 2컷 추가</span></b>",
  BUY_5_DOWNLOAD_PACK_POINT_1: '5컷의 사진·일러스트 다운로드',
  BUY_5_DOWNLOAD_PACK_POINT_2: '다운로드 기간 1년',
  BUY_5_GET_2_FREE: '2컷이 무료!',
  LANG_ASK_DOWNLOAD_PLUS: 'PLUS 콘텐츠를 받고 싶으신가요?',
  LANG_SIGN_UP_PLUS: '123RF PLUS 플랜에 가입하기',
  LANG_FOR_ONLY: "<span class='text'>단</span> <span class='price'>{price}</span>/1개월",
  LANG_FOR_ONLY_2: "<span class='text'>단</span> <span class='price'>{price}</span>",
  LANG_BENEFIT_PLUS: 'PLUS 플랜을 고려해 보세요!',
  LANG_RELEASES: '사용 동의서',
  LANG_UNAVAILABLE: '없는',
  LANG_ATTR_FREE_IMG_TITLE2: '필요한 것을 찾으셨다니 다행입니다. 🎉',
  LANG_PLEASE_NOTE: '이 이미지에는 첨부된 모델 또는 재산권 사용 동의서가 없습니다. 상업적 사용에 따른 위험은 사용자가 감수해야 합니다.',
  LANG_PLEASE_ADVISED: '인식 가능한 개인이 포함 된 이미지에는 릴리스가 포함되어 있지 않다고 조언하십시오. 이러한 이미지를 상업적으로 사용하려면 관련 당사자로부터 허가를 받아야합니다. 이미지에 인식 가능한 개인이 포함되어 있지 않은 경우 상업적으로 사용할 때 저작권 문제가 없어야합니다.',
  LANG_REACHED_LIMIT: '일일 다운로드 한도에 도달했습니다.',
  LANG_COME_TOMORROW: '무료 이미지가 마음에 드셨다니 정말 기쁩니다! 다운로드 제한을 새로 고치려면 내일 다시 오십시오. 고맙습니다. 😊',
  LANG_DOWNLOAD_ERROR: '앗! 오류로 인해 이미지 다운로드가 불가해요.',
  LANG_REFRESH_AND_TRY_AGAIN: "브라우저를 새로고침하고 다시 시도해 주세요. <a href={url} target='_blank'>문제가 지속된다면 알려주세요!</a>",
  LANG_REACHED_LIMIT_PLUS: '일일 다운로드 가능 컷 수를 모두 소진하였어요.',
  LANG_COME_TOMORROW_PLUS: '오늘 다운로드 가능한 컷 수를 모두 소진했어요. 내일 돌아와서 새롭게 다운로드를 시작하세요. 이용해주셔서 감사해요!',
  SEE_YOU_TOMORROW: '내일 만나요!',
  ORIGINAL: '오리지널',
  'AUTO_B&W': '자동 B&w',
  AUTO_ADJUST: '자동 조절',
  AUTO_POP: '자동 팝',
  NONE: '없음',
  LANG_CROP: '자르기',
  LANG_ENABLE_EDITOR: '이미지 편집',
  LANG_DISABLE_EDITOR: '닫기',
  LANG_IMAGE_FREE_CC0: "CC0 콘텐츠입니다. 출처표기는 불필요합니다. <a class='linkCC0' href={url} target='_blank'>CC0 라이선스 & 이용약관</a>에 따라 이용해주세요.",
  LANG_PREVIEW: '미리보기',
  LANG_ATTRIBUTION_CC0_TITLE: 'CCO 이미지 : 출처표기 불필요',
  LANG_ATTRIBUTION_TITLE: '출처 표기 필수',
  LANG_ATTRIBUTION_CONTENT: '다운로드 후 출처 링크가 제공됩니다.',
  LANG_ATTRIBUTION_CC0_CONTENT: 'CCO 이미지이므로 출처표기는 필수가 아닙니다.',
  LANG_SUBCRIBE_PREMIUM: '일일 다운로드 수량을 모두 소진하였습니다. 무한 다운로드가 가능한 123RF 프리미엄으로 그레이드하세요.',
  LANG_REMOVE_ATTRIBUTION: '출처 표기를 피하고 싶다면?',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "<img src={image} alt='123RF Plus Logo'>를 구독하여 8천만 컷의 스톡 이미지와 일러스트를 무제한으로 담으세요. 구독하면 더이상 출처 표기를 안해도 된답니다.",
  LANG_CREATOR_ATTRIBUTION: '아래 출처 링크를 붙여넣기하여 원작자를 표기해주세요.',
  LANG_GET_ATTRIBUTION_LINK_2: '이 링크를 복사하여 콘텐츠가 쓰인 곳에 표기해주세요. 웹사이트의 푸터 또는 출처 표기를 위한 별도 영역 등 이용자에게 노출이 되는 곳이라면 관계 없습니다.',
  LANG_APPLY_BG_REMOVAL: '배경 제거하기',
  LANG_APPLYING_EDIT: '이미지 수정 중 ',
  LANG_APPLYING_EDIT_ERROR: '앗, 오류가 발생했어요.',
  LANG_TRY_AGAIN: '다시 시도해주세요!',
  LANG_SEARCHCOLLECTION_PREMIUM: '전체 컬렉션',
  LANG_SEARCHCOLLECTION_PLUS: '에센셜 컬렉션',
  LANG_SEARCHCOLLECTION_FREE: '베이직 컬렉션',
  LANG_SIMILARIMAGESPAGE_TITLE: '123RF - 글로벌 이미지 검색 사이트',
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: '유사이미지',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: '죄송합니다! 여기는 볼게 없다.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: '지원되지 않는 이미지 파일입니다.',
  LANG_PER_IMAGE: '/이미지',
  LANG_PLANS_AND_PRICING: '플랜 안내',
  LANG_CHOOSE_BEST_PLAN: '나에게 맞는 플랜을 선택하세요',
  LANG_WANT_MORE_SAVING_1: '더 저렴하게 구매를 희망한다면?',
  LANG_WANT_MORE_SAVING_2: '{price}/컷 플랜 또한 확인하기',
  LANG_BEST_VALUE: '{percentage}% 더욱 저렴하게',
  LANG_YEARLY_PLAN: '연간 플랜',
  LANG_WITH_MONTHLY_PAYMENTS: '월마다 결제',
  LANG_GRAB_YEARLY_PLAN: '{percentage}% 할인가에 연간플랜 이용하세요.',
  LANG_PRICE_PER_MONTH: '{price} / 1월',
  LANG_PRICE_PER_YEAR: '{price} / 1년',
  LANG_GET_X_EVERY_MONTH: '{quota}컷 사진 또는 일러스트를 월마다 다운로드',
  LANG_X_EVERY_MONTH: '{quota}컷의 사진 또는 일러스트 매월 다운로드',
  LANG_SINGLE_IMAGE_PURCHASE: '싱글이미지 구매',
  LANG_GET_ONLY_WHAT_YOU_NEED: '필요한만큼 받아보세요.',
  LANG_TOTAL_PRICE: '총 {price}',
  LANG_DOWNLOAD_IMMEDIATELY: '결제 후 이미지 즉시 다운로드하기',
  LANG_NEXT: '다음',
  LANG_TITLE: '123RF - 글로벌 이미지 검색 사이트',
  SIMILAR_STOCK_PHOTOS: '유사이미지',
  EMPTY_SIMILAR_SEARCH_RESULTS: '죄송합니다! 여기는 볼게 없다.',
  UNAVAILABLE_IMAGE: '지원되지 않는 이미지 파일입니다.',
  LANG_DOWNLOAD_PACK: '다운로드팩',
  LANG_GET_X_ANY_TIME: '언제든지 {quota} 장의 사진 또는 벡터',
  LANG_X_CREDITS: '{total} 크레딧',
  LANG_PER_CREDIT: '/크레딧',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '이미지 {photoCount}컷{videoCount, plural, =0 {} other { 또는 영상 #개}}',
  INCLUDES_STANDARD_LICENSE: '스탠다드 라이선스 적용',
  LANG_MEDIUM_SIZE: 'M 사이즈',
  LANG_LARGE_SIZE: 'L 사이즈',
  LANG_EXTRA_LARGE_SIZE: 'XL 사이즈',
  LANG_STANDARD_SIZES: '표준 사이즈',
  LANG_ROYALTY_FREE: '로열티프리',
  LANG_OPTIONS: '옵션',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'PLUS 플랜을 고려해 보세요!',
  LANG_RELATED_FONT_SEARCH: '관련 검색결과',
  AI_GENERATED_PHOTO: 'AI 생성 이미지',
  FONT_PRICING_CREDIT: '크레딧',
  '3_FONTS_3_IMGS': '폰트 3종 <b>또는</b> 이미지 3컷 <b>또는</b>  영상 1개',
  '9_FONTS_9_IMG_3_VIDEOS': '폰트 9종  <b>또는</b> 이미지 9컷  <b>또는</b> 영상 3개',
  PURCHASE_AND_DOWNLOAD: '플랜 구매&다운로드',
  FONT_PATH: 'fonts',
  FONT_ZIP: '폰트 (OTF, TTF)',
  FONT_PACKS_LICENSE: '123RF 팩 라이선스',
  FONT_META_DESC: '{product_title} 폰트 또는 타이포그래피를 다운로드하여 인쇄 웹 용도의  멋진 디자인을 완성하세요.',
  FONT_META_TITLE: '폰트- {product_title} | 123RF',
  FONT_SIMILAR: '유사한 폰트',
  LANG_PLANS: '플랜',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: '이미지 변형하기',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: '원본 이미지를 변형하여 새로운 이미지를 생성하세요. AI를 통해 생성된 이미지를 받을 때마다 123RF 작가에게 수익이 발생합니다.',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: '이용해주셔서 감사합니다! 콘텐츠 다운로드 시 123RF 작가에게 일정 수익이 발생합니다.',
  LANG_CONTRIBUTORS_AI_VARIATION: '원본 이미지를 제공한 123RF 작가는 다운로드된 모든 AI 변형 콘텐츠에 대해 보상을 받습니다.',
  LANG_AI_VARIATION_LIMIT: '하루 AI 이미지 생성 한도에 도달했습니다.',
  LANG_GENERATE_COMEBACK: '내일 다시 시도해주세요!',
  LANG_LICENSE_AND_DOWNLOAD: '라이선스 & 다운로드',
  LANG_CONFIRM_OK: '네, 알겠습니다',
  LANG_AI_SWITCH_CONTENT: '사진 편집기 는 AI 변형 기능을 지원하지 않습니다. AI 변형 기능을 이용 시 편집이 취소됩니다. ',
  LANG_GENERATE_VARIATIONS: '변형버전 생성하기',
  LANG_GENERATE_WITH_AI: 'AI 이미지 생성',
  LANG_NEW: '새로운 기능',
  LANG_AI_GENERATOR_LICENSE: '123RF AI 생성 서비스 약관(“약관”)',
  LANG_I_UNDERSTAND: '동의합니다.',
  LANG_WHAT_IS_THIS: '기능 안내',
  LANG_AUTHENTIC: '실사',
  LANG_ABSTRACT: '추상적',
  LANG_MACRO_CLOSEUP: '접사 / 클로즈업',
  LANG_OBJECT: '오브젝트',
  LANG_WATERCOLOR: '수채화',
  LANG_FLAT: '플랫',
  LANG_CARTOON: '만화',
  LANG_GEOMETRIC: '기하학',
  LANG_GRADIENT: '그라데이션',
  LANG_ISOMETRIC: '아이소메트릭',
  LANG_3D: '3D',
  LANG_HAND_DRAWN: '손그림',
  LANG_NATURAL: '자연스러운',
  LANG_WARM: '따뜻한',
  LANG_DRAMATIC: '드라마틱',
  LANG_VIVID_BOLD: '비비드 & 볼드',
  LANG_BLACK_WHITE: '블랙 & 화이트',
  LANG_ASIAN: '아시아인',
  LANG_AFRICAN_AMERICAN: '아프리카계 미국인',
  LANG_STANDARD: '스탠다드 라이선스',
  LANG_EXTENDED: '특별 라이선스',
  LANG_STANDARD_CONTENT_1: '인쇄(홍보용 현수막,카달로그 등) 및 온라인 용도로 이용 가능',
  LANG_STANDARD_CONTENT_2: '출판용 교과서/서적 50만 부 미만까지 제작 가능',
  LANG_STANDARD_CONTENT_3: '판매되는 유상상품 또는 유료서비스의 디자인 요소로 이용 불가',
  LANG_EXTENDED_CONTENT_1: '스탠다드 라이선스 범위로 이용 가능',
  LANG_EXTENDED_CONTENT_2: '출판용 고과서/서적 50만 매 이상 제작 가능',
  LANG_EXTENDED_CONTENT_3: '판매되는 유상상품 또는 유료서비스의 디자인 요소로 이용 가능',
  LANG_ORIGINAL_IMAGE: '원본 이미지',
  LANG_IMPORTANT_NOTE: '안내사항',
  LANG_AI_ORIGINAL_ALTERED: 'AI 변형 이미지는 AI 기술을 통해 원본 이미지의 특징은 유지하며 다른 분위기로 느껴질 수 있도록 변형한 이미지입니다. ',
  LANG_GENERATE_VARIATIONS_NOTE: '현재 AI 이미지 변형 기술은 준비 단계인 베타 버전으로, 변형된 이미지 결과과 예상과 다를 수 있습니다.  123RF는 윤리적이고 책임감 있는 AI 가이드라인 준수에 최선을 다하고 있습니다.',
  LANG_LICENSE_AI_EDITED: 'AI 변형을 통해 다운로드 된 이미지는 저작권자(콘텐츠 작가)에게 일정 로열티가 지급됩니다.',
  LANG_MOOD: '분위기',
  LANG_AI_EXCLUDE_PLACEHOLDER: '예: 녹색, 생머리',
  LANG_SIMILAR_STOCK: '유사한 스톡 이미지',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: '모델 릴리즈 포함',
  WITH_PROPERTY_RELEASE: '재산권 릴리즈 포함',
  MODEL_RELEASE_CONTENT: '초상권이 확보되어 있는 이미지로써, 본 이미지는 출판, 배포 등의 용도로 사용할 수 있습니다.',
  PROPERTY_RELEASE_CONTENT: '재산권이 확보되어 있는 이미지로써 본 이미지는 출판, 배포 등의 용도로 사용할 수 있습니다.',

  variousStockGAI: '수백만 장의 스톡 사진으로 학습되고 기업의 요구사항에 최적화된 법적 규정을 준수하는 AI 모델이 책임 보험을 통해 안심하고 사용할 수 있습니다.',
  extendedLicense: '특별라이센스',
  sizes: '사이즈',

  downloadMail1st: '이미지 확대 서비스 제공업체에서 다음 영업일 이내에 최종 확대 파일을 다운로드할 수 있는 지침을',
  downloadMail2nd: '로 보내드립니다',

  aiGenarated: 'AI 생성 이미지',

  variousOpenJourney: 'AI 모델이 미드저니의 독특한 스타일과 유사한 아트를 생성합니다.',
  variousTurboVision: "'일반' XL 해상도에서 고속으로 생성하여 전반적인 품질을 향상시킵니다.",
  variousRealVisXL: '정밀하고 정확하게 사실적인 결과물을 얻을 수 있는 모델 목표.',
  variousPortraitPlus: '1:1 종횡비에 이상적인 균일한 세로 구도의 모델입니다.',
  enterVirifiedCode: '인증 코드 입력',
  verify: '확인',
  didNotGetCode: '코드를 받지 못하셨나요?',
  clickToResend: '다시 보내려면 클릭하세요.',
  otpCannotEmpty: 'OTP는 비워둘 수 없습니다.',
  sentCodeToMail: '등록하신 이메일로 코드를 보내드렸습니다.',
  invalidCode: '잘못된 코드',
  expiredOTP: 'OTP가 만료되었습니다. 다시 보내주세요.',
  sendEmail: '이메일이 성공적으로 전송되었습니다. 계속하려면 메일함을 확인하세요.',
  remainingTime: '남은 시간: ',
};
export default ko;
