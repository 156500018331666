const cz = {
  // Header
  photos: 'Fotografie',
  Photos: 'Fotografie',
  vectors: 'Vektory',
  Vectors: 'Vektory',
  footageupper: 'VIDEO',
  footage: 'Video',
  video: 'Video',
  audio: 'Audio',
  signin: 'Přihlásit se',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Cesky.png',
  language: 'Jazyk',
  // Footer
  contact: 'Kontakt',
  contactUs: 'Kontaktujte nás',
  privacy: 'Ochrana osobních údajů',
  terms: 'Podmínky použití',
  cookie: 'COOKIE POLITIKA',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: 'Zapomněli jste heslo',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: 'Zadejte prosím platnou emailovou adresu.',
  emailCannotEmpty: 'E-mail musí být vyplněn.',
  returnToLogin: 'Zpět k přihlášení',
  email: 'Email',
  // Login
  username: 'UŽIVATELSKÉ JMÉNO',
  pw: 'Heslo',
  rememberMe: 'Trvale přihlásit na tomto počítači',
  login: 'Přihlásit se',
  forgotPassword: 'Zapomenuté heslo',
  loginAsGuest: 'Přihlášení hosta',
  // ResetPassword
  resetPwdDesc: 'Zadejte Vaše nové heslo a my Vás obratem přihlásíme.',
  newPw: 'Nové heslo',
  confirmPwd: 'Potvrďte heslod',
  resetPwdButton: 'Obnovit heslo',
  resetPwdTitle: 'Obnovte své heslo',
  enterYourNewPw: 'Zadejte Vaše nové heslo a my Vás obratem přihlásíme.',
  pwMiniMumChars: 'Minimálně 8 znaků',
  pwMaxChar20: 'Maximální počet znaků je 20',
  pwLowerCase: 'Alespoň jedno malé písmeno',
  pwUpperCase: 'Alespoň jedno velké písmeno',
  pwNumeral: 'Alespoň jednu číslici',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: 'Potvrzené heslo nesouhlasí s Vámi zadaný heslem',
  resetPwdInvalidLink: 'Tento odkaz není platný, nebo vypršel.',
  pwHasBeenReset: 'Heslo bylo obnoveno, pro pokračování se prosím přihlašte.',
  // Admin index
  companyName: 'Název společnosti',
  phone: 'Telefon',
  fax: '传真',
  address: 'Adresa',
  idd: 'IDD',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: 'Celkový počet souborů',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: 'Staré heslo',
  confirmNewPassword: 'Potvrdit nové heslo',
  returnToHome: 'Vrátit se na domovskou stránku',
  // Cookie Policy
  langCookie01:
    'Při návštěvě %host% umístíme malý textový soubor zvaný cookie na váš pevný disk ve vašem počítači.',
  langCookie02:
    'Tento soubor nám umožňuje sledovat vás, abychom vám mohli poskytnout základní funkce, jako jsou nákupní košíky. Jsou také používány k vylepšení uživatelských online služeb a poskytují vám funkce jako je "likebox".',
  langCookie03:
    'Dále nám umožňuje analyzovat interakci s našimi webovými stránkami (včetně navštívených stránek, vyhledaných výrazů a zobrazených obrázků). A to bez ohledu na to, zda se je rozhodnete zakoupit, či nikoliv.',
  langCookie04:
    'Cookies, se kterými souhlasíte, jsou také používány k vytvoření vašeho profilu, abychom vám byli schopni poskytovat cílenou reklamu šitou na míru vašim zájmům.',
  langCookie05:
    'Většina prohlížečů umožňuje cookies odmítnout; pokud tak ale učiníte, některé funkce nemusí být k dispozici a některé webové stránky se nemusí správně zobrazit.',

  downloadModalTitle: 'Snímek ke stažení',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf:
    'Soubory TIFF, video a audio média nejsou podporovány. Následující média:',
  messageFileNotSupported2ndHalf: 'byl odstraněn z hromadného stažení.',
  licenseOnBehalf: 'Licencovat jménem',
  customizedPortal: 'Customized portal',
  normalDownload: 'Stáhnout normálně',
  myCredit: 'Mé kredity',
  lowCreditsReminder: 'Nedostatečný zůstatek',
  downloadModalId: 'ID obrázku:',
  downloadModalPrice: 'Kredit:',
  clearAll: 'Vyčistit vše',
  myActivities: 'Mé aktivity',
  info: 'Informace',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: 'stáhněte si tento obsah pomocí',
  // homepage page

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  home3Title: 'Free Browsing',
  toSearchKeyword: 'Zadejte své vyhledávání zde',
  toSearchAIKeyword: 'Zadejte klíčová slova, název článku nebo název blogu',
  allImages: 'Všechny snímky',
  freeImages: 'Free Images',
  categoryImage: 'Category',
  recentViewed: 'Naposledy shlédnuté',
  latestIMG: 'Latest Images',
  // Setting
  adminControlPanel: 'Administrátorský ovládací panel',
  downloadHistory: 'Historie stahování',
  hruc: 'HRUC',
  changePassword: 'Změnit heslo',
  logOut: 'Odhlásit se',
  hello: 'Dobrý den,',
  viewAccount: 'View Account',
  myAccount: 'Můj účet',
  myCollections: 'Mé kolekce',
  personalCredits: 'Osobní kredity',
  groupTotal: 'Celkový počet kreditů ve skupině',
  toTalUnpaidLicense: 'Neuhrazené licence',
  highRUnwatermarkComp: 'Náhledy ve vysokém rozlišení bez vodoznaku',
  monthSelection: 'Month selection',

  // Menu
  categories: 'Kategorie',
  myLikebox: 'Můj Likebox',
  settings: 'Nastavení',
  notification: 'Oznámení',
  clear: 'Vyčistit',
  likebox: 'Likebox2',
  credits: 'Credits2',
  // Credit summary at header
  downloadAndUsege: 'Stahování a využití',
  totalDlCredit: 'Osobní kredity',
  allocatedDlCredit: 'Přiřazené kredity',
  personalAvailableCre: 'Osobní kredity',
  groupAvailableCre: 'Kredity skupiny',
  availableDlSlot: 'Dostupné sloty pro stahování',
  uNPLD: 'Použij nyní, uhraď později',
  hiResHRUC: 'Hi-Res náhledy bez vodoznaku (HRUC)',
  monthlyAllocation: 'Měsíční limit',
  remainingQuota: 'Zbývá k využití',
  remainingHRUCQ: 'Zbývající kvóta pro Hi-Res náhledy bez vodoznaku',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: 'Skupiny',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: 'Dejte nám prosímm vědět, s čím vám můžeme pomoci?  ',
  liveChat: 'Live chat',
  whatsApp: 'WhatsApp',
  hiThere: 'Dobrý den, ',
  // email response
  emailIsNotAvailable: 'E-mail není dostupný.',
  anErrorHaSoccured: 'Vyskytla se chyba, zkuste to prosím znovu.',
  emailHasBeenSent:
    'E-mail byl úspěšně odeslán, pro pokračování si prosím zkontrolujte Vaši emailovou schránku. Odeslali jsme email na __email s návodem na obnovu hesla. Jestliže se email nezobrazí během 5 minut ve vašem inboxu, zkontrolujte si prosím vaši spam složku.',
  // INVALIDPASSWORD
  inValidPassword: 'Neplatné heslo! Zkontrolujte prosím, zda nemáte zapnutý Caps Lock.',
  userIsBlocked: 'Příliš mnoho neúspěšných pokusů, byli jste dočasně zablokováni.',
  blockWarrings: 'Po 10 neúspěšných pokusech budete dočasně zablokování!',
  // Update Password
  resetPwdUpdated: 'Vaše heslo bylo obnoveno.',
  updatePwDfailed: 'Heslo se nepodařilo aktualizovat!',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'minutami',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: 'Licenční smlouva',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: 'Uživatelské jméno musí být vyplněno',
  passWordCannotEmpty: 'Heslo nesmí být prázdné',
  oldPasswordCannotEmpty: 'Původní heslo musí být zadáno',
  confirmPasswordCannotBeEmpty: 'Potvrzení nového heslo nesmí být prázdné',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: 'Cannot send more emails, please check your mailbox.',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: 'Používáte starší prohlížeč?',
  upgradeBrowser: 'Upgradujte na novější verzi prohlížeče a užijte si plynulejší zážitek!',
  clickToDownload: 'Kliknutím si stáhnete novější verzi daného prohlížeče:',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: 'Zobrazit vše',
  // Filter bar
  aiGenerate: 'Obrázek generovaný AI',
  standardModels: 'Standardní modely',
  fineTunedModels: 'Vyladěné modely',
  aiContent: 'Zahrňte obsah generovaný AI',
  filter: 'Filtr',
  safeSearch: 'Bezpečné vyhledávání',
  aiAssist: 'AI Podpora',
  freshness: 'Přidáno',
  gender: 'Pohlaví',
  sortBy: 'SEŘADIT PODLE',
  mediaType: 'Typ média',
  orientation: 'Orientace',
  modelPreferences: 'Preference modelu',
  people: 'Lidé',
  more: 'Více',
  numOfPeople: 'Počet lidí',
  age: 'Věk',
  ethnicity: 'Ethnicity',
  collection: 'Kolekce',
  uploaded: 'Uploaded',
  style: 'Styl',
  cutOut: 'Jednobarevné/průhledné pozadí',
  doNotIncludeWords: 'Nezahrnovat následující výrazy',
  doNotIncludeWords_ex: 'např. sýr',
  color: 'Barva',
  max1Color: 'Max. 1 barva',
  license: 'Typ licence',
  fps: 'FPS',
  resolution: 'Rozlišení',
  clipDuration: 'Délka klipu (minuty)',
  loop: 'Smyčka',
  category: 'Kategorie',
  genre: 'Druh',
  tempo: 'Tempo (bpm)',
  // Filter button text
  Relevance: 'Důležitost',
  New: 'Nové',
  Georank: 'Georank',
  DownloadHistory: 'Historie stahování',
  Popularity: 'Oblíbenost',
  Photography: 'Fotografie',
  VectorIllustration: 'Vektorová ilustrace',
  Horizontal: 'Horizontal',
  Landscape: 'Krajina',
  Portrait: 'Portrét',
  Square: 'Čtverec',
  Panorama: 'Panorama',
  numPpl: 'Počet lidí',
  WithoutPeople: 'Bez lidí',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: 'Neobsahuje',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: 'Minulý týden',
  LastMonth: 'Last month',
  PastThreeMonths: 'Během posledních 3 měsíců',
  PastTwodays: 'V minulých 2 dnech',
  Male: 'Mužské',
  Female: 'Ženské',
  Standard: 'Standardní',
  extended: 'Rozšířený',
  Editorial: 'Redakční',
  Black: 'Black',
  Caucasian: 'Běloch',
  AfricanAmerican: 'Afroameričan',
  Asian: 'Asiat',
  EastAsian: 'East Asian',
  Hispanic: 'Hispánec',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: 'Děti',
  Teenagers: 'Mládež',
  Adults: 'Dospělí',
  Seniors: 'Senioři',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: 'Ano',
  Nonloopable: 'Ne',
  Music: 'Hudba',
  SoundEffects: 'Zvukové efekty',
  LogoAndIdents: 'Loga & Identity',
  Veryslow: 'Velmi pomalé',
  VeryslowSubText: '(0-79)',
  Slow: 'Pomalé',
  SlowSubText: '(80-119)',
  Medium: 'Střední',
  MediumSubText: '(120-139)',
  Fast: 'Rychlé',
  FastSubText: '(140-159)',
  Veryfast: 'Velmi rychlé',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: 'Oblíbené',
  MostDownload: 'Nejvíce stahované',
  Credits: 'Kredity',
  Freshness: 'Přidáno',
  pickAGenre: 'Zvolte žánr',
  // Paginations
  Of: 'z',
  Page: 'Stránka',
  // home
  recommended: 'Doporučeno vám',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: 'Populární klíčová slova',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: 'Herní zvuky',
  cartoonSounds: 'Cartoon zvuky',
  soundPacks: 'Zvukové balíčky',
  interfaceSounds: 'Zvuky rohraní',
  transitionsAndMovementsSounds: 'Přechody & Pohyb',
  natureSounds: 'Zvudy přírody',
  domesticSounds: 'Domácí zvuky',
  urbanSounds: 'Městské zvuky',
  industrialSounds: 'Industriální zvuky',
  futuristicSounds: 'Futuristické zvuky',
  humanSounds: 'Lidské zvuky',
  othersSounds: 'Jiné',
  ambientSounds: 'Okolní',
  childrenSounds: 'Dětské',
  cinematicSounds: 'Filmové',
  classicalSounds: 'Vážná hudba',
  corporateSounds: 'Korporátní',
  electronicaSounds: 'Electronická hudba',
  soulSounds: 'Soul & R&B',
  jazzSounds: 'Jazz',
  popSounds: 'Pop',
  worldBeatSounds: 'World Beat',

  countrySounds: 'Country & Western',
  drumSounds: 'Drum & Bass',

  folkSounds: 'Folk & Akustické',
  funkSounds: 'Funk & Groove',

  hiphopSounds: 'Hip Hop',
  holidaySounds: 'Prázdninové & sezónní',

  rockSounds: 'Rock',

  individualSounds: 'Individuální',
  introSounds: 'Intro & Outro',
  ascSounds: 'Ascending & Descending',
  businessSounds: 'Business & Media',
  orchestralSounds: 'Orchestral',
  festiveSounds: 'Festive',
  percussiveSounds: 'Percussive',
  miscellaneousSounds: 'Miscellaneous',
  packsSounds: 'Packs',
  percussionSounds: 'Perkusní zvuky',
  sampleLoopSounds: 'Samplovací smyčka',

  // Search images
  searchImage: 'Photos & Vectors',
  StockPhotosAndImages: 'Fotografie & snímky',
  matches: 'odpovídá',
  editorial: 'Redakční',
  downloaded: 'Tento obrázek byl již v minulosti stažen',
  to: 'to',
  relatedSearches: 'Podobné vyhledávání',
  similarTo: 'Podobné jako',
  searchByImage: 'Vyhledávání pomocí obrázku',
  options: 'Možnosti',
  searchByImageMsg:
    'Vyhledávejte snímky pomocí fotografie namísto textu. Zkuste snímek přetáhnout do vyhledávacího pole.',
  mustBeJPGorPNG: 'Snímek musí být ve formátu JPEG / PNG',
  sizeLessThan5MB: 'Velikost snímku musí mít méně než 5 MB',
  sizeLessThan4MB: 'Velikost snímku musí mít méně než 4 MB',
  dragOrDropFile: 'Přetáhněte soubor nebo',
  browse: 'Procházet',
  uploadedImage: 'Nahraný obrázek',
  similarStockPhotos: 'Podobné fotografie',
  // Search footage
  searchFootage: 'Video',
  StockFootage: 'Video',
  businessFootage: 'Hacks to make the most of your business videos',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  audioProperties: 'Vlastnosti zvuku',
  footageProperties: 'Vlastnosti záznamu',
  modalDownloadTitle: 'Možnosti stahování souborů',
  credit: 'Kredit',
  saveAs: 'Uložit jako',
  ownLicense: 'Normální stažení (vlastní licence)',
  downloadAs: 'Stáhnout jako',
  insufficientCredits: 'Nedostatečné kredity',
  footageDetail: 'Detail videa',
  custom: 'Vlastní',
  personalAndCom: 'Osobní a komerční použití Reklamy, prezentace, sociální média, web.',
  licenseType: 'Typ licence',
  requiredForResale:
    'Požadováno pro převýšení nebo distribuci v divadelních představeních, placeném přístupu, streamovaném videu, hrách a aplikacích.',
  stockAudioKeywords: 'Klíčová slova pro stock audio',
  stockFootageKeywords: 'Klíčová slova videa',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: 'Stereo',
  fileSize: 'Velikosti souborů',
  footageId: 'Video ID',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  fileName: 'Název souboru',
  // Search audio
  searchAudio: 'Audio (Hudba & Zvukové efekty)',
  StockAudio: 'Stock audio',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: 'Zkopírovat odkaz',
  preview: 'Náhled',

  bestSellingFootage: 'Prohlédněte si nejprodávanější videa',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: 'Podobné snímky',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: 'Nedávno zobrazené snímky naleznete zde',
  rVVectors: 'Nedávno zobrazené ilustrace naleznete zde',
  rVFootage: 'Nedávno přehraná videa naleznete zde',
  rVAudio: 'Nedávno poslechnuté audio soubory naleznete zde',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: 'Jejda, vyhledání s těmito filtry nenašlo žádné výsledky.',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle: 'Zkuste odstranit některé filtry nebo vyzkoušejte jiné klíčové slovo.',
  emptyReverseSearchTitle: 'Nepodařilo se nalézt žádné výsledky!!',
  emptyReverseSearchSubTitle1:
    'Nahraný soubor obsahuje chyby, ujistěte se prosím, zda jste splnili následující kritéria:',
  emptyReverseSearchSubTitle2: 'Upřesněte vyhledávání pomocí klíčových slov',
  emptyReverseSearchSubTitle3: 'Snímek musí být v JPG / PNG formátu',
  emptyReverseSearchSubTitle4: 'Velikost fotografie musí být menší než 5 MB',
  emptySearchResultClearFilter: 'Vymazat všechny filtry',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: 'Zadejte prosím klíčové slovo',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // collection search result page
  searchCollection: 'Vyhledat kolekce',
  personal: 'Osobní',
  groups: 'Skupiny',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  createNew: 'Vytvořit nový',
  createNewCollection: 'Vytvořit novou kolekci',
  titleItem: 'NADPIS',
  selectGroup: 'VYBRAT SKUPINU',
  description: 'Popis',
  charactersRemaining: 'Zbývá 180 písmen',
  cancel: 'Zrušit',
  create: 'Vytvořit',
  or: 'NEBO',
  defaultCollection: 'Výchozí kolekce',
  addToMyCollections: 'Přidat do Lightboxu',
  addedToCollection: 'Přidáno do sbírky',
  removedFromCollection: 'Odstraněno ze sbírky',
  collectionModalPlaceholder: 'např. Příroda',
  groupNameShowsHere: 'Název skupiny je zobrazen zde',
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: 'Sdíleno se mnou',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: 'Majitel',
  images: 'Snímky',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: 'Osobní',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: 'Sdílet',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: 'Nemáte žádné sbírky',
  btnUnDo: 'Vrátit zpět',
  // my collection
  dateAdded: 'Přidáno',
  collectionName: 'Název kolekce',
  selectAll: 'Vybrat vše',
  delete: 'Odstranit',
  seeAll: 'Zobrazit vše',
  deleteCollection: 'Odstranit kolekci',
  deleteWarning:
    'Dokončením této akce trvale smažete svou kolekci/kolekce spolu s jejím/jejich obsahem. Vezměte prosím na vědomí, že tuto akci nelze vrátit zpět.',
  createCollection: ' likebox byl vytvořen',
  shareCollection: 'Sdílet kolekci',
  shareWithTeam: 'Share in team',
  selectUsers: 'Vybrat uživatelé',
  searchUserName: 'vyhledat jméno uživatele',
  copy: 'Zkopírovat',
  titleCopyModal: 'Zkopírovat vybrané položky do',
  collectionUppercase: 'KOLEKCE',
  groupCollection: 'SKUPINOVÉ',
  moveImage: 'Přesunout vybrané položky do',
  defaultSelect: 'Název kolekce',
  moveLikebox: 'Move',
  messsageDeleteLikebox:
    'Dokončením této akce trvale smažete svou kolekci/kolekce spolu s jejím/jejich obsahem. Vezměte prosím na vědomí, že tuto akci nelze vrátit zpět.',
  areYouSure: 'Jste si opravdu jist/a?',
  editCollection: 'Upravit kolekci',
  collectionNameUpper: 'Název kolekce',
  deleteSuccessful: 'has been successfully deleted',
  edit: 'Upravit',
  messageRemoveImage: 'Opravdu si přejete odstranit vybrané položky z kolekce?',
  titleRemove: 'Odstranit vybrané položky',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: 'Uložit',
  shareLikebox: 'Sdíleno se mnou',
  remove: 'Odstranit',
  moveTo: 'Přesunout do',
  createNewPlaceholder: 'např. Příroda',
  shareViaEmail: 'Sdílet pomocí emailu',
  sharePublicly: 'Share publicly',
  receipnetEmail: 'Emailová adresa příjemce',
  message: 'ZPRÁVA',
  insertReceipmentEmail: 'Vložit emailovou adresu příjemce',
  messageText: 'Text zprávy',
  exportCollection: 'Exportovat kolekci',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: 'Přidat novou kolekci',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: 'FORMÁT',
  lowCredit: 'Nedostatečný zůstatek',
  downloadSuccess: 'Děkujeme za stažení! Během chvilky proběhne stažení.',
  downloadFailed: 'Chyba při stahování: Vyskytla se chyba, zkuste to prosím znovu.',
  downloadedLabel: 'Staženo',
  any: 'Jakýkoliv',
  resetFilter: 'Vyčistit filtr',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: 'Sledovat',
  name: 'Jméno',
  location: 'Lokalita',
  since: 'Od',

  // Můj účet
  updated: 'Update',
  userName: 'UŽIVATELSKÉ JMÉNO',
  firstName: 'Jméno',
  lastName: 'Příjmení',
  myProfile: 'My Profile',
  changeProfiledetail: 'Zde si změníte své profilové údaje',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: 'Mé balíčky',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: 'Použij nyní, uhraď později',
  bodyUNPL:
    'Use Now, Pay Later (UNPL) je druh krátkodobého financování, který spotřebitelům umožňuje stahovat obsah a platit za něj později, často navíc bez poplatků.',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',
  // audio player
  categoryGenre: 'Kategorie / Žánr',
  duration: 'Délka',
  seeMore: 'Zobrazit více',
  seeLess: 'Zobrazit méně',
  audioID: 'ID audia',
  // category photo
  landscape: 'Landscape',
  Babies: 'Babies',
  business: 'Business',
  food: 'Food',
  animals: 'Animals',
  technology: 'Technology',
  healthcare: 'Healthcare',
  nature: 'Nature',
  romance: 'Romance',
  // category vector
  graphic: 'Graphic',
  advertising: 'Advertising',
  flower: 'Flower',
  abstract: 'Abstraktní',
  Illustration: 'Ilustrace',
  pop: 'Pop',
  bird: 'Bird',
  pixel: 'Pixel',
  threeD: '3D',

  // footage category
  education: 'Education',
  communication: 'Communication',
  beauty: 'Beauty',
  concept: 'Concept',
  fitness: 'Fitness',
  lifestyle: 'Lifestyle',
  celebration: 'Celebration',
  family: 'Family',
  fashion: 'Fashion',

  // Download-history modal
  reDownload: 'Opětovně stáhnout',
  chooseCustomTagOrCreate: 'Zvolte customizované označení nebo vytvořte nové',
  downloadDetails: 'Údaje o stahování',
  image: 'Image',
  downloadType: 'Typ stažení',
  groupName: 'Jméno skupiny',
  size: 'Velikost',
  dateDownloaded: 'Datum stažení',
  reLicense: 'Znovu licencovat',
  customFields: 'Vlastní pole',
  saveAndClose: 'Uložit a zavřít',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: 'Datum',
  export: 'Exportovat',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: 'Obsah zdarma',
  searchById: 'Vyhledat pomocí ID',
  pleaseSelect: 'Vyberte si prosím',
  totalOutstanding: 'Celkem k úhradě',
  viewInvoices: 'Zobrazit faktury',
  // Image detail page - select download size sidebar
  standardSizes: 'Standardní velikosti',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: 'Oříznout',
  // empty data
  opsNothingToSeeHere: 'Jejda! Nelze nic zobrazit.',
  // search image
  authentic: 'Autentický',
  // search audio
  mostDownloaded: 'Nejčastěji stahované',
  download: 'Stáhnout',
  // audio category
  soulAndRB: 'Soul & R&B',
  corporate: 'Corporate',
  electronica: 'Electronica',
  classical: 'Classical',
  funkAndGrooveMusic: 'Funk & Groove music',
  worldBeat: 'World Beat',
  cinematic: 'Kinematický',
  jazz: 'Jazz',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: 'Další strana',
  reverseImageSearch: 'Reverzní Vyhledávání Fotografií',

  group: 'SKUPINOVÉ',
  needHelp: 'Potřebujete pomoc?',
  copied: 'Zkopírováno!',
  copyPortfolioLinkSuccess: 'Odkaz přispěvatele portfolio zkopíroval do schránky.',

  // my activities
  myActivity: 'Mé aktivity',
  recentLike: 'Nedávno označené jako oblíbené',
  recentView: 'Má nedávná zobrazení',
  myActivityViewDesc: 'Historie nedávných zobrazení se ukládá pouze po dobu 3 měsíců',
  myActivityLikeDesc: 'Zobrazování historie pouze za poslední 3 měsíce',

  // dashboard
  profile: 'Profil',

  // Pricing
  pricingCreditText: 'Kredit',

  // Dashboard - Credit Usage
  user: 'User',
  creditUsage: 'Credit usage',
  searchPlaceholder: 'Search name, username, role, group',
  all: 'VŠE',
  active: 'Active',
  deleted: 'Deleted',
  reset: 'Resetovat',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: 'AI Powered Search',
  aiPoweredSearchOn: 'AI Powered Search is on',
  aiSearchIntroduction:
    'Vyzkoušejte náš rychlejší a výkonnější vyhledávač. Naše vyhledávání s AI se postará o zbytek!',
  ok: 'OK',

  // Filter bar ai

  bstract: 'Abstraktní',
  macroCloseUp: 'Makro/detailní záběr',
  object: 'Objekt',
  watercolor: 'Akvarel',
  flat: 'Plochý design',
  cartoon: 'Cartoon',
  geometric: 'Geometrické',
  gradient: 'Gradient',
  isometric: 'Isometrické',
  '3d': '3D',
  handDrawn: 'Ručně kreslený',
  natural: 'Přírodní',
  warm: 'Teplý',
  dramatic: 'Dramatický',
  vividAndBold: 'Živý a odvážný',
  blackAndWhite: 'Černobílý',
  // introduce Free image research
  imageResearchContentTitle:
    'Představujeme bezplatnou službu pro vyhledávání 123RF snímků: Dokonalé obrázky, nulové problémy',
  imageResearchContentDesc:
    'Revolucionizujeme vyhledávání snímků pro korporátní zákazníky, díky čemuž je snadné najít dokonalý obrázek a uvést vaši vizi do praxe.',
  submitRequest: 'Odeslat žádost',
  imageResearchContentInfoTitle1: 'Vyhledávání obrázků zjednodušené',
  imageResearchContentInfoDesc1:
    'Začít je snadné. Stačí nám poskytnout podrobný popis obrázku, který si přejete, včetně stylu, nálady, barev a konkrétních prvků. Čím více detailů poskytnete, tím lépe dokážeme splnit vaše požadavky.',
  imageResearchContentInfoTitle2: 'Jak to funguje?',
  imageResearchContentInfoTitle3: 'Rychlá, spolehlivá služba',
  imageResearchContentInfoTitle4: 'Nechť práci děláme za vás',
  imageResearchContentInfoDesc4:
    'Najdeme pro vás dokonalé obrázky, takže se můžete soustředit na svůj projekt. Důvěřujte nám, že vaši vizi uvádíme do života s vysoce kvalitními, pečlivě vybranými obrázky.',
  requestForm: 'Žádost o formulář',
  whatDoYouWantThisImageFor: 'K čemu chcete tento obrázek použít?',
  websiteBanner: 'Webový banner',
  socialMedia: 'Sociální média',
  blogPost: 'Blogový příspěvek',
  advertisement: 'Reklama',
  print: 'Tisk',
  others: 'Ostatní',
  othersOutputPlaceholder: 'Zadejte jiné typy výstupu',
  whatIsPrimaryMessage: 'Jakou hlavní zprávu nebo téma chcete, aby obrázek předával?',
  whatIsPrimaryMessagePlaceholder: 'Např. Pes s kožešinou běží s ženou v parku',
  areThereSubjectYouWantInThePicture:
    'Jsou zde konkrétní předměty nebo prvky, které chcete zahrnout do obrázku?',
  egPeopleNatureTechnologyAbstract: 'Např. lidé, příroda, technologie, abstraktní koncepty',
  areThereImageOrStyleReference:
    'Jsou zde nějaké obrázky nebo styly, které jste dříve viděli a které byste chtěli, abychom použili jako inspiraci nebo referenci?',
  ifPeopleIncludeWhatTheirDemographic:
    'Pokud mají být zahrnuty osoby, jaká by měla být jejich demografie?',
  egAgeGenderEthnicityOccupation: 'Např. věk, pohlaví, etnicita, povolání',
  areThereAnyThingToAvoid:
    'Jsou zde nějaké prvky, předměty nebo témata, kterým chcete v obrázku vyhnout?',
  whoisYourTargetAudience: 'Kdo je vaše cílová skupina?',
  whoisYourTargetAudiencePlaceholder: 'Např. Mladí dospělí, kteří rádi čtou',
  typeOfImage: 'Typ obrázku',
  whatMoodShouldImageEvoke: 'Jakou náladu nebo emoci by měl obrázek vyvolat?',
  happy: 'Šťastný',
  sad: 'Smutný',
  calm: 'Klidný',
  excited: 'Vzrušený',
  angry: 'Rozzlobený',
  enterCustomMood: 'Zadejte vlastní náladu',
  areThereAnyOrientationForImage: 'Jsou pro obrázek potřeba nějaké konkrétní orientace?',
  enterCustomOrientation: 'Zadejte vlastní orientaci',
  isThereLocationYouWantImageToBeIn:
    'Je nějaké konkrétní místo nebo prostředí, kde byste chtěli, aby se obrázek nacházel?',
  no: 'Ne',
  yes: 'Ano',
  shareWithUsAReference: 'Sdílejte s námi referenci (pouze JPG nebo PDF. Max 4MB)',
  replace: 'Nahradit',
  areThereAnySpecificRequirements: 'Existují nějaké jiné konkrétní požadavky nebo preference?',
  enterOtherComments: 'Zadejte další komentáře',
  submit: 'Odeslat',
  fieldCannotEmpty: 'Toto pole nemůže být prázdné',
  selectAtLeastOne: 'Vyberte prosím alespoň jednu možnost',
  fileMustBeJpgOrPdf: 'Soubor musí být formátu JPG nebo PDF',
  fileSizeLessThan4MB: 'Velikost souboru musí být menší než 4MB',
  maximumFileNameAllow255: 'Maximální povolený počet znaků je 255',
  updateSuccessfully: 'Vaši žádost jsme přijali.',
  freeImageResearch: 'Bezplatné vyhledávání obrázků',
  newService: 'Nová služba!',
  freeImageResearchIntroduction:
    'Vyzkoušejte naši službu pro vyhledávání obrázků a uvidíte, proč naše obrázky dokonale odpovídají vašim požadavkům.',
  later: 'Později',
  letTryIt: 'Zkusme to!',
  viewPdfOfSampleOutput: 'Zobrazit PDF ukázky výstupu',
  imageResearchContentInfoDesc2: (
    <>
      Náš tým kombinuje
      {' '}
      <strong>technologii AI</strong>
      {' '}
      a zkušenosti k nalezení ideálních obrázků
      pro vás a pokročilé algoritmy zúží možnosti z naší rozsáhlé knihovny na základě vašeho vstupu.
      Náš tým
      {' '}
      <strong>oddaných a zkušených výzkumníků</strong>
      {' '}
      upraví výsledky tak, aby perfektně
      vyhovovaly vašim kritériím.
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      Výsledky doručujeme do
      {' '}
      <strong>dvou pracovních dnů</strong>
      {' '}
      včetně podrobného vysvětlení výběru obrázků. Zaměřte se na svůj byznys, zbytek zařídíme my.
    </>
  ),
  characterMaxChar255: 'Maximální povolený počet znaků je 255',

  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: 'je vyžadován',

  relatedSearch: 'Related searches',
  showMore: 'Ukázat více',
  showLess: 'Zobrazit méně',

  requiredWithVariable: '{{var}} je vyžadován',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: 'Generátor Obrázků AI',
  imageGenerator: 'Generátor Obrázků',
  aiImageGeneratorDesc: 'Kreativita s rychlostí vaší představivosti.',
  generate: 'Generovat',
  exploreVisualsCreatedWithThePowerOfAI: 'Objevujte vizuály vytvořené s využitím síly AI',
  turnYourImaginationIntoRealityIn3SimpleSteps:
    'Proměňte své představy ve skutečnost ve 3 jednoduchých krocích',
  startWithGoodTextPrompt: (
    <>
      Začněte s
      {' '}
      <span>
        dobrým
        <br />
        textovým zadáním
      </span>
    </>
  ),
  startWithGoodTextPromptDesc:
    'Dobrý požadavek zaručí skvělé výsledky. Abyste dosáhli nejlepších výsledků, měl by váš podnět obsahovat perspektivu, téma, akci, klíčová slova, osvětlení a styl.',
  reviewYourAIGeneratedImages: (
    <>
      Prohlédněte si vaše
      {' '}
      <br />
      <span>vygenerované obrázky pomocí AI</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    'Náš generátor AI vygeneruje a zobrazí 3 náhledové obrázky, které nejlépe odpovídají vašemu zadání.',
  downloadAndLicenseVisuals: (
    <>
      Stáhněte a
      {' '}
      <br />
      <span>licencujte vizuály</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    'Nyní můžete stahovat a licencovat tyto obrázky pro osobní a komerční použití s právním zajištěním ve výši 25 000 dolarů.',
  needSomeIdeas:
    'Potřebujete nějaké nápady? Začněte od těchto vzorů a znovu vygenerujte své nápady!',
  tryPrompt: 'Vyzkoušejte tip',
  creativityAtTheSpeedOfYourImagination: 'Kreativita s rychlostí vaší představivosti. ',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    'Vygenerujte si své vlastní obrázky pomocí umělé inteligence z textových zadání s Corporate+ během několika sekund!',
  tryNow: 'Vyzkoušet nyní',

  /// Random prompt
  prompt1: 'Zobrazte sebevědomého, ale přátelského 28letého korejského zpěváka s fascinujícím jevištním vystupováním. Předveďte jeho dynamické vokální schopnosti a magnetické charisma prostřednictvím expresivního portrétu, který zdůrazňuje jeho nápadné rysy. Vykreslete jeho husté, černé vlasy a intenzivní, emotivní oči v živých detailech, které zprostředkují hloubku jeho umělecké vášně. Oblečte ho do stylové, moderní módy, která odráží jeho hudební osobnost, možná s nádechem drsnosti nebo sofistikovanosti. Umístěte ho do prostředí, které naznačuje živé vystoupení nebo nahrávací studio, zachycující energii a vzrušení jeho řemesla. Použijte dramatické osvětlení a rámování, abyste vytvořili filmovou, nadživotní kvalitu, která zdůrazňuje jeho status stoupající hvězdy. Vneste do kompozice pocit pohybu a dynamiky, jako by byl uprostřed podmanivého vokálního vystoupení. Cílem je vytvořit obraz, který okamžitě zaujme diváka a předvede zpěvákovo nepopiratelné jevištní vystupování a vokální talent.',
  prompt2: 'Fotorealistický obraz majestátního lva odpočívajícího na skále s rozlehlou africkou savanou v pozadí. Použijte fotoaparát s objektivem 400 mm při cloně F 2,8, abyste rozostřili pozadí a ostře zaostřili na lva. Osvětlení by mělo být přirozené a teplé, zachycující zlaté odstíny západu slunce. Zdůrazněte fotorealismus, abyste vytvořili živý, detailní obraz, který zprostředkuje krásu a majestát divoké zvěře.',
  prompt3: 'Smíšená technika zobrazující futuristického samuraje v akční póze, který vládne zářícím katanou, umístěného před kyberpunkovou městskou krajinou s neonovými světly a hologramy. Začleňte různé textury a materiály, včetně kovových a digitálních prvků, abyste vytvořili vizuálně bohatou a dynamickou scénu.',
  prompt4: 'Veselá digitální ilustrace mladé princezny v splývavých šatech, která si hraje s magickými tvory v zakletém lese. Obklopte ji barevnými květinami, motýly a třpytivým vílím prachem na živém a rozmarném pozadí.',
  prompt5: 'Detailní kresba tužkou starověkého válečníka s povětrnostně zvětralou tváří a odhodlaným výrazem, který drží meč. Použijte propracované stínování a textury, abyste zdůraznili válečnickou zbroj a bojové jizvy, a vytvořte nadčasový a silný obraz.',
  prompt6: 'Surrealistická smíšená mediální koláž cestovatele časem proplouvajícího různými historickými érami, s prvky jako staré mapy, starověké artefakty a futuristické vychytávky. K vytvoření humorného a k zamyšlení nutícího díla použijte kombinaci starých výstřižků z novin, fotografií a digitálního umění.',
  prompt7: 'Detailní digitální ilustrace dystopického městského panoramatu s vysokými mrakodrapy, létajícími auty a tmavými, zlověstnými mraky. Zaměřte se na vytvoření pocitu hloubky a měřítka s propracovanými detaily architektury a dramatickým osvětlením pro zvýraznění nálady.',
  prompt8: 'Portrét moudrého starce držícího magickou hůl, zachycený v dramatickém chiaroscuro osvětlení, aby se zdůraznily rysy starcova obličeje a zářící runy na holi. Použijte bohaté textury a stíny, abyste vytvořili pocit tajemství a moudrosti.',
  prompt9: 'Živá fantasy ilustrace jezdce na drakovi prolétávajícího oblohou, s jezdcem oděným v brnění a s roztaženými křídly draka. Obklopte je mraky a vzdálenými horami a přidejte pocit dobrodružství a majestátnosti.',
  prompt10: 'Neposedná černobílá pouliční fotografie rušného trhu v asijském městě, s prodejci prodávajícími barevné ovoce a zeleninu. Použijte malou hloubku ostrosti, abyste se zaměřili na výrazy a interakce prodejců a zachytili živou atmosféru.',
  prompt11: 'Kresba v rozmaritém stylu akvarelu zobrazující jednorožce s duhovou hřívou, stojícího v magickém lese obklopeném zářícími houbami a třpytivými světluškami. Použijte jemné pastelové barvy a plynulé tahy štětcem, abyste vytvořili zasněnou, okouzlující scénu.',
  prompt12: '3D render moderní kuchyně s elegantním, minimalistickým designem, nerezovými spotřebiči a velkým ostrůvkem uprostřed. Použijte fotorealistické textury a osvětlení, abyste zdůraznili čisté linie a moderní styl, se zaměřením na odrazy a materiály.',
  prompt13: 'Fantastická krajinná malba plovoucího ostrova na obloze, s vodopády kaskádovitě stékajícími z okrajů a bujnou vegetací pokrývající povrch. Obklopte ostrov nadýchanými mraky a sytě modrou oblohou, abyste vytvořili pocit úžasu a magie.',
  prompt14: 'Detailní portrét vikingského válečníka s copánky a divokým výrazem, držícího bojovou sekeru. Pozadí by mělo zobrazovat bouřlivé moře a drsné útesy, s dramatickým osvětlením, které zvýrazní rysy válečníka a jeho zbroj.',
  prompt15: 'Surrealistická koláž smíšených médií zobrazující steampunk vynálezce v přeplněné dílně plné ozubených kol, hodinových mechanismů a parních strojů. Použijte vintage fotografie, staré výkresy a kovové textury, abyste vytvořili vizuálně bohatou a imaginativní scénu.',
  prompt16: 'Digitální ilustrace futuristické noční metropole s vysokými mrakodrapy, neonovými značkami a létajícími vozidly. Použijte živé barvy a dynamické osvětlení k vytvoření živé a imersívní atmosféry, zdůrazňující technologický pokrok a rušnou energii města.',
  prompt17: 'Fotorealistický 3D render útulného koutku na čtení s pohodlným křeslem, malým stolkem s lampou a policemi plnými knih. Scéna by měla mít teplé, lákavé osvětlení a měkké textury, zachycující klidnou a relaxační atmosféru prostoru.',
  prompt18: 'Zobrazte průhlednou, skleněnou kabelku s plně funkčním akváriem integrovaným do designu. Pečlivě vykreslete jemné, plavající ryby uvnitř kabelky a vytvořte fascinující a nekonvenční doplněk. Zajistěte, aby bylo akvárium bezchybně integrováno do struktury kabelky s plynulými přechody mezi oběma prvky. Umístěte kabelku prominentně do prostředí luxusního obchodního domu, obklopeného pozadím elegantních, moderních polic, osvětlení a dalších luxusních maloobchodních displejů. Použijte dramatické, směrové osvětlení k vytvoření dramatického efektu a zaujetí pozornosti diváka, čímž se jeho zrak zaměří přímo na vodní kabelku. Zachyťte kabelku z úhlu, který ukáže její průhlednou, téměř éterickou kvalitu, umožňující jasnou viditelnost ryb a vytvářející dojem beztíže nebo tekutinosti.',
  prompt19: 'Zachyťte útulnou, lákavou scénu s plyšovými křesly v tlumené paletě hnědých a olivově zelených tónů. Předveďte křesla v teplém, intimním prostředí, obklopeném detaily, které evokují pocit relaxace a pohodlí, jako jsou měkké textilie, bohaté dřevěné akcenty a možná pohled na krb nebo okno s výhledem na klidnou přírodní krajinu. Zdůrazněte nádhernou, texturní kvalitu čalounění a celkový pocit útulnosti a ústraní.',
  prompt20: 'Zachyťte živý, realistický obraz koroptve chocholaté usedlé na mechem pokryté větvi, umístěné před svěží, smaragdově zelené pozadí, které doplňuje úžasné peří ptáka. Vykreslete složité peří koroptve s vynikajícími detaily, pomocí jemného přechodu světla a stínu, abyste zdůraznili jejich textury. Zdůrazněte bohatou, tmavě zelenou chocholku koroptve, teplý hnědý zobák a jasné, zvídavé černé oči, čímž vytvoříte fascinující portrét přirozené krásy ptáka. Projděte celou scénu teplou, klidnou atmosférou, která zve diváka, aby vstoupil do klidného přírodního prostředí.',
  prompt21: 'Navrhněte vizuálně lákavý obrázek s miskou krémového, zmrazeného jogurtu ozdobeného pestrou směsicí čerstvého ovoce a křupavých ořechů. Zachyťte osvěžující a výživné vlastnosti tohoto zdravého dezertu a zdůrazněte kontrast mezi hladkým, světlým jogurtem a živými barevnými akcenty ovocných ozdob. Uspořádejte ingredience uměleckým a lákavým způsobem, abyste divákovi umožnili ocenit vizuální přitažlivost a předvídat osvěžující, chutný zážitek. Zajistěte, aby celkový obrázek vyzařoval pocit zdraví a hýčkání a zval diváka, aby si vychutnal lahodné a výživné vlastnosti tohoto lahodného pohoštění.',
  prompt22: 'Ponořte se do vizuální hostiny a prozkoumejte surrealistickou květinovou zahradu plnou živých barev a propracovaných detailů. Zachyťte fascinující podstatu květů s kadeřavými, spirálovitými okvětními lístky, pestrými listy a dalšími fascinujícími odstíny – každý z nich je sám o sobě uměleckým dílem. Využijte plynulost akvarelu a výrazové schopnosti inkoustu a použijte techniku mokrého na mokré, abyste vytvořili pocit pohybu a hloubky. Zprostředkujte surrealistickou, snovou kvalitu této květinové říše prostřednictvím hry bohatých, sytých barev a jemného, propracovaného vykreslení každého okvětního lístku a listu.',
  prompt23: 'Vytvořte poutavou ilustraci noční scény s dávným majákem tyčícím se na skalnatém ostrově. Maják zdobený mnoha okny vydává teplé, vlídné světlo, které zdůrazňuje jeho propracovanou architekturu a bohatou historii. Skalnatý ostrov je pokryt svěžími, zelenými stromy, jejichž odraz se třpytí na klidných, měsícem ozářených vodách, které zrcadlí stříbřité záření úplňku. Nebeské těleso zdobí oblohu a vrhá éterické světlo na mraky a moře, zatímco načechrané mraky částečně zakrývají jeho záři a dodávají scéně tajemnost. Jiskřivé hvězdy pokrývají inkoustovou rozlohu a vytvářejí nebeskou tapiserii, která ladí s měsíční září. Toto okouzlující umělecké dílo zachycuje klidnou a mírumilovnou noc, kde osvětlený maják pevně stojí uprostřed nebeské krásy, slouží jako strážce a vytváří pro diváka filmový, snový zážitek.',
  /// Tool list
  // ratio
  ratio: 'Poměr',
  square1_1: 'Čtverec (1:1)',
  square: 'Čtverec',
  portrait2_3: 'Portrét (2:3)',
  portrait: 'Portrét',
  landscape3_2: 'Krajina (3:2)',
  wide16_9: 'Široká (16:9)',
  wide: 'Široká',
  // style
  none: 'Žádný',
  enhance: 'Vylepšit',
  anime: 'Anime',
  photographic: 'Fotografický',
  digitalArt: 'Digitální umění',
  comicBook: 'Komiksový',
  fantasyArt: 'Fantasy umění',
  analogFilm: 'Analogový film',
  neonPunk: 'Neonový punk',
  lowPoly: 'Low poly',
  origami: 'Origami',
  lineArt: 'Linková kresba',
  tileTexture: 'Dlaždicová textura',
  threeDModel: '3D model',
  pixelArt: 'Pixelové umění',
  craftClay: 'Hrnčířský jíl',
  // color
  coolTone: 'Chladný tón',
  mutedColors: 'Potlačené barvy',
  pastelColors: 'Pastelové barvy',
  vibrantColors: 'Živé barvy',
  warmTone: 'Teplý tón',
  // lighting
  lighting: 'Osvětlení',
  backlight: 'Protisvětlo',
  crepuscularRays: 'Sutiny paprsky',
  dimlyLit: 'Stísněně osvětleno',
  goldenHour: 'Zlatá hodina',
  lowLight: 'Slabé světlo',
  rimLighting: 'Okrajové osvětlení',
  studio: 'Studio',
  sunlight: 'Sluneční světlo',
  volumetric: 'Objemové',
  // composition
  composition: 'Kompozice',
  blurryBackground: 'Rozmazaný pozadí',
  closeUp: 'Záznam zblízka',
  macrophotograph: 'Makrofotografie',
  narrowDepth: 'Malá hloubka',
  shotFromAbove: 'Snímek shora',
  shotFromBelow: 'Snímek zdola',
  wideAngle: 'Široký úhel',

  /// FQAs
  allAIImageQuestionsAnswered: 'Všechny vaše dotazy ohledně snímků generovaných pomocí AI.',
  TTI_Q1: 'Co jsou snímky generované pomocí AI?',
  TTI_A1:
    'Snímky generované umělou inteligencí (AI) jsou obrázky vytvořené pomocí algoritmů umělé inteligence. Tyto algoritmy jsou navrženy tak, aby simulovaly lidskou kreativitu, a mohou vytvářet snímky podobné těm, které vytvářejí lidští umělci, ale s jedinečnými a často surrealistickými nebo abstraktními prvky.',
  TTI_Q2: 'Je nějak omezeno, kolik obrázků mohu denně vygenerovat?',
  TTI_A2:
    'Ano, počet snímků, které můžete denně vygenerovat, je omezený. Tento limit zajistí, aby všichni uživatelé měli spravedlivý přístup a dobrou uživatelskou zkušenost při generování AI snímků.',
  TTI_Q3: 'Do you support text prompts in languages other than English?',
  TTI_A3: 'We support only the English language at the moment.',
  TTI_Q4: 'Jaký je rozdíl mezi generováním a licencováním snímku?',
  TTI_A4:
    'Při generování obrázku vytvoříte sadu AI obrázků pro zobrazení. Když je na Corporate+ licencujete, oficiálně získáte přístup ke stažení vysoce kvalitní verze AI obrázku, která je dodávána s vlastní licencí na použití.',
  TTI_Q5:
    'Obrázek, který jsem vytvořil, obsahuje rozpoznatelnou ochrannou známku, pamětihodnost nebo veřejně známou osobnost. Mohu ho stále použít?',
  TTI_A5:
    'Pokud obsahuje rozpoznatelnou osobu, objekt nebo se hlavně zaměřuje na význačný orientační bod, museli byste si zabezpečit povolení přímo od strany zobrazené na obrázku, zejména pokud máte v úmyslu obrázek použít v komerčním prostředí.',
  TTI_Q6: 'Kdo jsou lidé, kteří se objevují na vygenerovaných obrázcích?',
  TTI_A6:
    'Lidé, kteří se objevují na obrázcích vygenerovaných pomocí umělé inteligence, nejsou skuteční lidé, ale digitální výtvory.',
  TTI_Q7: 'Co se děje s obsahem, který jsem vygeneroval(a)? Bude dostupný i pro ostatní uživatele?',
  TTI_A7:
    'Obsah, který vygenerujete, může být zpřístupněn i ostatním uživatelům. Pro více informací se můžete odkázat na naši licenci.',
  TTI_Q8: 'Proč to trvá nějakou dobu načíst, když se pokouším vygenerovat obrázek?',
  TTI_A8:
    'Stránka bude potřebovat několik sekund na vygenerování obrázku. Doba trvání závisí na složitosti zadání, dostupných hardwarových a výpočetních zdrojích a také na množství požadavků, které musí umělá inteligence zpracovat současně.',
  TTI_Q9: 'Jak licencovat AI obrázky, které jsem vygeneroval(a)?',
  TTI_A9:
    'AI obrázky, které jste vytvořili, můžete licencovat jejich stažením z účtu Corporate+. Při každém stažení obdržíte vysoce kvalitní verzi generovaného obrázku včetně právní záruky ve výši 25 000 $.',
  TTI_Q10: 'Můžu si prohlížet minulé generace?',
  TTI_A10:
    'Ano, můžete tak učinit tím, že si uložíte URL adresu svých minulých generací a znovu ji navštívíte. Výsledky generací můžete také sdílet se svými přáteli.',
  TTI_Q11: 'Jak generovat vysoce kvalitní obrázky pomocí AI?',
  TTI_A11: `<ol>
              <li>Začněte s jasným cílem: "Vytvoření vysoce kvalitního obrázku horské krajiny pro cestovatelský blog."</li>
              <li>Specifikujte požadovaný styl a náladu: "Obrázek by měl být jasný a živý s přirozeným, organickým pocitem."</li>
              <li>Popište téma: Buďte konkrétní ohledně tématu obrázku, včetně detailů jako prostředí, osvětlení a další důležité prvky. Například "Zobrazte vrchol hory pokrytý sněhem s klikatou řekou v popředí a jasnou modrou oblohou na pozadí."</li>
              <li>Přidejte příklady: Zahrňte příklady dalších obrázků, které mají podobný styl nebo náladu jako ten, který hledáte. To může pomoci řídit algoritmus umělé inteligence a zajistit, že výstup splňuje vaše očekávání.</li>
              <li>Použijte relevantní klíčová slova: "horská krajina," "vrchol pokrytý sněhem" nebo "jasný a živý."</li>
              <li>Vyhněte se nejasnostem: Buďte při poskytování pokynů co nejjasnější a konkrétnější a vyhněte se používání nejasného nebo víceznačného jazyka, který by mohl vést k neočekávaným výsledkům.</li>
            </ol>`,

  /// Generating page
  bringingYourImagesToLife: 'Oživení vašich obrázků...',
  cplusAiGenerator: 'Generátor',
  generatedImages: 'Vygenerované obrázky',
  promptSettings: 'Nastavení nápovědy',
  pastGeneration: 'Minulé generace',
  generateVariation: 'Generovat variantu',
  iLikeThis: 'To se mi líbí',
  iDislikeThis: 'To se mi nelíbí',
  shareOptions: 'Možnosti sdílení',
  variations: 'Variace',
  copiedSharedUrl: 'Zkopírováno sdílené URL do schránky',

  galleryPrompt1: 'Eterická skromná mořská panna pod vodou, koruna z mušlí obklopená rybami, portrét',
  galleryPrompt2: 'Nedělní pečeně na stole, plná jídla a svíček',
  galleryPrompt3: 'Roztomilá chlupatá liška se směje v lese, vysoce detailní, se zvířaty v lese',
  galleryPrompt4: 'Vysoká kvalita, 8K Ultra HD, tvary hudebních nástrojů a not v dýni z křišťálu, vysoce detailní, magická dýňová lampa, podzimní pocit, klid',
  galleryPrompt5: 'Panoramatická filmová krajina Skotska, jasný krásný den',
  galleryPrompt6: '3D styl malá dívka, 4k, 8k, fotorealistický Octane render, HDR, fotografie, vysoká definice, symetrická tvář, objemové osvětlení, prachový opar, foto, Octane render, 24mm, 4k, DSLR, vysoká kvalita, 60 fps, ultrarealistické',
  galleryPrompt7: 'Muž s hnědou kůží, hrdina úderem planety Jupiter ve vesmíru, čelně',
  galleryPrompt8: 'Sušenka ve horké kávě, ve vánočním prostředí',
  galleryPrompt9: 'Umělecká láhev na vodu na bílém minimalistickém pozadí s listy Monstera',
  galleryPrompt10: 'Mizející diamantový fantasy drak',
  galleryPrompt11: 'Planety v rosných kapkách, planetární rosné kapky, při východu slunce, detailní záběr, hyperdetailní, ostré zaostření, studiová fotografie, složité detaily, velmi detailní',
  galleryPrompt12: 'Straka sedící na truhle plné zlata, ve stylu realistického fantasy umění, drahokam, temné pohádky, tajemné.',
  galleryPrompt13: 'Hora sněhu a ledová stezka na vrcholu hory jdoucí opačným směrem, ultra HD, realistické, živé barvy, vysoce detailní, UHD kresba, pero a inkoust, perfektní kompozice, krásně detailní složité šíleně detailní octanové renderování trendy na artstation, 8k umělecká fotografie, fotorealistické konceptuální umění, měkké přirozené volumetrické filmové perfektní světlo',
  galleryPrompt14: 'Éra mezihvězdné civilizace, stojící vysoko a shlížející na tajemný digitální prostor: super sci-fi mimozemské městské budovy, Exquisite krásný vnější super mrakodrap, ulice, nebeský most, v nebi plavající super sci-fi architektura, válečný stroj, vysoká technologie, nesčetné elektronické obrazovky světla',
  galleryPrompt15: 'Krásná pláž s oceánem v pozadí na Florida Keys v noci, neonové barvy, černé pozadí, klidné vlny, přirozený odstín, západ slunce, klid, relaxační přiblížení na vlny, pláž, srovnaný horizont, růžová, modrá, oranžová, fialová',
  galleryPrompt16: 'Nejlepší kvalita, dvojitá expozice, poštovní umění, umění na popraskaném papíře, detailní pohled na roztomilé kotě hrající si s klubíčkem vlny detailně útulný interiér pokoje, třpytivý, sluneční svit, sluneční paprsky, dynamická póza, ilustrace pohádkové knihy, 2D, ploché, roztomilé, okouzlující, vintage, pohádkové, patchwork, vitráž, detailní ilustrace pohádkové knihy, kinematografické, ultra vysoký detail, malé detaily, krásné detaily, mystické, živé barvy, složité pozadí',

  galleryPrompt17:
    'pyramid na vodě s odrazy ohně, hory, temný surrealismus, atmosférické portréty, ostrý ohnisko',
  galleryPrompt18: 'rudý panda v lese, kinematografický',
  galleryPrompt19: 'moře, měsíc, ryba, hvězdná obloha, loď, barevná ilustrace',
  galleryPrompt20:
    'Živé městské odrazy tancují mezi odvážnými budovami a třpytivou vodou v minimalistickém abstraktním stylu, zachycují živou večerní energii, geometrická abstrakce, současný pop-artový styl s neonovým osvětlením a technikou nalévání akrylu',
  galleryPrompt21:
    'pohled zezadu na siluetu ženy s zářivým mečem, kruhový portál v Arktidě vedoucí k ledovcům, kinematografický střih ve středním plánu, monumentální postava',

  /// error page
  copyrightDetected: 'Detekovány autorskoprávní termíny/osobnosti nebo ochranné známky',
  copyrightDetectedDesc:
    'Jsme odhodláni být zodpovědným AI generátorem a nebudeme generovat výsledky pro autorskými právy chráněné ochranné známky, termíny, značky nebo osobnosti zahrnuté v pokynech, aby se předešlo možnému porušení. Naši Smluvní podmínky stanoví, že NESMÍTE využívat služby způsobem, který porušuje, neoprávněně přisvojuje nebo porušuje jakákoli práva duševního vlastnictví. Upravte prosím svůj pokyn a zkuste to znovu.',
  errorMesTTI: 'Oops! Omlouváme se, ale něco se pokazilo.',
  errorMesTTIDesc: 'Problém byl zaznamenán a bude prošetřen. Prosím, zkuste to znovu později.',
  tryAgain: 'Zkusit znovu',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',
  // download feature
  downloadQuotaFinished: 'Vyčerpali jste kvótu pro stahování',
  aiLicenseHeader: 'Tento soubor byl vygenerován AI (Umělou inteligencí).',
  aiLicenseContent: 'Obsah vytvořený umělou inteligencí nepodléhá standardnímu procesu kontroly shody společnosti 123RF. Tvůrce je odpovědný za to, že takové materiály vytvořené umělou inteligencí neporušují práva duševního vlastnictví ani jiná práva třetích stran. Produkce spamu a jakéhokoli obsahu, který je nepravdivý, zavádějící, škodlivý, násilný, pornografický nebo nezákonný, je přísně zakázána.',
  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: 'Najděte úchvatné obrázky. Hledejte podle klíčových slov nebo frází.',
  searchBarPlaceholderAllImageTablet: 'Najděte úchvatné obrázky.',
  searchBarPlaceholderAllImageMobile: 'Najděte obrázky.',
  searchBarPlaceholderPhotoDesktop: 'Najděte úchvatné fotografie. Hledejte podle klíčových slov nebo frází.',
  searchBarPlaceholderPhotoTablet: 'Najděte úchvatné fotografie.',
  searchBarPlaceholderPhotoMobile: 'Najděte fotografie.',
  searchBarPlaceholderVectorDesktop: 'Najděte úchvatné vektory. Hledejte podle klíčových slov nebo frází.',
  searchBarPlaceholderVectorTablet: 'Najděte úchvatné vektory.',
  searchBarPlaceholderVectorMobile: 'Najděte vektory.',
  searchBarPlaceholderFootageDesktop: 'Najděte úchvatné video. Hledejte podle klíčových slov nebo frází.',
  searchBarPlaceholderFootageTablet: 'Najděte úchvatné video.',
  searchBarPlaceholderFootageMobile: 'Najděte video.',
  searchBarPlaceholderAudioDesktop:
    'Najděte úchvatné audio a hudební soubory. Hledejte podle klíčových slov nebo frází.',
  searchBarPlaceholderAudioTablet: 'Najděte úchvatné audio a hudební soubory.',
  searchBarPlaceholderAudioMobile: 'Najděte audio a hudební soubory.',
  searchBarPlaceholderFreeDesktop: 'Najděte úchvatné snímky zdarma. Hledejte podle klíčových slov nebo frází.',
  searchBarPlaceholderFreeTablet: 'Najděte úchvatné snímky zdarma.',
  searchBarPlaceholderFreeMobile: 'Najděte snímky zdarma.',
  // editorial warning
  forEditorialUseOnly: 'Pouze pro redakční použit',
  forEditorialUseOnlyDesc:
    'Použití tohoto snímku v reklamě nebo k jiným komerčním účelům je zakázáno, pokud není opatřeno dodatečné povolení.',

  // download comp
  downloadHiResComp: 'STÁHNOUT NÁHLED VE VYSOKÉ KVALITĚ',
  downloadComp: 'STÁHNOUT NÁHLED',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),
  textToImage: 'Z textu obrázek',
  descTourTTI: 'Popusťte uzdu své fantazii a tvořte obrázky pomocí textu!',
  colorAndTone: 'Barva a tón',
  titleSuccessDownload: 'Jsme rádi, že jste našli to, co jste potřebovali.',
  hangInThere: 'Držte se! Nebude to trvat dlouho.',
  adultContentTitle: 'Byly zjištěny potenciálně nevhodné termíny pro dospělé.',
  adultContentDesc:
    'Jsme zavázáni k odpovědné umělé inteligenci a proto neposkytujeme výsledky pro určité fráze, koncepty a/ nebo nápady. Prosím, upravte svůj popis (textový popis, který jste zadali pro generování obrázku) a zkuste to znovu.',
  reachedTheLimit: 'Oh ne! Dosáhli jste limitu pro generování AI obrázků na dnešek.',
  backTomorrow: 'Přijďte zítra pro další generace!',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder: 'Pro začátek popište svou vizi zde a my ji oživíme!',

  downloadAgain: 'Download again',
  btnClose: 'Zavřít',

  variousTitle: 'Objevte naše různé modely',
  variousSDXL: 'Přivedete do života ohromující fotorealistické postavy a scény.',
  amazonNova: 'Špičkový model pro generování obrázků.',
  variousEimisAnime: 'Generativní AI model navržený pro tvorbu v anime stylu.',
  variousDynaVisionXL: 'Vytvořte stylizované 3D kreslené postavy.',
  variousJuggernautXL: 'S důrazem na architektonické prvky a krajiny/pozadí.',
  variousDreamShaperXL:
    'Zoom s vysokým rozlišením, vylepšené generace postav a draků, redukce neostrých okrajů.',
  variousRealismEngineSDXL: 'Tento model je specializován na vysoce detailní, realistické obrázky.',
  variousDALLE:
    'DALL·E 3, univerzální model exceluje v přesných transformacích od koncepce k vizuálním proměnám.',
  aiEngine: 'AI Engine',
  'DALL-E': 'DALL-E 3',

  langText: 'Text',
  langElements: 'Elements',
  langAuto: 'Automatické',
  langBgRemoval: 'Odstranění pozadí',
  resetAll: 'Resetovat Vše',
  facebookImage: 'Facebookový obrázek',
  facebookLink: 'Facebookový odkaz',
  facebookCover: 'Titulní fotka na Facebook',
  facebookStory: 'Příspěvek na Facebook',
  instagramPost: 'Příspěvek na Instagram',
  instagramStory: 'Instagramový příběh',
  twitterPost: 'Příspěvek na Twitter',
  twitterHeader: 'Titulní fotka na Twitter',
  pinterestPost: 'Příspěvek na Pinterest',
  emailHeader: 'Emailová hlavička',
  presentation: 'Prezentace',
  presentationWide: 'Široká prezentace',
  ebookCover: 'Titulní strana E-booku',
  linkedinPost: 'Příspěvek na Linkedin',
  brochureCover: 'Titulní strana brožury (A4)',
  postcard: 'Pohlednice',
  photoPrint: 'Tištěná fotografie',
  youtubeProfileImage: 'Profilový obrázek na Youtube',
  youtubeChannelCoverPhoto: 'Titulní fotografie Youtube kanálu',
  langApplyBgRemoval: 'Apply Background Removal',
  autoBnW: 'Automatická černobílá',
  autoAdjust: 'Automatická úprava',
  autoPop: 'Auto Pop',
  original: 'Původní',
  addHeader: 'Přidat záhlaví',
  addTitle: 'Přidat nadpis',
  addBody: 'Přidat hlavní text',
  addAssistive: 'Přidat pomocný text',
  langCompare: 'Porovnání',
  langEnableEditor: 'Upravit obrázek',
  langSimilarStock: 'Podobné skladové obrázky',
  langTextUndo: 'Vrátit zpět',
  langTextRedo: 'Opakovat',
  langTextFont: 'Písmo',
  langTextFontSize: 'Velikost písma',
  langTextColor: 'Barva textu',
  langTextHighlight: 'Zvýraznění',
  langTextAlignment: 'Zarovnání',
  langTextBold: 'Tučné písmo',
  langTextItalic: 'Kurzíva',
  langTextUnderline: 'Podtržení',
  langTextLineSpacing: 'Řádkování',
  langTextLetterSpacing: 'Mezery mezi písmeny',
  langBringForward: 'Přenést blíž',
  langBringBackward: 'Přenést dál',
  langDuplicate: 'Kopírovat',
  langOpacity: 'Neprůhlednost',
  langDelete: 'Odstranit',
  imageProperties: 'Vlastnosti snímku',
  noEditCompareMsg: 'Nebyly provedeny žádné úpravy k porovnání.',
  stockPhoto: 'Reklamní fotografie',
  basicShape: 'Basic Shapes',
  chitChat: 'Chit Chat',
  shapes: 'Shapes',
  elementChitChat: 'Element Chit Chat',
  organicAbstractShapes: 'Organic Abstract Shapes',
  elementShapes: 'Element Shapes',
  langApplyingEdit: 'Applying edit...',
  langTextAllFonts: 'Všechna písma',
  langSearchFontType: 'Hledat typ písma',
  editorCompareImageBeforeTitle: 'Před',
  editorCompareImageAfterTitle: 'Depois',
  langSizePx:
    'Pixel je základní jednotka digitálního obrazu. Velikost obrázku, měřená v pixelech, určuje úroveň detailu a velikost ke stažení.',
  langSizeDpi:
    'Bodů na palec měří rozlišení tisku. Vyšší dpi znamená více detailů. Pro tisk je doporučené rozlišení pro všechny obrázky 300 dpi.',
  cautionTTI: 'Upozornění: Zjistili jsme použití frází v pokynech, které jsou spojeny s ochrannými známkami, autorskými právy, slavnými památkami a/nebo jednotlivci. Upozorňujeme, že jste výhradně zodpovědní za používání Obsahu, včetně toho, že nebudete porušovat žádné platné zákony nebo narušovat žádná práva.',

  addImageReference: 'Přidat referenci obrázku (nepovinné)',
  uploadImageOnly: 'Nahrajte pouze JPG nebo PNG. Max 4 MB.',
  uploadJPGImageOnly: 'Nahrajte pouze JPG. Max 4 MB.',
  dragAndDrop: 'Přetáhněte soubor nebo',
  errorFileSize: 'Tento soubor je příliš velký. Povoleny jsou max. 4 MB.',
  adjustScale: 'Upravte tento rozsah, aby vytvářel velmi podobné nebo odlišné výsledky od vašeho nahraného obrázku. Doporučený rozsah je 7 až 15.',
  similar: 'Podobný',
  different: 'Odlišný',
  uploadNew: 'Nahrát nový',

  imageGuideGeneration: 'Váš obrázek bude řídit váš prompt během generování.',
  notAvailableForEngine: 'Není k dispozici pro vybraný AI Engine.',

  imagePromptDescribe: 'Začněte tím, že poskytnete výzvu k obrázku, který chcete, aby naše AI vygenerovala, popisem toho, co máte na mysli.',
  fileTypeNotSp: 'Nepodporovaný typ souboru.',
  theFileSizeTooSmall: 'Velikost souboru je příliš malá. Musí mít minimálně 512px.',

  relatedSearchPhoto: 'Podobné výsledky vyhledávání',

  STOCK_PHOTO_PATH: 'stock-fotografie',
  STOCK_FOOTAGE_PATH: 'dostupne-videozaznamy',
  STOCK_AUDIO_PATH: 'stock-audio',
  CLIPART_VECTOR_PATH: 'kliparty-vektory',
  ALL_IMAGES: 'Vše',
  PHOTO: 'Photo',
  PHOTOS: 'Fotografie',
  VECTORS: 'Vektory',
  FOOTAGE: 'Videa',
  AUDIO: 'Audio',
  LANG_MORETHANMILLION: 'ROYALTY FREE STOCK FOTOGRAFIE',
  LANG_SVG_OR_EPS: 'SVG nebo EPS',
  LANG_OR: 'Nebo',
  STANDARD_LICENSE: 'Standardní licence',
  EXTENDED_LICENSE: 'Rozšířená licence',
  CREDIT_PACK: 'Balíček s kredity',
  DOWNLOAD_PACK: 'Balíček stahování',
  SUBSCRIPTION_PLAN: 'Plán předplatného',
  LANG_DOWNLOAD: 'Stáhnout',
  STOCKPHOTO: 'Reklamní fotografie',
  STOCK_PHOTO: 'Stock fotografie',
  STOCK_VECTOR: 'Stock vektor',
  PREMIUM_STOCKPHOTO: 'PREMIUM Fotografie',
  PLUS_STOCK_PHOTO: 'PLUS Fotografie',
  PLUS_STOCK_VECTOR: 'PLUS Vektory',
  MOBILE_STOCKPHOTO: 'Mobilní Stock fotografie',
  STOCKFOOTAGE: 'Dostupné videozáznamy',
  IMAGEID: 'ID obrázku',
  FONTID: 'ID',
  MEDIATYPE: 'Typ média',
  COPYRIGHT_TEXT: 'Autorské právo',
  KEYWORDS: 'Klíčová slova',
  LANG_CANCEL: 'Zrušit',
  LANG_PURCHASE: 'Nákup',
  LANG_STOCKVECTORSANDILLUS: 'Reklamní vektory a ilustrace',
  LANG_STOCKPHOTOSANDIMAGES: 'Obrázky a Fotografie',
  LANG_MATCHES: 'shoduje se',
  LANG_VECTORRESULTS: '{number} {keyword} royalty-free vektorových ilustrací, které pro vás byly nalezeny.',
  LANG_PHOTORESULTS: '{number} {keyword} royalty-free fotografií a snímků, které pro vás byly nelezeny.',
  LANG_SIMILARIMGS: 'Podobné fotografie',
  LANG_COLLECTIONS: 'Kolekce',
  LANG_ALL: 'Vše',
  LANG_STD: 'Standardní',
  LANG_EXTD: 'Rozšířená',
  LANG_COMMERCIAL: 'Komerční',
  LANG_SUBSCRIPTION: 'Předplatné',
  LANG_EDITORIAL: 'Redakční',
  LANG_UPLOADED: 'Nahráno',
  LANG_FRESHNESS: 'Období',
  LANG_ANYTIME: 'Kdykoli',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: '< 2 dny',
  LANG_PASTWEEK: 'Poslední týden',
  LANG_PASTMONTH: 'Poslední měsíc',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/měsíc",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/rok",
  LANG_PAST3MONTHS: '3 měsíce',
  LANG_NUMOFPPL: 'Počet osob',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: 'Neobdrželi jste ověřovací e-mail?',
  LANG_WITHOUTPEOPLE: 'Bez lidí',
  LANG_PERSON: 'osoba',
  LANG_PEOPLE: 'lidé',
  LANG_INFANTS: 'Kojenci',
  LANG_TODDLERS: 'Batolata',
  LANG_TEENS: 'Dospívající',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: 'Kavkazané',
  LANG_BLACK: 'Černoši',
  LANG_HISPANIC: 'Hispánci',
  LANG_EAST_ASIAN: 'Východoasijci',
  LANG_SOUTHEAST_ASIAN: 'Jihovýchodní Asie',
  LANG_INDIAN: 'Indiáni',
  LANG_MIDDLE_EASTERN: 'Střední východ',
  LANG_AGE: 'Věk',
  LANG_ALL_SHAPE: 'See All',
  LANG_ETHNICITY: 'Etnická příslušnost',
  LANG_PEOPLEORMORE: '4 osoby nebo více',
  LANG_COLOR: 'Barva',
  LANG_OTHEROPTION: 'Ostatní',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: 'Jednobarevné/průhledné pozadí',
  LANG_FILTER_BY: 'Filtrovat podle',
  LANG_FILTER: 'Filtr',
  LANG_PHOTOGRAPHY: 'Fotografie',
  LANG_VECTORILLUST: 'Vektorové ilustrace',
  LANG_SORTBY: 'Rozdělení dle',
  LANG_MORE: 'Více na',
  LANG_RELEVANCE: 'Náhodně',
  LANG_RESEND: 'Odeslat znovu',
  LANG_RESEND_VERIFICATION: 'Opětovné odeslání ověřovacího e-mailu',
  LANG_NEWEST: 'Nové',
  LANG_GEORANK: 'Georank',
  LANG_ORIENTATION: 'Orientace',
  LANG_HORIZONTAL: 'Horizontální',
  LANG_PORTRAIT: 'Portrét',
  LANG_SQUARE: 'Čtvercová',
  LANG_PANORAMA: 'Panorama',
  LANG_SELECTIVE_FOCUS: 'Selektivní zaměření',
  LANG_PATTERN: 'Vzor',
  LANG_VIBRANCE: 'Vibrance',
  LANG_VERIFICATION_DESCRIPTION: 'Před tím, než začnete stahovat obsah z 123RF, byste měli svůj účet ověřit. Zkontrolujte prosím svůj e-mail, nebo klikněte na níže uvedený odkaz, pokud si přejete email znovu odeslat.',
  LANG_VERIFICATION_TITLE: 'Ověřte prosím svůj e-mail.',
  VERIFICATIONRESETUNABLE: 'Ups! Váš email se nepodařilo odeslat. Kontaktujte prosím naši ',
  LANG_CHOOSEUPTO3COLORS: 'Max. 3 barvy',
  LANG_ISOLATED: 'Izolovaný',
  LANG_STYLE: 'Styl',
  LANG_SAFESEARCH: 'Bezpečné vyhledávání',
  LANG_ONLY_AUTHENTIC: 'Pouze autentické',
  LANG_EXCLUDE_PLACEHOLDER: 'např. sýr',
  LANG_EXCLUDEWORDS: 'Nezahrnujte tato slova',
  LANG_EXCLUDE: 'Neobsahuje',
  LANG_INCLUDE_AICONTENT: 'Zahrnout obsah vygenerovaný AI',
  VISUAL_SEARCH: 'Visuální vyhledávání',
  VISUAL_SEARCH_TOOLTIP_TITLE: '3 jednoduché nástroje pro upřesnění vyhledávání v rámci snímku!',
  VISUAL_SEARCH_TOOLTIP_INFO_1: '1. Pro identifikaci hledané položky klikněte na tečku ve snímku dle vaší volby.',
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2. Pro zvětšení či zmenšení, přetáhněte pole výřezu do oblasti, která vás zajímá. ',
  VISUAL_SEARCH_TOOLTIP_INFO_3: "3. Pro návrat k výsledku vyhledávání klikněte na tlačítko 'X'.",
  VISUAL_SEARCH_NO_RESULTS: 'Nebyly nalezeny žádné výsledky vyhledávání.',
  LANG_RIS_TITLE: 'Reversní vyhledávání obrázků',
  LANG_RIS_NOTFOUND: 'Nebyl nalezen žádný výsledek!',
  LANG_RIS_ERROR_HEADER: 'Nahraný obrázek obsahuje chyby, ujistěte se, že obrázek splňuje následující kritéria:',
  LANG_RIS_REFINE_SRCH_MSG: 'Zkontrolujte své upřesněné vyhledávání klíčovým slovem',
  LANG_RIS_UPLOADED_IMAGE: 'Nahraný obrázek',
  LANG_TELL_ME_MORE: 'Chci se dozvědět více',
  LANG_ENTERKEYWORD: 'Please enter a keyword',
  LANG_SIZE: 'Velikost',
  LANG_STANDARD_SIZE: 'Standardní velikosti',
  LANG_EXTENDED_SIZE: 'ROZŠÍŘENÉ VELIKOSTI',
  LANG_SIZE_PX: 'Pixel je základní jednotka digitálního obrazu. Velikost obrázku, měřená v pixelech, určuje úroveň detailu a velikost ke stažení.',
  LANG_SIZE_DPI: 'Bodů na palec měří rozlišení tisku. Vyšší dpi znamená více detailů. Pro tisk je doporučené rozlišení pro všechny obrázky 300 dpi.',
  LANG_SOCMED_SIZE: 'Velikost pro sociální média',
  LANG_COMPARE: 'Porovnání',
  LANG_AUTO_ENHANCE: 'Automatické vylepšení',
  LANG_AUTO: 'Automatické',
  LANG_BG_REMOVAL: 'Odstranění pozadí',
  LANG_TEXT: 'Text',
  LANG_ELEMENTS: 'Elements',
  LANG_IMAGE_INFO: 'Informace o obrázku',
  LANG_FOLLOW: 'Sledujte',
  LANG_FOLLOWING: 'Následující',
  LANG_FOLLOWED: 'Sledované',
  FACEBOOK_IMAGE: 'Facebookový obrázek',
  FACEBOOK_LINK: 'Facebookový odkaz',
  FACEBOOK_COVER: 'Titulní fotka na Facebook',
  FACEBOOK_STORY: 'Příspěvek na Facebook',
  INSTAGRAM_POST: 'Příspěvek na Instagram',
  INSTAGRAM_STORY: 'Instagramový příběh',
  TWITTER_POST: 'Příspěvek na Twitter',
  TWITTER_HEADER: 'Titulní fotka na Twitter',
  PINTEREST_POST: 'Příspěvek na Pinterest',
  EMAIL_HEADER: 'Emailová hlavička',
  PRESENTATION: 'Prezentace',
  PRESENTATION_WIDE: 'Široká prezentace',
  EBOOK_COVER: 'Titulní strana E-booku',
  LINKEDIN_POST: 'Příspěvek na Linkedin',
  BROCHURE_COVER: 'Titulní strana brožury (A4)',
  POSTCARD: 'Pohlednice',
  PHOTO_PRINT: 'Tištěná fotografie',
  YOUTUBE_PROFILE_IMAGE: 'Profilový obrázek na Youtube',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'Titulní fotografie Youtube kanálu',
  AUTO_COLOR: 'Automatické zabarvení',
  BALANCE: 'Vyvážení',
  AUTO_LEVEL: 'Automatická úroveň',
  AUTO_TONE: 'Automatické tónování',
  ENRICH: 'Obohacení',
  RESET: 'Resetovat',
  RESET_ALL: 'Resetovat Vše',
  FILE_DOWNLOAD_OPTIONS: 'Možností stažení souboru',
  FILE_DOWNLOAD_INITIATED: 'Stahování souboru bylo zahájeno',
  IMAGE_PROPERTIES: 'Vlastnosti snímku',
  DOWNLOAD_DETAILS: 'Stáhnout vlastnosti',
  FILE_SIZE: 'Velikost souboru',
  LICENSE: 'Licence',
  FILEFORMAT: 'Typ souboru',
  CHANGE: 'Změnit',
  PRICING: 'Ceny',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: 'Uložit jako',
  IMAGE_USAGE: 'Použití snímku',
  PRINT: 'Tisk',
  ELECTRONIC: 'Elektronický',
  COMPREHENSIVE: 'Komplexní',
  DOWNLOAD_CREDITS: '<b>{quota}</b> kreditů z vašeho balíčku s kredity',
  BASIC_SHAPE: 'Základní tvary',
  CHIT_CHAT: 'Pokec',
  SHAPES: 'Tvary',
  ELEMENT_CHIT_CHAT: 'Prvky pokecu',
  ORGANIC_ABSTRACT_SHAPES: 'Organické abstraktní tvary',
  ELEMENT_SHAPES: 'Prvky tvarů',
  BUY_SINGLE_IMAGE: 'Koupit jeden snímek <br/><b>{quota} kreditů</b>',
  BUY_SINGLE_IMAGE_CREDIT: 'Koupit kreditní balíček <br/><b>{quota} Kreditů</b>',
  BUY_CREDIT_PACK: 'Kupte si balíček s kredity a <br/><b>ušetříte více</b>',
  BUY_CREDIT_PACK_POINT_1: 'Balíček s {quota}-ti kredity za <b>{price}</b>',
  BUY_CREDIT_PACK_POINT_2: 'Žádný měsíční závazek.',
  BUY_CREDIT_PACK_POINT_3: 'Platnost 1 rok.',
  BUY_SINGLE_IMAGE_PSUB: 'Koupit jeden snímek <br/><b>{quota} stažení</b>',
  BUY_DOWNLOAD_PACK: 'Kupte si balíček stahování a <br/><b>ušetřete více</b>',
  BUY_DOWNLOAD_PACK_POINT_1: 'Balíček stahování se {quota} snímky za <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: 'Žádný měsíční závazek.',
  BUY_DOWNLOAD_PACK_POINT_3: 'Platnost 1 rok.',
  LOWEST_PRICE: '<b>Nejnižší cena</b> <br/>s plánem předplatného',
  LOWEST_PRICE_POINT_1: 'Vyzkoušejte 1 měsíc za <b>{price}</b>',
  LOWEST_PRICE_POINT_2: 'Stáhněte si 10 fotografií nebo 10 vektorů.',
  LOWEST_PRICE_POINT_3: 'žádný denní limit stahování, nevyužitá stažení budou převedena do dalšího měsíce',
  PER_IMAGE_SIZE: 'za snímek <b>ve velikosti {size}</b>',
  PER_IMAGE_ANY: 'za snímek <b>v jakékoli velikosti</b>',
  PER_IMAGE: 'za snímek',
  VIEW_PRICING: 'Více balíčků a cen',
  DOWNLOAD_BEGIN: 'Vaše stahování začne za okamžik, prosím čekejte.',
  DOWNLOAD_NOT_STARTING: 'Stahování ještě nezačalo?',
  SELECT_MIRROR: '[Select a mirror]',
  SHOW_MORE: 'Ukázat více',
  SHOW_LESS: 'Zobrazit méně',
  LICENSE_TYPE: 'Typ licence',
  LICENSE_SUMMARY: 'Souhrn licencí',
  BACK: 'Zpět',
  LANG_VECTOR: 'Vektor',
  PREMIUM_VECTOR: 'PREMIUM Vektory',
  SCALE_ANY_SIZE: 'Měřítko jakékoliv velikosti',
  LANDINGPAGE_VECTOR_HEADLINE: 'Vektory - Royalty Free ilustrace, kliparty a grafiky',
  LANDINGPAGE_PHOTO_HEADLINE: 'Royalty Free fotografie, reklamní obrázky a snímky',
  CATEGORIES: 'Kategorie',
  LANDINGPAGE_PRICING_1: 'Kredity',
  LANDINGPAGE_PRICING_2: 'Koupit Předplatné',
  LANDINGPAGE_AS_LOW_AS: 'Již od',
  EMPTY_SEARCH_TITLE: 'Jejda, vyhledání nepřineslo žádné výsledky.',
  LANG_SMALL: 'Malý',
  LANG_MEDIUM: 'Střední',
  LANG_LARGE: 'Velký',
  LANG_EXTRA_LARGE: 'Extra velký',
  LANG_LOW_CREDITS: 'Vypadá to, že vám dochází kredity ke stažení!',
  LANG_TOPUP: 'Top up',
  LANG_TOPUP_NOW: 'Doplnit kredity nyní',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description} Royalty Free fotografie a reklamní fotografie. Image {mediaId}.',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} Royalty Free svg, kliparty, vektory a ilustrace. Image {mediaId}.',
  SEO_DETAILS_METADESC_STOCKPHOTO: 'Obrázek {description} fotografie, snímků a reklamní fotografie. Image {mediaId}.',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: 'Ilustrace {description} vektorového díla, clipartu a stock vektorů. Image {mediaId}.',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} Snímek a Fotografie Zdarma {mediaId}.',
  SEO_DETAILS_METADESC_FREE_PHOTO: 'Obrázek zdarma {description}. Snímek zdarma {mediaId}.',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} Vektor a grafika zdarma {mediaId}.',
  SEO_DETAILS_METADESC_FREE_VECTOR: 'Vektor zdarma {description}. Grafika zdarma {mediaId}.',
  SEO_DETAILS_OGDESCRIPTION: '123RF - Millions of Creative Stock Photos, Vectors, Videos and Music Files For Your Inspiration and Projects.',
  LIVECHAT_LOOKINGHELP: 'Potřebujete pomoc?',
  LIVECHAT_CHATWITHUS: 'Chatujte s námi!',
  FREE_TRIAL: '<b>Get 10 Free Images</b> <br/>with Subscription Plan',
  LANG_RELATEDSEARCH: 'Související vyhledávání',
  LANG_RELATED_STOCKPHOTO_SEARCH: 'Podobné výsledky vyhledávání',
  LANG_PROPERATTRIBUTE: 'Tady je naše přiřazení:',
  LANG_ATTRIBUTEDESC: 'Přiřaďte porosím tento obrázek danému fotografovi umístěním tohoto kreditního limitu níže do jakékoli oblasti uvnitř vašeho produktu, webu neboi  mimo.',
  LANG_ATTRIBUTE_TITLE: 'Uvedení zdroje je volitelné.',
  LANG_ATTRIBUTE_CREDIT: 'Přejete-li si zmínit informace o autorovi, použijte níže uvedený odkaz:',
  LANG_ATTRIBUTE_COPYLINK: 'Kopírovat odkaz',
  LANG_ATTRIBUTE_COPYTEXT: 'Kopírovat text',
  LANG_LICENSE_AGREEMENT: '<a href={url}><b>Zde</b></a> naleznete licenční smlouvu k tomuto stažení',
  LANG_NEXTPAGE: 'Další strana',
  LANG_IMAGE_NO_AVAILABLE: 'Obrázek č. {mediaId} již není k dispozici.',
  LANG_PHOTOGRAPHY_METAIMG_DESC: 'Vyhledávání a stahování z milionů vysoce kvalitních obrázků, royalty free fotografií a snímků pro komerční použití za přijatelné ceny.',
  LANG_VECTOR_METAIMG_DESC: 'Search and download from millions of HD stock photos, royalty free images and pictures',
  LANG_ANOTHERMETAKWDEFAULT: 'royalty free, stock photo, stock photography, stock images, pictures, photographs',
  LANG_ANOTHERMETADESCDEFAULT: 'Search and download from millions of HD stock photos, royalty free images, cliparts, vectors and illustrations',
  EMPTY_FILTERED_RESULT_TITLE: 'Jejda, vyhledání s těmito filtry nenašlo žádné výsledky.',
  EMPTY_FILTERED_RESULT_SUBTITLE: 'Zkuste odstranit některé filtry nebo vyzkoušejte jiné klíčové slovo.',
  CLEAR_ALL_FILTER: 'Vymazat všechny filtry',
  PROMOBAR_FREE10_TEXT: 'Get <b>10 FREE Images</b> when you get started on our 1 Month-Free Trial.',
  PROMOBAR_FREE10_BTNLABEL: 'START NOW',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: 'Obrovské slevy na všechna předplatná.',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: 'Zobrazit plány',
  LANG_MODEL_RELEASED: 'Model uvolněn',
  LANG_PROPERTY_RELEASED: 'Autorská práva uvolněna',
  LANG_MODEL_RELEASE: 'se Souhlasem modelu',
  LANG_PROPERTY_RELEASE: 'se Souhlasem majetku',
  LANG_YES: 'Ano',
  LANG_NO: 'Ne',
  LANG_MREXPLAIN: 'Souhlas modelu se zobrazením je právní dokument, podepsaný modelem zachyceným na snímku, udělující povolení k publikaci/ distribuci/ nebo použití jeho či její podobizny na fotografii v té či oné formě.',
  LANG_PREXPLAIN: 'Souhlas se zobrazením majetku je právní dokument, podepsaný majitelem objektu, předmětu nebo prostor ve kterých byla fotografie pořízena, udělující povolení k publikaci/ distribuci/ nebo použití fotografie v té či oné formě.',
  LANG_DOWNLOADS: 'Downloads',
  LANG_OLDFILENAME: 'Starý název souboru',
  LANG_DOWNLOAD_PREVIEW: 'Stáhnout náhled',
  LANG_SHARE: 'Sdílení',
  LANG_RESTRICTIONS: 'Omezení',
  LANG_READ_MORE: 'Přečtěte si více',
  LANG_EDITORIAL_USE: 'Pouze pro redakční použití',
  LANG_EDITORIALDESCP: 'Pouze k redakčním účelům: Tento snímek smí být použit výhradně k redakčním účelům. Jeho použití k reklamním, komerčním či propagačním účelům je zakázáno, pokud si držitel licence neopatří potřebná dodatečná povolení. Tuto službu nezajišťuje 123RF.COM.',
  LANG_LOGO_OWNERSHIP: 'Vlastnická práva k logu',
  LANG_PHOTO_SHOT_SMARTPHONE: 'Tato fotografie byla pořízena pomocí smartphonu.',
  LANG_VECTORNOLOGO: 'If you wish to use this as your company logo, we may arrange for a <b>rights buy out</b> on your behalf. Please contact us at <b>{phonenum}</b> to find out more!',
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> stažení z celkových Plány předplatného',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> stažení ze zbývajících Balíček stahování',
  LANG_LOGO: 'Logo',
  USING_OLDER_BROWSER: 'Používáte starší prohlížeč?',
  UPGRADE_NEWER_BROWSER: 'Upgradujte na novější verzi prohlížeče a užijte si plynulejší zážitek!',
  CLICK_TO_DOWNLOAD: 'Kliknutím si stáhnete novější verzi daného prohlížeče:',
  LANG_VECTOR_EDITABLE: 'Editovatelné soubory ve formátech SVG a EPS',
  PROMOBAR_GOOD15_TEXT1: 'Stahujte více, plaťte méně. ',
  PROMOBAR_GOOD15_TEXT2: '15% sleva na předplatné s promo kódem:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: 'Stahujte více, plaťte méně. ',
  PROMOBAR_SUMMER25_TEXT2: '25% sleva na VŠECHNY plány s <b>promo kódem:</b>',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: '30% sleva na VŠECHNY plány s <b>promo kódem:</b>',
  NO_EDIT_COMPARE_MSG: 'Nebyly provedeny žádné úpravy k porovnání.',
  SWITCH_TO_VECTOR_MSG: 'Editor fotografií není podporován pro obrázky ve vektorové velikosti. Při přepnutí na vektorový formát budou všechny úpravy ztraceny.',
  SWITCH_TO_XL_MSG: 'Editor fotografií není podporován pro obrázky ve velikosti XL. Při přepnutí na velikost XL budou všechny úpravy ztraceny.',
  UNAVAILABLE_FOR_THIS_SIZE: 'Není k dispozici pro velikost {size}.',
  SWITCH: 'PŘEPNOUT',
  SWITCH_LICENSE_EXT_MSG: 'Přejete si tento obrázek nejprve stáhnout v {type} formátu? Pokud změníte druh licence na Rozšířenou licenci, přijdete o všechny úpravy.',
  LANG_SESSION_EXPIRED: 'Vaše přihlášení bylo ukončeno. <a href={url}>Přihlaste se prosím zde</a>',
  LANG_TEXT_COLOR: 'Barva textu',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: 'Zvýraznění',
  LANG_TEXT_ALIGNMENT: 'Zarovnání',
  LANG_TEXT_BOLD: 'Tučné písmo',
  LANG_TEXT_ITALIC: 'Kurzíva',
  LANG_TEXT_UNDERLINE: 'Podtržení',
  LANG_TEXT_BULLET: 'Odrážka',
  LANG_TEXT_LINE_SPACING: 'Řádkování',
  LANG_TEXT_LETTER_SPACING: 'Mezery mezi písmeny',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: 'Přenést blíž',
  LANG_BRING_BACKWARD: 'Přenést dál',
  LANG_DUPLICATE: 'Kopírovat',
  LANG_OPACITY: 'Neprůhlednost',
  LANG_DELETE: 'Odstranit',
  LANG_TEXT_ADD: 'Přidat text',
  LANG_TEXT_EDIT: 'Upravit text',
  LANG_TEXT_FONT_TYPE: 'Typ písma',
  LANG_TEXT_FONT_SIZE: 'Velikost písma',
  LANG_TEXT_ALL_FONTS: 'Všechna písma',
  LANG_TEXT_UNDO: 'Vrátit zpět',
  LANG_TEXT_REDO: 'Opakovat',
  LANG_TEXT_FONT: 'Písmo',
  LANG_SEARCH_FONT_TYPE: 'Hledat typ písma',
  LANG_SEARCH_SHAPE_TYPE: 'Prvky vyhledávání',
  LANG_DONE: 'Hotovo',
  ADD_HEADER: 'Přidat záhlaví',
  ADD_TITLE: 'Přidat nadpis',
  ADD_BODY: 'Přidat hlavní text',
  ADD_ASSISTIVE: 'Přidat pomocný text',
  STOCK_PHOTO_BANNER_TITLE: 'Miliony Royalty free snímků pro vaší neomezenou tvorbu',
  STOCK_PHOTO_BANNER_DESC: 'Objevte úžasnou knihovnu s obsahem, který vám pomůže zrealizovat ty největší kreativní nápady.',
  STOCK_PHOTO_SUBSCRIBE_TITLE: 'Vizualizujte své příběhy pomocí těch správných obrázků.',
  STOCK_PHOTO_SUBSCRIBE_DESC: 'Získejte okamžitý přístup k více než 180 milionům fotografiím, které vám pomohou vyprávět příběhy. Naše vizuály a váš příběh, to je dokonalá kombinace pro maximalizaci vašeho tvůrčího potenciálu!',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: 'Balíček s kredity',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: 'Již za',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: 'Plán předplatného',
  STOCK_PHOTO_CARD_IMAGE: 'snímek',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: 'Získat předplatné',
  STOCK_PHOTO_CARD_BUY_NOW: 'Koupit nyní',
  STOCK_PHOTO_POPULAR_ROYALTY: 'Oblíbené kategorie royalty free obrázků',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: 'Objevte cenově dostupné plány a ceny fotografií',
  STOCK_PHOTO_DISCOVER_NOW: 'Prohlédnout si',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: 'Krajina',
  STOCK_PHOTO_BABIES: 'Děti',
  STOCK_PHOTO_BUSINESS: 'Obchod',
  STOCK_PHOTO_FOOD: 'Jídlo',
  STOCK_PHOTO_ANIMALS: 'Zvířata',
  STOCK_PHOTO_TECHNOLOGY: 'Technologie',
  STOCK_PHOTO_HEALTHCARE: 'Zdravotnictví',
  STOCK_PHOTO_NATURE: 'Příroda',
  STOCK_PHOTO_ROMANCE: 'Romantika',
  CLIPART_VECTOR_EXPLORE_MILLIONS: 'Tvořte s miliony Royalty free vektorů, které máte na dosah ruky.',
  CLIPART_VECTOR_NEED_AN_EYE: 'Najděte skvělé vektorové ilustrace, infografiky, kliparty, ikony a mnoho dalšího, co vám pomůže proměnit vaše úžasné nápady ve skutečnost.',
  CLIPART_VECTOR_FIND_VECTOR: 'Najděte ty správné vektory, které okoření vaše projekty.',
  CLIPART_VECTOR_FIND_VECTOR_DESC: 'Ať už jsou to skvělé infografiky, ilustrace, karikatury, kliparty, pozadí, ikony nebo cokoli, co potřebujete k vytvoření úžasných vizuálů; najdete to tady. Oživte své návrhy ještě dnes!',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Získat předplatné',
  CLIPART_VECTOR_POPULAR_ROYALTY: 'Oblíbené kategorie royalty free vektorů',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: 'Objevte cenově dostupné plány a ceny vektorů',
  CLIPART_VECTOR_DISCOVER_NOW: 'Discover Now',
  SPEND_AND_WIN_SUBTITLE: 'Každý nákup v min. hodnotě {currency} = 1 vstupenka',
  SPEND_AND_WIN_IMGALT: 'Kup a vyhraj iPhone 13 Pro',
  CLIPART_VECTOR_GRAPHIC: 'Grafika',
  CLIPART_VECTOR_ADVERTISING: 'Reklama',
  CLIPART_VECTOR_FLOWER: 'Květiny',
  CLIPART_VECTOR_ABSTRACT: 'Abstrakce',
  CLIPART_VECTOR_POP: 'Pop',
  CLIPART_VECTOR_BIRD: 'Ptactvo',
  CLIPART_VECTOR_ILLUSTRATION: 'Ilustrace',
  CLIPART_VECTOR_PIXEL: 'Pixel',
  CLIPART_VECTOR_3D: '3D',
  LANG_VECTOR_SVG_EPS: 'Vektor, SVG a EPS',
  FREE_UPGRADE: 'Bezplatná aktualizace',
  FREE_UPGRADE_MESSAGE: 'BEZPLATNÉ vylepšení na XL velikost pro všechny Balíčky stahování a všechna předplatná. Kupte nyní.',
  LANG_FREE_IMAGE: '123RF Zdarma',
  LANG_IMAGE_FREE: 'Tento snímek je zdarma pro komerční i osobní použití. Je vyžadováno uvedení odkazu na zdroj. ',
  LANG_HOW_TO_ATTRIBUTE: 'Jak mohu uvést odkaz na zdroj? ',
  LANG_FREE_DOWNLOAD: 'Stažení zdarma',
  LANG_FREE_DOWNLOAD_BUTTON: 'FREE Stáhnout',
  LANG_GO_PLUS_BUTTON: 'přejděte na PLUS',
  LANG_ATTRIBUTION_IS_KEY: 'Atribuce je důležitým prvkem pro tvůrce obsahu, kteří svá díla zpřístupňují veřejnosti. Naši přispěvatelé si velice váží toho, že jejich výtvory používají, sdílejí a zmiňují miliony uživatelů, jako jste vy.',
  LANG_GET_ATTRIBUTION_LINK: 'Zkopírujte tento odkaz a umístěte jej poblíž místa, kde je použit stažený obsah.',
  LANG_CREDIT_OWNER: 'Uveďte prosím jméno autora:',
  LANG_USE_ATTRIBUTION: 'Použijte níže uvedený odkaz.',
  LANG_ATTRIBUTION_LOCATION: 'Pokud to není možné, dalším vhodným místem je zápatí webové stránky, blogu nebo zpravodaje. Případně stačí i vyhrazená sekce na webu, kde bude uveden autor {media_type}.',
  LANG_GOT_A_QUESTION: "Mať otázku? <a href={url} target='_blank'><u>Kontaktujte nás</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: 'Je potřeba zmínit autora tohoto snímku zdarma.',
  LANG_ATTR_FREE_DOWNLOADING: 'Nebojte se, nebude to trvat dlouho.',
  LANG_PLUS_CONTENT_1: 'Získáte neomezené stahování',
  LANG_PLUS_CONTENT_2: 'Přístup k 100 mil. fotografií a vektorů',
  LANG_PLUS_CONTENT_3: 'Exkluzivní 3D ilustrace, které získáte pouze na 123RF!',
  LANG_PLUS_CONTENT_4: 'Neomezené stahování',
  LANG_PLUS_CONTENT_5: 'Neomezená svoboda v kreativitě',
  FREE_VECTOR: 'FREE Vektory',
  FREE_STOCKPHOTO: 'FREE Fotografie',
  FREE_PUBLIC_DOMAIN_CC0: 'Public Domain (CC0)',
  BUY_5_DOWNLOAD_PACK: "<b>Časově omezená nabídka!</b><br/>3 + <b><span style='color:red'>2 ZDARMA</span></b> = 5 fotografií nebo vektorů",
  BUY_5_DOWNLOAD_PACK_POINT_2: 'Stáhněte je kdykoliv (během jednoho roku)',
  BUY_5_GET_2_FREE: 'Získejte 2 snímky ZDARMA!',
  LANG_ASK_DOWNLOAD_PLUS: 'Přejete si stáhnout tento PLUS obsah?',
  LANG_SIGN_UP_PLUS: 'Přihlašte se k našemu 123RF PLUS plánu od pouhých',
  LANG_FOR_ONLY: "<span class='text'>Za pouhých</span> <span class='price'>{price}</span>/měsíc",
  LANG_FOR_ONLY_2: "<span class='text'>Za pouhých</span> <span class='price'>{price}</span>",
  LANG_BENEFIT_PLUS: 'Získejte výhody našeho 123RF PLUS plánu již od',
  LANG_RELEASES: 'Připojená uvolnění',
  LANG_UNAVAILABLE: 'Není k dispozici',
  LANG_ATTR_FREE_IMG_TITLE2: 'Jsme rádi, že jste našli, co jste potřebovali. 🎉',
  LANG_PLEASE_NOTE: 'Vezměte prosím na vědomí, že k tomuto obrázku nejsou přiložena žádná modelová nebo majetková uvolnění. Komerční použití je na vaše vlastní riziko.',
  LANG_PLEASE_ADVISED: 'Upozorňujeme, že obrázky obsahující rozpoznatelné jednotlivce nepřicházejí s žádnými vydáními. K komerčnímu používání těchto obrázků musí být povolení získáno od příslušných stran. Pokud obrázek neobsahuje rozpoznatelné jednotlivce, neměly by se při komerčním používání existovat žádné problémy s autorskými právy.',
  LANG_REACHED_LIMIT: 'Ops! Dosáhli jste denního limitu stahování.',
  LANG_COME_TOMORROW: 'Jsme nadšeni, že se vám naše obrázky zdarma líbí! Vraťte se zítra pro obnovení limitu stahování. Děkujeme. 😊',
  LANG_DOWNLOAD_ERROR: 'Jejda! Váš snímek se kvůli nějaké chybě nepodařilo stáhnout.',
  LANG_REFRESH_AND_TRY_AGAIN: "Obnovte prosím prohlížeč a zkuste to znovu. <a href={url} target='_blank'>Dejte nám vědět,</a> pokud problém přetrvává.",
  LANG_REACHED_LIMIT_PLUS: 'Denní limit stahování byl dosažen',
  LANG_COME_TOMORROW_PLUS: 'Wow! Dosáhli jste vašeho denního limitu stahování. Vraťte se zítra, kdy bude váš limit znovu obnoven. Děkujeme za vaší podporu!',
  SEE_YOU_TOMORROW: 'Těšíme se na vás zítra!',
  ORIGINAL: 'Původní',
  'AUTO_B&W': 'Automatická černobílá',
  AUTO_ADJUST: 'Automatická úprava',
  AUTO_POP: 'Auto Pop',
  NONE: 'Žádné',
  LANG_CROP: 'Oříznout',
  LANG_ENABLE_EDITOR: 'Upravit obrázek',
  LANG_DISABLE_EDITOR: 'Zavřít',
  LANG_IMAGE_FREE_CC0: "Toto je obrázek CC0. Přiřazení není vyžadováno. Můžete jej použít podle <a className='linkCC0' href={url} target='_blank'>licenčních podmínek CC0</a>.",
  LANG_PREVIEW: 'Náhled',
  LANG_ATTRIBUTION_CC0_TITLE: 'Obrázek CCO: Přiřazení není vyžadováno',
  LANG_ATTRIBUTION_TITLE: 'Uvedení odkazu na zdroj je vyžadováno',
  LANG_ATTRIBUTION_CONTENT: 'Odkaz na zdroj bude uveden po stažení souboru.',
  LANG_ATTRIBUTION_CC0_CONTENT: 'Přiřazení není vyžadováno, protože se jedná o obrázek CCO.',
  LANG_SUBCRIBE_PREMIUM: 'You’ve reached your daily download limit! To download more, subscribe to 123RF PREMIUM.',
  LANG_REMOVE_ATTRIBUTION: 'Odstranit odkaz na zdroj? ',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "Předplaťte si <img src={image} alt='123RF Plus Logo'> s neomezeným přístupem k 100 milionům snímků a ilustrací bez nutnosti uvedení odkazu na zdroj.",
  LANG_CREATOR_ATTRIBUTION: 'Pro odkaz na zdroj prosíme využijte následující odkaz.',
  LANG_GET_ATTRIBUTION_LINK_2: 'Zkopírujte tento odkaz a vložte ho poblíž použitého snímku, např. do zápatí stránek nebo do vyhrazené sekce, která je tomu věnována.',
  LANG_APPLY_BG_REMOVAL: 'Apply Background Removal',
  LANG_APPLYING_EDIT: 'Applying edit...',
  LANG_APPLYING_EDIT_ERROR: 'Something went wrong.',
  LANG_TRY_AGAIN: 'Try again?',
  LANG_SEARCHCOLLECTION_PREMIUM: 'Full Collection',
  LANG_SEARCHCOLLECTION_PLUS: 'Essential Collection',
  LANG_SEARCHCOLLECTION_FREE: 'Basic Collection',
  LANG_SIMILARIMAGESPAGE_TITLE: 'Fotobanka s předplatným fotografií',
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: 'Podobné snímky',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: 'Jejda! Zde není nic k vidění.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: 'Fotografie není k dispozici',
  LANG_PER_IMAGE: '/obraz',
  LANG_PLANS_AND_PRICING: 'Plány a ceny',
  LANG_CHOOSE_BEST_PLAN: 'Vyberte nejlepší plán, který vyhovuje vašim potřebám.',
  LANG_WANT_MORE_SAVING_1: 'Chcete ušetřit ještě více? ',
  LANG_WANT_MORE_SAVING_2: 'Podívejte se na další plány, které nabízejí sthaování od {price}/snímek',
  LANG_BEST_VALUE: 'Nejlepší hodnota, ušetřete {percentage}%',
  LANG_YEARLY_PLAN: 'Roční plán',
  LANG_WITH_MONTHLY_PAYMENTS: 'S měsíčními platbami',
  LANG_GRAB_YEARLY_PLAN: 'Popadněte roční plán a ušetřete {percentage}%.',
  LANG_PRICE_PER_MONTH: '{price} za měsíc',
  LANG_PRICE_PER_YEAR: '{price} ročně',
  LANG_GET_X_EVERY_MONTH: 'Získejte {quota} fotografií nebo vektorů každý měsíc',
  LANG_X_EVERY_MONTH: '{quota} fotografií nebo vektorů<br>každý měsíc',
  LANG_SINGLE_IMAGE_PURCHASE: 'Nákup jednoho obrázku',
  LANG_GET_ONLY_WHAT_YOU_NEED: 'Získejte jen to, co potřebujete na tuto chvíli.',
  LANG_TOTAL_PRICE: 'Celkem {price}',
  LANG_DOWNLOAD_IMMEDIATELY: 'Okamžitě stáhněte obrázek Po pokladně.',
  LANG_NEXT: 'Dále',
  LANG_TITLE: 'Fotobanka s předplatným fotografií',
  SIMILAR_STOCK_PHOTOS: 'Podobné snímky',
  EMPTY_SIMILAR_SEARCH_RESULTS: 'Jejda! Zde není nic k vidění.',
  UNAVAILABLE_IMAGE: 'Fotografie není k dispozici',
  LANG_DOWNLOAD_PACK: 'Balíček stahování',
  LANG_GET_X_ANY_TIME: '{quota} fotografií nebo vektorů kdykoli',
  LANG_X_CREDITS: '{total} Kredity',
  LANG_PER_CREDIT: '/Kredit',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '{photoCount} fotografie{videoCount, plural,  =0 {} =1 { nebo # video} other { nebo # videa}} kdykoli',
  INCLUDES_STANDARD_LICENSE: 'Zahrnout standardní licenci',
  LANG_MEDIUM_SIZE: 'Střední velikost',
  LANG_LARGE_SIZE: 'Velká velikost',
  LANG_EXTRA_LARGE_SIZE: 'Extra velká velikost',
  LANG_STANDARD_SIZES: 'Standardní velikosti',
  LANG_ROYALTY_FREE: 'Royalty-Free',
  LANG_OPTIONS: 'Možnosti',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'Získejte výhody našeho 123RF PLUS plánu již od',
  LANG_RELATED_FONT_SEARCH: 'Související vyhledávání',
  AI_GENERATED_PHOTO: 'Obrázek generovaný AI',
  FONT_PRICING_CREDIT: 'Kreditů',
  '3_FONTS_3_IMGS': '3 písma <b>nebo</b> 3 obrázků <b>nebo</b> 1 video',
  '9_FONTS_9_IMG_3_VIDEOS': '9 písma <b>nebo</b> 9 obrázků <b>nebo</b> 3 videí',
  PURCHASE_AND_DOWNLOAD: 'Koupit & stáhnout',
  FONT_PATH: 'fonts',
  FONT_ZIP: 'Písmo (OTF, TTF)',
  FONT_PACKS_LICENSE: 'Licence 123RF Packs',
  FONT_META_DESC: 'Stáhněte si {product_title} písmo, typ písma nebo typografii pro vytvoření ohromujících návrhů pro tisk a web.',
  FONT_META_TITLE: 'Písmo - {product_title} | 123rf',
  FONT_SIMILAR: 'Podobná písma',
  LANG_PLANS: 'PLÁNY',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: 'Vyzkoušejte vytváření variant',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: 'Generujte nové obrázky na základě původního obrázku. Navíc je to nový způsob, jak odměnit naše přispěvatele 123RF pokaždé, když je obrázek generovaný AI licencován!',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: 'Naše komunita vám za to děkuje. Licenční poplatky budou rozděleny mezi přispěvatele 123RF.',
  LANG_CONTRIBUTORS_AI_VARIATION: 'Přispěvatelé 123RF původního obrázku jsou odměňováni za každou licencovanou variantu AI.',
  LANG_AI_VARIATION_LIMIT: 'Ale ne! Pro dnešek jste dosáhli limitu pro generování obrázků AI.',
  LANG_GENERATE_COMEBACK: 'Vraťte se zítra pro další generace!',
  LANG_LICENSE_AND_DOWNLOAD: 'Licence a stahování',
  LANG_CONFIRM_OK: 'Dobře',
  LANG_AI_SWITCH_CONTENT: 'Editor fotografií není podporován pro varianty AI. Všechny úpravy budou ztraceny, pokud přejdete na varianty AI.',
  LANG_GENERATE_VARIATIONS: 'Generovat variace',
  LANG_GENERATE_WITH_AI: 'Generování obrázků pomocí umělé inteligence',
  LANG_NEW: 'Nové stránky',
  LANG_AI_GENERATOR_LICENSE: 'Podmínky použití generátoru 123RF AI (“Podmínky”)',
  LANG_I_UNDERSTAND: 'Rozumím.',
  LANG_AUTHENTIC: 'Autentický',
  LANG_ABSTRACT: 'Abstraktní',
  LANG_MACRO_CLOSEUP: 'Makro/detailní záběr',
  LANG_OBJECT: 'Objekt',
  LANG_WATERCOLOR: 'Akvarel',
  LANG_FLAT: 'Plochý design',
  LANG_CARTOON: 'Cartoon',
  LANG_GEOMETRIC: 'Geometrické',
  LANG_GRADIENT: 'Gradient',
  LANG_ISOMETRIC: 'Izometrický',
  LANG_3D: '3D',
  LANG_HAND_DRAWN: 'Ručně kreslený',
  LANG_NATURAL: 'Přírodní',
  LANG_WARM: 'Teplý',
  LANG_DRAMATIC: 'Dramatický',
  LANG_VIVID_BOLD: 'Živý a odvážný',
  LANG_BLACK_WHITE: 'Černobílý',
  LANG_ASIAN: 'Asijský',
  LANG_AFRICAN_AMERICAN: 'Afro-američan',
  LANG_STANDARD: 'Standard',
  LANG_EXTENDED: 'Rozšířený',
  LANG_STANDARD_CONTENT_1: 'Neomezené zobrazení pro sociální sítě, web, e-mail a mobilní zařízení.',
  LANG_STANDARD_CONTENT_2: 'Até 500.000 impressões.',
  LANG_STANDARD_CONTENT_3: 'Não pode ser usado em produtos para venda.',
  LANG_EXTENDED_CONTENT_1: 'Neomezené zobrazení pro sociální sítě, web, e-mail a mobilní zařízení.',
  LANG_EXTENDED_CONTENT_2: 'Impressões ilimitadas.',
  LANG_EXTENDED_CONTENT_3: 'Pode ser usado em produtos para venda.',
  LANG_ORIGINAL_IMAGE: 'Originální obrázek',
  LANG_IMPORTANT_NOTE: 'Důležitá poznámka',
  LANG_AI_ORIGINAL_ALTERED: 'Variace AI jsou varianty původního obrázku, které byly upraveny pomocí technologie AI. Je to podobné jako když váš vybraný obrázek retušuje editor AI.',
  LANG_GENERATE_VARIATIONS_NOTE: 'V současné době jsme v beta fázi Variací obrázků poháněných AI a generované obrázky mohou produkovat neočekávané výsledky. Usilovně pracujeme na tom, aby takové výsledky a variace odpovídaly etickým a zodpovědným směrnicím pro AI.',
  LANG_LICENSE_AI_EDITED: 'Když licencujete jednu z těchto verzí upravených AI, držitel autorských práv původního obrázku je placen licenční poplatek.',
  LANG_MOOD: 'Nálada',
  LANG_AI_EXCLUDE_PLACEHOLDER: 'Např. Zelená barva, rovné vlasy',
  LANG_SIMILAR_STOCK: 'Podobné skladové obrázky',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: 'se Souhlasem modelu',
  WITH_PROPERTY_RELEASE: 'se Souhlasem majetku',
  MODEL_RELEASE_CONTENT: 'Souhlas modelu se zobrazením je právní dokument, podepsaný modelem zachyceným na snímku, udělující povolení k publikaci/ distribuci/ nebo použití jeho či její podobizny na fotografii v té či oné formě.',
  PROPERTY_RELEASE_CONTENT: 'Souhlas se zobrazením majetku je právní dokument, podepsaný majitelem objektu, předmětu nebo prostor ve kterých byla fotografie pořízena, udělující povolení k publikaci/ distribuci/ nebo použití fotografie v té či oné formě.',

  variousStockGAI: 'Právně shodný model umělé inteligence, který byl trénován miliony stockových fotografií a optimalizován pro potřeby podniku, poskytující klid s pojištěním odpovědnosti.',
  extendedLicense: 'Rozšířená Licence',
  sizes: 'Velikosti',

  downloadMail1st: 'Náš poskytovatel služby zvětšení obrázku vám na e-mailovou adresu',
  downloadMail2nd:
    'zašle pokyny ke stažení finálního zvětšeného souboru během následujícího pracovního dne',

  aiGenarated: 'Obrázek generovaný AI',

  variousOpenJourney: 'Model generuje umění připomínající charakteristický styl Midjourney.',
  variousTurboVision: 'Vysokorychlostní generace při „normální“ XL rozlišení, zlepšení celkové kvality.',
  variousRealVisXL: 'Model cílí na dosažení fotorealistických výsledků s přesností a přesností.',
  variousPortraitPlus: 'Model s jednotnou kompozicí portrétu, ideální pro poměr stran 1:1.',
  enterVirifiedCode: 'Zadejte ověřovací kód',
  verify: 'Ověřit',
  didNotGetCode: 'Nedostali jste kód?',
  clickToResend: 'Klikněte pro opětovné odeslání',
  otpCannotEmpty: 'OTP nemůže být prázdný.',
  sentCodeToMail: 'Poslali jsme kód na vaši registrovanou e-mailovou adresu.',
  invalidCode: 'Neplatný kód.',
  expiredOTP: 'OTP vypršel, prosím, odešlete znovu.',
  sendEmail: 'E-mail byl úspěšně odeslán, prosím zkontrolujte svůj e-mailový schránku pro pokračování.',
  remainingTime: 'Zbývající čas: ',
};

export default cz;
