/* eslint-disable max-len */
const br = {
  // Header
  photos: 'Fotos',
  Photos: 'Fotos',
  vectors: 'Vetores',
  Vectors: 'Vetores',
  footageupper: 'VÍDEOS',
  footage: 'Footage',
  video: 'Vídeos',
  audio: 'Áudios',
  signin: 'Login',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Portugese.png',
  language: 'Idioma',
  // Footer
  contact: 'Contato',
  contactUs: 'Contato',
  privacy: 'Política de Privacidade',
  terms: 'Termos de Utilização',
  cookie: 'POLÍTICA DE COOKIE',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: 'Esqueceu sua senha',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: 'Por favor digite um endereço de e-mail válido.',
  emailCannotEmpty: 'E-mail não pode estar vazio.',
  returnToLogin: 'RETORNAR PARA LOGIN',
  email: 'E-mail',
  // Login
  username: 'Nome do usuário',
  pw: 'Senha',
  rememberMe: 'Lembrar',
  login: 'Login',
  forgotPassword: 'Esqueceu Senha',
  loginAsGuest: 'Pular Login',
  // ResetPassword
  resetPwdDesc: 'Por favor digite sua nova senha e nós vamos fazer seu login imediatamente.',
  newPw: 'Nova senha',
  confirmPwd: 'Confirme a Senha',
  resetPwdButton: 'Redefinir uma senha',
  resetPwdTitle: 'Redefina Sua Senha',
  enterYourNewPw: 'Por favor digite sua nova senha.',
  pwMiniMumChars: 'Mínimo de 8 caracteres',
  pwMaxChar20: 'O máximo de caracteres permitido é 20.',
  pwLowerCase: 'Pelo menos uma letra minúscula',
  pwUpperCase: 'Pelo menos uma letra maiúscula',
  pwNumeral: 'Pelo menos um número',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: 'A senha de confirmação é diferente da senha informada',
  resetPwdInvalidLink: 'Este link é inválido ou expirou.',
  pwHasBeenReset: 'Senha foi redefinida, faça login no site para prosseguir.',
  // Admin index
  companyName: 'Nome da empresa',
  phone: 'Telefone',
  fax: 'Fax',
  address: 'Endereço',
  idd: 'CNPJ/CPF',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: 'Total de artigos',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: 'Senha Antiga',
  confirmNewPassword: 'Confirmar nova senha',
  returnToHome: 'Retornar para Home',
  // Cookie Policy
  langCookie01:
    'WQuando você visita %host%, colocamos um pequeno arquivo de texto chamado cookie no disco rígido do seu computador.',
  langCookie02:
    'Esse arquivo nos permite rastreá-lo para que possamos fornecer funcionalidades básicas para você, como carrinhos de compras. Eles também são usados para fornecer uma experiência de usuário online aprimorada e fornecer a você funcionalidades como "likebox".',
  langCookie03:
    'Além disso, permite-nos analisar a sua interação com o nosso site (incluindo páginas visitadas, termos de pesquisa e imagens visualizadas). Ou seja, independentemente de você optar por fazer uma compra ou não.',
  langCookie04:
    'Os cookies que você autoriza, também são usados para criar um perfil em uma lista de audiência para que possamos entregar publicidade direcionada e adaptada aos seus interesses.',
  langCookie05:
    'A maioria dos navegadores permite rejeitar cookies, mas se você fizer isso determinadas funcionalidades poderão estar indisponíveis e determinadas páginas da Web poderão não ser exibidas corretamente.',

  downloadModalTitle: 'Baixe imagem',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf:
    'Imagens em formato TIFF, vídeos e áudios não são suportados. O seguinte arquivo:',
  messageFileNotSupported2ndHalf: 'foram removidos do Download em Lote.',
  licenseOnBehalf: 'Licença em nome de',
  customizedPortal: 'Customized portal',
  normalDownload: 'Normal download',
  myCredit: 'Meu crédito',
  lowCreditsReminder: 'Crédito baixo',
  downloadModalId: 'Image ID:',
  downloadModalPrice: 'Credit:',
  clearAll: 'Reiniciar tudo',
  myActivities: 'Minhas atividades',
  info: 'Informações',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: 'Baixe este conteúdo usando',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: 'Digite sua pesquisa aqui',
  toSearchAIKeyword: 'Insira palavras-chave, título do artigo ou título do blog',
  allImages: 'Todas as Imagens',
  freeImages: 'Free Images',
  categoryImage: 'Category',
  recentViewed: 'Visto Recentemente',
  recentView: 'Minhas visualizações recentes',
  latestIMG: 'Latest Images',
  // Setting
  adminControlPanel: 'Painel de Controle Admin',
  downloadHistory: 'Histórico de Download',
  hruc: 'HRUC',
  changePassword: 'Alterar senha',
  logOut: 'Sair',
  hello: 'Olá,',
  viewAccount: 'View Account',
  myAccount: 'Minha Conta',
  myCollections: 'Minhas coleções',
  personalCredits: 'Créditos pessoais',
  groupTotal: 'Grupo Total',
  toTalUnpaidLicense: 'Licenças não pagas ',
  highRUnwatermarkComp: "Layout em Alta Resolução Sem Marca D'água",
  myActivityLikeDesc: 'Ver Histórico dos Últimos 3 Meses',
  myActivityViewDesc: 'Ver Histórico Recente será armazenado apenas por até 3 meses',
  monthSelection: 'Month selection',
  // Menu
  categories: 'Categorias',
  myLikebox: 'Meu Likebox',
  settings: 'Configurações',
  notification: 'Notificações',
  clear: 'Limpar',
  likebox: 'Likebox',
  credits: 'Créditos',
  // Credit summary at header
  downloadAndUsege: 'Downloads e Usos',
  totalDlCredit: 'Créditos para Downloads Disponíveis:',
  allocatedDlCredit: 'Créditos para Downloads Alocados',
  personalAvailableCre: 'Crédito Pessoal Disponível',
  groupAvailableCre: 'Crédito de Grupo Disponível',
  availableDlSlot: 'Slot(s) de Downloads Disponíveis',
  uNPLD: 'Use Agora, Pague Após Download (UNPL)',
  hiResHRUC: 'Layout em Alta Resolução Sem Marca D água (HRUC)',
  monthlyAllocation: 'Alocação Mensal:',
  remainingQuota: 'Quota restante',
  remainingHRUCQ: 'Quota Restante Layout em Alta Resolução Sem Marca D água (HRUC):',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: 'Grupos',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: 'Por favor, deixe-nos saber como podemos ajudá-lo.',
  liveChat: 'Chat ao vivo',
  whatsApp: 'WhatsApp',
  hiThere: 'Olá, ',
  // email response
  emailIsNotAvailable: 'E-mail não está disponível.',
  anErrorHaSoccured: 'An error has occured, please try again.',
  emailHasBeenSent:
    'E-mail enviado com sucesso, favor verificar sua caixa de entrada para continuar. Nós enviamos um e-mail para __email com as instruções de redefinição. Se esta mensagem não aparecer em sua caixa de entrada em até 5 minutos, por favor verificar sua caixa de Spam.',
  tooManyAttempts: 'Muitas tentativas malsucedidas, você foi temporariamente bloqueado.',
  // INVALIDPASSWORD
  inValidPassword: 'Senha inválida! Verifique se o Caps Lock está acionado.',
  userIsBlocked: 'Muitas tentativas malsucedidas, você foi temporariamente bloqueado.',
  blockWarrings: 'Você será bloqueado temporariamente após 10 tentativas! ',
  // Update Password
  resetPwdUpdated: 'Sua senha foi atualizada.',
  updatePwDfailed: 'Falha ao atualizar a senha! ',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'minutos',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: 'Contrato de licença',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: 'Usuário não pode estar vazio',
  passWordCannotEmpty: 'Senha não pode estar vazio',
  oldPasswordCannotEmpty: 'Senha antiga não pode estar vazia',
  confirmPasswordCannotBeEmpty: 'Confirmar nova senha não pode estar vazio',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: 'Cannot send more emails, please check your mailbox.',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',

  // old browsers
  usingOlderBrowsers: 'Você está usando um navegador mais antigo?',
  upgradeBrowser:
    'Atualize para uma versão mais nova do navegador para desfrutar de uma experiência mais suave!',
  clickToDownload: 'Clique para fazer o download de qualquer um destes:',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: 'Ver Tudo',
  // Filter bar
  aiGenerate: 'Imagem gerada pela AI',
  standardModels: 'Modelos Padrão',
  fineTunedModels: 'Modelos Ajustados',
  aiContent: 'Inclua conteúdo gerado pela AI',
  filter: 'Filtro',
  safeSearch: 'Pesquisa segura',
  aiAssist: 'Assistência AI',
  freshness: 'Novidade',
  gender: 'Gênero',
  sortBy: 'Classificar por',
  mediaType: 'Tipo de Mídia',
  orientation: 'Orientação',
  modelPreferences: 'Preferências de modelo',
  people: 'Pessoas',
  more: 'Mais',
  numOfPeople: 'Número de pessoas',
  age: 'Idade',
  ethnicity: 'Origem étnica',
  collection: 'Coleções',
  uploaded: 'Uploaded',
  style: 'Estilo',
  cutOut: 'Sólidos/Transparentes',
  doNotIncludeWords: 'Não incluir essas palavras',
  doNotIncludeWords_ex: 'ex. queijo',
  color: 'Colorido',
  max1Color: 'Max 1 cor',
  license: 'Tipo de licença',
  fps: 'FPS',
  resolution: 'Resolução',
  clipDuration: 'Duração do Áudio (minutos)',
  loop: 'Loop',
  category: 'Categoria',
  genre: 'Gênero',
  tempo: 'Tempo (bpm)',
  // Filter button text
  Relevance: 'Relevância',
  New: 'Novo',
  Georank: 'Georank',
  DownloadHistory: 'Histórico de Download',
  Popularity: 'Popularidade',
  Photography: 'Fotografia',
  VectorIllustration: 'Ilustração vetorial',
  Horizontal: 'Horizontal',
  Landscape: 'Paisagem',
  Portrait: 'Retrato',
  Square: 'Quadrado',
  Panorama: 'Panorâmica',
  numPpl: 'Número de pessoas',
  WithoutPeople: 'Sem pessoas',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: 'Excluir',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: 'Última semana',
  LastMonth: 'Last month',
  PastThreeMonths: 'Últimos 3 meses',
  PastTwodays: 'Últimos 2 dias',
  Male: 'Masculino',
  Female: 'Feminino',
  Standard: 'Padrão',
  extended: 'Extendido',
  Editorial: 'Editorial',
  Commercial: 'Tipo de conteúdo',
  Black: 'Black',
  Caucasian: 'Caucasiano',
  AfricanAmerican: 'Afro-americano',
  Asian: 'Asiático',
  EastAsian: 'East Asian',
  Hispanic: 'Latino',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: 'Crianças',
  Teenagers: 'Adolescentes',
  Adults: 'Adultos',
  Seniors: 'Idosos',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: 'Sim',
  Nonloopable: 'Não',
  Music: 'Música',
  SoundEffects: 'Sound Effects',
  LogoAndIdents: 'Logotipos e Idents',
  Veryslow: 'Muito Lento',
  VeryslowSubText: '(0-79)',
  Slow: 'Lento',
  SlowSubText: '(80-119)',
  Medium: 'Médio',
  MediumSubText: '(120-139)',
  Fast: 'Rápido',
  FastSubText: '(140-159)',
  Veryfast: 'Muito Rápido',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: 'Popular',
  MostDownload: 'Most Download',
  Credits: 'Créditos',
  Freshness: 'Novidade',
  pickAGenre: 'Escolha o Gênero',
  // Paginations
  Of: 'de',
  Page: 'Página',
  // home
  recommended: 'Recomendadas para você',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: 'Palavras-chave de tendencia',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: 'Game Sounds',
  cartoonSounds: 'Cartoon Sounds',
  soundPacks: 'Sound Packs',
  interfaceSounds: 'Interface Sounds',
  transitionsAndMovementsSounds: 'Transitions & Movement',
  natureSounds: 'Nature Sounds',
  domesticSounds: 'Domestic Sounds',
  urbanSounds: 'Urban Sounds',
  industrialSounds: 'Industrial Sounds',
  futuristicSounds: 'Futuristic Sounds',
  humanSounds: 'Human Sounds',
  othersSounds: 'Others',
  ambientSounds: 'Ambient',
  childrenSounds: "Children's",
  cinematicSounds: 'Cinematic',
  classicalSounds: 'Classical',
  corporateSounds: 'Corporate',
  electronicaSounds: 'Electronica',
  soulSounds: 'Soul & R&B',
  jazzSounds: 'Jazz',
  popSounds: 'Pop',
  worldBeatSounds: 'World Beat',

  countrySounds: 'Country & Western',
  drumSounds: 'Drum & Bass',

  folkSounds: 'Folk & Acoustic',
  funkSounds: 'Funk & Groove',

  hiphopSounds: 'Hip Hop',
  holidaySounds: 'Holiday & Seasonal',

  rockSounds: 'Rock',

  individualSounds: 'Individual',
  introSounds: 'Intro & Outro',
  ascSounds: 'Ascending & Descending',
  businessSounds: 'Business & Media',
  orchestralSounds: 'Orchestral',
  festiveSounds: 'Festive',
  percussiveSounds: 'Percussive',
  miscellaneousSounds: 'Miscellaneous',
  packsSounds: 'Packs',
  percussionSounds: 'Sons de percussão.',
  sampleLoopSounds: 'Loop de amostra',

  // Search images
  searchImage: 'Fotos & Vetores',
  StockPhotosAndImages: 'Banco de Imagens',
  StockVectorClipartAndIllustration: 'Banco de Imagens',
  matches: 'corresponde',
  editorial: 'Editorial',
  downloaded: 'Esta imagem foi baixada antes',
  to: 'to',
  relatedSearches: 'Pesquisas relacionadas',
  similarTo: 'Similar a',
  searchByImage: 'Search by image',
  options: 'Opções',
  searchByImageMsg:
    'Pesquise em 123RF com uma imagem em vez de texto. Tente arrastar uma imagem para a caixa de pesquisa.',
  mustBeJPGorPNG: 'A imagem deve ser JPG / PNG',
  sizeLessThan5MB: 'O tamanho da imagem deve ser inferior a 5 MB',
  sizeLessThan4MB: 'O tamanho da imagem deve ser inferior a 4 MB',
  dragOrDropFile: 'Arraste e solte o arquivo ou',
  browse: 'Procurar',
  uploadedImage: 'Imagem carregada',
  similarStockPhotos: 'Imagens Similares',
  // Search footage
  searchFootage: 'Footage & Videos',
  StockFootage: 'Vídeos',
  businessFootage: 'Hacks to make the most of your business videos',

  // Search audio
  searchAudio: 'Áudios (Músicas & Efeitos Sonoros)',
  StockAudio: 'Stock audio ',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: 'Copiar link',
  preview: 'Pré-visualização',

  bestSellingFootage: 'Navegue pelos vídeos isentos de royalties mais vendidos',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: 'Imagens similares',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: 'Suas imagens visualizadas recentemente serão mostradas aqui',
  rVVectors: 'Seus vetores visualizados recentemente serão mostrados aqui',
  rVFootage: 'Seu vídeo visualizado recentemente será mostrado aqui',
  rVAudio: 'Seu áudio visualizado recentemente será mostrado aqui ',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: 'Ops, sua pesquisa com esses filtros não deu nenhum resultado.',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle: 'Tente remover alguns filtros ou tente outra palavra-chave.',
  emptyReverseSearchTitle: 'Nenhum resultado encontrado!',
  emptyReverseSearchSubTitle1:
    'O envio da imagem contém erros. Verifique se a imagem possui os seguintes critérios:',
  emptyReverseSearchSubTitle2: 'Faça uma pesquisa refinada com palavras-chave',
  emptyReverseSearchSubTitle3: 'Image must be JPG / PNG',
  emptyReverseSearchSubTitle4: 'Image size must be less than 5 MB',
  emptySearchResultClearFilter: 'Limpar todos os filtros',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: 'Escreva uma palavra-chave',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // collection search result page
  searchCollection: 'Pesquisa coleções',
  personal: 'Uso Pessoal',
  groups: 'Grupos',
  createNew: 'Crie novo',
  createNewCollection: 'Crie uma nova seleção',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  titleItem: 'Título',
  selectGroup: 'SELECIONE GRUPO',
  description: 'Descrição',
  charactersRemaining: '180 caracteres restantes',
  cancel: 'Cancelar',
  or: 'OU',
  create: 'Crie',
  defaultCollection: 'Coleção por default',
  addToMyCollections: 'Adicionar à Likebox',
  addedToCollection: 'Adicionado à coleção',
  removedFromCollection: 'Removido da coleção',
  collectionModalPlaceholder: 'ex. Natureza',
  groupNameShowsHere: 'O nome do grupo aparece aqui',
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: 'Compartilhado comigo',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: 'Proprietário',
  images: 'Imagens',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: 'Pessoal',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: 'Compartilhe',

  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: 'Você não tem nenhuma coleção.',
  btnUnDo: 'Desfazer',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // my collection
  dateAdded: 'data adicionada',
  collectionName: 'Nome da coleção',
  selectAll: 'Selecionar tudo',
  delete: 'Deletar',
  seeAll: 'Veja todos',
  deleteCollection: 'Exclui coleção',
  deleteWarning:
    'Ao realizar esta ação, você exclui permanentemente sua(s) coleção(ões) e seu conteúdo. Observe que esta ação não pode ser desfeita',
  createCollection: ' foi criado',
  shareCollection: 'Compartilhar coleção',
  shareWithTeam: 'Share in team',
  selectUsers: 'Selecionar usuários',
  creditUsage: 'Credit usage',
  searchUserName: 'Procure o nome de um usuário',
  copy: 'Copie',
  titleCopyModal: 'Copie item selecionado',
  collectionUppercase: 'COLEÇÃO',
  groupCollection: 'Grupo',
  moveImage: 'Mover item selecionado para',
  defaultSelect: 'Nome da coleção aqui',
  moveLikebox: 'Move',
  messsageDeleteLikebox:
    'Completing this action will permanently delete your Collection(s) along with its contents. Be aware that this action cannot be undone.',
  areYouSure: 'Tem certeza?',
  editCollection: 'Editar coleção',
  collectionNameUpper: 'Nome da coleção',
  deleteSuccessful: 'has been successfully deleted',
  edit: 'Editar',
  messageRemoveImage: 'Tem certeza de que deseja remover o(s) item(ns) selecionado(s) da coleção?',
  titleRemove: 'Excluir itens selecionados',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: 'SALVAR',
  shareLikebox: 'Compartilhado comigo',
  remove: 'Excluir',
  moveTo: 'Mover para',
  createNewPlaceholder: 'ex. Natureza',
  shareViaEmail: 'Compartilhar por email',
  sharePublicly: 'Share publicly',
  receipnetEmail: 'Email do destinatário',
  message: 'Mensagem',
  insertReceipmentEmail: 'Insira o e-mail do destinatário',
  messageText: 'Texto da mensagem',
  exportCollection: 'Exporte coleção',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: 'Add New Collection',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: 'Formato',
  lowCredit: 'low credit',
  downloadSuccess: 'Obrigado pelo seu download! O download começará em um momento.',
  downloadFailed: 'Erro de Download: Um erro ocorreu, por favor tente de novo.',
  downloadedLabel: 'Baixadas',
  any: 'Qualquer',
  resetFilter: 'Redefinir filtro',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: 'Seguir',
  name: 'Nome',
  location: 'Lugar',
  since: 'da',

  // Minha Conta
  updated: 'Update',
  userName: 'USUÁRIO',
  firstName: 'NOME',
  lastName: 'Sobrenome',
  myProfile: 'My Profile',
  changeProfiledetail: 'Altere os detalhes do seu perfil aqui',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: 'Meus Planos',
  usePayLater: 'Use agora, pague depois',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  bodyUNPL:
    'Use Agora, Pague Depois (UNPL) é um tipo de financiamento de curto prazo que permite ao consumidor fazer compras e pagá-las em uma data posterior, muitas vezes sem juros',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',
  // Download-history modal
  reDownload: 'Baixe novamente',
  chooseCustomTagOrCreate: 'Escolha uma ou mais tags personalizadas ou crie uma nova.',
  downloadDetails: 'Informações de download',
  image: 'Image',
  downloadType: 'Tipo de Download',
  groupName: 'Nome do grupo',
  size: 'Tamanho',
  dateDownloaded: 'Data de Download',
  reLicense: 'Relicenciar',
  customFields: 'Campos personalizados',
  saveAndClose: 'Salve e feche',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: 'data',
  export: 'Exporte',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: 'Conteúdo Gratuito',
  searchById: 'Pesquisa por ID',
  pleaseSelect: 'Por favor, selecione',
  totalOutstanding: 'Total Pendente',
  viewInvoices: 'Ver Fatura',
  recentLike: 'Minhas curtidas recentes',
  // Image detail page - select download size sidebar
  standardSizes: 'Tamanhos standard',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: 'CROP',
  // empty data
  opsNothingToSeeHere: 'Opa! Nada para ver aqui.',

  // audio player
  categoryGenre: 'Categoria / gênero',
  duration: 'Duração',
  seeMore: 'Mostrar mais',
  seeLess: 'Mostrar menos',
  audioID: 'ID do Arquivo',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  audioProperties: 'Propriedades de áudio',
  footageProperties: 'Propriedades de Gravação',
  modalDownloadTitle: 'Opções de download de arquivo',
  credit: 'Crédito',
  saveAs: 'Salvar como',
  ownLicense: 'Download normal (licença própria)',
  downloadAs: 'Baixar como',
  custom: 'Personalizado',
  insufficientCredits: 'Créditos insuficientes',
  personalAndCom: 'Uso pessoal e comercial Anúncios, apresentações, redes sociais, web.',
  licenseType: 'Tipo de licença',
  requiredForResale:
    'Necessário para revenda ou distribuição em apresentações teatrais, acesso pago, streaming de vídeo, jogos e aplicativos.',
  footageDetail: 'Detalhe dos vídeos',
  stockAudioKeywords: 'Palavras-chave de estoque de áudio',
  stockFootageKeywords: 'Palavras-chave do vídeo',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: 'Estéreo',
  fileSize: 'Tamanhos de arquivo',
  footageId: 'ID Video',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  fileName: 'Nome do Arquivo',
  authentic: 'Autêntico',
  // search audio
  mostDownloaded: 'Mais baixados',
  download: 'Baixar',
  copied: 'Copiado!',
  // category photo
  landscape: 'Landscape',
  Babies: 'Babies',
  business: 'Business',
  food: 'Food',
  animals: 'Animals',
  technology: 'Technology',
  healthcare: 'Healthcare',
  nature: 'Nature',
  romance: 'Romance',
  // category vector
  graphic: 'Graphic',
  advertising: 'Advertising',
  flower: 'Flower',
  abstract: 'Abstrato',
  Illustration: 'Illustration',
  pop: 'Pop',
  bird: 'Bird',
  pixel: 'Pixel',
  threeD: '3D',

  // footage category
  education: 'Education',
  communication: 'Communication',
  beauty: 'Beauty',
  concept: 'Concept',
  fitness: 'Fitness',
  lifestyle: 'Lifestyle',
  celebration: 'Celebration',
  family: 'Family',
  fashion: 'Fashion',

  // audio category
  soulAndRB: 'Soul & R&B',
  corporate: 'Corporate',
  electronica: 'Electronica',
  classical: 'Classical',
  funkAndGrooveMusic: 'Funk & Groove music',
  worldBeat: 'World Beat',
  cinematic: 'Cinematic',
  jazz: 'Jazz',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: 'Próxima página',
  reverseImageSearch: 'Reverse Image Search',

  group: 'Grupo',
  needHelp: 'Precisa de ajuda?',
  copyPortfolioLinkSuccess: 'Link de portfólio colaborador copiado para a área de transferência.',

  // dashboard
  profile: 'Perfil',

  // Pricing
  pricingCreditText: 'Crédito',

  // Dashboard - Credit Usage
  user: 'User',
  searchPlaceholder: 'Search name, username, role, group',
  all: 'Todas',
  active: 'Active',
  deleted: 'Deleted',
  reset: 'Atualizar',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: 'AI Powered Search',
  aiPoweredSearchOn: 'AI Powered Search is on',
  aiSearchIntroduction:
    'Experimente nosso mecanismo de pesquisa mais rápido e poderoso. Nosso mecanismo de pesquisa de IA cuida do resto!',
  ok: 'OK',

  // Filter bar ai

  bstract: 'Abstrato',
  macroCloseUp: 'Macro/Close up',
  object: 'Objeto',
  watercolor: 'Watercolor',
  flat: 'Plano',
  cartoon: 'Desenho animado',
  geometric: 'Geométrico',
  gradient: 'Gradiente',
  isometric: 'Isométrico',
  '3d': '3d',
  handDrawn: 'Desenhado à mão',
  natural: 'Natural',
  warm: 'Quente',
  dramatic: 'Dramático',
  vividAndBold: 'Vivo e ousado',
  blackAndWhite: 'Preto e branco',
  // introduce Free image research
  imageResearchContentTitle:
    'Apresentando o serviço gratuito de pesquisa de imagens do 123RF: imagens perfeitas, sem problemas',
  imageResearchContentDesc:
    'Estamos revolucionando a pesquisa de imagem para nossos clientes - encontrando sem esforço a imagem perfeita e dando vida à sua visão.',
  submitRequest: 'Enviar pedido',
  imageResearchContentInfoTitle1: 'Pesquisa de imagem facilitada',
  imageResearchContentInfoDesc1:
    'Os primeiros passos são muito simples. Apenas nos dê uma descrição detalhada da imagem que você deseja, incluindo estilo, humor, cores e elementos específicos. Quanto mais detalhes você fornecer, melhor poderemos atender às suas necessidades.',
  imageResearchContentInfoTitle2: 'Como funciona?',
  imageResearchContentInfoTitle3: 'Serviço rápido e confiável',
  imageResearchContentInfoTitle4: 'Deixe-nos fazer o trabalho para você',
  imageResearchContentInfoDesc4:
    'Encontraremos as imagens perfeitas para você, para que você possa se concentrar no seu projeto. Confie em nós para dar vida à sua visão com imagens cuidadosamente selecionadas de alta qualidade.',
  requestForm: 'formulário de consulta',
  whatDoYouWantThisImageFor: 'Para que você gostaria de usar esta imagem?',
  websiteBanner: 'Banner do site',
  socialMedia: 'Mídia social',
  blogPost: 'Postagem no blog',
  advertisement: 'Publicidade',
  print: 'Impressão',
  others: 'Outros',
  othersOutputPlaceholder: 'Insira outros tipos de output',
  whatIsPrimaryMessage:
    'Qual é a mensagem ou tema principal que a imagem está tentando transmitir?',
  whatIsPrimaryMessagePlaceholder: 'Exemplo: Cachorro com pelo corre com mulher no parque',
  areThereSubjectYouWantInThePicture:
    'Existem assuntos ou elementos específicos que você deseja incluir na imagem?',
  egPeopleNatureTechnologyAbstract: 'Ex: pessoas, natureza, tecnologia, conceitos abstratos',
  areThereImageOrStyleReference:
    'Você já viu alguma imagem ou estilo que gostaria que usássemos como inspiração ou referência?',
  ifPeopleIncludeWhatTheirDemographic:
    'Se as pessoas devem ser incluídas, qual deve ser sua demografia?',
  egAgeGenderEthnicityOccupation: 'Ex: idade, gênero, etnia, ocupação',
  areThereAnyThingToAvoid: 'Há algum elemento ou tema que você gostaria de evitar na imagem?',
  whoisYourTargetAudience: 'Quem é seu público alvo?',
  whoisYourTargetAudiencePlaceholder: 'Ex: Jovens que gostam de ler',
  typeOfImage: 'Tipo de imagem',
  whatMoodShouldImageEvoke: 'Que humor ou emoção você quer que a imagem evoque?',
  happy: 'Feliz',
  sad: 'Triste',
  calm: 'Calmo',
  excited: 'Animado',
  angry: 'Bravo',
  enterCustomMood: 'Entre no modo personalizado',
  areThereAnyOrientationForImage: 'Existem alinhamentos específicos necessários para a imagem?',
  enterCustomOrientation: 'Insira a orientação personalizada',
  isThereLocationYouWantImageToBeIn:
    'Existe um local ou ambiente específico em que você deseja que a imagem esteja?',
  no: 'Não',
  yes: 'Sim',
  shareWithUsAReference: 'Envie-nos uma referência (somente JPG ou PDF. Max 4MB)',
  replace: 'Substituir',
  areThereAnySpecificRequirements: 'Existem outros requisitos ou preferências?',
  enterOtherComments: 'Insira outros comentários',
  submit: 'Enviar',
  fieldCannotEmpty: 'Este campo não pode estar vazio',
  selectAtLeastOne: 'Selecione pelo menos uma opção',
  fileMustBeJpgOrPdf: 'O arquivo deve ser um JPG ou um PDF.',
  fileSizeLessThan4MB: 'O tamanho do arquivo deve ser inferior a 4 MB',
  maximumFileNameAllow255: 'O máximo de caracteres permitidos são 255',
  updateSuccessfully: 'Recebemos seu pedido.',
  freeImageResearch: 'Pesquisa de imagens gratuita',
  newService: 'Novo serviço!',
  freeImageResearchIntroduction:
    'Experimente nosso serviço de pesquisa de imagens e obtenha imagens que atendam às suas necessidades.',
  later: 'Mais tarde',
  letTryIt: 'Vamos experimentar!',
  // viewPdfOfSampleOutput: 'Ver PDF da saída de exemplo',
  imageResearchContentInfoDesc2: (
    <>
      Nossa equipe combina
      {' '}
      <strong>tecnologia de IA</strong>
      {' '}
      e experiência para encontrar as imagens
      ideais para você. Algoritmos avançados filtram as opções de nossa extensa biblioteca com base
      em sua entrada. Nossa
      {' '}
      <strong>equipe de pesquisa</strong>
      {' '}
      refina os resultados para atender
      aos seus critérios.
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      Entregamos os resultados em
      {' '}
      <strong>dois dias úteis</strong>
      , fornecendo uma explicação abrangente para as seleções de imagens. Concentre-se em seus negócios enquanto nós cuidamos do resto.
    </>
  ),
  viewPdfOfSampleOutput: 'Ver PDF da saída de exemplo',
  characterMaxChar255: 'O máximo de caracteres permitidos são 255',

  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: 'é necessário',

  relatedSearch: 'Related searches',
  showMore: 'Show more',
  showLess: 'Show less',
  requiredWithVariable: '{{var}} é necessário',
  mood: 'Humor',
  person: 'Pessoa',
  doNotIncludeWords_ex2: 'Ex. Cor verde, cabelo liso',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: 'Gerador de imagens com IA',
  imageGenerator: 'Gerador de Imagens',
  aiImageGeneratorDesc: 'Proporcionando criatividade na velocidade de sua imaginação.',
  generate: 'Gerar',
  exploreVisualsCreatedWithThePowerOfAI: 'Explore imagens criadas com o poder da I.A.',
  turnYourImaginationIntoRealityIn3SimpleSteps:
    'Transforme sua imaginação em realidade em 3 etapas simples',
  startWithGoodTextPrompt: (
    <>
      Comece com um
      {' '}
      <span>
        bom
        <br />
        prompt de texto
      </span>
    </>
  ),
  startWithGoodTextPromptDesc:
    'Um bom prompt garante ótimos resultados. Para obter os melhores resultados, uma solicitação deve incluir perspectiva, assunto, ação, palavras-chave, iluminação e estilo.',
  reviewYourAIGeneratedImages: (
    <>
      Revise suas
      {' '}
      <br />
      <span>imagens geradas por IA</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    'Nosso gerador de IA criará e exibirá 3 imagens de visualização mais próximas de sua solicitação.',
  downloadAndLicenseVisuals: (
    <>
      Faça o download e
      {' '}
      <br />
      <span>licencie os recursos visuais</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    'Agora você pode baixar e licenciar essas imagens para uso pessoal e comercial com uma taxa legal de $ 25.000.',
  needSomeIdeas: 'Precisa de algumas ideias? Comece com esses exemplos e dê vida às suas ideias!',
  tryPrompt: 'Experimente as instruções',
  creativityAtTheSpeedOfYourImagination: 'Crie na velocidade da sua imaginação',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    'Gere suas próprias imagens de IA a partir de prompts de texto com o Corporate+ em segundos!',
  tryNow: 'Experimente agora',

  /// Random prompt
  prompt1: 'Retrate um cantor coreano de 28 anos, confiante, porém acessível, com uma presença de palco cativante. Mostre suas habilidades vocais dinâmicas e seu charme magnético por meio de um retrato expressivo que destaque seus traços marcantes. Renderize seu cabelo preto e espesso e seus olhos intensos e emotivos em detalhes vívidos, transmitindo a profundidade de sua paixão artística. Vista-o com roupas elegantes e contemporâneas que reflitam sua personalidade musical, talvez com um toque de ousadia ou sofisticação. Posicione-o em um ambiente que sugira uma apresentação ao vivo ou um estúdio de gravação, capturando a energia e a emoção de sua arte. Use iluminação e enquadramento dramáticos para criar uma qualidade cinematográfica e grandiosa, enfatizando seu status de estrela em ascensão. Injete na composição uma sensação de movimento e dinamismo, como se ele estivesse no meio de uma apresentação vocal cativante. O objetivo é criar uma imagem que imediatamente prenda a atenção do espectador e mostre a inegável presença de palco e o talento vocal do cantor.',
  prompt2: 'Uma imagem foto-realista de um leão majestoso deitado em uma rocha com a vasta savana africana ao fundo. Use uma câmera com uma lente de 400 mm na configuração de abertura F 2.8 para desfocar o fundo e focar nitidamente no leão. A iluminação deve ser natural e quente, capturando os tons dourados do pôr do sol. Enfatize o fotorrealismo para criar uma imagem vívida e detalhada que transmita a beleza e a grandeza da vida selvagem.',
  prompt3: 'Uma peça de mídia mista representando um samurai futurista em uma pose de ação, empunhando uma katana brilhante, ambientada em uma paisagem urbana cyberpunk com luzes de néon e hologramas. Incorpore uma variedade de texturas e materiais, incluindo elementos metálicos e digitais, para criar uma cena visualmente rica e dinâmica.',
  prompt4: 'Uma ilustração digital alegre de uma jovem princesa com um vestido fluido, brincando com criaturas mágicas em uma floresta encantada. Cerque-a com flores coloridas, borboletas e poeira de fada brilhante, em um fundo vibrante e fantasioso.',
  prompt5: 'Um desenho detalhado a grafite de um guerreiro antigo com rosto envelhecido e expressão determinada, segurando uma espada. Use sombreamento e texturas intrincados para destacar a armadura e as cicatrizes de batalha do guerreiro, criando uma imagem atemporal e poderosa.',
  prompt6: 'Uma colagem surrealista em mídia mista de um explorador viajante no tempo navegando por diferentes eras históricas, com elementos como mapas antigos, artefatos antigos e gadgets futuristas. Use uma combinação de recortes de jornais antigos, fotografias e arte digital para criar uma peça humorística e instigante.',
  prompt7: 'Uma ilustração digital detalhada de uma paisagem urbana distópica, com arranha-céus imponentes, carros voadores e nuvens escuras e ameaçadoras. Concentre-se em criar uma sensação de profundidade e escala, com detalhes intrincados na arquitetura e iluminação dramática para realçar o clima.',
  prompt8: 'Um retrato de um ancião sábio segurando um cajado mágico, capturado em uma iluminação dramática de claro-escuro para acentuar os traços faciais do ancião e as runas brilhantes do cajado. Use texturas e sombras ricas para criar uma sensação de mistério e sabedoria.',
  prompt9: 'Uma vibrante ilustração de fantasia de um cavaleiro de dragão voando pelos céus, com o cavaleiro vestido com armadura e as asas do dragão abertas. Cerque-os com nuvens e montanhas distantes, adicionando uma sensação de aventura e majestade.',
  prompt10: 'Uma fotografia de rua candidata em preto e branco de uma movimentada cena de mercado em uma cidade asiática, com vendedores vendendo frutas e verduras coloridas. Use uma pequena profundidade de campo para focar nas expressões e interações do vendedor, capturando a atmosfera animada.',
  prompt11: 'Uma pintura aquarela whimsical de um unicórnio com uma crina arco-íris, em pé em uma floresta mágica cercada por cogumelos brilhantes e vaga-lumes cintilantes. Use cores pastel suaves e pinceladas fluidas para criar uma cena sonhadora e encantadora.',
  prompt12: 'Uma renderização 3D de uma cozinha moderna com design elegante e minimalista, eletrodomésticos de aço inoxidável e uma grande ilha no centro. Use texturas e iluminação fotorrealistas para destacar as linhas limpas e o estilo contemporâneo, com foco nos reflexos e materiais.',
  prompt13: 'Uma pintura de paisagem de fantasia de uma ilha flutuante no céu, com cachoeiras caindo pelas bordas e vegetação exuberante cobrindo a superfície. Cerque a ilha com nuvens fofas e um céu azul vibrante, criando uma sensação de maravilha e magia.',
  prompt14: 'Um retrato detalhado de um guerreiro viking com cabelo trançado e expressão feroz, segurando um machado de batalha. O fundo deve retratar um mar tempestuoso e penhascos escarpados, com iluminação dramática para destacar os traços e a armadura do guerreiro.',
  prompt15: 'Uma colagem surrealista de mídia mista de um inventor steampunk em uma oficina desordenada cheia de engrenagens, mecanismos de relojoaria e engenhocas movidas a vapor. Use fotografias antigas, plantas antigas e texturas metálicas para criar uma cena visualmente rica e imaginativa.',
  prompt16: 'Uma ilustração digital de uma cidade futurista à noite, com arranha-céus imponentes, letreiros de néon e veículos voadores. Use cores vibrantes e iluminação dinâmica para criar uma atmosfera animada e imersiva, enfatizando os avanços tecnológicos e a energia agitada da cidade.',
  prompt17: 'Uma renderização 3D fotorrealista de um aconchegante canto de leitura com uma poltrona confortável, uma mesinha com uma lâmpada e prateleiras cheias de livros. O cenário deve ter uma iluminação quente e convidativa e texturas suaves, capturando o ambiente sereno e relaxante do espaço.',
  prompt18: 'Retrate uma bolsa de mão de moda transparente, semelhante a vidro, com um aquário totalmente funcional integrado ao design. Renderize meticulosamente os delicados peixes nadando dentro da bolsa, criando um acessório fascinante e incomum. Certifique-se de que o aquário esteja perfeitamente integrado à estrutura da bolsa, com transições suaves entre os dois elementos. Posicione a bolsa de forma proeminente em uma loja de departamento de alta qualidade, cercada por um cenário de prateleiras elegantes e modernas, iluminação e outras vitrines de varejo de luxo. Utilize iluminação dramática e direcional para criar uma sensação de drama e cativar a atenção do espectador, chamando sua atenção diretamente para a bolsa aquática. Capture a bolsa de um ângulo que mostre sua qualidade transparente, quase etérea, permitindo que os peixes sejam claramente visíveis e criando uma impressão de leveza ou fluidez.',
  prompt19: 'Capture uma cena aconchegante e convidativa com poltronas de pelúcia em uma paleta suave de tons marrons e verde-oliva. Apresente as cadeiras em um ambiente quente e íntimo, cercadas por detalhes que evocam uma sensação de relaxamento e conforto, como tecidos macios, detalhes em madeira rica e talvez um vislumbre de uma lareira ou janela com vista para uma paisagem natural pacífica. Enfatize a qualidade suntuosa e textural da tapeçaria e a sensação geral de aconchego e refúgio.',
  prompt20: 'Capture uma representação vibrante e realista de uma perdiz-crestada empoleirada no topo de um galho coberto de musgo, contra um fundo verde esmeralda exuberante que complementa a plumagem deslumbrante do pássaro. Renderize as penas intrincadas da perdiz com detalhes requintados, usando um degradê sutil de luz e sombra para acentuar suas texturas. Destaque a crista verde-escura rica, o bico marrom-terra quente e os olhos negros brilhantes e inquisitivos da perdiz, criando um retrato cativante da beleza natural do pássaro. Invista a cena geral em uma atmosfera quente e tranquila, convidando o espectador a entrar no ambiente natural sereno.',
  prompt21: 'Projete uma imagem visualmente atraente com uma tigela de iogurte congelado cremoso coberta com uma variedade vibrante de frutas frescas e nozes crocantes. Capture as qualidades refrescantes e nutritivas dessa sobremesa saudável, mostrando o contraste entre o iogurte suave e de cor clara e as vibrantes pinceladas de cor das coberturas de frutas. Arrume os ingredientes de maneira artística e apetitosa, permitindo que o espectador aprecie o apelo visual e antecipe a experiência refrescante e saborosa. Certifique-se de que a imagem geral emane uma sensação de saúde e indulgência, convidando o espectador a saborear as qualidades deliciosas e nutritivas dessa guloseima deliciosa.',
  prompt22: 'Mergulhe em um banquete visual, explorando um jardim de flores surreal que explode em cores vibrantes e detalhes intrincados. Capture a essência cativante das flores com pétalas curvas e espirais, folhagem variegada e outras tonalidades hipnóticas - cada uma uma obra de arte por si só. Utilize a fluidez da aquarela e a expressividade da tinta, empregando uma técnica de úmido sobre úmido para criar uma sensação de movimento e profundidade. Transmita a qualidade surreal e onírica dessa maravilha floral por meio da interação de cores ricas e saturadas e da representação delicada e intrincada de cada pétala e folha.',
  prompt23: 'Crie uma ilustração cativante de uma cena noturna com um antigo farol imponente em uma ilha rochosa. O farol, adornado com várias janelas, emite um brilho quente e acolhedor, destacando sua arquitetura intrincada e sua rica história. A ilha rochosa é coberta por árvores exuberantes e verdejantes, cujo reflexo dança nas águas calmas e iluminadas pela lua que espelham o brilho prateado da lua cheia. O orbe celestial enfeita o céu, lançando um brilho etéreo nas nuvens e no mar, enquanto nuvens etéreas obscurecem parcialmente seu brilho, adicionando mistério à cena. Estrelas cintilantes salpicam a extensão escura, criando uma tapeçaria celestial que harmoniza com o brilho da lua. Esta obra de arte encantadora captura uma noite serena e pacífica, onde o farol iluminado permanece resoluto em meio à beleza celestial, servindo como sentinela e criando uma experiência cinematográfica e onírica para o espectador.',

  /// Tool list
  // ratio
  ratio: 'Formato',
  square1_1: 'Quadrado (1:1)',
  square: 'Quadrado',
  portrait2_3: 'Retrato (2:3)',
  portrait: 'Retrato',
  landscape3_2: 'Paisagem (3:2)',
  wide16_9: 'Panorâmica (16:9)',
  wide: 'Panorâmica',
  // style
  none: 'Nenhum',
  enhance: 'Aprimorar',
  anime: 'Anime',
  photographic: 'Fotográfico',
  digitalArt: 'Arte digital',
  comicBook: 'História em quadrinhos',
  fantasyArt: 'Arte fantasia',
  analogFilm: 'Filme analógico',
  neonPunk: 'Neon punk',
  lowPoly: 'Low poly',
  origami: 'Origami',
  lineArt: 'Arte linear',
  tileTexture: 'Texture',
  threeDModel: 'Modelo 3D',
  pixelArt: 'Pixel art',
  craftClay: 'Massa de modelar',
  // color
  coolTone: 'Tom frio',
  mutedColors: 'Cores suaves',
  pastelColors: 'Cores pastel',
  vibrantColors: 'Cores vibrantes',
  warmTone: 'Tom quente',
  // lighting
  lighting: 'Iluminação',
  backlight: 'Contraluz',
  crepuscularRays: 'Raios crepusculares',
  dimlyLit: 'Pouco iluminado',
  goldenHour: 'Pôr do sol',
  lowLight: 'Pouca luz',
  rimLighting: 'Contornos iluminados',
  studio: 'Estúdio',
  sunlight: 'Luz solar',
  volumetric: 'Volumétrico',
  // composition
  composition: 'Composição',
  blurryBackground: 'Fundo desfocado',
  closeUp: 'Primeiro plano',
  macrophotograph: 'Macrofotografia',
  narrowDepth: 'Profundidade restrita',
  shotFromAbove: 'Capturado de cima',
  shotFromBelow: 'Capturado de baixo',
  wideAngle: 'Ângulo amplo',

  /// FQAs
  allAIImageQuestionsAnswered: 'Todas as suas perguntas de imagem geradas pela AI respondidas.',
  TTI_Q1: 'O que são imagens geradas pela IA?',
  TTI_A1:
    'Imagens geradas por IA são imagens criadas usando algoritmos de inteligência artificial (IA). Esses algoritmos são projetados para simular a criatividade humana e podem gerar imagens semelhantes às produzidas por artistas humanos, mas com elementos exclusivos e, muitas vezes, surreais ou abstratos.',
  TTI_Q2: 'Há um limite para o número de imagens que posso gerar por dia?',
  TTI_A2:
    'Sim, há um limite para o número de imagens que você pode gerar por dia. O limite garante que todos os usuários tenham acesso justo e uma boa experiência de usuário ao acessar o serviço de imagens de IA.',
  TTI_Q3: 'Do you support text prompts in languages other than English?',
  TTI_A3: 'We support only the English language at the moment.',
  TTI_Q4: 'Qual é a diferença entre gerar e licenciar uma imagem?',
  TTI_A4:
    'Ao gerar uma imagem, você cria um conjunto de imagens de IA para exibir. Ao licenciá-los no Corporate+, você terá acesso para baixar uma versão de alta resolução da imagem AI com sua própria licença.',
  TTI_Q5:
    'Uma imagem que gerei contém uma marca registrada, um ponto de referência ou uma personalidade pública reconhecível. Ainda posso usá-la?',
  TTI_A5:
    'Se tiver uma pessoa ou objeto reconhecível, ou se se concentrar principalmente num edifício ou monumento distinto, deverá obter permissão de utilização diretamente da parte representada na imagem, especialmente se pretender utilizá-la num contexto comercial.',
  TTI_Q6: 'Quem são as pessoas que aparecem nas imagens geradas?',
  TTI_A6:
    'As pessoas que aparecem nas imagens geradas por IA não são pessoas reais, mas sim criações digitais.',
  TTI_Q7:
    'O que acontece com o conteúdo que eu gerei? Ele será disponibilizado para outros usuários?',
  TTI_A7:
    'O conteúdo que você gera também pode ser disponibilizado a outros usuários. Você pode consultar nossa licença para obter mais informações.',
  TTI_Q8: 'Por que demora um pouco para carregar quando tento gerar uma imagem?',
  TTI_A8:
    'A página levará alguns segundos para gerar a imagem. O tempo gasto depende da complexidade do prompt, do hardware e dos recursos de computação disponíveis, bem como da quantidade de solicitações que a IA precisa processar simultaneamente.',
  TTI_Q9: 'Como faço para licenciar as imagens de IA que gerei?',
  TTI_A9:
    'Você poderá adquirir as imagens que criou sob licença baixando-as de sua conta Corporate+. Com cada download você receberá a versão em alta resolução da imagem gerada e cobertura legal de US$ 25.000.',
  TTI_Q10: 'Posso visualizar as gerações anteriores?',
  TTI_A10:
    'Sim, você pode fazer isso marcando e revistando a URL das gerações passadas. Você também pode compartilhar os resultados das gerações com seus amigos.',
  TTI_Q11: 'Como faço para gerar imagens de alta qualidade com IA?',
  TTI_A11: `<ol>
              <li>Comece com um objetivo claro: "Imagem de alta qualidade de uma paisagem de montanha para um blog de viagens".</li>
              <li>Especifique o estilo e o clima desejados: "A imagem deve ser brilhante e vibrante com um toque natural e orgânico."</li>
              <li>Descreva o assunto: Seja específico sobre o assunto da imagem, incluindo detalhes como o cenário, a iluminação e quaisquer outros recursos ou elementos importantes. Por exemplo, "Destaque um pico de montanha coberto de neve com um rio sinuoso em primeiro plano e um céu azul claro ao fundo".</li>
              <li>Forneça exemplos: Inclua exemplos de outras imagens que tenham um estilo ou clima semelhante ao que você está procurando. Isso pode ajudar a orientar o algoritmo de IA e garantir que o resultado atenda às suas expectativas.</li>
              <li>Use palavras-chave relevantes: "paisagem de montanha", "pico coberto de neve" ou "brilhante e vibrante".</li>
              <li>Evite ambiguidade: Seja o mais claro e específico possível ao fornecer instruções e evite usar linguagem vaga ou ambígua que possa levar a resultados inesperados.</li>
            </ol>`,

  /// Generating page
  bringingYourImagesToLife: 'Dando vida às suas imagens...',
  cplusAiGenerator: 'Gerador de AI',
  generatedImages: 'Imagens geradas',
  promptSettings: 'Configurações de prompt',
  pastGeneration: 'Gerações passadas',
  generateVariation: 'Gerar variação',
  iLikeThis: 'Gostei',
  iDislikeThis: 'Não gostei',
  shareOptions: 'Opções de compartilhamento',
  variations: 'Variações',
  copiedSharedUrl: 'URL compartilhado copiado para a área de transferência',
  aiLicenseHeader: 'Este arquivo foi gerado por IA (Inteligência Artificial).',
  aiLicenseContent: 'O conteúdo gerado por inteligência artificial não está sujeito ao processo padrão de revisão de conformidade da 123RF. É responsabilidade do criador garantir que tais materiais gerados por IA não infrinjam direitos de propriedade intelectual ou outros direitos de terceiros. A produção de spam, bem como qualquer conteúdo falso, enganoso, prejudicial, que represente violência, pornográfico ou ilegal, é estritamente proibida por nossas políticas.',
  galleryPrompt1: "Sereia modesta e etérea, debaixo d'água, coroa de conchas cercada por peixes, retrato",
  galleryPrompt2: 'assado de domingo na mesa, cheio de comida e velas',
  galleryPrompt3: 'Raposa fofa e bonita sorrindo em uma floresta, altamente detalhada, ampliada com outros animais da floresta',
  galleryPrompt4: 'alta qualidade, 8K Ultra HD, notas musicais, formas de instrumentos dentro de uma abóbora feita de cristal, alto nível de detalhes, lâmpada mágica de abóbora, sensação de outono, calma',
  galleryPrompt5: 'Paisagem cinematográfica panorâmica da Escócia, dia lindo e brilhante',
  galleryPrompt6: 'menina estilo 3d, 4k, 8k, octane render photorealistic, hdr, fotografia, alta definição, rosto simétrico, iluminação volumétrica, névoa empoeirada, foto, octane render, 24mm, 4k, 24mm, DSLR, alta qualidade, 60 fps, ultra realista',
  galleryPrompt7: 'cara de pele marrom, herói socando o planeta Júpiter no espaço, de frente',
  galleryPrompt8: 'biscoito com café quente, em um ambiente natalino',
  galleryPrompt9: 'Garrafa de água artística em um fundo branco minimalista com folhas de Montserra',
  galleryPrompt10: 'dragão de fantasia de diamante evanescente',
  galleryPrompt11: 'planetas em gotas de orvalho, gotas de orvalho planetárias, ao nascer do sol, close-up, hiperdetalhado, foco nítido, foto de estúdio, detalhes intrincados, altamente detalhado',
  galleryPrompt12: 'uma pássaro em cima de um baú de ouro, no estilo de obra de arte de fantasia realista, pedra preciosa, contos de fadas sombrios, misterioso',
  galleryPrompt13: 'uma montanha de neve e um rastro de gelo no topo da montanha indo na direção oposta, ultra hd, realista, cores vivas, altamente detalhado, desenho UHD, caneta e tinta, composição perfeita, belo e detalhado, intrincado, insanamente detalhado, renderização octanagem, tendência na artstation, fotografia artística 8k, arte conceitual fotorrealista, luz perfeita cinematográfica volumétrica natural suave',
  galleryPrompt14: 'Era da civilização interestelar, com vista para os misteriosos edifícios da cidade alienígena de super ficção científica do espaço digital, edifício de torre de super arranha-céu exterior requintado e bonito, rua, ponte do céu, arquitetura de super ficção científica flutuante no céu, máquina de guerra, alta tecnologia, inúmeras luzes eletrônicas na tela, Super Sci-Fi Visual Feast Space, Sci-Fi, Mystery, sci-fi, surreal, imagens super nítidas, pixels HD, ultra-HD, resolução 5D, 8K, pixel perfeito, detalhes ultrafinos perfeitos, foco nítido, foto de estúdio, detalhes intrincados, altamente detalhados',
  galleryPrompt15: 'Linda praia com o oceano ao fundo em Florida Keys à noite, cores neon, fundo preto, ondas calmas, tonalidade natural, pôr do sol, calma, zoom relaxante nas ondas, praia, horizonte reto, rosa, azul, laranja, roxo',
  galleryPrompt16: 'melhor qualidade, exposição dupla, arte de correio, arte em um papel rachado, um gatinho fofo em close-up brincando com um novelo de lã, interior de quarto aconchegante detalhado, brilhante, luz do sol, raios de sol, pose dinâmica, ilustração de livro de histórias, 2d, plano, fofo, adorável, vintage, conto de fadas, patchwork, vitral, ilustração detalhada de livro de histórias, cinematográfico, ultra altamente detalhado, detalhes minúsculos, detalhes bonitos, místico, cores vibrantes, fundo complexo',

  // galleryPrompt17:
  //   'pirâmide na água com reflexos de fogo, montanhas, surrealismo escuro, retratos atmosféricos, fogo',
  // galleryPrompt18: 'panda vermelho na floresta, cinematográfico',
  // galleryPrompt19: 'mar, lua, peixe, céu estrelado, barco, ilustração colorida',
  // galleryPrompt20:
  //   'Reflexos urbanos vívidos dançam entre edifícios e água com gás em estilo abstrato minimalista, capturando a energia viva da noite, abstração geométrica, estilo pop art contemporâneo com iluminação neon e técnica de vazamento de acrílico',
  // galleryPrompt21:
  //   'vista traseira da mulher em silhueta com espada leve, portal circular no Ártico levando ao iceberg, tiro cinematográfico, figura monumental',

  /// error page
  copyrightDetected: 'Termos de Uso para Personagens ou Marcas Comerciais Conhecidas',
  copyrightDetectedDesc:
    'Temos o compromisso de ser um gerador de IA responsável e não geraremos resultados para marcas registradas, termos, marcas ou personalidades incluídas em avisos para evitar possíveis infrações. Nossos Termos de Serviço determinam que você NÃO deve usar os serviços de maneira que infrinja, se aproprie indevidamente ou viole quaisquer direitos de propriedade intelectual. Modifique seu aviso e tente novamente.',
  errorMesTTI: 'Ops! Pedimos desculpas, mas algo deu errado.',
  errorMesTTIDesc: 'O problema foi registrado para investigação. Tente novamente mais tarde.',
  tryAgain: 'Tente novamente',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',

  // download feature
  downloadQuotaFinished:
    'You have reached the maximum quota available. Please contact your system administrator.',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: 'Encontre imagens impressionantes. Pesquisar por palavras-chave ou frases.',
  searchBarPlaceholderAllImageTablet: 'Encontrar imagens impressionantes.',
  searchBarPlaceholderAllImageMobile: 'Encontrar imagens.',
  searchBarPlaceholderPhotoDesktop: 'Encontre fotos impressionantes. Pesquisar por palavras-chave ou frases.',
  searchBarPlaceholderPhotoTablet: 'Encontrar fotos impressionantes.',
  searchBarPlaceholderPhotoMobile: 'Encontrar fotos.',
  searchBarPlaceholderVectorDesktop: 'Encontre vetores impressionantes. Pesquisar por palavras-chave ou frases.',
  searchBarPlaceholderVectorTablet: 'Encontrar vetores impressionantes.',
  searchBarPlaceholderVectorMobile: 'Encontrar vetores.',
  searchBarPlaceholderFootageDesktop: 'Encontre vídeos impressionantes. Pesquisar por palavras-chave ou frases.',
  searchBarPlaceholderFootageTablet: 'Encontrar vídeos impressionantes.',
  searchBarPlaceholderFootageMobile: 'Encontrar vídeos.',
  searchBarPlaceholderAudioDesktop:
    'Encontre arquivos de áudio e música impressionantes. Pesquisar por palavras-chave ou frases.',
  searchBarPlaceholderAudioTablet: 'Encontrar arquivos de áudio e música impressionantes.',
  searchBarPlaceholderAudioMobile: 'Encontrar arquivos de áudio e música.',
  searchBarPlaceholderFreeDesktop: 'Encontre imagens gratuitas impressionantes. Pesquisar por palavras-chave ou frases.',
  searchBarPlaceholderFreeTablet: 'Encontrar imagens gratuitas impressionantes.',
  searchBarPlaceholderFreeMobile: 'Encontrar imagens gratuitas.',
  // editorial warning
  forEditorialUseOnly: 'Somente Uso Editorial',
  forEditorialUseOnlyDesc:
    'o uso desta imagem em publicidade ou para fins promocionais/comerciais é proibido, a menos que direitos adicionais sejam licenciados pelo próprio usuário.',

  // download comp
  downloadHiResComp: 'DOWNLOAD EM ALTA LAYOUT',
  downloadComp: 'DOWNLOAD PARA LAYOUT',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),
  textToImage: 'Texto para imagem',
  descTourTTI: 'Deixe sua imaginação voar e crie imagens com texto!',
  colorAndTone: 'Cor e tom',
  titleSuccessDownload: 'Ficamos felizes por você ter encontrado o que precisava.',
  hangInThere: 'Aguente firme! Não vai demorar muito.',
  adultContentTitle: 'Termos potenciais de conteúdo adulto detectados',
  adultContentDesc:
    'Estamos comprometidos com uma IA responsável e, portanto, não geramos resultados para certas frases, conceitos e/ou ideias. Por favor, altere sua descrição (o texto fornecido para gerar a imagem) e tente novamente.',
  reachedTheLimit: 'Oh não! Você atingiu o limite de geração de imagens AI para hoje.',
  backTomorrow: 'Volte amanhã para ver mais gerações!',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder: 'Para começar, descreva sua visão aqui e daremos vida a ela!',

  downloadAgain: 'Download again',
  btnClose: 'Fechar',

  fluxPro: 'Flux oferece geração de imagens avançada, de alta qualidade e variada.',
  flux: 'Nosso modelo mais rápido, projetado para desenvolvimento local.',
  ideogram: 'Transformando uma descrição em texto em belas imagens em questão de segundos.',
  realStockPhoto: 'Crie fotos de banco atraentes com facilidade usando nosso modelo de IA avançado.',
  realCinematic: 'Um modelo especializado em produzir resultados cinematográficos, excelente para proporções mais amplas.',
  realFood: 'Melhore sua marca culinária com o modelo de visualização de alimentos.',
  realMacro: 'Um ótimo modelo para gerar imagens macro detalhadas.',
  realBokeh: 'Um modelo de estilo fotorrealista, perfeito para criar imagens com bokeh impressionante.',
  variousTitle: 'Descubra nossos vários modelos',
  variousSDXL: 'Dê vida a pessoas e cenas com realismo fotográfico impressionante.',
  amazonNova: 'Um modelo de geração de imagens de última geração.',
  variousEimisAnime: 'Modelo de IA generativo projetado para criar imagens no estilo anime.',
  variousDynaVisionXL: 'Gerar personagens de desenho animado estilizados em 3D.',
  variousJuggernautXL: 'Centrado em elementos arquitetônicos e paisagens/backgrounds.',
  variousDreamShaperXL:
    'Aumento da resolução, gerações aprimoradas de personagens e dragões, redução do desfoque das bordas.',
  variousRealismEngineSDXL:
    'Esse modelo é especializado em imagens altamente detalhadas e realistas.',
  variousDALLE:
    'DALL-E 3, um modelo completo que se destaca em transformações precisas de conceito para visual.',
  aiEngine: 'Motor de IA',
  'DALL-E': 'DALL-E 3',

  langText: 'Texto',
  langElements: 'Elementos',
  langAuto: 'Auto',
  langBgRemoval: 'Remoção de fundo',
  resetAll: 'Resetear Todo',
  facebookImage: 'Imagem do Facebook',
  facebookLink: 'Link do Facebook',
  facebookCover: 'Capa do Facebook',
  facebookStory: 'História do Facebook',
  instagramPost: 'Postagem no Instagram',
  instagramStory: 'História do Instagram',
  twitterPost: 'Post no Twitter',
  twitterHeader: 'Twitter header',
  pinterestPost: 'Post no Pinterest',
  emailHeader: 'Email header',
  presentation: 'Apresentação',
  presentationWide: 'Ampla apresentação',
  ebookCover: 'Capa do e-book',
  linkedinPost: 'Post no LinkedIn',
  brochureCover: 'Capa do folheto (A4)',
  postcard: 'Cartão postal',
  photoPrint: 'Impressão de fotos',
  youtubeProfileImage: 'Foto de perfil do YouTube',
  youtubeChannelCoverPhoto: 'Foto de capa do canal do YouTube',
  langApplyBgRemoval: 'Ativar a remoção de plano de fundo',
  autoBnW: 'Auto B&W',
  autoAdjust: 'Auto ajuste',
  autoPop: 'Auto Pop',
  original: 'Original',
  addHeader: 'Adicionar um cabeçalho',
  addTitle: 'Adicione um título',
  addBody: 'Adicionar um corpo de texto',
  addAssistive: 'Adicionar um texto de ajuda',
  langCompare: 'Comparar',
  langEnableEditor: 'Editar imagem',
  langSimilarStock: 'Imagens de stock similares',
  langTextUndo: 'Desfazer',
  langTextRedo: 'Refazer',
  langTextFont: 'Font',
  langTextFontSize: 'Tamanho de fonte',
  langTextColor: 'Cor do texto',
  langTextHighlight: 'Realçar',
  langTextAlignment: 'Alinhamento',
  langTextBold: 'Negrito',
  langTextItalic: 'Itálico',
  langTextUnderline: 'Sublinhado',
  langTextLineSpacing: 'Espaçamento entre linhas',
  langTextLetterSpacing: 'Espaçamento entre letras',
  langBringForward: 'Traga para a frente',
  langBringBackward: 'Leve para trás',
  langDuplicate: 'Dobrar',
  langOpacity: 'Opacidade',
  langDelete: 'Excluir',
  imageProperties: 'Propriedades da imagem',
  noEditCompareMsg: 'Nenhuma edição foi feita para comparar.',
  stockPhoto: 'Banco de Imagens',
  basicShape: 'Forma base',
  chitChat: 'Bater papo',
  shapes: 'Formas',
  elementChitChat: 'Elementos de bate-papo',
  organicAbstractShapes: 'Formas abstratas orgânicas',
  elementShapes: 'Elementos de formas',
  langApplyingEdit: 'Processando alterações...',
  langTextAllFonts: 'Todas as fontes',
  langSearchFontType: 'Pesquisar tipo de fonte',
  editorCompareImageBeforeTitle: 'Antes',
  editorCompareImageAfterTitle: 'Depois',
  langSizePx:
    'Um pixel é a unidade básica de uma imagem digital. O tamanho da imagem, medido em pixels, determina o nível de detalhes e o tamanho do download.',
  langSizeDpi:
    'Os pontos por polegada medem a resolução da impressão. Um dpi mais alto significa mais detalhes. Para impressão, a resolução recomendada para todas as imagens é de 300 dpi',
  cautionTTI:
    'Cuidado: detectamos o uso de frases no prompt que estão associadas a marcas registradas, direitos autorais, pontos de referência famosos e/ou indivíduos.  Observe que você é o único responsável pelo uso do Conteúdo, incluindo o fato de que ele não viola nenhuma lei aplicável nem infringe nenhum direito.',
  downloadTTIBtn: 'Download',

  addImageReference: 'Adicionar referência de imagem (Opcional)',
  uploadImageOnly: 'Faça upload apenas de JPG ou PNG. Máx. 4MB.',
  uploadJPGImageOnly: 'Faça upload apenas de JPG. Máx. 4MB.',
  dragAndDrop: 'Arraste e solte o arquivo ou',
  errorFileSize: 'Este arquivo é muito grande. O máximo permitido é de 4MB.',
  adjustScale: 'Ajuste esta escala para gerar resultados muito semelhantes ou diferentes da sua imagem carregada. A faixa recomendada é de 7 a 15.',
  similar: 'Semelhante',
  different: 'Diferente',
  uploadNew: 'Fazer novo upload',

  imageGuideGeneration: 'Sua imagem guiará seu prompt durante a geração.',
  notAvailableForEngine: 'Não disponível para o motor de IA selecionado.',

  imagePromptDescribe: 'Para começar, forneça um prompt da imagem que você deseja que nossa IA gere, descrevendo o que está em sua mente.',
  fileTypeNotSp: 'O tipo de arquivo não é suportado.',
  theFileSizeTooSmall: 'O tamanho do arquivo é muito pequeno. Mínimo de 512px permitido.',

  relatedSearchPhoto: 'Pesquisas de banco de imagens',

  STOCK_PHOTO_PATH: 'stock-photo',
  STOCK_FOOTAGE_PATH: 'banco-de-videos',
  STOCK_AUDIO_PATH: 'stock-audio',
  CLIPART_VECTOR_PATH: 'clipart-vetores',
  ALL_IMAGES: 'Todas as Imagens',
  PHOTO: 'Photo',
  PHOTOS: 'Fotos',
  VECTORS: 'Vetores',
  FOOTAGE: 'Vídeos',
  AUDIO: 'Áudios',
  LANG_MORETHANMILLION: 'BANCO DE IMAGENS ROYALTY FREE',
  LANG_SVG_OR_EPS: 'SVG ou EPS',
  LANG_OR: 'Ou',
  STANDARD_LICENSE: 'Licença Padrão',
  EXTENDED_LICENSE: 'Licença Estendida',
  CREDIT_PACK: 'Pacote de créditos',
  DOWNLOAD_PACK: 'Pacote de Downloads',
  SUBSCRIPTION_PLAN: 'Planos de Assinaturas',
  LANG_DOWNLOAD: 'Download',
  STOCKPHOTO: 'Imagens',
  STOCK_PHOTO: 'Foto de Banco de imagens',
  STOCK_VECTOR: 'Vetor de Banco de imagens',
  PREMIUM_STOCKPHOTO: 'Foto PREMIUM',
  PLUS_STOCK_PHOTO: 'Foto PLUS',
  PLUS_STOCK_VECTOR: 'Ilustrações vetoriais PLUS',
  MOBILE_STOCKPHOTO: 'Banco de Imagens Mobile',
  STOCKFOOTAGE: 'Vídeos',
  IMAGEID: 'ID da imagem',
  FONTID: 'ID',
  MEDIATYPE: 'Tipo de medio',
  COPYRIGHT_TEXT: 'Direitos autorais',
  KEYWORDS: 'Palavras-chave',
  LANG_CANCEL: 'Cancelar',
  LANG_PURCHASE: 'Compra',
  LANG_STOCKVECTORSANDILLUS: 'Vetores Stock e Ilustrações',
  LANG_STOCKPHOTOSANDIMAGES: 'Imagens para seus Projetos Criativos',
  LANG_MATCHES: 'resultados',
  LANG_VECTORRESULTS: '{number} {keyword} imagens vetoriais livre de royalties encontradas para você.',
  LANG_PHOTORESULTS: '{number} {keyword} fotos e imagens livre de royalties encontradas para você.',
  LANG_SIMILARIMGS: 'Fotos livre de royalties semelhantes',
  LANG_COLLECTIONS: 'Coleções',
  LANG_ALL: 'Todos',
  LANG_STD: 'Padrão',
  LANG_EXTD: 'Estendida',
  LANG_COMMERCIAL: 'Tipo de conteúdo',
  LANG_SUBSCRIPTION: 'Assinaturas',
  LANG_EDITORIAL: 'Editorial',
  LANG_UPLOADED: 'Carregado para',
  LANG_FRESHNESS: 'Novidades',
  LANG_ANYTIME: 'Em qualquer momento',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: '< 2 dias',
  LANG_PASTWEEK: 'Semana Anterior',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/mês",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/ano",
  LANG_PASTMONTH: 'Último mês',
  LANG_PAST3MONTHS: '3 meses',
  LANG_NUMOFPPL: 'Número de pessoas',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: 'Não recebeu o e -mail de verificação?',
  LANG_WITHOUTPEOPLE: 'Sem Pessoas',
  LANG_PERSON: 'Pessoa',
  LANG_PEOPLE: 'Pessoas',
  LANG_INFANTS: 'Bebês',
  LANG_TODDLERS: 'Crianças pequenas',
  LANG_TEENS: 'Adolescentes',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: 'caucasiano',
  LANG_BLACK: 'Preto',
  LANG_HISPANIC: 'hispânico',
  LANG_EAST_ASIAN: 'leste Asiático',
  LANG_SOUTHEAST_ASIAN: 'Sudeste Asiático',
  LANG_INDIAN: 'indiano',
  LANG_MIDDLE_EASTERN: 'Oriente Médio',
  LANG_AGE: 'Idade',
  LANG_ALL_SHAPE: 'Ver Tudo',
  LANG_ETHNICITY: 'Origem étnica',
  LANG_PEOPLEORMORE: '4 ou mais pessoas',
  LANG_COLOR: 'Cor',
  LANG_OTHEROPTION: 'Outras opções',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: 'Sólidos/Transparentes',
  LANG_FILTER_BY: 'Filtrar por',
  LANG_FILTER: 'Filtro',
  LANG_PHOTOGRAPHY: 'Foto',
  LANG_VECTORILLUST: 'Ilustrações vetoriais',
  LANG_SORTBY: 'Ordenar por',
  LANG_MORE: 'Mais',
  LANG_RELEVANCE: 'Relevancia',
  LANG_RESEND: 'Reenviar',
  LANG_RESEND_VERIFICATION: 'Reenviar email de verificação',
  LANG_NEWEST: 'Nuevo',
  LANG_GEORANK: 'Georank',
  LANG_ORIENTATION: 'Orientação',
  LANG_HORIZONTAL: 'Horizontal',
  LANG_PORTRAIT: 'Retrato',
  LANG_SQUARE: 'Cuadrado',
  LANG_PANORAMA: 'Panorama',
  LANG_SELECTIVE_FOCUS: 'Foco Seletivo',
  LANG_PATTERN: 'Padrão',
  LANG_VIBRANCE: 'Vibração',
  LANG_VERIFICATION_DESCRIPTION: 'Você precisaria verificar sua conta antes de baixar o conteúdo do 123RF. Verifique seu e -mail ou clique no link fornecido abaixo se quiser reenviar.',
  LANG_VERIFICATION_TITLE: 'Por favor, verifique seu e -mail.',
  VERIFICATIONRESETUNABLE: 'Oops! Seu e-mail não foi capaz de enviar. Por favor, entre em contato com nosso ',
  LANG_CHOOSEUPTO3COLORS: 'Máximo de 3 cores',
  LANG_ISOLATED: 'Isolado',
  LANG_STYLE: 'Estilo',
  LANG_SAFESEARCH: 'Búsqueda segura',
  LANG_ONLY_AUTHENTIC: 'Apenas autêntico',
  LANG_EXCLUDE_PLACEHOLDER: 'ex. queijo',
  LANG_EXCLUDEWORDS: 'Não inclua essas palavras',
  LANG_EXCLUDE: 'Excluir',
  LANG_INCLUDE_AICONTENT: 'Inclua conteúdo gerado pela IA',
  VISUAL_SEARCH: 'Pesquisa Visual',
  VISUAL_SEARCH_TOOLTIP_TITLE: '03 maneiras fáceis de personalizar uma pesquisa dentro de uma imagem!',
  VISUAL_SEARCH_TOOLTIP_INFO_1: '1. Clique nos pontos de uma imagem para identificar seu item de pesquisa.',
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2. Arraste a caixa de corte para ampliar ou reduzir a área de pesquisa.',
  VISUAL_SEARCH_TOOLTIP_INFO_3: "3. Clique no 'X' para retornar à pesquisa.",
  VISUAL_SEARCH_NO_RESULTS: 'Sua pesquisa não retornou nenhum resultado.',
  LANG_RIS_TITLE: 'Pesquisa reversa de imagens',
  LANG_RIS_NOTFOUND: 'Nenhum resultado encontrado!',
  LANG_RIS_ERROR_HEADER: 'A imagem enviada contém erros. Certifique-se de que a imagem atenda aos seguintes critérios:',
  LANG_RIS_REFINE_SRCH_MSG: 'Otimize sua pesquisa com palavras-chave',
  LANG_RIS_UPLOADED_IMAGE: 'Imagem carregada',
  LANG_TELL_ME_MORE: 'Me diga mais',
  LANG_ENTERKEYWORD: 'Escreva uma palavra-chave',
  LANG_SIZE: 'Tamanho',
  LANG_STANDARD_SIZE: 'Tamanhos standard',
  LANG_EXTENDED_SIZE: 'TAMANHOS AMPLIADOS',
  LANG_SIZE_PX: 'Um pixel é a unidade básica de uma imagem digital. O tamanho da imagem, medido em pixels, determina o nível de detalhes e o tamanho do download.',
  LANG_SIZE_DPI: 'Os pontos por polegada medem a resolução da impressão. Um dpi mais alto significa mais detalhes. Para impressão, a resolução recomendada para todas as imagens é de 300 dpi',
  LANG_SOCMED_SIZE: 'Tamanhos para redes sociais',
  LANG_COMPARE: 'Comparar',
  LANG_AUTO_ENHANCE: 'Auto-aperfeiçoamento',
  LANG_AUTO: 'Auto',
  LANG_BG_REMOVAL: 'Remoção de fundo',
  LANG_TEXT: 'Texto',
  LANG_ELEMENTS: 'Elementy',
  LANG_IMAGE_INFO: 'Informação da imagem',
  LANG_FOLLOW: 'Siga',
  LANG_FOLLOWING: 'Following',
  LANG_FOLLOWED: 'Seguiu-se',
  FACEBOOK_IMAGE: 'Imagem do Facebook',
  FACEBOOK_LINK: 'Link do Facebook',
  FACEBOOK_COVER: 'Capa do Facebook',
  FACEBOOK_STORY: 'História do Facebook',
  INSTAGRAM_POST: 'Postagem no Instagram',
  INSTAGRAM_STORY: 'História do Instagram',
  TWITTER_POST: 'Post no Twitter',
  TWITTER_HEADER: 'Twitter header',
  PINTEREST_POST: 'Post no Pinterest ',
  EMAIL_HEADER: 'Email header',
  PRESENTATION: 'Apresentação',
  PRESENTATION_WIDE: 'Ampla apresentação',
  EBOOK_COVER: 'Capa do e-book',
  LINKEDIN_POST: 'Post no LinkedIn',
  BROCHURE_COVER: 'Capa do folheto (A4)',
  POSTCARD: 'Cartão postal',
  PHOTO_PRINT: 'Impressão de fotos',
  YOUTUBE_PROFILE_IMAGE: 'Foto de perfil do YouTube',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'Foto de capa do canal do YouTube',
  AUTO_COLOR: 'Auto-color',
  BALANCE: 'Balance',
  AUTO_LEVEL: 'Auto nivelamento',
  AUTO_TONE: 'Tom automático',
  ENRICH: 'Enriquecer',
  RESET: 'Reiniciar',
  RESET_ALL: 'Resetear Todo',
  FILE_DOWNLOAD_OPTIONS: 'Opções de download de arquivo',
  FILE_DOWNLOAD_INITIATED: 'Download de arquivo iniciado',
  IMAGE_PROPERTIES: 'Propriedades da imagem',
  DOWNLOAD_DETAILS: 'Descargar propiedades',
  FILE_SIZE: 'Tamanho do arquivo',
  LICENSE: 'Licencia',
  FILEFORMAT: 'Tipo de arquivo',
  CHANGE: 'Cambiar',
  PRICING: 'Preços',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: 'Salvar como',
  IMAGE_USAGE: 'Uso de imagem',
  PRINT: 'Impressão',
  ELECTRONIC: 'Eletrônico',
  COMPREHENSIVE: 'Abrangente',
  DOWNLOAD_CREDITS: '<b>{quota}</b> Créditos para Download Pacotes de Créditos',
  BASIC_SHAPE: 'Formas básicas',
  CHIT_CHAT: 'Bate-papo',
  SHAPES: 'Formas',
  ELEMENT_CHIT_CHAT: 'Elemento Chit Chat',
  ORGANIC_ABSTRACT_SHAPES: 'Formas abstratas orgânicas',
  ELEMENT_SHAPES: 'Formas de elementos',
  BUY_SINGLE_IMAGE: 'Compre uma única imagem <br/><b>{quota} créditos</b>',
  BUY_SINGLE_IMAGE_CREDIT: 'Compre pacote de crédito<br/><b>{quota} créditos',
  BUY_CREDIT_PACK: 'Compre o pacote de crédito e <br/><b>economize mais</b>',
  BUY_CREDIT_PACK_POINT_1: '{quota} Pacote de Créditos por <b>{price}</b>',
  BUY_CREDIT_PACK_POINT_2: 'Nenhum compromisso mensal.',
  BUY_CREDIT_PACK_POINT_3: 'Validade de 01 ano.',
  BUY_SINGLE_IMAGE_PSUB: 'Compre uma única imagem <br/><b>01 download</b>',
  BUY_DOWNLOAD_PACK: 'Compre um pacote de download e <br/><b>ganhe mais</b>',
  BUY_DOWNLOAD_PACK_POINT_1: '{quota} Pacote de downloads de imagens por <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: 'Nenhum compromisso mensal.',
  BUY_DOWNLOAD_PACK_POINT_3: 'Validade de 01 ano.',
  LOWEST_PRICE: '<b>Menor preço</b><br/>com Plano de Assinatura',
  LOWEST_PRICE_POINT_1: 'Experiemente 1 mês por <b>{price}</b>',
  LOWEST_PRICE_POINT_2: 'Baixe 10 fotos ou vetores.',
  LOWEST_PRICE_POINT_3: 'Downloads diários ilimitados. Downloads não utilizados serão transferidos para o próximo mês.',
  PER_IMAGE_SIZE: 'por imagem <b>{size} tamanho</b>',
  PER_IMAGE_ANY: 'por imagem <b>qualquer tamanho</b>',
  PER_IMAGE: 'por imagem',
  VIEW_PRICING: 'Mais planos e preços',
  DOWNLOAD_BEGIN: 'Seu download começará em um momento. Por favor, aguarde.',
  DOWNLOAD_NOT_STARTING: 'Download ainda não começou?',
  SELECT_MIRROR: '[Selecione outro local]',
  SHOW_MORE: 'Mostrar mais',
  SHOW_LESS: 'Show Less',
  LICENSE_TYPE: 'Tipo de Licença',
  LICENSE_SUMMARY: 'Resumo de Licença',
  BACK: 'Retornar',
  LANG_VECTOR: 'Vetor',
  PREMIUM_VECTOR: 'Ilustrações vetoriais PREMIUM',
  SCALE_ANY_SIZE: 'Escala para qualquer tamanho',
  LANDINGPAGE_VECTOR_HEADLINE: 'Vetores, Ilustrações Royalty Free, Cliparts, Desenhos e Gráficos',
  LANDINGPAGE_PHOTO_HEADLINE: 'Banco de Imagens Royalty Free, Fotos, Fotografias e Ilustrações',
  CATEGORIES: 'Categorias',
  LANDINGPAGE_PRICING_1: 'PLANOS DE CRÉDITOS',
  LANDINGPAGE_PRICING_2: 'Planos de Assinaturas',
  LANDINGPAGE_AS_LOW_AS: 'POR ATÉ',
  EMPTY_SEARCH_TITLE: 'Ops, sua pesquisa não retornou nenhum resultado.',
  LANG_SMALL: 'S',
  LANG_MEDIUM: 'M',
  LANG_LARGE: 'L',
  LANG_EXTRA_LARGE: 'XL',
  LANG_LOW_CREDITS: 'Parece que seus créditos de download estão acabando!',
  LANG_TOPUP: 'Recarregue',
  LANG_TOPUP_NOW: 'Recarregue agora',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description} Banco de Imagens Royalty Free, Ilustrações, Imagens e Banco de Imagens. Image {mediaId}.',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} Royalty Free SVG, Cliparts, Vetores, e Ilustrações Stock. Image {mediaId}.',
  SEO_DETAILS_METADESC_STOCKPHOTO: 'Imagem de {description} fotos, gravuras e fotografias stock. Image {mediaId}.',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: 'Ilustração de {description} arte vetorial, clipart e vetores stock. Image {mediaId}.',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} Imagem e Fotografia Gratuitas {mediaId}.',
  SEO_DETAILS_METADESC_FREE_PHOTO: 'Imagem gratuita de {description}. Imagem gratuita {mediaId}.',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} Vetor e gráfico gratuitos {mediaId}.',
  SEO_DETAILS_METADESC_FREE_VECTOR: 'Vetor gratuito de {description}. Gráfico gratuito {mediaId}.',
  SEO_DETAILS_OGDESCRIPTION: '123RF - Milhões de Imagens Criativas, Vetores, Vídeos e Áudios para sua Inspiração e Projetos.',
  LIVECHAT_LOOKINGHELP: 'Precisa de ajuda?',
  LIVECHAT_CHATWITHUS: 'Fale com a gente!',
  FREE_TRIAL: '<b>Get 10 Free Images</b> <br/>with Subscription Plan',
  LANG_RELATEDSEARCH: 'Pesquisas relacionadas',
  LANG_RELATED_STOCKPHOTO_SEARCH: 'Pesquisas de banco de imagens',
  LANG_PROPERATTRIBUTE: 'Aqui está nossa atribuição:',
  LANG_ATTRIBUTEDESC: 'Atribua esta imagem a seu fotógrafo, colocando esta linha de crédito abaixo em qualquer área dentro de seu produto, website ou garantia',
  LANG_ATTRIBUTE_TITLE: 'A atribuição é opcional.',
  LANG_ATTRIBUTE_CREDIT: 'Se você deseja atribuir o criador, por favor, use a linha de crédito abaixo:',
  LANG_ATTRIBUTE_COPYLINK: 'Link de cópia',
  LANG_ATTRIBUTE_COPYTEXT: 'Copiar texto',
  LANG_LICENSE_AGREEMENT: '<a href={url}><b>Aqui</b></a> está o contrato de licença para este download',
  LANG_NEXTPAGE: 'Próxima Página',
  LANG_IMAGE_NO_AVAILABLE: 'A imagem nº {mediaId} não está mais disponível',
  LANG_PHOTOGRAPHY_METAIMG_DESC: 'Pesquise e baixe imagens royalty free do nosso arquivo de milhões de imagens e ilustrações em alta-resolução',
  LANG_VECTOR_METAIMG_DESC: 'Pesquise e baixe imagens royalty free do nosso arquivo de milhões de imagens e ilustrações em alta-resolução',
  LANG_ANOTHERMETAKWDEFAULT: 'royalty-free, banco de imagens, acervo fotográfico, fotografias, fotos',
  LANG_ANOTHERMETADESCDEFAULT: 'Pesquise e faça download de imagens em alta resolução, imagens royalty-free, ilustrações, vetoriais e muito mais',
  EMPTY_FILTERED_RESULT_TITLE: 'Ops, sua pesquisa com esses filtros não deu nenhum resultado.',
  EMPTY_FILTERED_RESULT_SUBTITLE: 'Tente remover alguns filtros ou tente outra palavra-chave.',
  CLEAR_ALL_FILTER: 'Limpar todos os filtros',
  PROMOBAR_FREE10_TEXT: 'Get <b>10 FREE Images</b> when you get started on our 1 Month-Free Trial.',
  PROMOBAR_FREE10_BTNLABEL: 'START NOW',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: 'Descontos espetaculares em todas as assinaturas.',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: 'Ver planos',
  LANG_MODEL_RELEASED: 'Autorização de Modelo',
  LANG_PROPERTY_RELEASED: 'Autorização de Propriedade',
  LANG_MODEL_RELEASE: 'com liberação do modelo',
  LANG_PROPERTY_RELEASE: 'com autorização de propriedade',
  LANG_YES: 'Sim',
  LANG_NO: 'Não',
  LANG_MREXPLAIN: 'Uma liberação de modelo é um documento legal assinado pelo modelo(s) de uma fotografia garantindo a publicação/distribuição/uso de sua imagem.',
  LANG_PREXPLAIN: 'Uma liberação de propriedade é um documento legal assinado pelo dono de um objeto, marca ou local fotografado, garantindo a permissão para publicação/distribuição/uso da imagem.',
  LANG_DOWNLOADS: 'Downloads',
  LANG_OLDFILENAME: 'Antigo nome do arquivo',
  LANG_DOWNLOAD_PREVIEW: 'Preview Download',
  LANG_SHARE: 'Compartilhar',
  LANG_RESTRICTIONS: 'Restrictions',
  LANG_READ_MORE: 'Leia mais',
  LANG_EDITORIAL_USE: 'For Editorial Use Only',
  LANG_EDITORIALDESCP: 'Somente Uso Editorial: esta imagem só poderá ser usada para fins editoriais. O uso desta imagem para fins publicitários ou promocionais é proibido, salvo se os direitos de utilização forem contratados previamente pelo usuário. O 123RF não fornece serviços adicionais de liberação/obtenção de direito autoral.',
  LANG_LOGO_OWNERSHIP: 'Logo Ownership Rights',
  LANG_PHOTO_SHOT_SMARTPHONE: 'This photo was shot with a smartphone.',
  LANG_VECTORNOLOGO: 'If you wish to use this as your company logo, we may arrange for a <b>rights buy out</b> on your behalf. Please contact us at <b>{phonenum}</b> to find out more!',
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> Quota de Downloads Planos de Assinaturas',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> Quota de Downloads Pacote de Downloads',
  LANG_LOGO: 'Logo',
  USING_OLDER_BROWSER: 'Você está usando um navegador mais antigo?',
  UPGRADE_NEWER_BROWSER: 'Atualize para uma versão mais nova do navegador para desfrutar de uma experiência mais suave!',
  CLICK_TO_DOWNLOAD: 'Clique para fazer o download de qualquer um destes:',
  LANG_VECTOR_EDITABLE: 'Arquivos Editáveis em EPS e SVG',
  PROMOBAR_GOOD15_TEXT1: 'Pague menos, baixe mais. ',
  PROMOBAR_GOOD15_TEXT2: '15% de desconto em todos os planos com:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: 'Pague menos, baixe mais. ',
  PROMOBAR_SUMMER25_TEXT2: '25% de desconto em todos os planos com',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: '30% de desconto em todos os planos com',
  NO_EDIT_COMPARE_MSG: 'Nenhuma edição foi feita para comparar.',
  SWITCH_TO_VECTOR_MSG: 'O Editor de fotos não é compatíveis com as imagens de tamaño vetorial. Todas as edições perdem e mudam para o formato vetorial.',
  SWITCH_TO_XL_MSG: 'O Editor de fotos não é compatíveis com imagens de tamanho XL. Todas as edições serão perdidas se alternadas para o formato XL.',
  UNAVAILABLE_FOR_THIS_SIZE: 'Não disponível para o tamanho {size}.',
  SWITCH: 'MUDAR',
  SWITCH_LICENSE_EXT_MSG: 'Você gostaria de baixar esta imagem como {type} primeiro? Se você mudar para a licença estendida, todas as alterações serão perdidas',
  LANG_SESSION_EXPIRED: 'Sua sessão expirou. Por favor <a href={url}>faça o login aqui</a>',
  LANG_TEXT_COLOR: 'Cor do texto',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: 'Realçar',
  LANG_TEXT_ALIGNMENT: 'Alinhamento',
  LANG_TEXT_BOLD: 'Negrito',
  LANG_TEXT_ITALIC: 'Itálico',
  LANG_TEXT_UNDERLINE: 'Sublinhado',
  LANG_TEXT_BULLET: 'Bolha',
  LANG_TEXT_LINE_SPACING: 'Espaçamento entre linhas',
  LANG_TEXT_LETTER_SPACING: 'Espaçamento entre letras',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: 'Traga para a frente',
  LANG_BRING_BACKWARD: 'Leve para trás',
  LANG__DUPLICATE: 'Dobrar',
  LANG_OPACITY: 'Opacidade',
  LANG_DELETE: 'Excluir',
  LANG_TEXT_ADD: 'Adicione texto',
  LANG_TEXT_EDIT: 'Editar texto',
  LANG_TEXT_FONT_TYPE: 'Tipo de fonte',
  LANG_TEXT_FONT_SIZE: 'Tamanho de fonte',
  LANG_TEXT_ALL_FONTS: 'Todas as fontes',
  LANG_TEXT_UNDO: 'Desfazer',
  LANG_TEXT_REDO: 'Refazer',
  LANG_TEXT_FONT: 'Font',
  LANG_SEARCH_FONT_TYPE: 'Pesquisar tipo de fonte',
  LANG_SEARCH_SHAPE_TYPE: 'Pesquisar elementos',
  LANG_DONE: 'Feito',
  ADD_HEADER: 'Adicionar um cabeçalho',
  ADD_TITLE: 'Adicione um título',
  ADD_BODY: 'Adicionar um corpo de texto',
  ADD_ASSISTIVE: 'Adicionar um texto de ajuda',
  STOCK_PHOTO_BANNER_TITLE: 'Milhões de imagens royalty-free para criar tudo.',
  STOCK_PHOTO_BANNER_DESC: 'Encontre todas as imagens impressionantes que você precisa para criar visuais de rolagem paradas para dar vida às suas grandes ideias agora.',
  STOCK_PHOTO_SUBSCRIBE_TITLE: 'Visualize suas histórias com o imagens corretas',
  STOCK_PHOTO_SUBSCRIBE_DESC: 'Obtenha acesso instantâneo a mais de 180 milhões de imagens para capacitar sua narrativa agora. Nossas imagens e sua história são a combinação perfeita para maximizar seu potencial criativo!',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: 'Baixar pacote',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: 'para tão baixo quanto',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: 'Plano de assinatura',
  STOCK_PHOTO_CARD_IMAGE: 'imagem',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: 'Inscreva-se agora',
  STOCK_PHOTO_CARD_BUY_NOW: 'Compre Agora',
  STOCK_PHOTO_POPULAR_ROYALTY: 'Categorias populares de imagens isentas de royalties',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: 'Descubra planos e preços acessíveis para fotos.',
  STOCK_PHOTO_DISCOVER_NOW: 'Confira',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: 'Paisagem',
  STOCK_PHOTO_BABIES: 'Bebês',
  STOCK_PHOTO_BUSINESS: 'Negócio',
  STOCK_PHOTO_FOOD: 'Comida',
  STOCK_PHOTO_ANIMALS: 'Animais',
  STOCK_PHOTO_TECHNOLOGY: 'Tecnologia',
  STOCK_PHOTO_HEALTHCARE: 'Assistência médica',
  STOCK_PHOTO_NATURE: 'Natureza',
  STOCK_PHOTO_ROMANCE: 'Romance',
  CLIPART_VECTOR_EXPLORE_MILLIONS: 'Crie com milhões de vetores isentos de royalties ao seu alcance.',
  CLIPART_VECTOR_NEED_AN_EYE: 'Encontre ilustrações vetoriais legais, infográficos, clip-arts, ícones e muito mais para ajudá-lo a transformar suas ideias incríveis em realidade.',
  CLIPART_VECTOR_FIND_VECTOR: 'Encontre os vetores certos para especificar seus projetos.',
  CLIPART_VECTOR_FIND_VECTOR_DESC: 'Sejam infográficos, ilustrações, desenhos animados, clip-arts, planos de fundo, ícones ou o que você precisar para criar imagens incríveis, nós temos aqui. Dê vida aos seus projetos agora.',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: 'Categorias populares de vetores isentos de royalties',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: 'Descubra planos e preços acessíveis para vetores',
  CLIPART_VECTOR_DISCOVER_NOW: 'Discover Now',
  SPEND_AND_WIN_SUBTITLE: 'A cada {currency} gastos = 1 Ingresso',
  SPEND_AND_WIN_IMGALT: 'Compre e ganhe um iPhone 13 Pro',
  CLIPART_VECTOR_GRAPHIC: 'Gráfico',
  CLIPART_VECTOR_ADVERTISING: 'Publicidade',
  CLIPART_VECTOR_FLOWER: 'Flor',
  CLIPART_VECTOR_ABSTRACT: 'Abstrato',
  CLIPART_VECTOR_POP: 'Pop',
  CLIPART_VECTOR_BIRD: 'Pássaro',
  CLIPART_VECTOR_ILLUSTRATION: 'Ilustração',
  CLIPART_VECTOR_PIXEL: 'Pixel',
  CLIPART_VECTOR_3D: '3D',
  LANG_VECTOR_SVG_EPS: 'Vetor, SVG e EPS',
  FREE_UPGRADE: 'Atualização gratuita',
  FREE_UPGRADE_MESSAGE: 'Atualização XL GRATUITA para todas as assinaturas ou pacotes de download. Compre Agora.',
  LANG_FREE_IMAGE: '123RF Grátis',
  LANG_IMAGE_FREE: 'Esta foto stock é gratuita para uso comercial e pessoal. Atribuição é necessária.',
  LANG_HOW_TO_ATTRIBUTE: 'Como posso fazer a atribuição?',
  LANG_FREE_DOWNLOAD: 'Download grátis',
  LANG_FREE_DOWNLOAD_BUTTON: 'FREE Download',
  LANG_GO_PLUS_BUTTON: 'Escolha PLUS',
  LANG_ATTRIBUTION_IS_KEY: 'A atribuição é um elemento importante para os criadores de conteúdo que tornam seu trabalho publicamente acessível. Nossos criadores de conteúdo apreciam a exposição obtida com suas criações sendo usadas, compartilhadas e creditadas por milhões de usuários como você.',
  LANG_GET_ATTRIBUTION_LINK: 'Copie este link e coloque -o em um local próximo a onde o conteúdo é usado.',
  LANG_CREDIT_OWNER: 'Por favor, credite o Criador:',
  LANG_USE_ATTRIBUTION: 'Use o link de atribuição abaixo.',
  LANG_ATTRIBUTION_LOCATION: 'Se isso não for possível, outros bons locais estão no rodapé do site, blog ou boletim informativo ou mesmo uma seção de créditos específicos do site será suficiente para creditar o proprietário do {media_type}.',
  LANG_GOT_A_QUESTION: "Ter uma questão? <a href={url} target='_blank'><u>Entre em contato conosco</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: 'Você precisaria creditar o proprietário para esta imagem gratuita.',
  LANG_ATTR_FREE_DOWNLOADING: 'Mantenha-se firme! Não vai demorar muito.',
  LANG_PLUS_CONTENT_1: 'MAIS conteúdo exclusivo adicionado todos os meses',
  LANG_PLUS_CONTENT_2: 'LICENÇA PLUS - Nenhuma atribuição necessária',
  LANG_PLUS_CONTENT_3: 'Suporte exclusivo ao cliente 24 horas por dia, 7 dias por semana',
  LANG_PLUS_CONTENT_4: 'Unlimited Downloads',
  LANG_PLUS_CONTENT_5: 'Unlimited Creative Freedom',
  FREE_VECTOR: 'Ilustrações vetoriais FREE',
  FREE_STOCKPHOTO: 'Foto FREE',
  FREE_PUBLIC_DOMAIN_CC0: 'Domínio Público CC0',
  BUY_5_DOWNLOAD_PACK: "<b>Tempo limitado de oferta!</b><br/>3 + <b><span style='color:red'>2 GRÁTIS</span></b> = 5 fotos ou vetores",
  BUY_5_DOWNLOAD_PACK_POINT_2: 'Baixar a qualquer momento (até um ano)',
  BUY_5_GET_2_FREE: 'Ganhe 2 GRÁTIS!',
  LANG_ASK_DOWNLOAD_PLUS: 'Deseja fazer o download deste PLUS conteúdo?',
  LANG_SIGN_UP_PLUS: 'Inscreva -se para o nosso plano 123RF PLUS de tão baixo quanto',
  LANG_FOR_ONLY: "<span class='text'>por apenas</span> <span class='price'>{price}</span>/mês",
  LANG_FOR_ONLY_2: "<span class='text'>por apenas</span> <span class='price'>{price}</span>",
  LANG_BENEFIT_PLUS: 'Beneficie-se de nosso plano PLUS apenas de',
  LANG_RELEASES: 'Liberação anexada',
  LANG_UNAVAILABLE: 'Não Disponível',
  LANG_ATTR_FREE_IMG_TITLE2: 'Estamos felizes por você ter encontrado o que precisava. 🎉',
  LANG_PLEASE_NOTE: 'Observe que esta imagem não tem nenhum modelo ou liberação de propriedade anexada. O uso comercial é por sua própria conta e risco.',
  LANG_PLEASE_ADVISED: 'Esteja ciente de que imagens que contêm indivíduos reconhecíveis não vêm com nenhum lançamento. Para usar essas imagens comercialmente, a permissão deve ser obtida das partes relevantes. Se a imagem não contiver indivíduos reconhecíveis, não deve haver problemas de direitos autorais ao usá -la comercialmente.',
  LANG_REACHED_LIMIT: 'Ops! Você atingiu seu limite de download diário.',
  LANG_COME_TOMORROW: 'Estamos emocionados por você adorar nossas imagens gratuitas! Volte amanhã para uma atualização de seu limite de downloads. Obrigado. 😊',
  LANG_DOWNLOAD_ERROR: 'Ops! Esta imagem não pode ser baixada devido um erro.',
  LANG_REFRESH_AND_TRY_AGAIN: "Por favor atualize seu navegador e tente novamente. <a href={url} target='_blank'>Deixe-nos saber</a> se o problema persistir.",
  LANG_REACHED_LIMIT_PLUS: 'Limite diário de download alcançado',
  LANG_COME_TOMORROW_PLUS: 'Uau! Você atingiu seu limite de download para hoje. Por favor, volte amanhã para baixar mais. Obrigado pela ajuda!',
  SEE_YOU_TOMORROW: 'Vejo você amanhã!',
  ORIGINAL: 'Original',
  'AUTO_B&W': 'Auto B&W',
  AUTO_ADJUST: 'Auto ajuste',
  AUTO_POP: 'Auto Pop',
  NONE: 'Nenhum',
  LANG_CROP: 'Cortar',
  LANG_ENABLE_EDITOR: 'Editar imagem',
  LANG_DISABLE_EDITOR: 'Fechar',
  LANG_IMAGE_FREE_CC0: "Esta é uma imagem CC0. Atribuição não é necessária. Você pode usá -lo de acordo com os <a className='linkCC0' href={url} target='_blank'> termos e condições da licença CC0</a>.",
  LANG_PREVIEW: 'Pré-visualização',
  LANG_ATTRIBUTION_CC0_TITLE: 'Imagem do CCO: atribuição não necessária',
  LANG_ATTRIBUTION_TITLE: 'Atribuição obrigatória',
  LANG_ATTRIBUTION_CONTENT: 'O link de atribuição será fornecido após o download do arquivo.',
  LANG_ATTRIBUTION_CC0_CONTENT: 'A atribuição não é necessária, pois esta é uma imagem de CCO.',
  LANG_SUBCRIBE_PREMIUM: 'Você atingiu seu limite diário de download! Para baixar mais, assine o 123RF PREMIUM.',
  LANG_REMOVE_ATTRIBUTION: 'Deseja remover a atribuição?',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "Assine o <img src={image} alt='123RF Plus Logo'> para acesso ilimitado a 100 milhões de fotos e ilustrações sem necessidade de atribuição.",
  LANG_CREATOR_ATTRIBUTION: 'Por favor, credite o autor com o link abaixo.',
  LANG_GET_ATTRIBUTION_LINK_2: 'Copie este link e coloque-o próximo ao espaço onde o conteúdo é utilizado, ao rodapé do site ou na seção que contém as principais informações do site.',
  LANG_APPLY_BG_REMOVAL: 'Ativar a remoção de plano de fundo',
  LANG_APPLYING_EDIT: 'Processando alterações...',
  LANG_APPLYING_EDIT_ERROR: 'Algo deu errado.',
  LANG_TRY_AGAIN: 'Você quer tentar novamente?',
  LANG_SEARCHCOLLECTION_PREMIUM: 'Coleção completa',
  LANG_SEARCHCOLLECTION_PLUS: 'Coleção Essencial',
  LANG_SEARCHCOLLECTION_FREE: 'Coleção básica',
  LANG_SIMILARIMAGESPAGE_TITLE: 'Banco de Imagens, Vídeos, Áudios, Logos e Planos de Assinaturas Royalty-Free',
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: 'Imagens Semelhantes',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: 'Opa! Nada para ver aqui.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: 'Imagem não disponível',
  LANG_PER_IMAGE: '/imagem',
  LANG_PLANS_AND_PRICING: 'Planos e preços',
  LANG_CHOOSE_BEST_PLAN: 'Escolha o melhor plano que atenda às suas necessidades.',
  LANG_WANT_MORE_SAVING_1: 'Quer mais economia?',
  LANG_WANT_MORE_SAVING_2: 'Veja mais planos de {price}/imagem',
  LANG_BEST_VALUE: 'Melhor valor, economize {percentage}%',
  LANG_YEARLY_PLAN: 'Plano anual',
  LANG_WITH_MONTHLY_PAYMENTS: 'Com pagamentos mensais',
  LANG_GRAB_YEARLY_PLAN: 'Pegue um plano anual e economize {percentage}%.',
  LANG_PRICE_PER_MONTH: '{price} por mês',
  LANG_PRICE_PER_YEAR: '{price} por ano',
  LANG_GET_X_EVERY_MONTH: 'Obtenha {quota} fotos ou vetores todo mês',
  LANG_X_EVERY_MONTH: 'Acesse {quota} fotos ou vetores<br>todo mês',
  LANG_SINGLE_IMAGE_PURCHASE: 'Compra de imagem única',
  LANG_GET_ONLY_WHAT_YOU_NEED: 'Obtenha apenas o que você precisa para o momento.',
  LANG_TOTAL_PRICE: 'Total {price}',
  LANG_DOWNLOAD_IMMEDIATELY: 'Baixe a imagem imediatamente Após o checkout.',
  LANG_NEXT: 'Próximo',
  LANG_TITLE: 'Banco de Imagens, Vídeos, Áudios, Logos e Planos de Assinaturas Royalty-Free',
  SIMILAR_STOCK_PHOTOS: 'Imagens Semelhantes',
  EMPTY_SIMILAR_SEARCH_RESULTS: 'Opa! Nada para ver aqui.',
  UNAVAILABLE_IMAGE: 'Imagem não disponível',
  LANG_DOWNLOAD_PACK: 'Baixe o pacote',
  LANG_GET_X_ANY_TIME: '{quota} fotos ou vetores a qualquer momento',
  LANG_X_CREDITS: '{total} Credits',
  LANG_PER_CREDIT: '/Credit',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '{photoCount} fotos{videoCount, plural, =0 {} =1 { ou # vídeo} other { ou # vídeos}} a qualquer momento',
  INCLUDES_STANDARD_LICENSE: 'Inclua licença padrão',
  LANG_MEDIUM_SIZE: 'Tamanho médio',
  LANG_LARGE_SIZE: 'Tamanho grande',
  LANG_EXTRA_LARGE_SIZE: 'Tamanho extra grande',
  LANG_STANDARD_SIZES: 'Tamanhos standard',
  LANG_ROYALTY_FREE: 'livre de royalties',
  LANG_OPTIONS: 'Opções',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'Beneficie-se de nosso plano PLUS apenas de',
  LANG_RELATED_FONT_SEARCH: 'pesquisas relacionadas',
  AI_GENERATED_PHOTO: 'Imagem gerada pela IA',
  FONT_PRICING_CREDIT: 'créditos',
  '3_FONTS_3_IMGS': '3 fontes <b>ou</b> 3 imagens <b>ou</b> 1 vídeo',
  '9_FONTS_9_IMG_3_VIDEOS': '9 fontes <b>ou</b> 9 imagens <b>ou</b> 3 vídeos',
  PURCHASE_AND_DOWNLOAD: 'Compre e baixe',
  FONT_PATH: 'fonts',
  FONT_ZIP: 'Fonte (OTF, TTF)',
  FONT_PACKS_LICENSE: '123RF Packs License',
  FONT_META_DESC: 'Download {product_title} Fonte, tipo de letra ou tipografia para criar designs impressionantes para impressão e web.',
  FONT_META_TITLE: 'Fonte - {product_title} | 123RF',
  FONT_SIMILAR: 'Fontes semelhantes',
  LANG_PLANS: 'PLANOS',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: 'Tente criar variações',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: 'Gere novas imagens com base na imagem original contribuída. Além disso, é uma nova maneira de recompensar nossos contribuidores da 123RF toda vez que uma imagem gerada por IA é licenciada!',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: 'Nossa comunidade agradece. As taxas de licenciamento serão compartilhadas com o contribuidor da 123RF.',
  LANG_CONTRIBUTORS_AI_VARIATION: 'Os contribuidores do 123RF da imagem original, são compensados por cada variação de IA licenciada.',
  LANG_AI_VARIATION_LIMIT: 'Oh não! Você atingiu o limite de geração de imagens de IA para hoje.',
  LANG_GENERATE_COMEBACK: 'Volte amanhã para mais gerações!',
  LANG_LICENSE_AND_DOWNLOAD: 'Licença e Download',
  LANG_CONFIRM_OK: 'Certo',
  LANG_AI_SWITCH_CONTENT: 'O Editor de fotos não é compatível com variações de IA. Todas as edições serão perdidas se você mudar para variações de IA.',
  LANG_GENERATE_VARIATIONS: 'Gerar variações',
  LANG_GENERATE_WITH_AI: 'Gerar imagens com IA',
  LANG_NEW: 'Novo',
  LANG_AI_GENERATOR_LICENSE: 'Termos de Serviço do 123RF AI Generator (“Termos”)',
  LANG_I_UNDERSTAND: 'Eu entendo',
  LANG_AUTHENTIC: 'Autêntico',
  LANG_ABSTRACT: 'Abstrato',
  LANG_MACRO_CLOSEUP: 'Macro/Close up',
  LANG_OBJECT: 'Objeto',
  LANG_WATERCOLOR: 'Aquarela',
  LANG_FLAT: 'Plano',
  LANG_CARTOON: 'Desenho animado',
  LANG_GEOMETRIC: 'Geométrico',
  LANG_GRADIENT: 'Gradiente',
  LANG_ISOMETRIC: 'Isométrico',
  LANG_3D: '3d',
  LANG_HAND_DRAWN: 'Desenhado à mão',
  LANG_NATURAL: 'Natural',
  LANG_WARM: 'Quente',
  LANG_DRAMATIC: 'Dramático',
  LANG_VIVID_BOLD: 'Vivo e ousado',
  LANG_BLACK_WHITE: 'Preto e branco',
  LANG_ASIAN: 'Asiático',
  LANG_AFRICAN_AMERICAN: 'Afro-americano',
  LANG_STANDARD: 'Padrão',
  LANG_EXTENDED: 'Estendido',
  LANG_STANDARD_CONTENT_1: 'Visualizações ilimitadas para redes sociais, web, e-mail e dispositivos móveis.',
  LANG_STANDARD_CONTENT_2: 'Até 500.000 impressões.',
  LANG_STANDARD_CONTENT_3: 'Não pode ser usado em produtos para venda.',
  LANG_EXTENDED_CONTENT_1: 'Visualizações ilimitadas para redes sociais, web, e-mail e dispositivos móveis.',
  LANG_EXTENDED_CONTENT_2: 'Impressões ilimitadas.',
  LANG_EXTENDED_CONTENT_3: 'Pode ser usado em produtos para venda.',
  LANG_ORIGINAL_IMAGE: 'Imagem original',
  LANG_IMPORTANT_NOTE: 'Observação importante',
  LANG_AI_ORIGINAL_ALTERED: 'As variações de IA são variantes da imagem original que foram alteradas usando a tecnologia de IA. É semelhante a ter um editor de IA retocando a imagem escolhida.',
  LANG_GENERATE_VARIATIONS_NOTE: 'Como estamos atualmente no estágio Beta das variações de imagem com tecnologia de IA, as imagens geradas podem produzir resultados inesperados. Estamos trabalhando arduamente para garantir que esses resultados e variações estejam em conformidade com as diretrizes éticas e responsáveis de IA.',
  LANG_LICENSE_AI_EDITED: 'Quando você licencia uma dessas versões editadas por IA, o detentor dos direitos autorais da imagem original recebe uma taxa de licenciamento.',
  LANG_MOOD: 'estado de humor',
  LANG_AI_EXCLUDE_PLACEHOLDER: 'Por exemplo, cor verde, cabelo liso',
  LANG_SIMILAR_STOCK: 'Imagens de estoque semelhantes',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: 'com liberação do modelo',
  WITH_PROPERTY_RELEASE: 'com autorização de propriedade',
  MODEL_RELEASE_CONTENT: 'Uma liberação de modelo é um documento legal assinado pelo modelo(s) de uma fotografia garantindo a publicação/distribuição/uso de sua imagem.',
  PROPERTY_RELEASE_CONTENT: 'Uma liberação de propriedade é um documento legal assinado pelo dono de um objeto, marca ou local fotografado, garantindo a permissão para publicação/distribuição/uso da imagem.',

  variousStockGAI: 'Um modelo de IA em conformidade com a lei, treinado com milhões de fotos de banco de imagens e otimizado para as necessidades da empresa, proporcionando tranquilidade com cobertura de responsabilidade.',
  extendedLicense: 'Licença Estendida',
  sizes: 'Tamanhos',

  downloadMail1st: 'Nosso provedor de serviços de ampliação de imagens enviará um e-mail para',
  downloadMail2nd: 'com as instruções para baixar o arquivo final ampliado no próximo dia útil',

  aiGenarated: 'Imagem gerada pela IA',

  variousOpenJourney: 'O AI Model gera arte semelhante ao estilo característico do Midjourney.',
  variousTurboVision: 'Geração de alta velocidade em resoluções XL "normais", melhorando a qualidade geral.',
  variousRealVisXL: 'O modelo visa alcançar resultados fotorrealistas com precisão e exatidão.',
  variousPortraitPlus: 'Modelo com composição de retrato uniforme, ideal para proporção 1:1.',

  enterVirifiedCode: 'Digite o código de verificação',
  verify: 'Verificar',
  didNotGetCode: 'Não recebeu um código?',
  clickToResend: 'Clique para reenviar',
  otpCannotEmpty: 'OTP não pode estar vazio.',
  sentCodeToMail: 'Enviamos um código para o seu e-mail registrado.',
  invalidCode: 'Código inválido',
  expiredOTP: 'O OTP expirou, por favor, envie novamente.',
  sendEmail: 'O e-mail foi enviado com sucesso, por favor, verifique sua caixa de correio para continuar.',
  remainingTime: 'Tempo restante: ',
};

export default br;
