const de = {
  // Header
  photos: 'Fotos',
  Photos: 'Fotos',
  vectors: 'Vektorgrafiken',
  Vectors: 'Vektorgrafiken',
  footageupper: 'VIDEOS',
  footage: 'Videos',
  video: 'Video',
  audio: 'Audio',
  signin: 'Anmeldung',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Deutsch.png',
  language: 'Sprache',
  // Footer
  contact: 'Kontakt',
  contactUs: 'Kontakt',
  privacy: 'Datenschutz',
  terms: 'Nutzungsbedingungen',
  cookie: 'Cookie-Richtlinie',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: 'Passwort vergessen',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: 'Bitte eine gültige eMail-Adresse eingeben.',
  emailCannotEmpty: 'Bitte eMail-Adresse eingeben',
  returnToLogin: 'ZURÜCK ZUM LOGIN',
  email: 'E-Mail',
  // Login
  username: 'Benutzername',
  pw: 'Passwort',
  rememberMe: 'Merken',
  login: 'Login',
  forgotPassword: 'Passwort vergessen',
  loginAsGuest: 'Gast Login',
  // ResetPassword
  resetPwdDesc: 'Zum Einloggen bitte das neue Passwort eingeben.',
  newPw: 'Neues Passwort',
  confirmPwd: 'Passwort bestätigen',
  resetPwdButton: 'PASSWORT ZURÜCKSETZEN',
  resetPwdTitle: 'Passwort zurücksetzen',
  enterYourNewPw: 'Zur Anmeldung bitte das neue Passwort eingeben.',
  pwMiniMumChars: 'Mindestens 8 Zeichen',
  pwMaxChar20: 'Maximal 20 Zeichen sind erlaubt',
  pwLowerCase: 'Mindestens ein Kleinbuchstabe',
  pwUpperCase: 'Mindestens ein Großbuchstabe',
  pwNumeral: 'Mindestens eine Ziffer',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: 'Bestätigung fehlerhaft',
  resetPwdInvalidLink: 'Dieser Link ist ungültig oder abgelaufen.',
  pwHasBeenReset: 'Das Passwort wurde zurückgesetzt, bitte anmelden.',
  // Admin index
  companyName: 'Firmenname',
  phone: 'Telefon',
  fax: 'Fax',
  address: 'Adresse',
  idd: 'IDD',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: 'Gesamtzahl',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: 'Altes Passwort',
  confirmNewPassword: 'Neues Passwort bestätigen',
  returnToHome: 'Zurück',
  // Cookie Policy
  langCookie01:
    'Wenn Sie %host% besuchen, legen wir eine kleine Textdatei namens Cookie auf der Festplatte Ihres Computers ab.',
  langCookie02:
    'Diese Datei ermöglicht es uns, Sie zu verfolgen, so dass wir Ihnen grundlegende Funktionen wie z.B. Einkaufswagen zur Verfügung stellen können. Sie werden auch verwendet, um ein verbessertes Online-Benutzererlebnis zu bieten und Ihnen Funktionen wie "Likeboxen" zur Verfügung zu stellen.',
  langCookie03:
    'Darüber hinaus ermöglicht es uns, Ihre Interaktion mit unserer Website (einschließlich der besuchten Seiten, Suchbegriffe und Bilder) zu analysieren. Dies gilt unabhängig davon, ob Sie einen Kauf tätigen oder nicht.',
  langCookie04:
    'Die Cookies, mit denen Sie einverstanden sind, werden auch verwendet, um Sie in eine Zielgruppenliste einzutragen, so dass wir zielgerichtete Werbung liefern können, die auf Ihre Interessen zugeschnitten ist.',
  langCookie05:
    'Die meisten Browser erlauben es Ihnen, Cookies abzulehnen, aber wenn Sie dies tun, kann es sein, dass bestimmte Funktionen nicht verfügbar sind und bestimmte Webseiten nicht richtig angezeigt werden.',

  downloadModalTitle: 'Bilddownload',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf:
    'Bilder im TIFF-Format, Videos und Audios werden nicht unterstützt. Die folgenden Medien:',
  messageFileNotSupported2ndHalf: 'wurden vom Massendownload entfernt.',
  licenseOnBehalf: 'Lizenz im Namen von',
  customizedPortal: 'Customized portal',
  normalDownload: 'Normaler Download',
  myCredit: 'Meine Credits',
  lowCreditsReminder: 'Niedriges Guthaben',
  downloadModalId: 'Dateinummer:',
  downloadModalPrice: 'Credits:',
  clearAll: 'Alles löschen',
  myActivities: 'Meine Aktionen',
  info: 'Information',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: 'diesen Inhalt herunterladen mit',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: 'Geben Sie Ihre Suche ein',
  toSearchAIKeyword: 'Geben Sie Keywörter, Artikel- oder Blogtitel ein',
  allImages: 'Alle Bilder',
  freeImages: 'Free Images',
  categoryImage: 'Category',
  recentViewed: 'Zuletzt angesehen',
  latestIMG: 'Latest Images',
  // Setting
  adminControlPanel: 'Admin Schaltfläche',
  downloadHistory: 'Download-Verlauf',
  hruc: 'HRUC',
  changePassword: 'Passwort ändern',
  logOut: 'Abmelden',
  hello: 'Hallo,',
  viewAccount: 'View Account',
  myAccount: 'Konto',
  myCollections: 'Meine Kollektionen',
  personalCredits: 'Ihre Credits',
  groupTotal: 'Insgesamt verfügbare Credits',
  toTalUnpaidLicense: 'Anzahl unbezahlter Lizenzen',
  highRUnwatermarkComp: 'Hi-Res Layoutbilder ohne Wasserzeichen',
  monthSelection: 'Month selection',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // Menu
  categories: 'Kategorien',
  myLikebox: 'Likeboxen - Übersicht',
  settings: 'Einstellungen',
  notification: 'Gruppenmitteilungen',
  clear: 'löschen',
  likebox: '收藏夹',
  credits: 'Credits',
  // Credit summary at header
  downloadAndUsege: 'Download und Nutzung',
  totalDlCredit: 'Verfügbare Download Credits:',
  allocatedDlCredit: 'Zugewiesene Download Credits',
  personalAvailableCre: 'Ihre verfügbaren Credits',
  groupAvailableCre: 'Verfügbare Credits der Gruppe',
  availableDlSlot: 'Verfügbare Download Slot(s)',
  uNPLD: 'Jetzt nutzen, später bezahlen',
  hiResHRUC: 'Hi-Res Layoutbilder ohne Wasserzeichen',
  monthlyAllocation: 'Monatliche Zuweisung',
  remainingQuota: 'Verbleibendes Kontingent',
  remainingHRUCQ: 'Verbleibende Downloads Hi-Res ohne Wasserzeichen',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: 'Gruppen',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: 'Bitte lassen Sie uns wissen, wie wir Ihnen weiterhelfen können',
  liveChat: 'Live chat',
  whatsApp: 'WhatsApp',
  hiThere: 'Hallo!',
  // email response
  emailIsNotAvailable: 'eMail nicht verfügbar.',
  anErrorHaSoccured: 'Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut.',
  emailHasBeenSent:
    'Die eMail wurde erfolgreich verschickt – bitte überprüfen Sie Ihren Posteingang. Wir haben eine eMail mit Anweisungen an __email geschickt. IFalls Sie die Email nicht in 5 Minuten erhalten, überprüfen Sie bitte den Spam-Ordner.',
  // INVALIDPASSWORD
  inValidPassword: 'Ungültiges Passwort! Bitte überprüfen Sie die Hochstelltaste.',
  userIsBlocked: 'Zu viele Fehlversuche, Sie werden vorübergehend gesperrt.',
  blockWarrings: 'Nach 10 Fehlversuchen werden Sie vorübergehend gesperrt!',
  // Update Password
  resetPwdUpdated: 'Passwort wurde aktualisiert.',
  updatePwDfailed: 'Passwort konnte nicht geändert werden!',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'Minuten',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: 'Lizenzvereinbarung',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: 'Bitte Benutzernamen eingeben',
  passWordCannotEmpty: 'Bitte Passwort eingeben',
  oldPasswordCannotEmpty: 'Altes Passwort eingeben',
  confirmPasswordCannotBeEmpty: 'Neues Passwort bestätigen',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: 'Kann keine weiteren E-Mails senden, bitte überprüfen Sie Ihr Postfach.',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: 'Verwenden Sie einen älteren Browser?',
  upgradeBrowser:
    'Aktualisieren Sie auf eine neuere Browserversion für eine reibungslosere Nutzung.',
  clickToDownload: 'Klicken Sie hier, um diese herunterzuladen:',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: 'Alle anzeigen',
  // Filter bar
  aiGenerate: 'KI-generiertes Bild',
  standardModels: 'Standardmodelle',
  fineTunedModels: 'Feinabgestimmte Modelle',
  aiContent: 'KI-generierte Inhalten einbeziehen',
  filter: 'Filter',
  safeSearch: 'Sichere Suche',
  aiAssist: 'KI Assistent',
  freshness: 'Veröffentlichung',
  gender: 'Geschlecht',
  sortBy: 'Sortiert nach',
  mediaType: 'Bildtype',
  orientation: 'Ausrichtung',
  modelPreferences: 'Modelltyp',
  people: 'Menschen',
  more: 'Mehr',
  numOfPeople: 'Personenzahl',
  age: 'Alter',
  ethnicity: 'Ethnizität',
  collection: 'Kollektionen',
  uploaded: 'Uploaded',
  style: 'Stil',
  cutOut: 'Einfarbige/transparente Hintergründe',
  doNotIncludeWords: 'Folgende Worte ausschließen',
  doNotIncludeWords_ex: 'z.B. Käse',
  color: 'Farbe',
  max1Color: 'Max. 1 Farbe',
  license: 'Lizenz-typ',
  fps: 'FPS',
  resolution: 'Auflösung',
  clipDuration: 'Laufzeit (Minuten)',
  loop: 'Loop',
  category: 'Kategorien',
  genre: 'Genre',
  tempo: 'Tempo (bpm)',
  // Filter button text
  Relevance: 'Willkürlich',
  New: 'Neu',
  Georank: 'Georank',
  DownloadHistory: 'Download-Historie',
  Popularity: 'Beliebtheit',
  Photography: 'Fotografie',
  VectorIllustration: 'Vektor-Illustration',
  Horizontal: 'Horizontal',
  Landscape: 'Landschaft',
  Portrait: 'Portrait',
  Square: 'Quadrat',
  Panorama: 'Panorama',
  numPpl: 'Personenzahl',
  WithoutPeople: 'Ohne Personen',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: 'Ausschliessen',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: 'Letzte Woche',
  LastMonth: 'Last month',
  PastThreeMonths: 'Letzte 3 Monate',
  PastTwodays: 'Letzte 2 Tage',
  Male: 'Männlich',
  Female: 'Weiblich',
  Standard: 'Standard',
  extended: 'Erweitert',
  Editorial: 'Redaktionell',
  Commercial: 'Kommerziell',
  Black: 'Black',
  Caucasian: 'kaukasisch',
  AfricanAmerican: 'afrikanisch',
  Asian: 'asiatisch',
  EastAsian: 'East Asian',
  Hispanic: 'hispanisch',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: 'Kinder',
  Teenagers: 'Teenager',
  Adults: 'Erwachsene',
  Seniors: 'Senioren',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: 'Ja',
  Nonloopable: 'Nein',
  Music: 'Musik',
  SoundEffects: 'Soundeffekte',
  LogoAndIdents: 'Musiklogo & Identitäten',
  Veryslow: 'Sehr langsam',
  VeryslowSubText: '(0-79)',
  Slow: 'Langsam',
  SlowSubText: '(80-119)',
  Medium: 'Medium',
  MediumSubText: '(120-139)',
  Fast: 'Schnell',
  FastSubText: '(140-159)',
  Veryfast: 'Sehr schnell',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: 'Beliebt',
  MostDownload: 'Most Download',
  Credits: 'Credits',
  Freshness: 'Veröffentlichung',
  pickAGenre: 'Genre auswählen',
  // Paginations
  Of: 'von',
  Page: 'Seite',
  // home
  recommended: 'Empfohlen',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: 'Aktuelle Suchbegriffe',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: 'Game Sounds',
  cartoonSounds: 'Cartoon Sounds',
  soundPacks: 'Sound Packs',
  interfaceSounds: 'Interface Sounds',
  transitionsAndMovementsSounds: 'Überblendungen und Übergänge',
  natureSounds: 'Naturgeräusche',
  domesticSounds: 'Häusliche Geräusche',
  urbanSounds: 'Urbane Geräusche',
  industrialSounds: 'Industrielle Klänge',
  futuristicSounds: 'Futuristische Klänge',
  humanSounds: 'Menschliche Geräusche',
  othersSounds: 'Andere',
  ambientSounds: 'Ambiente',
  childrenSounds: 'Kinder',
  cinematicSounds: 'Filmisch',
  classicalSounds: 'Klassich',
  corporateSounds: 'Corporate',
  electronicaSounds: 'Electronica',
  soulSounds: 'Soul & R&B',
  jazzSounds: 'Jazz',
  popSounds: 'Pop',
  worldBeatSounds: 'World Beat',

  countrySounds: 'Country & Western',
  drumSounds: 'Drum & Bass',

  folkSounds: 'Folk & Acoustic',
  funkSounds: 'Funk & Groove',

  hiphopSounds: 'Hip Hop',
  holidaySounds: 'Ferien & Jahreszeiten',

  rockSounds: 'Rock',

  individualSounds: 'Individuell',
  introSounds: 'Intro & Outro',
  ascSounds: 'Aufsteigend & Absteigend',
  businessSounds: 'Arbeit & Wirtschaft',
  orchestralSounds: 'Orchester',
  festiveSounds: 'Festlich',
  percussiveSounds: 'Schlagwerk',
  miscellaneousSounds: 'Sonstiges',
  packsSounds: 'Pakete',
  percussionSounds: 'Percussion Sounds',
  sampleLoopSounds: 'Loop Samples',

  // Search images
  searchImage: 'Fotos & Illustrationen',
  StockPhotosAndImages: 'Stock Fotos und Bilder',
  StockVectorClipartAndIllustration: 'Vektorengrafiken und Illustrationen',

  matches: 'Treffer',
  editorial: 'Redaktionell',
  downloaded: 'Diese Datei wurde bereits heruntergeladen',
  to: 'to',
  relatedSearches: 'Ähnliche Suchanfragen',
  similarTo: 'Ähnlich zu',
  searchByImage: 'Suche per Bildbeispiel',
  options: 'Optionen',
  searchByImageMsg:
    'Suchen Sie bei 123RF mit einem Bild anstelle von Text, indem Sie ein Bild in das Suchfeld ziehen.',
  mustBeJPGorPNG: 'Bild muss JPG / PNG sein',
  sizeLessThan5MB: 'Die Bildgröße darf nicht größer als 5 MB sein.',
  sizeLessThan4MB: 'Die Bildgröße darf nicht größer als 4 MB sein.',
  dragOrDropFile: 'Datei ziehen oder',
  browse: 'Durchsuchen',
  uploadedImage: 'Hochgeladenes Bild',
  similarStockPhotos: 'Ähnliche Bilder',
  // Search footage
  searchFootage: 'Videos',
  StockFootage: 'Stock Video',
  businessFootage: 'Hacks to make the most of your business videos',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  audioProperties: 'Audioeigenschaften',
  footageProperties: 'Eigenschaften des Videomaterials',
  modalDownloadTitle: 'Datei-Download-Optionen',
  credit: 'Credit',
  saveAs: 'Speichern unter',
  ownLicense: 'Normaler Download (eigene Lizenz)',
  downloadAs: 'Herunterladen als',
  custom: 'benutzerdefiniert',
  personalAndCom:
    'Persönliche und kommerzielle Nutzung: Werbung, Präsentationen, soziale Netzwerke, Web.',
  licenseType: 'Lizenztyp',
  requiredForResale:
    'Erforderlich für den Weiterverkauf oder die Verbreitung in den Bereichen Theateraufführung, kostenpflichtiger Zugänge, Videostreaming, Spiele und Apps.',
  insufficientCredits: 'Credits nicht ausreichend',
  footageDetail: 'Video-Details',
  stockAudioKeywords: 'Stock-Audio-Stichwörter',
  stockFootageKeywords: 'Stock-Video Schlüsselwörter',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: 'Stereo',
  fileSize: 'Dateigrößen',
  footageId: 'Video ID',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  fileName: 'Dateiname',
  // Search audio
  searchAudio: 'Audio (Musik und Soundeffekte)',
  StockAudio: 'Stock-Audio',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: 'Link kopieren',
  preview: 'Vorschau',

  bestSellingFootage: 'Entdecken Sie die meistverkauften Videos',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: 'Ähnliche Bilder',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: 'Ihre zuletzt angesehenen Bilder werden hier angezeigt',
  rVVectors: 'Ihre zuletzt angesehenen Vektoren werden hier angezeigt',
  rVFootage: 'Ihre zuletzt angesehenen Videos werden hier angezeigt',
  rVAudio: 'Ihre zuletzt angesehenen Audios werden hier angezeigt',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: 'Ups, Ihre Suche mit diesen Filtern hat keine Ergebnisse geliefert.',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle:
    'Versuchen Sie, einige Filter zu entfernen oder einen anderen Suchbegriff zu wählen.',
  emptyReverseSearchTitle: 'Nepodařilo se nalézt žádné výsledky!!',
  emptyReverseSearchSubTitle1:
    'Nahraný soubor obsahuje chyby, ujistěte se prosím, zda jste splnili následující kritéria:',
  emptyReverseSearchSubTitle2: 'Upřesněte vyhledávání pomocí klíčových slov',
  emptyReverseSearchSubTitle3: 'Snímek musí být v JPG / PNG formátu',
  emptyReverseSearchSubTitle4: 'Velikost fotografie musí být menší než 5 MB',
  emptySearchResultClearFilter: 'Alle Filter deaktivieren',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: 'Bitte geben Sie ein Stichwort ein',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // collection search result page
  searchCollection: 'Kollektionen suchen',
  personal: 'Persönlich',
  groups: 'Gruppen',
  createNew: 'Neu anlegen',
  createNewCollection: 'Neue Sammlung erstellen',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  titleItem: 'Titel',
  selectGroup: 'Gruppe auswählen',
  description: 'Beschreibung',
  charactersRemaining: '180 verbleibende Zeichen',
  cancel: 'Abbrechen',
  create: 'Erstellen',
  or: 'ODER',
  defaultCollection: 'Vorschau-Kollektion',
  addToMyCollections: 'vom gleichen Modell',
  addedToCollection: 'Zur Sammlung hinzugefügt',
  removedFromCollection: 'Aus Sammlung entfernen',
  collectionModalPlaceholder: 'z.B. Natur',
  groupNameShowsHere: 'Gruppenname wird hier angezeigt',
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: 'Mit mir geteilt',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: 'Eigentümer',
  images: 'Bilder',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: 'Persönlich',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: 'Teilen',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: 'Sie haben keine Sammlungen.',
  btnUnDo: 'Rückgängig',
  // my collection
  dateAdded: 'Hinzugefügt am',
  collectionName: 'Name der Kollektion',
  selectAll: 'Alle auswählen',
  delete: 'Löschen',
  seeAll: 'Alle ansehen',
  deleteCollection: 'Kollektion löschen',
  deleteWarning:
    'Wenn Sie diese Aktion durchführen, werden Ihre Kollektion(en) mitsamt ihrem Inhalt endgültig gelöscht. Beachten Sie, dass diese Aktion nicht rückgängig gemacht werden kann.',
  createCollection: ' wurde erstellt',
  shareCollection: 'Kollektion teilen',
  shareWithTeam: 'Share in team',
  selectUsers: 'Nutzer auswählen',
  searchUserName: 'Nach einem Benutzernamen suchen',
  copy: 'Kopieren',
  titleCopyModal: 'Auswahl kopieren nach',
  collectionUppercase: 'Kollektion',
  groupCollection: 'Gruppe',
  moveImage: 'Auswahl verschieben nach',
  defaultSelect: 'Name der Kollektion',
  moveLikebox: 'Move',
  messsageDeleteLikebox:
    'Wenn Sie diese Aktion durchführen, werden Ihre Kollektion(en) mitsamt ihrem Inhalt endgültig gelöscht. Beachten Sie, dass diese Aktion nicht rückgängig gemacht werden kann.',
  areYouSure: 'Sind Sie sich sicher? ',
  editCollection: 'Kollektion bearbeiten',
  collectionNameUpper: 'Name der Kollektion',
  deleteSuccessful: 'has been successfully deleted',
  edit: 'Bearbeiten',
  messageRemoveImage:
    'Sind Sie sicher, dass Sie die ausgewählte(n) Position(en) aus der Sammlung entfernen möchten?',
  titleRemove: 'Auswahl löschen',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: 'SPEICHERN',
  shareLikebox: 'Mit mir geteilt',
  remove: 'Entfernen',
  moveTo: 'Verschieben nach',
  createNewPlaceholder: 'z.B. Natur',
  shareViaEmail: 'Per Email teilen',
  sharePublicly: 'Share publicly',
  receipnetEmail: 'Email-Adresse Empfänger',
  message: 'Nachricht',
  insertReceipmentEmail: 'Email-Adresse vom Empfänger eingeben',
  messageText: 'Nachricht',
  exportCollection: 'Kollektion exportieren',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: 'Neue Kollektion hinzufügen',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: 'FORMAT',
  lowCredit: 'Niedriges Guthaben',
  downloadSuccess: 'Vielen Dank für das Herunterladen. Der "Download" beginnt gleich.',
  downloadFailed: 'Downloadfehler: Fehler, bitte wiederholen.',
  downloadedLabel: 'Heruntergeladen',
  any: 'Alle',
  resetFilter: 'Filter zurücksetzen',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: 'Folgen',
  name: 'Name',
  location: 'Ortsangabe',
  since: 'seit',

  // Konto
  updated: 'Update',
  userName: 'BENUTZERNAME',
  firstName: 'VORNAME',
  lastName: 'NACHNAME',
  myProfile: 'My Profile',
  changeProfiledetail: 'Ändern Sie hier Ihre Profildaten',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: 'Meine Einkäufe',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: 'Jetzt nutzen, später bezahlen',
  bodyUNPL:
    'Use Now, Pay Later (UNPL) ist eine Art der kurzfristigen Finanzierung, die es Verbrauchern ermöglicht, Einkäufe zu tätigen und diese zu einem späteren Zeitpunkt zu bezahlen, oft gebührenfrei.',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',
  // audio player
  categoryGenre: 'Kategorien / Genre',
  duration: 'Dauer',
  seeMore: 'Mehr anzeigen',
  seeLess: 'Weniger anzeigen',
  audioID: 'Audio ID',
  // catelogy
  landscape: 'Landschaft',
  Babies: 'Baby',
  business: 'Unternehmen',
  food: 'Essen',
  animals: 'Tiere',
  technology: 'Technologie',
  healthcare: 'Gesundheit',
  nature: 'Natur',
  romance: 'Romantik',
  // category vector
  graphic: 'Grafik',
  advertising: 'Werbung',
  flower: 'Blume',
  abstract: 'Abstrakt',
  Illustration: 'Illustration',
  pop: 'Pop',
  bird: 'Vogel',
  pixel: 'Pixel',
  threeD: '3D',

  // footage category
  education: 'Bildung',
  communication: 'Kommunikation',
  beauty: 'Beauty',
  concept: 'Konzepte',
  fitness: 'Fitness',
  lifestyle: 'Lifestyle',
  celebration: 'Feier',
  family: 'Familie',
  fashion: 'Mode',

  // Download-history modal
  reDownload: 'Erneut herunterladen',
  chooseCustomTagOrCreate: 'Wählen Sie benutzerdefinierte Tags aus oder erstellen Sie neue Tags',
  downloadDetails: 'Details herunterladen',
  image: 'Image',
  downloadType: 'Download-Typ',
  groupName: 'Gruppenname',
  size: 'Größe',
  dateDownloaded: 'Datum des Downloads',
  reLicense: 'Re-Lizenzieren',
  customFields: 'Benutzerdefinierte Felder',
  saveAndClose: 'Speichern und schließen',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: 'Datum',
  export: 'Exportieren',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: 'Kostenlose Inhalte',
  searchById: 'Suche nach Dateinummer',
  pleaseSelect: 'Bitte auswählen',
  totalOutstanding: 'Insgesamt ausstehend',
  viewInvoices: 'Rechnung anzeigen',
  // Image detail page - select download size sidebar
  standardSizes: 'Standardgrößen',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: 'Zuschneiden',
  // empty data
  opsNothingToSeeHere: 'Ups! Hier gibt es nichts zu sehen.',
  // search image
  authentic: 'Authentisch',
  // search audio
  mostDownloaded: 'Meist heruntergeladen',
  download: 'Herunterladen',
  // audio category
  soulAndRB: "R'n'B & Soul",
  corporate: 'Business',
  electronica: 'Electronica',
  classical: 'Klassik',
  funkAndGrooveMusic: 'Funk & Groove',
  worldBeat: 'World-Musik',
  cinematic: 'Film',
  jazz: 'Jazz',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: 'Nächste Seite',
  reverseImageSearch: 'Reverzní Vyhledávání Fotografií',

  group: 'Gruppe',
  needHelp: 'Brauchen Sie Hilfe?',
  copied: 'Kopiert!',
  copyPortfolioLinkSuccess: 'Mitwirkender Portfolio -Link in die Zwischenablage kopiert.',

  // my activities
  myActivity: 'Meine Aktionen',
  recentLike: 'Meine letzten Likes',
  recentView: 'Meine letzten Views',
  myActivityViewDesc: 'Der Verlauf der letzten Ansichten wird nur bis zu 3 Monate gespeichert.',
  myActivityLikeDesc: 'Zeigt nur den Verlauf der letzten 3 Monate',

  // dashboard
  profile: 'Profil',

  // Pricing
  pricingCreditText: 'Credits',

  // Dashboard - Credit Usage
  user: 'User',
  creditUsage: 'Credit usage',
  searchPlaceholder: 'Search name, username, role, group',
  all: 'Alle',
  active: 'Active',
  deleted: 'Deleted',
  reset: 'Zurücksetzen',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: 'KI-gestützte Suche',
  aiPoweredSearchOn: 'KI gestützte Suche ist aktiv',
  aiSearchIntroduction:
    'Probieren Sie unsere schnellere und leistungsfähigere Suchmaschine aus. Unsere KI -Suche übernimmt die ganze Arbeit!',
  ok: 'OK',
  // Filter bar ai

  bstract: 'Abstrakt',
  macroCloseUp: 'Makro/Nahaufnahme',
  object: 'Objekt',
  watercolor: 'Aquarell',
  flat: 'Flach',
  cartoon: 'Karikatur',
  geometric: 'Geometrisch',
  gradient: 'Farbverlauf',
  isometric: 'Isometrisch',
  '3d': '3d',
  handDrawn: 'Handgezeichnet',
  natural: 'Natürlich',
  warm: 'Warm',
  dramatic: 'Dramatisch',
  vividAndBold: 'Lebendig und fett',
  blackAndWhite: 'Schwarz und weiß',
  // introduce Free image research
  imageResearchContentTitle:
    'Wir stellen Ihnen den kostenlosen Bildrecherche-Service von 123RF vor: Perfekte Bilder, null Aufwand',
  imageResearchContentDesc:
    'Wir revolutionieren die Bildrecherche für unsere Kunden - ohne Mühe das perfekte Bild finden und Ihre Vision zum Leben erwecken.',
  submitRequest: 'Anfrage stellen',
  imageResearchContentInfoTitle1: 'Bildrecherche einfach gemacht',
  imageResearchContentInfoDesc1:
    'Die ersten Schritte sind ganz einfach. Geben Sie uns einfach eine detaillierte Beschreibung Ihres gewünschten Bildes, einschließlich Stil, Stimmung, Farben und spezifischer Elemente. Je mehr Details Sie angeben, desto besser können wir auf Ihre Bedürfnisse eingehen.',
  imageResearchContentInfoTitle2: 'Wie funktioniert es?',
  imageResearchContentInfoTitle3: 'Schneller, zuverlässiger Service',
  imageResearchContentInfoTitle4: 'Lassen Sie uns die Arbeit für Sie erledigen',
  imageResearchContentInfoDesc4:
    'Wir finden die perfekten Bilder für Sie, damit Sie sich auf Ihr Projekt konzentrieren können. Vertrauen Sie darauf, dass wir Ihre Vision mit hochwertigen, sorgfältig ausgewählten Bildern zum Leben erwecken.',
  requestForm: 'Anfrageformular',
  whatDoYouWantThisImageFor: 'Wofür möchten Sie dieses Bild verwenden?',
  websiteBanner: 'Website-Banner',
  socialMedia: 'Social Media',
  blogPost: 'Blog-Post',
  advertisement: 'Werbung',
  print: 'Druck',
  others: 'Andere',
  othersOutputPlaceholder: 'Geben Sie andere Ausgabetypen ein',
  whatIsPrimaryMessage:
    'Was ist die Hauptbotschaft oder das Thema, welches das Bild vermitteln soll?',
  whatIsPrimaryMessagePlaceholder: 'Bsp: Hund mit Fell rennt mit Frau im Park',
  areThereSubjectYouWantInThePicture:
    'Gibt es spezifische Themen oder Elemente, die im Bild enthalten sein sollen?',
  egPeopleNatureTechnologyAbstract: 'Bsp: Menschen, Natur, Technologie, abstrakte Konzepte',
  areThereImageOrStyleReference:
    'Gibt es Bilder oder Stile, die Sie schon einmal gesehen haben und die wir als Inspiration oder Referenz verwenden sollen?',
  ifPeopleIncludeWhatTheirDemographic:
    'Wenn Menschen enthalten sein sollen, wie sollte deren Demographie aussehen?',
  egAgeGenderEthnicityOccupation: 'Bsp: Alter, Geschlecht, Ethnizität, Beruf',
  areThereAnyThingToAvoid: 'Gibt es Elemente oder Themen, die Sie im Bild vermeiden möchten?',
  whoisYourTargetAudience: 'Wer ist Ihre Zielgruppe?',
  whoisYourTargetAudiencePlaceholder: 'Bsp: Junge Erwachsene, die gerne lesen',
  typeOfImage: 'Bildtyp',
  whatMoodShouldImageEvoke: 'Welche Stimmung oder Emotion soll das Bild hervorrufen?',
  happy: 'Glücklich',
  sad: 'Traurig',
  calm: 'Ruhig',
  excited: 'Aufgeregt',
  angry: 'Wütend',
  enterCustomMood: 'Benutzerdefinierte Stimmung eingeben',
  areThereAnyOrientationForImage:
    'Gibt es spezifische Ausrichtungen, die für das Bild benötigt werden?',
  enterCustomOrientation: 'Benutzerdefinierte Ausrichtung eingeben',
  isThereLocationYouWantImageToBeIn:
    'Gibt es einen bestimmten Ort oder eine bestimmte Umgebung, in der das Bild sein soll?',
  no: 'Nein',
  yes: 'Ja',
  shareWithUsAReference: 'Teilen Sie uns eine Referenz mit (nur JPG oder PDF. Max 4MB)',
  replace: 'Ersetzen',
  areThereAnySpecificRequirements: 'Gibt es weitere Anforderungen oder Präferenzen?',
  enterOtherComments: 'Geben Sie andere Kommentare ein',
  submit: 'Senden',
  fieldCannotEmpty: 'Dieses Feld darf nicht leer sein',
  selectAtLeastOne: 'Bitte wählen Sie mindestens eine Option aus',
  fileMustBeJpgOrPdf: 'Die Datei muss entweder ein JPG oder ein PDF sein.',
  fileSizeLessThan4MB: 'Die Dateigröße muss kleiner als 4MB sein',
  maximumFileNameAllow255: 'Die maximal erlaubten Zeichen sind 255',
  updateSuccessfully: 'Wir haben Ihre Anfrage erhalten.',
  freeImageResearch: 'Kostenlose Bildrecherche',
  newService: 'Neuer Service!',
  freeImageResearchIntroduction:
    'Probieren Sie unseren Service für Bildrecherche aus und erhalten Sie Bilder, die zu Ihren Anforderungen passen.',
  later: 'Später',
  letTryIt: 'Jetzt ausprobieren!',
  viewPdfOfSampleOutput: 'PDF der Beispielausgabe anzeigen',
  characterMaxChar255: 'Die maximal erlaubten Zeichen sind 255',

  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: 'ist erforderlich',

  requiredWithVariable: '{{var}} ist erforderlich',
  imageResearchContentInfoDesc2: (
    <>
      Unser Team kombiniert
      {' '}
      <strong>KI-Technologie</strong>
      {' '}
      und Erfahrung, um die idealen Bilder für
      Sie zu finden. Fortschrittliche Algorithmen filtern die Optionen aus unserer umfangreichen
      Bibliothek auf der Grundlage Ihrer Angaben. Unser
      {' '}
      <strong>Forscher-Team</strong>
      {' '}
      verfeinert
      die Ergebnisse, um Ihren Kriterien gerecht zu werden.
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      Wir liefern Ergebnisse innerhalb von
      {' '}
      <strong>2 Arbeitstagen</strong>
      {' '}
      und liefern eine umfassende
      Definition für die Bildauswahl. Konzentrieren Sie sich auf Ihr Geschäft, während wir uns um
      den Rest kümmern.
    </>
  ),
  mood: 'Stimmung',
  person: 'Person',
  doNotIncludeWords_ex2: 'z.B. Grüne Farbe, glattes Haar',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: 'KI-Bildgenerator',
  imageGenerator: 'Bildgenerator',
  aiImageGeneratorDesc: 'Kreativität in der Geschwindigkeit Ihrer Fantasie.',
  generate: 'Generieren',
  exploreVisualsCreatedWithThePowerOfAI:
    'Entdecken Sie Visualisierungen, die mit der Kraft der KI erstellt wurden',
  turnYourImaginationIntoRealityIn3SimpleSteps:
    'Verwirklichen Sie Ihre Vorstellungen in 3 einfachen Schritten',
  startWithGoodTextPrompt: (
    <>
      Beginnen Sie mit
      {' '}
      <br />
      {' '}
      <span>
        einer guten
        <br />
        Textaufforderung
      </span>
    </>
  ),
  startWithGoodTextPromptDesc:
    'Ein guter Prompt garantiert großartige Ergebnisse. Ein Prompt sollte Perspektive, Motiv, Handlung, Schlüsselwörter, Beleuchtung und Stil beinhalten, um die besten Ergebnisse zu erzielen.',
  reviewYourAIGeneratedImages: (
    <>
      Überprüfen Sie Ihre
      {' '}
      <br />
      <span>KI-generierten Bilder</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    'Unser KI-Generator wird 3 Vorschau-Bilder generieren und anzeigen, die Ihrer Eingabe am nächsten kommen.',
  downloadAndLicenseVisuals: (
    <>
      Bildmaterial herunterladen
      {' '}
      <br />
      <span>und lizenzieren</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    'Sie können diese Bilder jetzt herunterladen und mit einer rechtlichen Deckung von $25.000 für den persönlichen und kommerziellen Gebrauch lizenzieren.',
  needSomeIdeas:
    'Brauchen Sie einige Ideen? Beginnen Sie mit diesen Beispielen und gestalten Sie Ihre Ideen!',
  tryPrompt: 'Versuchen Sie den Vorschlag',
  creativityAtTheSpeedOfYourImagination: 'Kreativität Ihrer Fantasie',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    'Generieren Sie mit Corporate+ in Sekundenschnelle Ihre eigenen KI-Bilder aus Textvorgaben!',
  tryNow: 'Jetzt ausprobieren',

  /// Random prompt
  prompt1: 'Stellen Sie einen selbstbewussten, aber zugänglichen 28-jährigen koreanischen männlichen Sänger mit einer fesselnden Bühnenpräsenz dar. Präsentieren Sie seine dynamischen Gesangsfähigkeiten und seinen magnetischen Charme durch ein ausdrucksstarkes Porträt, das seine markanten Gesichtszüge hervorhebt. Rendern Sie sein dichtes, schwarzes Haar und seine intensiven, ausdrucksvollen Augen in lebendigen Details und vermitteln Sie die Tiefe seiner künstlerischen Leidenschaft. Kleiden Sie ihn in stilvolle, moderne Kleidung, die seine musikalische Persönlichkeit widerspiegelt, vielleicht mit einem Hauch von Kantigkeit oder Raffinesse. Positionieren Sie ihn in einer Umgebung, die auf einen Live-Auftritt oder ein Aufnahmestudio hindeutet und die Energie und Aufregung seines Handwerks einfängt. Verwenden Sie dramatische Beleuchtung und Rahmung, um eine filmische, überlebensgroße Qualität zu erzeugen, die seinen Status als aufstrebender Star betont. Verleihen Sie der Komposition ein Gefühl von Bewegung und Dynamik, als ob er mitten in einer fesselnden Gesangsdarbietung wäre. Ziel ist es, ein Bild zu schaffen, das sofort die Aufmerksamkeit des Betrachters auf sich zieht und die unbestreitbare Bühnenpräsenz und das Gesangstalent des Sängers zeigt.',
  prompt2: 'Ein fotorealistisches Bild eines majestätischen Löwen, der auf einem Felsen liegt, mit der weiten afrikanischen Savanne im Hintergrund. Verwenden Sie eine Kamera mit einem 400-mm-Objektiv bei einer Blende von F 2.8, um den Hintergrund zu verwischen und den Löwen scharf zu fokussieren. Die Beleuchtung sollte natürlich und warm sein und die goldenen Farbtöne des Sonnenuntergangs einfangen. Betonen Sie den Fotorealismus, um ein lebendiges, detailliertes Bild zu erzeugen, das die Schönheit und Größe der Tierwelt vermittelt.',
  prompt3: 'Ein Mixed-Media-Stück, das einen futuristischen Samurai in einer Actionpose darstellt, der ein leuchtendes Katana schwingt, vor einer Cyberpunk-Stadtlandschaft mit Neonlichtern und Hologrammen. Integrieren Sie eine Vielzahl von Texturen und Materialien, darunter metallische und digitale Elemente, um eine visuell reiche und dynamische Szene zu schaffen.',
  prompt4: 'Eine fröhliche digitale Illustration einer jungen Prinzessin in einem fließenden Kleid, die mit magischen Kreaturen in einem verzauberten Wald spielt. Umgeben Sie sie mit bunten Blumen, Schmetterlingen und glitzerndem Feenstaub vor einem lebendigen und skurrilen Hintergrund.',
  prompt5: 'Eine detaillierte Graphitskizze eines alten Kriegers mit einem wettergegerbten Gesicht und einem entschlossenen Ausdruck, der ein Schwert hält. Verwenden Sie eine komplizierte Schattierung und Texturen, um die Rüstung und die Kriegsschrammen des Kriegers hervorzuheben und ein zeitloses und kraftvolles Bild zu schaffen.',
  prompt6: 'Eine surrealistische Mixed-Media-Collage eines zeitreisenden Entdeckers, der durch verschiedene historische Epochen navigiert, mit Elementen wie alten Karten, antiken Artefakten und futuristischen Geräten. Verwenden Sie eine Kombination aus alten Zeitungsausschnitten, Fotografien und digitaler Kunst, um ein humorvolles und zum Nachdenken anregendes Werk zu schaffen.',
  prompt7: 'Eine detaillierte digitale Illustration einer dystopischen Stadtlandschaft mit hoch aufragenden Wolkenkratzern, fliegenden Autos und dunklen, unheilvollen Wolken. Konzentrieren Sie sich auf die Schaffung von Tiefe und Maßstab, mit komplexen Details in der Architektur und dramatischer Beleuchtung, um die Stimmung zu verstärken.',
  prompt8: 'Ein Porträt eines weisen Ältesten, der einen magischen Stab hält, aufgenommen in dramatischer Chiaroscuro-Beleuchtung, um die Gesichtszüge des Ältesten und die leuchtenden Runen des Stabes hervorzuheben. Verwenden Sie satte Texturen und Schatten, um ein Gefühl von Geheimnis und Weisheit zu erzeugen.',
  prompt9: 'Eine lebendige Fantasy-Illustration eines Drachenreiters, der durch den Himmel schwebt, wobei der Reiter in Rüstung gekleidet ist und die Flügel des Drachen weit ausgebreitet sind. Umgeben Sie sie mit Wolken und fernen Bergen und fügen Sie ein Gefühl von Abenteuer und Erhabenheit hinzu.',
  prompt10: 'Ein ungestelltes Schwarz-Weiß-Straßenfoto eines geschäftigen Marktgeschehens in einer asiatischen Stadt, mit Verkäufern, die farbenfrohes Obst und Gemüse verkaufen. Verwenden Sie eine geringe Schärfentiefe, um sich auf die Ausdrücke und Interaktionen der Verkäufer zu konzentrieren und die lebhafte Atmosphäre einzufangen.',
  prompt11: 'Ein skurriles Aquarellgemälde eines Einhorns mit einer Regenbogenmähne, das in einem magischen Wald steht, umgeben von leuchtenden Pilzen und funkelnden Glühwürmchen. Verwenden Sie weiche, Pastellfarben und fließende Pinselstriche, um eine verträumte, bezaubernde Szene zu schaffen.',
  prompt12: 'Ein 3D-Rendering einer modernen Küche mit schlankem, minimalistischem Design, Edelstahlgeräten und einer großen Insel in der Mitte. Verwenden Sie fotorealistische Texturen und Beleuchtung, um die klaren Linien und den modernen Stil hervorzuheben, mit Fokus auf Reflexionen und Materialien.',
  prompt13: 'Ein Fantasy-Landschaftsgemälde einer schwebenden Insel am Himmel, mit Wasserfällen, die über die Kanten stürzen, und üppiger Vegetation, die die Oberfläche bedeckt. Umgeben Sie die Insel mit flauschigen Wolken und einem leuchtend blauen Himmel, um ein Gefühl von Staunen und Magie zu erzeugen.',
  prompt14: 'Ein detailliertes Porträt eines Wikingerkriegers mit geflochtenem Haar und einem grimmigen Ausdruck, der eine Streitaxt hält. Der Hintergrund sollte ein stürmisches Meer und zerklüftete Klippen darstellen, mit dramatischer Beleuchtung, um die Gesichtszüge und die Rüstung des Kriegers hervorzuheben.',
  prompt15: 'Eine surrealistische Mixed-Media-Collage eines Steampunk-Erfinders in einer überfüllten Werkstatt voller Zahnräder, Uhrwerkmechanismen und dampfbetriebenen Apparaten. Verwenden Sie Vintage-Fotografien, alte Blaupausen und metallische Texturen, um eine visuell reiche und fantasievolle Szene zu schaffen.',
  prompt16: 'Eine digitale Illustration einer futuristischen Stadtlandschaft bei Nacht, mit hoch aufragenden Wolkenkratzern, Neon-Schildern und fliegenden Fahrzeugen. Verwenden Sie leuchtende Farben und dynamisches Licht, um eine lebendige und immersive Atmosphäre zu schaffen, die die technologischen Fortschritte und die geschäftige Energie der Stadt betont.',
  prompt17: 'Ein fotorealistisches 3D-Rendering einer gemütlichen Leseecke mit einem bequemen Sessel, einem kleinen Tisch mit einer Lampe und mit Büchern gefüllten Regalen. Die Umgebung sollte eine warme, einladende Beleuchtung und weiche Texturen aufweisen und die ruhige und entspannende Atmosphäre des Raumes einfangen.',
  prompt18: 'Stellen Sie eine transparente, glasartige Handtasche aus Mode mit einem voll funktionsfähigen Aquarium dar, das in das Design integriert ist. Rendern Sie die zarten, schwimmenden Fische in der Handtasche akribisch und schaffen Sie so ein faszinierendes und unkonventionelles Accessoire. Stellen Sie sicher, dass das Aquarium nahtlos in die Struktur der Handtasche integriert ist, mit nahtlosen Übergängen zwischen den beiden Elementen. Positionieren Sie die Handtasche prominent in einer High-End-Kaufhausumgebung, umgeben von einer Kulisse aus schlanken, modernen Regalen, Beleuchtung und anderen Luxus-Einzelhandelspräsentationen. Verwenden Sie dramatische, gerichtete Beleuchtung, um ein Gefühl von Dramatik zu erzeugen und die Aufmerksamkeit des Betrachters zu fesseln, indem Sie seinen Blick direkt auf die Wasserhandtasche lenken. Fangen Sie die Handtasche aus einem Winkel ein, der ihre transparente, fast ätherische Qualität zeigt, so dass die Fische deutlich sichtbar sind und einen Eindruck von Schwerelosigkeit oder Fließfähigkeit erzeugen.',
  prompt19: 'Fangen Sie eine gemütliche, einladende Szene mit Plüschsesseln in einer gedeckten Farbpalette aus Braun- und Olivgrüntönen ein. Präsentieren Sie die Stühle in einer warmen, intimen Umgebung, umgeben von Details, die ein Gefühl von Entspannung und Komfort hervorrufen, wie z. B. weiche Textilien, edle Holzakzente und vielleicht einen Blick auf einen Kamin oder ein Fenster mit Blick auf eine friedliche Naturlandschaft. Betonen Sie die üppige, texturale Qualität der Polsterung und das allgemeine Gefühl von Gemütlichkeit und Rückzug.',
  prompt20: 'Fangen Sie eine lebendige, lebensechte Darstellung eines Zwerghuhns ein, das auf einem mit Moos bewachsenen Ast sitzt, vor einem üppigen, smaragdgrünen Hintergrund, der das atemberaubende Gefieder des Vogels ergänzt. Rendern Sie die komplizierten Federn des Hähnchens mit exquisiten Details und verwenden Sie einen subtilen Farbverlauf aus Licht und Schatten, um ihre Texturen hervorzuheben. Heben Sie den satten, dunkelgrünen Scheitel, den warmen erdigen braunen Schnabel und die hellen, neugierigen schwarzen Augen des Hähnchens hervor und schaffen Sie so ein fesselndes Porträt der natürlichen Schönheit des Vogels. Verleihen Sie der gesamten Szene eine warme, ruhige Atmosphäre, die den Betrachter einlädt, in die ruhige Naturkulisse einzutauchen.',
  prompt21: 'Gestalten Sie ein visuell ansprechendes Bild mit einer Schale cremigen, gefrorenen Joghurts, belegt mit einer lebendigen Auswahl an frischen Früchten und knusprigen Nüssen. Fangen Sie die erfrischenden und nahrhaften Eigenschaften dieses gesunden Desserts ein und zeigen Sie den Kontrast zwischen dem glatten, hellen Joghurt und den leuchtenden Farbakzenten der Fruchtbeläge. Arrangieren Sie die Zutaten kunstvoll und appetitanregend, so dass der Betrachter die visuelle Attraktivität schätzen und das erfrischende, geschmackvolle Erlebnis erwarten kann. Stellen Sie sicher, dass das Gesamtbild ein Gefühl von Natürlichkeit und Genuss ausstrahlt und den Betrachter einlädt, die köstlichen und nahrhaften Eigenschaften dieses leckeren Leckerbissens zu genießen.',
  prompt22: 'Tauchen Sie ein in ein visuelles Festmahl und erkunden Sie einen surrealen Blumengarten, der vor lebendigen Farben und komplexen Details strotzt. Fangen Sie die faszinierende Essenz von Blüten mit sich kräuselnden, spiralförmigen Blütenblättern, bunten Blättern und anderen faszinierenden Farbtönen ein – jede einzelne ein Kunstwerk für sich. Nutzen Sie die Fließfähigkeit von Aquarell und die Ausdruckskraft von Tusche und verwenden Sie eine Nass-in-Nass-Technik, um ein Gefühl von Bewegung und Tiefe zu erzeugen. Vermitteln Sie die surreale, traumhafte Qualität dieses floralen Wunderlandes durch das Zusammenspiel von satten, intensiven Farben und der zarten, detaillierten Darstellung jedes Blütenblattes und Blattes.',
  prompt23: 'Gestalten Sie eine fesselnde Nachtszene-Illustration mit einem alten Leuchtturm, der hoch auf einer felsigen Insel steht. Der Leuchtturm, geschmückt mit mehreren Fenstern, strahlt ein warmes, einladendes Leuchten aus, das seine komplexe Architektur und seine reiche Geschichte hervorhebt. Die felsige Insel ist mit üppigen, grünen Bäumen bedeckt, deren Spiegelung auf dem ruhigen, mondbeschienenen Meereswasser tanzt, das die silberne Ausstrahlung des Vollmonds widerspiegelt. Die Himmelskugel ziert den Himmel und wirft einen ätherischen Schein auf die Wolken und das Meer, während zarte Wolken ihre Brillanz teilweise verdecken und zur Mystik der Szene beitragen. Funkelnde Sterne übersäen die tiefschwarze Weite und schaffen einen himmlischen Wandteppich, der mit der Ausstrahlung des Mondes harmoniert. Dieses bezaubernde Kunstwerk fängt eine ruhige und friedliche Nacht ein, in der der beleuchtete Leuchtturm inmitten der himmlischen Schönheit standhaft steht, als Wächter dient und dem Betrachter ein filmisches, traumhaftes Erlebnis vermittelt.',
  /// Tool list
  // ratio
  ratio: 'Verhältnis',
  square1_1: 'Quadrat (1:1)',
  square: 'Quadrat',
  portrait2_3: 'Hochformat (2:3)',
  portrait: 'Hochformat',
  landscape3_2: 'Querformat (3:2)',
  wide16_9: 'Breitbild (16:9)',
  wide: 'Breitbild',
  // style
  none: 'Keine',
  enhance: 'Verbessern',
  anime: 'Anime',
  photographic: 'Fotografie',
  digitalArt: 'Digitale Kunst',
  comicBook: 'Comic',
  fantasyArt: 'Fantasy-Kunst',
  analogFilm: 'Analogfilm',
  neonPunk: 'Neon punk',
  lowPoly: 'Low poly',
  origami: 'Origami',
  lineArt: 'Linienkunst',
  tileTexture: 'Kacheltextur',
  threeDModel: '3D-Modell',
  pixelArt: 'Pixelkunst',
  craftClay: 'Bastelton',
  // color
  coolTone: 'Kühler Ton',
  mutedColors: 'Gedämpfte Farben',
  pastelColors: 'Pastellfarben',
  vibrantColors: 'Lebendige Farben',
  warmTone: 'Warmer Ton',
  // lighting
  lighting: 'Beleuchtung',
  backlight: 'Gegenlicht',
  crepuscularRays: 'Dämmerungsstrahlen',
  dimlyLit: 'Gedämpft beleuchtet',
  goldenHour: 'Goldene Stunde',
  lowLight: 'Schwaches Licht',
  rimLighting: 'Streiflicht',
  studio: 'Studio',
  sunlight: 'Sonnenlicht',
  volumetric: 'Volumetrisch',
  // composition
  composition: 'Komposition',
  blurryBackground: 'Unscharfer Hintergrund',
  closeUp: 'Nahaufnahme',
  macrophotograph: 'Makrophotografie',
  narrowDepth: 'Geringe Tiefe',
  shotFromAbove: 'Von oben aufgenommen',
  shotFromBelow: 'Von unten aufgenommen',
  wideAngle: 'Weitwinkel',

  /// FQAs
  allAIImageQuestionsAnswered: 'Alle Ihre Fragen zu KI-generierten Bildern beantwortet.',
  TTI_Q1: 'Was sind KI-generierte Bilder?',
  TTI_A1:
    'KI-generierte Bilder sind Bilder, die mit Algorithmen der künstlichen Intelligenz (KI) erstellt werden. Diese Algorithmen sollen die menschliche Kreativität simulieren und können Bilder erzeugen, die denen von menschlichen Künstlern ähneln, aber einzigartige und oft surreale oder abstrakte Elemente enthalten.',
  TTI_Q2: 'Gibt es eine Grenze für die Anzahl der Bilder, die ich pro Tag erstellen kann?',
  TTI_A2:
    'Ja, es gibt ein Limit für die Anzahl der Bilder, die Sie täglich erstellen können. Das Limit stellt sicher, dass alle Nutzer einen fairen Zugriff und eine gute Nutzererfahrung beim Zugriff auf den KI-Bilddienst haben.',
  TTI_Q3: 'Unterstützen Sie Textaufforderungen in anderen Sprachen als Englisch?',
  TTI_A3: 'Im Moment unterstützen wir nur die englische Sprache.',
  TTI_Q4: 'Was ist der Unterschied zwischen der Erstellung und der Lizenzierung eines Bildes?',
  TTI_A4:
    'Wenn Sie ein Bild generieren, erstellen Sie einen Satz von KI-Bildern zur Ansicht. Wenn Sie sie bei Corporate+ lizenzieren, erhalten Sie offiziell Zugriff auf eine hochauflösende Version des KI-Bildes, das mit einer eigenen Nutzungs-Lizenz geliefert wird.',
  TTI_Q5:
    'Ein von mir erstelltes Bild enthält eine erkennbare Marke, ein Wahrzeichen oder eine öffentliche Persönlichkeit. Kann ich es trotzdem verwenden?',
  TTI_A5:
    'Wenn es eine erkennbare Person oder ein Objekt enthält oder hauptsächlich auf ein markantes Wahrzeichen abzielt, müssten Sie direkt die Erlaubnis von der im Bild dargestellten Partei einholen, besonders wenn Sie das Bild in einem kommerziellen Umfeld verwenden möchten.',
  TTI_Q6: 'Wer sind die Personen, die auf den generierten Bildern erscheinen?',
  TTI_A6:
    'Die Menschen auf den von der KI erzeugten Bildern sind keine echten Menschen, sondern digitale Kreationen.',
  TTI_Q7:
    'Was passiert mit den Inhalten, die ich erstellt habe? Werden sie anderen Nutzern zur Verfügung gestellt?',
  TTI_A7:
    'Die von Ihnen erstellten Inhalte können auch anderen Nutzern zur Verfügung gestellt werden. Weitere Informationen finden Sie in unserer Lizenz.',
  TTI_Q8:
    'Warum dauert es eine Weile, bis das Bild geladen ist, wenn ich versuche, ein Bild zu erzeugen?',
  TTI_A8:
    'Die Seite benötigt einige Sekunden, um das Bild zu erzeugen. Die benötigte Zeit hängt von der Komplexität der Eingabeaufforderung, den verfügbaren Hardware- und Computerressourcen sowie der Anzahl der Anfragen ab, die die KI gleichzeitig bearbeiten muss.',
  TTI_Q9: 'Wie lizenziere ich die von mir erstellten KI-Bilder?',
  TTI_A9:
    'Sie können die KI-Bilder, die Sie erstellt haben, lizenzieren, indem Sie sie aus Ihrem Corporate+-Konto herunterladen. Bei jedem Download erhalten Sie die hochauflösende Version des generierten Bildes und eine rechtliche Garantie von 25.000 $.',
  TTI_Q10: 'Kann ich mir frühere Versionen ansehen?',
  TTI_A10:
    'Ja, indem Sie die URL Ihrer vergangenen Versionen als Lesezeichen speichern und erneut aufrufen. Sie können die Ergebnisse auch mit Ihren Freunden teilen.',
  TTI_Q11: 'Wie generiere ich hochwertige Bilder mit der KI?',
  TTI_A11: `<ol>
              <li>Beginnen Sie mit einem klaren Ziel: "Hochwertiges Bild einer Berglandschaft für einen Reiseblog".</li>
              <li>Legen Sie den gewünschten Stil und die Stimmung fest: "Das Bild sollte hell und lebendig sein und ein natürliches, organisches Gefühl vermitteln".</li>
              <li>Beschreiben Sie das Thema: Machen Sie genaue Angaben zum Thema des Bildes, einschließlich Details wie Umgebung, Beleuchtung und andere wichtige Merkmale oder Elemente. Beispiel: "Zeigen Sie einen schneebedeckten Berggipfel mit einem Fluss im Vordergrund und einem klaren blauen Himmel im Hintergrund."</li>
              <li>Geben Sie Beispiele an: Geben Sie Beispiele für andere Bilder an, die einen ähnlichen Stil oder eine ähnliche Stimmung aufweisen wie das gesuchte Bild. Dies kann dem KI-Algorithmus als Orientierungshilfe dienen und sicherstellen, dass die Ausgabe Ihren Erwartungen entspricht.</li>
              <li>Verwenden Sie relevante Schlüsselwörter: "Berglandschaft", "schneebedeckter Gipfel" oder "hell und lebendig".</li>
              <li>Vermeiden Sie Zweideutigkeiten: Seien Sie so klar und spezifisch wie möglich, wenn Sie Anweisungen geben, und vermeiden Sie vage oder zweideutige Formulierungen, die zu unerwarteten Ergebnissen führen könnten.</li>
            </ol>`,

  /// Generating page
  bringingYourImagesToLife: 'Wir erwecken Ihre Bilder zum Leben...',
  cplusAiGenerator: 'KI Generator',
  generatedImages: 'Generierte Bilder',
  promptSettings: 'Eingabe-Einstellungen',
  pastGeneration: 'Vorherige Antworten',
  generateVariation: 'Variation generieren',
  iLikeThis: 'Mir gefällt das',
  iDislikeThis: 'Mir gefällt das nicht',
  shareOptions: 'Optionen zum Teilen',
  variations: 'Variationen',
  copiedSharedUrl: 'Geteilte URL in die Zwischenablage kopiert.',

  galleryPrompt1: 'Himmlische bescheidene Meerjungfrau, unter Wasser, mit Muschelkrone, umgeben von Fischen, Porträt',
  galleryPrompt2: 'Herzhafter Sonntagsbraten auf dem Tisch, voller Essen und Kerzen',
  galleryPrompt3: 'Niedlicher flauschiger Fuchs lächelnd in einem Wald, sehr detailliert, herausgezoomt mit anderen Waldtieren',
  galleryPrompt4: 'Hochwertig, 8K Ultra HD, musikalische Noten und Instrumentenformen in einem aus Kristall gefertigten Kürbis, hochdetailliert, magische Kürbislampe, Herbstgefühl, ruhig',
  galleryPrompt5: 'Schottische Panorama-Kinolandschaft, heller schöner Tag',
  galleryPrompt6: '3D-Stil kleines Mädchen, 4K, 8K, Octane-Render fotorealistisch, HDR, Fotografie, Hochdefinition, symmetrisches Gesicht, volumetrisches Licht, staubiger Dunst, Foto, Octane-Render, 24mm, 4K, 24mm, DSLR, hochwertig, 60 fps, ultra-realistisch',
  galleryPrompt7: 'Person of Colour-Mann, Held, der den Planeten Jupiter in den Weltraum stößt, nach vorne blickend',
  galleryPrompt8: 'Keks in warmem Kaffee, in einer weihnachtlichen Umgebung',
  galleryPrompt9: 'Künstlerische Wasserflasche auf einem weißen minimalistischen Hintergrund mit Montserra-Blättern',
  galleryPrompt10: 'Evaneszierender Diamant-Fantasiedrache',
  galleryPrompt11: 'Planeten in Tautropfen, planetarische Tautropfen, bei Sonnenaufgang, Nahaufnahme, hyperdetailliert, scharfer Fokus, Studiofoto, komplexe Details, sehr detailliert',
  galleryPrompt12: 'Eine Elster sitzt auf einer Truhe voller Gold, im Stil realistischer Fantasy-Kunst, Edelstein, dunkle Märchen, geheimnisvoll.',
  galleryPrompt13: 'Ein Berg aus Schnee und ein Eisweg auf dem Berg in die andere Richtung, ultra HD, realistisch, lebendige Farben, hochdetailliert, UHD-Zeichnung, Feder und Tinte, perfekte Komposition, schön detailliert, wahnsinnig detailliertes Octane-Rendering, trendig auf Artstation, 8k künstlerische Fotografie, fotorealistische Konzeptkunst, weiches natürliches volumetrisches kinematografisches perfektes Licht',
  galleryPrompt14: 'Ära der interstellaren Zivilisation, hochstehend und herabblickend auf den geheimnisvollen digitalen Raum: super-wissenschaftliche außerirdische Stadtgebäude, Exquisite schöne äußere Super-Wolkenkratzer, Straßen, Himmelsbrücken, im Himmel schwebende super-wissenschaftliche Architektur, Kriegsmaschinen, Hochtechnologie, unzählige elektronische Bildschirmlampen',
  galleryPrompt15: 'Wunderschöner Strand mit dem Ozean im Hintergrund in den Florida Keys bei Nacht, Neonfarben, schwarzer Hintergrund, ruhige Wellen, natürlicher Farbton, Sonnenuntergang, entspannendes Heranzoomen an Wellen, Strand, gerader Horizont, rosa, blau, orange, lila',
  galleryPrompt16: 'Beste Qualität, Doppelbelichtung, Mail-Kunst, Kunst auf gerissenem Papier, eine Nahaufnahme eines niedlichen spielenden Kätzchens mit einem Wollknäuel detaillierte gemütliche Zimmerinnenausstattung, glitzernd, Sonnenlicht, Sonnenstrahlen, dynamische Pose, Bilderbuchillustration, 2D, flach, süß, entzückend, Vintage, Märchen, Patchwork, Buntglas, detaillierte Bilderbuchillustration, kinematisch, ultra hochdetailliert, winzige Details, schöne Details, mystisch, lebendige Farben, komplexer Hintergrund',
  galleryPrompt17:
    'Pyramide auf dem Wasser mit Feuerreflexionen, Berge, dunkler Surrealismus, atmosphärische Porträts, scharfer Fokus',
  galleryPrompt18: 'Roter Panda im Wald, kinematisch',
  galleryPrompt19: 'Meer, Mond, Fisch, Sternenhimmel, Boot, bunte Illustration',
  galleryPrompt20:
    'Lebhafte städtische Reflexionen tanzen zwischen kühnen Gebäuden und schimmerndem Wasser im minimalistischem abstrakten Stil, einfangen der lebendigen Abendenergie, geometrische Abstraktion, zeitgenössischer Pop-Art-Stil mit Neonbeleuchtung und Acryl-Gießtechnik',
  galleryPrompt21:
    'Rückansicht einer Silhouettenfrau mit leuchtendem Schwert, kreisförmiges Portal in der Arktis, das zu Eisbergen führt, kinematische Nahaufnahme, monumentale Figur',

  /// error page
  copyrightDetected:
    'Urheberrechtlich geschützte Begriffe / Persönlichkeiten oder Warenzeichen erkannt',
  copyrightDetectedDesc:
    'Wir verpflichten uns, einen verantwortungsbewussten KI-Generator zur Verfügung zu stellen und werden keine Ergebnisse für urheberrechtlich geschützte Warenzeichen, Begriffe, Marken oder Persönlichkeiten generieren, die in den Prompts enthalten sind, um eine mögliche Verletzung zu verhindern. In unseren Nutzungsbedingungen ist festgelegt, dass Sie die Dienste NICHT in einer Weise nutzen dürfen, die gegen geistige Eigentumsrechte verstößt, diese unterschlägt oder verletzt. Bitte ändern Sie Ihren Prompt und versuchen Sie es erneut',
  errorMesTTI: 'Ups! Es tut uns leid, aber etwas ist schiefgelaufen.',
  errorMesTTIDesc:
    'Das Problem wurde zur Untersuchung protokolliert. Bitte versuchen Sie es später noch einmal.',
  tryAgain: 'Erneut versuchen',

  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',
  // download feature
  downloadQuotaFinished:
    'You have reached the maximum quota available. Please contact your system administrator.',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: 'Beeindruckende bilder finden. Suche nach Schlüsselwörtern oder Sätzen.',
  searchBarPlaceholderAllImageTablet: 'Beeindruckende bilder finden.',
  searchBarPlaceholderAllImageMobile: 'Finde bilder.',
  searchBarPlaceholderPhotoDesktop: 'Beeindruckende fotos finden. Suche nach Schlüsselwörtern oder Sätzen.',
  searchBarPlaceholderPhotoTablet: 'Beeindruckende fotos finden.',
  searchBarPlaceholderPhotoMobile: 'Finde fotos.',
  searchBarPlaceholderVectorDesktop: 'Beeindruckende vektorgrafiken finden. Suche nach Schlüsselwörtern oder Sätzen.',
  searchBarPlaceholderVectorTablet: 'Beeindruckende vektorgrafiken finden.',
  searchBarPlaceholderVectorMobile: 'Finde vektorgrafiken.',
  searchBarPlaceholderFootageDesktop: 'Beeindruckende video finden. Suche nach Schlüsselwörtern oder Sätzen.',
  searchBarPlaceholderFootageTablet: 'Beeindruckende video finden.',
  searchBarPlaceholderFootageMobile: 'Finde video.',
  searchBarPlaceholderAudioDesktop:
    'Beeindruckende audio- und musikdateien finden. Suche nach Schlüsselwörtern oder Sätzen.',
  searchBarPlaceholderAudioTablet: 'Beeindruckende audio- und musikdateien finden.',
  searchBarPlaceholderAudioMobile: 'Finde audio- und musikdateien.',
  searchBarPlaceholderFreeDesktop: 'Beeindruckende kostenlose bilder finden. Suche nach Schlüsselwörtern oder Sätzen.',
  searchBarPlaceholderFreeTablet: 'Beeindruckende kostenlose bilder finden.',
  searchBarPlaceholderFreeMobile: 'Finde kostenlose bilder.',
  // editorial warning
  forEditorialUseOnly: 'Nur zur redaktionellen Verwendung',
  forEditorialUseOnlyDesc:
    'Werblicher Einsatz dieses Bildes ist nicht gestattet, sofern nicht gesondere Rechteklärung erfolgt.',

  // download comp
  downloadHiResComp: 'HI-RES DATEI HERUNTERLADEN',
  downloadComp: 'LAYOUT-DATEI HERUNTERLADEN',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),

  textToImage: 'Text zu Bild',
  descTourTTI: 'Lassen Sie Ihrer Fantasie freien Lauf und erstellen Sie Bilder mit Text!',
  colorAndTone: 'Farbe und Ton',
  titleSuccessDownload: 'Wir freuen uns, dass Sie gefunden haben, was Sie brauchen.',
  hangInThere: 'Warten Sie! Es dauert nicht lange.',
  adultContentTitle: 'Potenzielle Begriffe mit Erwachseneninhalten erkannt',
  adultContentDesc:
    'Wir setzen uns für verantwortungsbewusste KI ein und generieren daher keine Ergebnisse für bestimmte Phrasen, Konzepte und/oder Ideen. Bitte überarbeiten Sie Ihre Eingabe (die Beschreibung, die Sie zur Generierung des Bildes bereitgestellt haben) und versuchen Sie es erneut.',
  reachedTheLimit:
    'Oh nein! Sie haben das Limit für die Erstellung von KI-Bildern für heute erreicht.',
  backTomorrow: 'Kommen Sie morgen wieder, um mehr zu generieren!',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder:
    'Um loszulegen, beschreiben Sie hier Ihre Idee und wir werden sie zum Leben erwecken!',

  downloadAgain: 'Download again',
  btnClose: 'Schliessen',

  fluxPro: 'Flux bietet fortschrittliche, hochwertige und vielfältige Bildgenerierung.',
  flux: 'Unser schnellstes Modell, speziell für die lokale Entwicklung konzipiert.',
  ideogram: 'Verwandelt eine Textbeschreibung in wenigen Sekunden in wunderschöne Bilder.',
  realStockPhoto: 'Erstellen Sie mühelos fesselnde Stockfotos mit unserem fortschrittlichen KI-Modell.',
  realCinematic: 'Ein Modell, das sich auf die Produktion von filmischen Ausgaben spezialisiert hat und bei breiteren Seitenverhältnissen überzeugt.',
  realFood: 'Verbessern Sie Ihre kulinarische Marke mit unserem Food-Visualisierungsmodell.',
  realMacro: 'Ein großartiges Modell zur Erstellung detaillierter Makrobilder.',
  realBokeh: 'Ein fotorealistisches Stilmodell, ideal für die Erstellung beeindruckender Bokeh-Bilder.',
  aiLicenseHeader: 'Diese Datei wurde von KI (Künstliche Intelligenz) generiert.',
  aiLicenseContent: 'Von künstlicher Intelligenz generierte Inhalte unterliegen nicht dem Standard-Konformitätsprüfungsprozess von 123RF. Es liegt in der Verantwortung des Erstellers sicherzustellen, dass solche KI-generierten Materialien keine Rechte an geistigem Eigentum oder andere Rechte Dritter verletzen. Die Produktion von Spam sowie jegliche Inhalte, die falsch, irreführend, schädlich, gewalttätig, pornografisch oder illegal sind, ist strengstens untersagt.',
  variousTitle: 'Entdecken Sie unsere verschiedenen Modelle',
  variousSDXL: 'Bringen Sie atemberaubende fotorealistische Menschen und Szenen zum Leben.',
  amazonNova: 'Ein hochmodernes Modell zur Bilderzeugung.',
  variousEimisAnime: 'KI-Modell für die Erstellung von Bildern im Anime-Stil.',
  variousDynaVisionXL: 'Erzeuge 3D-stilisierte Cartoon-Figuren.',
  variousJuggernautXL:
    'Mit Schwerpunkt auf architektonischen Elementen und Landschaften/Hintergründen.',
  variousDreamShaperXL:
    'Hochauflösendes Skalieren, verbesserte Charaktere und Drachengenerationen, reduzierte Kantenunschärfe.',
  variousRealismEngineSDXL:
    'Dieses Modell ist auf hochdetaillierte, realistische Bilder spezialisiert.',
  variousDALLE:
    'DALL-E 3, ein Allrounder, zeichnet sich durch eine präzise Umsetzung des Konzepts in visuelle Formen aus.',
  aiEngine: 'KI-Motor',
  'DALL-E': 'DALL-E 3',

  langText: 'Text',
  langElements: 'Elemente',
  langAuto: 'Auto',
  langBgRemoval: 'Hintergrund entfernen',
  resetAll: 'Alles Zurücksetzen',
  facebookImage: 'Facebook Bild',
  facebookLink: 'Facebook Link',
  facebookCover: 'Facebook Titelbild',
  facebookStory: 'Facebook Story',
  instagramPost: 'Instagram Beitrag',
  instagramStory: 'Instagram Story',
  twitterPost: 'Twitter Beitrag',
  twitterHeader: 'Twitter Titelbild',
  pinterestPost: 'Pinterest Beitrag',
  emailHeader: 'Email Kopfzeile',
  presentation: 'Präsentation',
  presentationWide: 'Präsentation breit',
  ebookCover: 'E-Book Titelbild',
  linkedinPost: 'LinkedIn Beitrag',
  brochureCover: 'Broschüre Cover (A4)',
  postcard: 'Postkarte',
  photoPrint: 'Fotoausdruck',
  youtubeProfileImage: 'Youtube Profilbild',
  youtubeChannelCoverPhoto: 'Youtube Kanal Titelbild',
  langApplyBgRemoval: 'Hintergrundentfernung anwenden',
  autoBnW: 'Auto S/W',
  autoAdjust: 'Automatisch anpassen',
  autoPop: 'Auto Pop',
  original: 'Original',
  addHeader: 'Kopfzeile hinzufügen',
  addTitle: 'Kopfzeile hinzufügen',
  addBody: 'Fließtext hinzufügen',
  addAssistive: 'Hilfstext hinzufügen',
  langCompare: 'Vergleichen',
  langEnableEditor: 'Bild bearbeiten',
  langSimilarStock: 'Ähnliche Stockbilder',
  langTextUndo: 'Rückgängig',
  langTextRedo: 'Wiederholen',
  langTextFont: 'Schriftart',
  langTextFontSize: 'Schriftgröße',
  langTextColor: 'Textfarbe',
  langTextHighlight: 'Hervorheben',
  langTextAlignment: 'Ausrichtung',
  langTextBold: 'Fett',
  langTextItalic: 'Kursiv',
  langTextUnderline: 'Unterstrichen',
  langTextLineSpacing: 'Zeilenabstand',
  langTextLetterSpacing: 'Abstand zwischen den Buchstaben',
  langBringForward: 'Nach vorne bringen',
  langBringBackward: 'Nach hinten verschieben',
  langDuplicate: 'Duplizieren',
  langOpacity: 'Deckkraft',
  langDelete: 'Löschen',
  imageProperties: 'Bildeigenschaften',
  showMore: 'Mehr anzeigen',
  showLess: 'Weniger anzeigen',
  noEditCompareMsg: 'Es wurden keine Bearbeitungen zum Vergleichen vorgenommen.',
  stockPhoto: 'Stockfoto',
  basicShape: 'Basic Formen',
  chitChat: 'Benachrichtigungen',
  shapes: 'Formen',
  elementChitChat: 'Chat Elemente',
  organicAbstractShapes: 'Abstrakte Formen',
  elementShapes: 'Elemente',
  langApplyingEdit: 'Bearbeitungen anwenden..',
  langTextAllFonts: 'Alle Schriftarten',
  langSearchFontType: 'Schriftart suchen',
  editorCompareImageBeforeTitle: 'Vorher',
  editorCompareImageAfterTitle: 'Nachher',
  langSizePx:
    'Ein Pixel ist die Grundeinheit eines digitalen Bildes. Die in Pixeln gemessene Bildgröße bestimmt den Detailgrad und die Downloadgröße.',
  langSizeDpi:
    'Punkte pro Zoll messen die Druckauflösung. Eine höhere dpi bedeutet mehr Detail. Für den Druck ist die empfohlene Auflösung für alle Bilder 300 dpi.',
  cautionTTI:
    'Vorsicht: Wir haben die Verwendung von Ausdrücken in der Aufforderung erkannt, die mit Markenzeichen, Urheberrechten, berühmten Wahrzeichen und/oder Personen verbunden sind. Beachten Sie, dass Sie allein für die Verwendung des Inhalts verantwortlich sind, einschließlich der Gewährleistung, dass diese keine geltenden Gesetze verletzt oder Rechte verletzt.',

  addImageReference: 'Bildreferenz hinzufügen (Optional)',
  uploadImageOnly: 'Nur JPG oder PNG hochladen. Max. 4MB.',
  uploadJPGImageOnly: 'Nur JPG hochladen. Max. 4MB.',
  dragAndDrop: 'Datei per Drag-and-Drop oder',
  errorFileSize: 'Diese Datei ist zu groß. Maximal 4MB erlaubt.',
  adjustScale: 'Passen Sie diese Skala an, um sehr ähnliche oder unterschiedliche Ergebnisse von Ihrem hochgeladenen Bild zu erzeugen. Der empfohlene Bereich liegt zwischen 7 und 15.',
  similar: 'Ähnlich',
  different: 'Anders',
  uploadNew: 'Erneut hochladen',

  imageGuideGeneration: 'Ihr Bild wird Ihre Eingaben bei der Erstellung leiten.',
  notAvailableForEngine: 'Nicht verfügbar für ausgewählte KI-Engines.',

  imagePromptDescribe: 'Um zu beginnen, geben Sie einen Prompt für das Bild an, das unsere KI generieren soll, indem Sie beschreiben, was Sie im Sinn haben.',
  fileTypeNotSp: 'Der Dateityp wird nicht unterstützt.',
  theFileSizeTooSmall: 'Die Dateigröße ist zu klein. Mindestens 512px erforderlich.',

  relatedSearchPhoto: 'Ähnliche Suchergebnisse',
  STOCK_PHOTO_PATH: 'lizenzfreie-bilder',
  STOCK_FOOTAGE_PATH: 'stock-video',
  STOCK_AUDIO_PATH: 'stock-audio',
  CLIPART_VECTOR_PATH: 'clipart-vektorgrafiken',
  ALL_IMAGES: 'Alle',
  PHOTO: 'Photo',
  PHOTOS: 'Fotos',
  VECTORS: 'Vektorgrafiken',
  FOOTAGE: 'Video',
  AUDIO: 'Audio',
  LANG_MORETHANMILLION: 'lizenzfreie Bilder und Videos',
  LANG_SVG_OR_EPS: 'SVG oder EPS',
  LANG_OR: 'oder',
  STANDARD_LICENSE: 'Standardlizenz',
  EXTENDED_LICENSE: 'Erweiterte Lizenz',
  CREDIT_PACK: 'Credit-Paket',
  DOWNLOAD_PACK: 'Download-Pakete',
  SUBSCRIPTION_PLAN: 'Abonnement',
  LANG_DOWNLOAD: 'Weiter',
  STOCKPHOTO: 'Standard-Bild',
  STOCK_PHOTO: 'Stockfoto',
  STOCK_VECTOR: 'Vektorgrafik',
  PREMIUM_STOCKPHOTO: 'PREMIUM Stock Bilder',
  PLUS_STOCK_PHOTO: 'PLUS Stockbilder',
  PLUS_STOCK_VECTOR: 'PLUS Stockvektoren',
  MOBILE_STOCKPHOTO: 'Mobile Stock Photo',
  STOCKFOOTAGE: 'Videos',
  IMAGEID: 'Dateinummer',
  FONTID: 'ID',
  MEDIATYPE: 'Medien-typ',
  COPYRIGHT_TEXT: 'Urheber',
  KEYWORDS: 'Suchbegriff',
  LANG_CANCEL: 'Abbrechen',
  LANG_PURCHASE: 'Einkaufen',
  LANG_STOCKVECTORSANDILLUS: 'Vektorengrafiken und Illustrationen',
  LANG_STOCKPHOTOSANDIMAGES: 'Stock-Fotos und Bilder',
  LANG_MATCHES: 'Treffer',
  LANG_VECTORRESULTS: '{number} {keyword} lizenzgebührenfreie Vektorbilder für Sie gefunden.',
  LANG_PHOTORESULTS: '{number} {keyword} lizenzgebührenfreie Stockfotos und Bilder für Sie gefunden.',
  LANG_SIMILARIMGS: 'Ähnliche lizenzgebührenfreie Fotos',
  LANG_COLLECTIONS: 'Kollektion',
  LANG_ALL: 'Alle',
  LANG_STD: 'Standard',
  LANG_EXTD: 'Erweiterte',
  LANG_COMMERCIAL: 'Kommerziell',
  LANG_SUBSCRIPTION: 'Abonnement',
  LANG_EDITORIAL: 'Redaktionell',
  LANG_UPLOADED: 'Hochgeladen',
  LANG_FRESHNESS: 'Veröffentlichung',
  LANG_ANYTIME: 'Jederzeit',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: '< 2 Tage',
  LANG_PASTWEEK: 'Letzte Woche',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/Monat",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/Jahr",
  LANG_PASTMONTH: 'Letzter Monat',
  LANG_PAST3MONTHS: '3 Monate',
  LANG_NUMOFPPL: 'Anzahl der Personen',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: 'Sie haben die Bestätigungs-Email nicht erhalten?',
  LANG_WITHOUTPEOPLE: 'Ohne Personen',
  LANG_PERSON: 'Person',
  LANG_PEOPLE: 'Personen',
  LANG_INFANTS: 'Babys',
  LANG_TODDLERS: 'Kleinkinder',
  LANG_TEENS: 'Teenager',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: 'Kaukasisch',
  LANG_BLACK: 'Schwarz',
  LANG_HISPANIC: 'Hispanoamerikanisch',
  LANG_EAST_ASIAN: 'Ostasiatisch',
  LANG_SOUTHEAST_ASIAN: 'Südostasiatisch',
  LANG_INDIAN: 'Indisch',
  LANG_MIDDLE_EASTERN: 'Mittlerer Osten',
  LANG_AGE: 'Alter',
  LANG_ALL_SHAPE: 'Alles anzeigen',
  LANG_ETHNICITY: 'Ethnizität',
  LANG_PEOPLEORMORE: ' ab 4 Personen',
  LANG_COLOR: 'Farbe',
  LANG_OTHEROPTION: 'Weitere Filter',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: 'Einfarbige/transparente Hintergründe',
  LANG_FILTER_BY: 'Filtern nach',
  LANG_FILTER: 'Filter',
  LANG_PHOTOGRAPHY: 'Fotografie',
  LANG_VECTORILLUST: 'Vektor-Illustrationen',
  LANG_SORTBY: 'Sortieren nach',
  LANG_MORE: 'Mehr',
  LANG_RELEVANCE: 'Relevanz',
  LANG_RESEND: 'Erneut senden',
  LANG_RESEND_VERIFICATION: 'Bestätigungs-Email erneut senden',
  LANG_NEWEST: 'Neu',
  LANG_GEORANK: 'Georank',
  LANG_ORIENTATION: 'Ausrichtung',
  LANG_HORIZONTAL: 'Horizontal',
  LANG_PORTRAIT: 'Hochformat',
  LANG_SQUARE: 'Quadratisch',
  LANG_PANORAMA: 'Panorama',
  LANG_SELECTIVE_FOCUS: 'Selektiver Fokus',
  LANG_PATTERN: 'Muster',
  LANG_VIBRANCE: 'Lebendigkeit',
  LANG_VERIFICATION_DESCRIPTION: 'Bevor Sie Inhalte von 123RF herunterladen können, müssen Sie Ihr Konto verifizieren. Bitte überprüfen Sie Ihre E-Mails oder klicken Sie auf den unten stehenden Link, wenn Sie sie nochmals übermitteln möchten.',
  LANG_VERIFICATION_TITLE: 'Bitte überprüfen Sie Ihre E -Mails.',
  VERIFICATIONRESETUNABLE: 'Ups! Ihre E-Mail konnte nicht gesendet werden. Bitte kontaktieren ',
  LANG_CHOOSEUPTO3COLORS: 'Maximal 3 Farben',
  LANG_ISOLATED: 'Freistehend',
  LANG_STYLE: 'Stil',
  LANG_SAFESEARCH: 'Sichere Suche',
  LANG_ONLY_AUTHENTIC: 'Nur authentisch',
  LANG_EXCLUDE_PLACEHOLDER: 'z. B. Käse',
  LANG_EXCLUDEWORDS: 'Diese Wörter dürfen nicht enthalten sein',
  LANG_EXCLUDE: 'Ausschliessen',
  LANG_INCLUDE_AICONTENT: 'KI-generierte Inhalten einbeziehen',
  VISUAL_SEARCH: 'Optische Suche',
  VISUAL_SEARCH_TOOLTIP_TITLE: '3 einfacher Weg, um eine Suche innerhalb eines Bildes anzupassen!',
  VISUAL_SEARCH_TOOLTIP_INFO_1: "1. Klicken Sie auf einen der Punkte im Bild, um ihn als 'Suchbegriff' zu nutzen.",
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2. Ziehen Sie das Funktionsfeld Zuschneiden, um den Suchbereich zu vergrößern oder zu verkleinern.',
  VISUAL_SEARCH_TOOLTIP_INFO_3: "3. Klicken Sie auf 'X', um zur Suche zurückzukehren.",
  VISUAL_SEARCH_NO_RESULTS: 'Ihre Suche ergab keine Ergebnisse.',
  LANG_RIS_TITLE: 'Rückwärtssuche',
  LANG_RIS_NOTFOUND: 'Kein Ergebnis gefunden!',
  LANG_RIS_ERROR_HEADER: 'Hochgeladenes Bild enthält Fehler, Stellen Sie sicher, dass das Bild die folgenden Kriterien erfüllt:',
  LANG_RIS_REFINE_SRCH_MSG: 'Überprüfen Sie Ihre verfeinerte Suche einem Suchbegriff',
  LANG_RIS_UPLOADED_IMAGE: 'Hochgeladenes Bild',
  LANG_TELL_ME_MORE: 'Erzähl mir mehr',
  LANG_ENTERKEYWORD: 'Bitte geben Sie ein Stichwort ein',
  LANG_SIZE: 'Größe',
  LANG_STANDARD_SIZE: 'Standard Lizenz',
  LANG_EXTENDED_SIZE: 'ERWEITERTE GRÖSSEN',
  LANG_SIZE_PX: 'Ein Pixel ist die Grundeinheit eines digitalen Bildes. Die in Pixeln gemessene Bildgröße bestimmt den Detailgrad und die Downloadgröße.',
  LANG_SIZE_DPI: 'Punkte pro Zoll messen die Druckauflösung. Eine höhere dpi bedeutet mehr Detail. Für den Druck ist die empfohlene Auflösung für alle Bilder 300 dpi.',
  LANG_SOCMED_SIZE: 'Social Medien Größen',
  LANG_COMPARE: 'Vergleichen',
  LANG_AUTO_ENHANCE: 'Auto-Vergrößerung',
  LANG_AUTO: 'Auto',
  LANG_BG_REMOVAL: 'Hintergrund entfernen',
  LANG_TEXT: 'Text',
  LANG_ELEMENTS: 'Elemente',
  LANG_IMAGE_INFO: 'Bildinformation',
  LANG_FOLLOW: 'Folgen',
  LANG_FOLLOWING: 'Following',
  LANG_FOLLOWED: 'Gefolgt',
  FACEBOOK_IMAGE: 'Facebook Bild',
  FACEBOOK_LINK: 'Facebook Link',
  FACEBOOK_COVER: 'Facebook Titelbild',
  FACEBOOK_STORY: 'Facebook Story',
  INSTAGRAM_POST: 'Instagram Beitrag',
  INSTAGRAM_STORY: 'Instagram Story',
  TWITTER_POST: 'Twitter Beitrag',
  TWITTER_HEADER: 'Twitter Titelbild',
  PINTEREST_POST: 'Pinterest Beitrag',
  EMAIL_HEADER: 'Email Kopfzeile',
  PRESENTATION: 'Präsentation',
  PRESENTATION_WIDE: 'Präsentation breit',
  EBOOK_COVER: 'E-Book Titelbild',
  LINKEDIN_POST: 'LinkedIn Beitrag',
  BROCHURE_COVER: 'Broschüre Cover (A4)',
  POSTCARD: 'Postkarte',
  PHOTO_PRINT: 'Fotoausdruck',
  YOUTUBE_PROFILE_IMAGE: 'Youtube Profilbild',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'Youtube Kanal Titelbild',
  AUTO_COLOR: 'Auto-Farbe',
  BALANCE: 'Balance',
  AUTO_LEVEL: 'Auto-Tonwertkorrektur',
  AUTO_TONE: 'Auto-Tönung',
  ENRICH: 'Aufhellen',
  RESET: 'Zurücksetzen',
  RESET_ALL: 'Alles Zurücksetzen',
  FILE_DOWNLOAD_OPTIONS: 'Download Optionen',
  FILE_DOWNLOAD_INITIATED: 'Datei Download gestartet',
  IMAGE_PROPERTIES: 'Bildeigenschaften',
  DOWNLOAD_DETAILS: 'Downloadeigenschaften',
  FILE_SIZE: 'Bildgröße',
  LICENSE: 'Lizenz',
  FILEFORMAT: 'Dateityp',
  CHANGE: 'Ändern',
  PRICING: 'Preise',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: 'Speichern als',
  IMAGE_USAGE: 'Bildverwendung',
  PRINT: 'Druck',
  ELECTRONIC: 'Elektronische Medien',
  COMPREHENSIVE: 'Umfassend',
  DOWNLOAD_CREDITS: '<b>{quota}</b> Credits aus Ihrem Credit Paket',
  BASIC_SHAPE: 'Basic Formen',
  CHIT_CHAT: 'Benachrichtigungen',
  SHAPES: 'Formen',
  ELEMENT_CHIT_CHAT: 'Chat Elemente',
  ORGANIC_ABSTRACT_SHAPES: 'Abstrakte Formen',
  ELEMENT_SHAPES: 'Elemente',
  BUY_SINGLE_IMAGE: 'Einzelbild kaufen <br/><b>{quota} credits</b>',
  BUY_SINGLE_IMAGE_CREDIT: 'Creditpaket kaufen <br/><b>{quota} credits</b>',
  BUY_CREDIT_PACK: 'Creditpaket kaufen <br/><b>und sparen</b>',
  BUY_CREDIT_PACK_POINT_1: '{quota} Credits für <b>{price}</b>',
  BUY_CREDIT_PACK_POINT_2: 'Keine monatliche Verpflichtung.',
  BUY_CREDIT_PACK_POINT_3: '<b>1 Jahr</b> gültig.',
  BUY_SINGLE_IMAGE_PSUB: 'Einzelbild kaufen <br/><b>{quota} Einzel-Download</b>',
  BUY_DOWNLOAD_PACK: 'Credits für Bilder kaufen und <br/><b>Geld sparen</b>',
  BUY_DOWNLOAD_PACK_POINT_1: '{quota} Bilder im Download-Paket für nur <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: 'Keine monatliche Verpflichtung.',
  BUY_DOWNLOAD_PACK_POINT_3: '<b>1 Jahr</b> gültig.',
  LOWEST_PRICE: '<b>Günstigster Bildpreis</b> <br/>mit Abonnement',
  LOWEST_PRICE_POINT_1: '1 Monat für <b>{price}</b> testen',
  LOWEST_PRICE_POINT_2: '10 Bilder oder Illstrationen.',
  LOWEST_PRICE_POINT_3: 'Kein tägliches Download-Limit, unbenutzte Downloads werden auf den nächsten Monat übertragen.',
  PER_IMAGE_SIZE: 'Bild in <b>{size} Größe</b>',
  PER_IMAGE_ANY: 'pro Bild - <b>alle Größen</b>',
  PER_IMAGE: 'pro Bild',
  VIEW_PRICING: 'Weiter Produkte',
  DOWNLOAD_BEGIN: 'Ihr Download beginnt in einem Moment. Bitte warten Sie.',
  DOWNLOAD_NOT_STARTING: 'Der Download hat noch nicht begonnen?',
  SELECT_MIRROR: '[Alternativen Download-Server nutzen]',
  SHOW_MORE: 'Mehr anzeigen',
  SHOW_LESS: 'Weniger anzeigen',
  LICENSE_TYPE: 'Lizenz-typ',
  LICENSE_SUMMARY: 'Lizenzübersicht',
  BACK: 'Zurück',
  LANG_VECTOR: 'Vektor',
  PREMIUM_VECTOR: 'PREMIUM Stock Vektoren',
  SCALE_ANY_SIZE: 'Vektorformat, frei skalierbar',
  LANDINGPAGE_VECTOR_HEADLINE: 'Vektorgrafik, Cliparts und Illustrationen - Bildagentur 123RF',
  LANDINGPAGE_PHOTO_HEADLINE: 'Lizenzfreie Bilder, Stockfotos & Fotos Kaufen - Bildagentur 123RF',
  CATEGORIES: 'Kategorien',
  LANDINGPAGE_PRICING_1: 'Credits',
  LANDINGPAGE_PRICING_2: 'Abonnieren',
  LANDINGPAGE_AS_LOW_AS: 'Fuer den unglaublichen Preis von',
  EMPTY_SEARCH_TITLE: 'Ups, Ihre Suche hat keine Ergebnisse geliefert.',
  LANG_SMALL: 'Klein',
  LANG_MEDIUM: 'Mittel',
  LANG_LARGE: 'Groß',
  LANG_EXTRA_LARGE: 'Sehr groß',
  LANG_LOW_CREDITS: 'Sieht aus, als ob Sie nicht genügend Credits zur Verfügung hätten!',
  LANG_TOPUP: 'Top up',
  LANG_TOPUP_NOW: 'Jetzt Credits aufladen',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description} Lizenzfreie Fotos, Bilder und Stock Fotografie. Image {mediaId}.',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} Lizenzfrei nutzbare SVG, Vektorgrafiken, Clip Arts, Illustrationen. Image {mediaId}.',
  SEO_DETAILS_METADESC_STOCKPHOTO: 'Bilder zu {description} Lizenzfreie Fotos. Image {mediaId}.',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: 'Illustrationen zu {description} als Vektoren und Clipart. Image {mediaId}.',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} Kostenloses Bild und Foto {mediaId}.',
  SEO_DETAILS_METADESC_FREE_PHOTO: 'Kostenloses Bild von {description}. Kostenloses Bild {mediaId}.',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} Kostenlose Vektoren und Grafiken {mediaId}.',
  SEO_DETAILS_METADESC_FREE_VECTOR: 'Kostenlose Illustration von {description}. Kostenlose Vektorgrafik {mediaId}.',
  SEO_DETAILS_OGDESCRIPTION: '123RF - Millionen von kreativen Fotos, Illustrationen, Videos und Audiodateien fï¿½r Ihre Inspiration und Projekte.',
  LIVECHAT_LOOKINGHELP: 'Können wir Ihnen dabei helfen?',
  LIVECHAT_CHATWITHUS: 'Sprechen Sie mit uns!',
  FREE_TRIAL: '<b>Get 10 Free Images</b> <br/>with Subscription Plan',
  LANG_RELATEDSEARCH: 'Verwandte Suchanfragen',
  LANG_RELATED_STOCKPHOTO_SEARCH: 'Ähnliche Suchergebnisse',
  LANG_PROPERATTRIBUTE: 'Hier finden Sie die Angabe des Urhebers: ',
  LANG_ATTRIBUTEDESC: 'Sie können den Urheber der Datei angeben, indem Sie die unten stehende Zeile in einem beliebigen Bereich Ihres Produkts, Ihrer Website oder Ihres Werbematerials platzieren',
  LANG_ATTRIBUTE_TITLE: 'Die Angabe des Anbieters ist optional.',
  LANG_ATTRIBUTE_CREDIT: 'Wenn Sie den Anbieter nennen möchten, verwenden Sie bitte die unten stehende Zeile für die Angabe:',
  LANG_ATTRIBUTE_COPYLINK: 'Link kopieren',
  LANG_ATTRIBUTE_COPYTEXT: 'Text kopieren',
  LANG_LICENSE_AGREEMENT: '<a href={url}><b>Hier</b></a> finden Sie die Lizenzvereinbarung für diesen Download',
  LANG_NEXTPAGE: 'Weiter',
  LANG_IMAGE_NO_AVAILABLE: 'Das Bild #{mediaId} ist nicht mehr verfügbar.',
  LANG_PHOTOGRAPHY_METAIMG_DESC: 'Entdecken Sie bei der Bildagentur 123RF lizenzfreie Bilder und hochauflösende Bilder und kaufen Sie Fotos zum bezahlbaren Preis. Bilder und andere Inhalte einmal kaufen und immer verwenden.',
  LANG_VECTOR_METAIMG_DESC: 'Entdecken Sie für Ihr Projekt die hochwertige und bezahlbare Vektorgrafik, Illustrationen, lizenzfreie Grafiken, Cliparts und vieles mehr bei der Bildagentur 123RF. Illustrationen und andere Inhalte einmal kaufen und immer verwenden.',
  LANG_ANOTHERMETAKWDEFAULT: 'lizenzfreie bilder, bilder kaufen, bilddatenbank, fotos kaufen, bildagentur, bildagenturen, stockfotos, stockfotografie, hochauflösende bilder, illustrationen, royalty free vektor, grafiken, cliparts, illustrationen kaufen, vektorgrafik kaufen, vektorgrafiken, icon kaufen, videoclips, rf bilder, fotoarchiv, gema-freie musik',
  LANG_ANOTHERMETADESCDEFAULT: 'Bei der Bildagentur 123RF gibt es Millionen lizenzfreie Fotos, Illustrationen, Vektorgrafiken und Videos zu kaufen. Wir lieben Bilder!',
  EMPTY_FILTERED_RESULT_TITLE: 'Ups, Ihre Suche mit diesen Filtern hat keine Ergebnisse geliefert.',
  EMPTY_FILTERED_RESULT_SUBTITLE: 'Versuchen Sie, einige Filter zu entfernen oder einen anderen Suchbegriff zu wählen.',
  CLEAR_ALL_FILTER: 'Alle Filter deaktivieren',
  PROMOBAR_FREE10_TEXT: 'Get <b>10 FREE Images</b> when you get started on our 1 Month-Free Trial.',
  PROMOBAR_FREE10_BTNLABEL: 'START NOW',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: 'Hoher Preisnachlass auf alle Abonnements.',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: 'Pakete ansehen',
  LANG_MODEL_RELEASED: 'Model freigegeben',
  LANG_PROPERTY_RELEASED: 'Eigentum freigegeben',
  LANG_MODEL_RELEASE: 'mit Modellfreigabe',
  LANG_PROPERTY_RELEASE: 'mit Eigentümerfreigabe',
  LANG_YES: 'Ja',
  LANG_NO: 'Nein',
  LANG_MREXPLAIN: 'Eine Model-Freigabe ist ein Dokument, in dem das Model dem Fotografen bestätigt, das die Bilder veröffentlicht und gemäß den Lizenzbedingungen vertrieben werden dürfen.',
  LANG_PREXPLAIN: 'Ein Eigentümerfreigabe ist ein Dokument, in dem der Eigentümer eines Gebäudes dem Fotografen bestätigt, dass die Bilder veröffentlicht gemäß den Lizenzbedingungen vertrieben werden dürfen.',
  LANG_DOWNLOADS: 'Downloads',
  LANG_OLDFILENAME: 'Alter Dateiname',
  LANG_DOWNLOAD_PREVIEW: 'Layoutdatei speichern',
  LANG_SHARE: 'Social',
  LANG_RESTRICTIONS: 'Einschränkungen',
  LANG_READ_MORE: 'Mehr lesen',
  LANG_EDITORIAL_USE: 'Nur zur redaktionellen Verwendung',
  LANG_EDITORIALDESCP: 'Nur zur redaktionellen Verwendung: Diese Datei kann ausschließlich für redaktionelle Zwecke verwendet werden. Die Verwendung für alle werblichen, kommerziellen und promotionalen Zwecke ist untersagt und bedarf einer zusätzlichen Vereinbarung, die jedoch nicht von 123RF besorgt werden kann.',
  LANG_LOGO_OWNERSHIP: 'Logo-Urheberrechte',
  LANG_PHOTO_SHOT_SMARTPHONE: 'Dieses Foto wurde mit einem Smartphone aufgenommen.',
  LANG_VECTORNOLOGO: 'If you wish to use this as your company logo, we may arrange for a <b>rights buy out</b> on your behalf. Please contact us at <b>{phonenum}</b> to find out more!',
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> Downloads aus Ihrem Abonnements',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> Downloads aus Ihrem Download-Pakete',
  LANG_LOGO: 'Logo',
  USING_OLDER_BROWSER: 'Verwenden Sie einen älteren Browser?',
  UPGRADE_NEWER_BROWSER: 'Aktualisieren Sie auf eine neuere Browserversion für eine reibungslosere Nutzung.',
  CLICK_TO_DOWNLOAD: 'Klicken Sie hier, um diese herunterzuladen:',
  LANG_VECTOR_EDITABLE: 'Editierbare Dateien im SVG und EPS Format',
  PROMOBAR_GOOD15_TEXT1: 'Mehr herunterladen, weniger zahlen. ',
  PROMOBAR_GOOD15_TEXT2: 'Erhalten Sie 15 % auf Abonnements mit dem Code:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: 'Mehr herunterladen, weniger zahlen. ',
  PROMOBAR_SUMMER25_TEXT2: 'Erhalten Sie 25 % auf Abonnements mit dem <b>Code</b>:',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: 'Erhalten Sie 30 % auf Abonnements mit dem <b>Code</b>:',
  NO_EDIT_COMPARE_MSG: 'Es wurden keine Bearbeitungen zum Vergleichen vorgenommen.',
  SWITCH_TO_VECTOR_MSG: 'Bei Bildern im Vektorformat können keine foto-editor verwendet werden. Alle Bearbeitungen gehen verloren, wenn Sie zum Vektorformat wechseln.',
  SWITCH_TO_XL_MSG: 'Bei Bildern im XL-Format können keine Fotoeditor verwendet werden. Alle Bearbeitungen gehen verloren, wenn Sie zum XL-Format wechseln.',
  UNAVAILABLE_FOR_THIS_SIZE: 'Nicht verfügbar für Größe {size}.',
  SWITCH: 'WECHSELN',
  SWITCH_LICENSE_EXT_MSG: 'Möchten Sie dieses Bild zuerst als {type} herunterladen? Wenn Sie zur erweiterten Lizenz wechseln, gehen alle Bearbeitungen verloren.',
  LANG_SESSION_EXPIRED: 'Ihre Sitzung ist abgelaufen. Bitte <a href={url}>hier anmelden</a>',
  LANG_TEXT_COLOR: 'Textfarbe',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: 'Hervorheben',
  LANG_TEXT_ALIGNMENT: 'Ausrichtung',
  LANG_TEXT_BOLD: 'Fett',
  LANG_TEXT_ITALIC: 'Kursiv',
  LANG_TEXT_UNDERLINE: 'Unterstrichen',
  LANG_TEXT_BULLET: 'Aufzählungszeichen',
  LANG_TEXT_LINE_SPACING: 'Zeilenabstand',
  LANG_TEXT_LETTER_SPACING: 'Abstand zwischen den Buchstaben',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: 'Nach vorne bringen',
  LANG_BRING_BACKWARD: 'Nach hinten verschieben',
  LANG_DUPLICATE: 'Duplizieren',
  LANG_OPACITY: 'Deckkraft',
  LANG_DELETE: 'Löschen',
  LANG_TEXT_ADD: 'Text hinzufügen',
  LANG_TEXT_EDIT: 'Text bearbeiten',
  LANG_TEXT_FONT_TYPE: 'Schriftart',
  LANG_TEXT_FONT_SIZE: 'Schriftgröße',
  LANG_TEXT_ALL_FONTS: 'Alle Schriftarten',
  LANG_TEXT_UNDO: 'Rückgängig',
  LANG_TEXT_REDO: 'Wiederholen',
  LANG_TEXT_FONT: 'Schriftart',
  LANG_SEARCH_FONT_TYPE: 'Schriftart suchen',
  LANG_SEARCH_SHAPE_TYPE: 'Elemente suchen',
  LANG_DONE: 'Erledigt',
  ADD_HEADER: 'Kopfzeile hinzufügen',
  ADD_TITLE: 'Kopfzeile hinzufügen',
  ADD_BODY: 'Fließtext hinzufügen',
  ADD_ASSISTIVE: 'Hilfstext hinzufügen',
  STOCK_PHOTO_BANNER_TITLE: 'Millionen Lizenzfreier Stockbilder für Ihre Projekte!',
  STOCK_PHOTO_BANNER_DESC: 'Entdecken Sie atemberaubende Bilder, um Ihre Ideen zu gestalten und zum Leben zu erwecken.',
  STOCK_PHOTO_SUBSCRIBE_TITLE: 'Visualisieren Sie Ihre Geschichten mit den passenden Bildern',
  STOCK_PHOTO_SUBSCRIBE_DESC: 'Erhalten Sie sofortigen Zugriff auf über 180 Millionen Bilder, um Ihre Geschichten zu erzählen. Unsere Bilder und Ihre Geschichte - eine perfekte Kombination.',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: 'Credits für Bilder',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: 'Jetzt noch günstiger',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: 'Abonnement',
  STOCK_PHOTO_CARD_IMAGE: 'Bild',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: 'Jetzt kaufen',
  STOCK_PHOTO_CARD_BUY_NOW: 'Jetzt kaufen',
  STOCK_PHOTO_POPULAR_ROYALTY: 'Beliebte Bilder Kategorien',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: 'Entdecken Sie attraktive Tarife und Preise für Fotos',
  STOCK_PHOTO_DISCOVER_NOW: 'Jetzt ansehen',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: 'Landschaft',
  STOCK_PHOTO_BABIES: 'Baby',
  STOCK_PHOTO_BUSINESS: 'Unternehmen',
  STOCK_PHOTO_FOOD: 'Essen',
  STOCK_PHOTO_ANIMALS: 'Tiere',
  STOCK_PHOTO_TECHNOLOGY: 'Technologie',
  STOCK_PHOTO_HEALTHCARE: 'Gesundheit',
  STOCK_PHOTO_NATURE: 'Natur',
  STOCK_PHOTO_ROMANCE: 'Romantik',
  CLIPART_VECTOR_EXPLORE_MILLIONS: 'Erstellen Sie großartige Werke mit Millionen von lizenzfreien Vektoren, die Ihnen sofort zur Verfügung stehen.',
  CLIPART_VECTOR_NEED_AN_EYE: 'Finden Sie coole Illustrationen, Infografiken, Cliparts, Symbole und vieles mehr. Setzen Sie jetzt Ihre Ideen um.',
  CLIPART_VECTOR_FIND_VECTOR: 'Entdecken Sie Vektordateien, die Ihre Projekte aufwerten.',
  CLIPART_VECTOR_FIND_VECTOR_DESC: 'Ob coole Infografiken, Illustrationen, Cartoons, Cliparts, Hintergründe, Icons oder was auch immer Sie brauchen, um atemberaubende Visualisierungen zu erstellen - wir haben sie hier. Erwecken Sie Ihre Designs jetzt zum Leben!',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: 'Beliebte Vektorgrafik Kategorien',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: 'Entdecken Sie attraktive Tarife und Preise für Vektoren',
  CLIPART_VECTOR_DISCOVER_NOW: 'Discover Now',
  SPEND_AND_WIN_SUBTITLE: 'Mit jedem Kauf AB {currency} kommen Sie in den Lostopf.',
  SPEND_AND_WIN_IMGALT: 'Kaufen und dabei ein iPhone 13 Pro gewinnen',
  CLIPART_VECTOR_GRAPHIC: 'Grafik',
  CLIPART_VECTOR_ADVERTISING: 'Werbung',
  CLIPART_VECTOR_FLOWER: 'Blume',
  CLIPART_VECTOR_ABSTRACT: 'Abstrakt',
  CLIPART_VECTOR_POP: 'Pop',
  CLIPART_VECTOR_BIRD: 'Vogel',
  CLIPART_VECTOR_ILLUSTRATION: 'Illustration',
  CLIPART_VECTOR_PIXEL: 'Pixel',
  CLIPART_VECTOR_3D: '3D',
  LANG_VECTOR_SVG_EPS: 'Vektor, SVG und EPS',
  FREE_UPGRADE: 'Kostenloses Upgrade',
  FREE_UPGRADE_MESSAGE: 'KOSTENLOSES XL-Upgrade für das gesamte Abonnement oder Download-Paket. Jetzt kaufen.',
  LANG_FREE_IMAGE: '123RF Kostenlos',
  LANG_IMAGE_FREE: 'Dieses Stockfoto ist für den kommerziellen und persönlichen Gebrauch frei. Eine Quellenangabe ist erforderlich. ',
  LANG_HOW_TO_ATTRIBUTE: 'Wie gebe ich den Urheber an?',
  LANG_FREE_DOWNLOAD: 'Kostenloser Download',
  LANG_FREE_DOWNLOAD_BUTTON: 'Kostenloser Download',
  LANG_GO_PLUS_BUTTON: 'PLUS wählen',
  LANG_ATTRIBUTION_IS_KEY: 'Die Quellenangabe ist ein wichtiges Element für die Urheber von Inhalten, die ihre Arbeit öffentlich zugänglich machen. Unsere Anbieter wissen es zu schätzen, dass ihre Werke von Millionen von Nutzern wie Ihnen verwendet, geteilt und entsprechend gewürdigt werden.',
  LANG_GET_ATTRIBUTION_LINK: 'Kopieren Sie diesen Link und platzieren Sie ihn unmittelbar neben, über oder unter dem Inhalt.',
  LANG_CREDIT_OWNER: 'Bitte geben Sie den Namen des Anbieters an:',
  LANG_USE_ATTRIBUTION: 'Benutzen Sie den unten stehenden Link zur Angabe der Quelle.',
  LANG_ATTRIBUTION_LOCATION: 'Wenn dies nicht möglich ist, können Sie den Link auch in der Fußzeile der Website, des Blogs oder des Newsletters platzieren. Auch ein spezieller Abschnitt auf der Website reicht aus, um den Eigentümer {media_type} zu nennen.',
  LANG_GOT_A_QUESTION: "Eine Frage haben? <a href={url} target='_blank'><u>Kontaktiere uns</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: 'Sie müssen den Urheber für dieses kostenlose Bild angeben.',
  LANG_ATTR_FREE_DOWNLOADING: 'Bleiben Sie dran! Es dauert nicht lange.',
  LANG_PLUS_CONTENT_1: 'Sie erhalten unbegrenzte Downloads',
  LANG_PLUS_CONTENT_2: 'Zugang zu über 100 Millionen Fotos und Vektoren',
  LANG_PLUS_CONTENT_3: 'Exklusive 3D-Illustrationen nur bei 123RF!',
  LANG_PLUS_CONTENT_4: 'Unbegrenzte Downloads',
  LANG_PLUS_CONTENT_5: 'Unbegrenzte kreative Freiheit',
  FREE_VECTOR: 'FREE Stockvektoren',
  FREE_STOCKPHOTO: 'FREE Stockbilder',
  FREE_PUBLIC_DOMAIN_CC0: 'Public Domain (CC0)',
  BUY_5_DOWNLOAD_PACK: "<b>Zeitlich befristetes Angebot!</b><br/>3 + <b><span style='color:red'>2 kostenlos</span></b> = 5 Fotos oder Vektoren",
  BUY_5_DOWNLOAD_PACK_POINT_2: 'Jederzeit herunterladen (innerhalb eines Jahres)',
  BUY_5_GET_2_FREE: 'Erhalten Sie 2 kostenlose Bilder!',
  LANG_ASK_DOWNLOAD_PLUS: 'Möchten Sie PLUS-Inhalte herunterladen?',
  LANG_SIGN_UP_PLUS: 'Melden Sie sich für unser 123RF PLUS-Abo an',
  LANG_FOR_ONLY: "<span class='text'>für nur</span> <span class='price'>{price} Dollar</span>/Monat",
  LANG_FOR_ONLY_2: "<span class='text'>für nur</span> <span class='price'>{price} Dollar</span>",
  LANG_BENEFIT_PLUS: 'Profitieren Sie von unserem PLUS-Tarif ',
  LANG_RELEASES: 'Freigaben',
  LANG_UNAVAILABLE: 'Nicht verfügbar',
  LANG_ATTR_FREE_IMG_TITLE2: 'Wir freuen uns, dass Sie gefunden haben, was Sie brauchen. 🎉',
  LANG_PLEASE_NOTE: 'Bitte beachten Sie, dass diesem Bild keine Modell- oder Eigentumsfreigaben beigefügt sind. Die kommerzielle Nutzung erfolgt auf eigene Gefahr.',
  LANG_PLEASE_ADVISED: 'Bitte beachten Sie, dass Bilder, die erkennbare Personen enthalten, keine Veröffentlichungen haben. Um diese Bilder kommerziell zu verwenden, muss die Erlaubnis von den zuständigen Parteien eingeholt werden. Wenn das Bild keine erkennbaren Personen enthält, sollte es keine Urheberrechtsprobleme geben, wenn es kommerziell verwendet wird.',
  LANG_REACHED_LIMIT: 'Ops! Você atingiu seu limite de download diário.',
  LANG_COME_TOMORROW: 'Estamos emocionados por você adorar nossas imagens gratuitas! Volte amanhã para uma atualização de seu limite de downloads. Obrigado. 😊',
  LANG_DOWNLOAD_ERROR: 'Ops! Ihr Bild konnte aufgrund eines Fehlers nicht heruntergeladen werden.',
  LANG_REFRESH_AND_TRY_AGAIN: "Bitte aktualisieren Sie Ihren Browser und versuchen Sie es erneut. <a href={url} target='_blank'> Lassen Sie uns wissen</a> wenn dieses Problem weiterhin besteht.",
  LANG_REACHED_LIMIT_PLUS: 'Tägliches Download -Limit erreicht',
  LANG_COME_TOMORROW_PLUS: 'Wow! Sie haben Ihr Download-Limit für heute erreicht. Bitte kommen Sie morgen wieder, um mehr herunterzuladen. Vielen Dank für die Zusammenarbeit!',
  SEE_YOU_TOMORROW: 'Bis morgen!',
  ORIGINAL: 'Original',
  'AUTO_B&W': 'Auto S/W',
  AUTO_ADJUST: 'Automatisch anpassen',
  AUTO_POP: 'Auto Pop',
  NONE: 'Keine',
  LANG_CROP: 'Zuschneiden',
  LANG_ENABLE_EDITOR: 'Bild bearbeiten',
  LANG_DISABLE_EDITOR: 'Schliessen',
  LANG_IMAGE_FREE_CC0: "Dies ist ein CC0 -Bild. Bildnachweis ist nicht erforderlich. Sie können es gemäß den <a class='linkCC0' href={url} target='_blank'>CC0 -Lizenzbedingungen</a> verwenden.",
  LANG_PREVIEW: 'Vorschau',
  LANG_ATTRIBUTION_CC0_TITLE: 'CCO-Bild: Urhebernennung nicht erforderlich',
  LANG_ATTRIBUTION_TITLE: 'Urhebernennung erforderlich',
  LANG_ATTRIBUTION_CONTENT: 'Der Link zur Urhebernennung wird Ihnen nach dem Herunterladen der Datei mitgeteilt.',
  LANG_ATTRIBUTION_CC0_CONTENT: 'Die Urhebernennung ist nicht erforderlich, da dies ein CCO -Bild ist.',
  LANG_SUBCRIBE_PREMIUM: 'Sie haben Ihr tägliches Download-Limit erreicht! Um mehr herunterzuladen, abonnieren Sie 123RF PREMIUM.',
  LANG_REMOVE_ATTRIBUTION: 'Urheberangabe entfernen?',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "Abonnieren Sie <img src={image} alt='123RF Plus Logo'>, um unbegrenzten Zugang zu 100m Stockfotos und Illustrationen zu erhalten, ohne dass eine Urheberangabe erforderlich ist.",
  LANG_CREATOR_ATTRIBUTION: 'Bitte nennen Sie den Urheber über unten stehenden Link.',
  LANG_GET_ATTRIBUTION_LINK_2: 'Kopieren Sie diesen Link und platzieren Sie ihn in der Nähe der Stelle, an der der Inhalt verwendet wird, z. B. in der Fußzeile der Webseite oder in einem bestimmten Abschnitt des Impressums auf der Webseite.',
  LANG_APPLY_BG_REMOVAL: 'Hintergrundentfernung anwenden',
  LANG_APPLYING_EDIT: 'Bearbeitungen anwenden...',
  LANG_APPLYING_EDIT_ERROR: 'Etwas ist schief gelaufen.',
  LANG_TRY_AGAIN: 'Erneut versuchen?',
  LANG_SEARCHCOLLECTION_PREMIUM: 'Komplette Kollektion',
  LANG_SEARCHCOLLECTION_PLUS: 'Essentielle Kollektion',
  LANG_SEARCHCOLLECTION_FREE: 'Basis-Kollektion',
  LANG_SIMILARIMAGESPAGE_TITLE: 'Lizenzfreie Bilder, Vektoren & Videos kaufen: 123RF',
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: 'Ähnliche Dateien',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: 'Hoppla! Es gibt hier nichts zu sehen.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: 'Bild nicht verfügbar',
  LANG_PER_IMAGE: '/Bild',
  LANG_PLANS_AND_PRICING: 'Pakete & Preise',
  LANG_CHOOSE_BEST_PLAN: 'Wählen Sie das Paket, das am besten zu Ihren Anforderungen passt.',
  LANG_WANT_MORE_SAVING_1: 'Wollen Sie noch mehr sparen?',
  LANG_WANT_MORE_SAVING_2: 'Weitere Pakete ab {price}/Bild finden Sie hier',
  LANG_BEST_VALUE: 'Bestes Angebot, {percentage}% sparen',
  LANG_YEARLY_PLAN: 'Jährliches Paket',
  LANG_WITH_MONTHLY_PAYMENTS: 'Mit monatlichen Zahlungen',
  LANG_GRAB_YEARLY_PLAN: 'Nehmen Sie ein Jahrespaket und sparen Sie {percentage}%.',
  LANG_PRICE_PER_MONTH: '{price} pro Monat',
  LANG_PRICE_PER_YEAR: '{price} pro Jahr',
  LANG_GET_X_EVERY_MONTH: 'Holen Sie sich {quota} Fotos oder Vektoren jeden Monat',
  LANG_X_EVERY_MONTH: '{quota} Fotos oder Vektoren<br>jeden Monat',
  LANG_SINGLE_IMAGE_PURCHASE: 'Einzelbildkauf',
  LANG_GET_ONLY_WHAT_YOU_NEED: 'Kaufen Sie nur das, was Sie gerade brauchen.',
  LANG_TOTAL_PRICE: 'Gesamt {price}',
  LANG_DOWNLOAD_IMMEDIATELY: 'Laden Sie das Bild sofort nach dem Bezahlvorgang herunter.',
  LANG_NEXT: 'Nächste',
  LANG_TITLE: 'Lizenzfreie Bilder, Vektoren & Videos kaufen: 123RF',
  SIMILAR_STOCK_PHOTOS: 'Ähnliche Dateien',
  EMPTY_SIMILAR_SEARCH_RESULTS: 'Hoppla! Es gibt hier nichts zu sehen.',
  UNAVAILABLE_IMAGE: 'Bild nicht verfügbar',
  LANG_DOWNLOAD_PACK: 'Download-Paket',
  LANG_GET_X_ANY_TIME: '{quota} Fotos oder Vektoren jederzeit.',
  LANG_X_CREDITS: '{total} Credits',
  LANG_PER_CREDIT: '/Credit',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '{photoCount} Fotos{videoCount, plural, =0 {} =1 { oder # Video} other { oder # Videos}}.',
  INCLUDES_STANDARD_LICENSE: 'Umfasst Standardlizenz',
  LANG_MEDIUM_SIZE: 'Medium',
  LANG_LARGE_SIZE: 'Large',
  LANG_EXTRA_LARGE_SIZE: 'Extra Large',
  LANG_STANDARD_SIZES: 'Standard Lizenz',
  LANG_ROYALTY_FREE: 'lizenzgebührenfrei',
  LANG_OPTIONS: 'Optionen',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'Profitieren Sie von unserem PLUS-Tarif ',
  LANG_RELATED_FONT_SEARCH: 'Verwandte Suchanfragen',
  AI_GENERATED_PHOTO: 'KI-generiertes Bild',
  FONT_PRICING_CREDIT: 'Credits',
  '3_FONTS_3_IMGS': '3 Schriftarten <b>oder</b> 3 Bilder <b>oder</b> 1 Video',
  '9_FONTS_9_IMG_3_VIDEOS': '9 Schriftarten <b>oder</b> 9 Bilder <b>oder</b> 3 Videos',
  PURCHASE_AND_DOWNLOAD: 'Kaufen und herunterladen',
  FONT_PATH: 'fonts',
  FONT_ZIP: 'Schriftart (OTF, TTF)',
  FONT_PACKS_LICENSE: '123RF Paket-Lizenz',
  FONT_META_DESC: 'Laden Sie die {product_title} Schriftart, Schrift oder Typografie herunter, um kreative Designs für Print und Web zu erstellen.',
  FONT_META_TITLE: 'Schriftart - {product_title} | 123rf',
  FONT_SIMILAR: 'Ähnliche Schriftarten',
  LANG_PLANS: 'Pakete',
  LANG_I_WANT_THIS: 'Möchte ich haben',
  LANG_AI_VARIATION_TOOLTIP_TITLE: 'Versuchen Sie, Variationen zu erstellen',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: 'Generieren Sie neue Bilder auf der Grundlage des Originalbildes. Außerdem ist dies eine neue Möglichkeit, unsere 123RF-Mitarbeiter jedes Mal zu belohnen, wenn ein KI-generiertes Bild lizenziert wird!',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: 'Unsere Community dankt Ihnen. Die Lizenzgebühren werden mit dem 123RF-Beitragenden geteilt.',
  LANG_CONTRIBUTORS_AI_VARIATIONS: '123RF-Mitwirkende des Originalbildes werden für jede lizenzierte KI-Variante entschädigt.',
  LANG_AI_VARIATION_LIMIT: 'Oh nein! Sie haben das Limit für die Erzeugung von KI-Bildern für heute erreicht.',
  LANG_GENERATE_COMEBACK: 'Kommen Sie morgen wieder für mehr Generationen!',
  LANG_LICENSE_AND_DOWNLOAD: 'Lizenzieren und herunterladen',
  LANG_CONFIRM_OK: 'Okay',
  LANG_AI_SWITCH_CONTENT: 'Foto-editor wird für KI-Variationen nicht unterstützt. Alle Bearbeitungen gehen verloren, wenn Sie zu KI-Variationen wechseln.',
  LANG_GENERATE_VARIATIONS: 'Varianten generieren',
  LANG_GENERATE_WITH_AI: 'Bilder mit KI generieren',
  LANG_NEW: 'Neu',
  LANG_AI_GENERATOR_LICENSE: '123RF KI Generator Nutzungsbedingungen („Bedingungen”)',
  LANG_I_UNDERSTAND: 'Ich verstehe',
  LANG_WHAT_IS_THIS: 'Was ist das?',
  LANG_AUTHENTIC: 'Authentisch',
  LANG_ABSTRACT: 'Abstrakt',
  LANG_MACRO_CLOSEUP: 'Makro/Nahaufnahme',
  LANG_OBJECT: 'Objekt',
  LANG_WATERCOLOR: 'Aquarell',
  LANG_FLAT: 'Flach',
  LANG_CARTOON: 'Karikatur',
  LANG_GEOMETRIC: 'Geometrisch',
  LANG_GRADIENT: 'Farbverlauf',
  LANG_ISOMETRIC: 'Isometrisch',
  LANG_3D: '3d',
  LANG_HAND_DRAWN: 'Handgezeichnet',
  LANG_NATURAL: 'Natürlich',
  LANG_WARM: 'Warm',
  LANG_DRAMATIC: 'Dramatisch',
  LANG_VIVID_BOLD: 'Lebendig und fett',
  LANG_BLACK_WHITE: 'Schwarz und weiß',
  LANG_ASIAN: 'Asiatisch',
  LANG_AFRICAN_AMERICAN: 'Afroamerikanisch',
  LANG_STANDARD: 'Standard',
  LANG_EXTENDED: 'Erweitert',
  LANG_STANDARD_CONTENT_1: 'Unbegrenzte Aufrufe für soziale Medien, Web, E-Mail und Mobilgeräte.',
  LANG_STANDARD_CONTENT_2: 'Bis zu einer Auflage von 500.000 Exemplaren.',
  LANG_STANDARD_CONTENT_3: 'Darf nicht für zum Verkauf bestimmte Waren verwendet werden.',
  LANG_EXTENDED_CONTENT_1: 'Unbegrenzte Aufrufe für soziale Medien, Web, E-Mail und Mobilgeräte.',
  LANG_EXTENDED_CONTENT_2: 'Unbegrenzte Druckauflagen.',
  LANG_EXTENDED_CONTENT_3: 'Kann für zum Verkauft bestimmte Waren verwendet werden.',
  LANG_ORIGINAL_IMAGE: 'Originalbild',
  LANG_IMPORTANT_NOTE: 'Wichtiger Hinweis',
  LANG_AI_ORIGINAL_ALTERED: 'KI-Variationen sind Varianten des Originalbildes, die mit Hilfe von KI-Technologie verändert wurden. Es ist vergleichbar mit einem KI-Editor, der Ihr gewähltes Bild nachbearbeitet.',
  LANG_GENERATE_VARIATIONS_NOTE: 'Da wir uns derzeit in der Beta-Phase der KI-gestützten Bildvariationen befinden, können die generierten Bilder noch unerwartete Ergebnisse liefern. Wir arbeiten hart daran, sicherzustellen, dass solche Ergebnisse und Variationen den ethischen und verantwortungsvollen KI-Richtlinien entsprechen.',
  LANG_LICENSE_AI_EDITED: 'Wenn Sie eine dieser KI-bearbeiteten Versionen lizenzieren, wird dem Inhaber des Urheberrechts am Originalbild eine Lizenzgebühr gezahlt.',
  LANG_MOOD: 'Stimmung',
  LANG_AI_EXCLUDE_PLACEHOLDER: 'z.B. Grüne Farbe, glattes Haar',
  LANG_SIMILAR_STOCK: 'Ähnliche Stockbilder',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: 'mit Modellfreigabe',
  WITH_PROPERTY_RELEASE: 'mit Eigentümerfreigabe',
  MODEL_RELEASE_CONTENT: 'Eine Model-Freigabe ist ein Dokument, in dem das Model dem Fotografen bestätigt, das die Bilder veröffentlicht und gemäß den Lizenzbedingungen vertrieben werden dürfen.',
  PROPERTY_RELEASE_CONTENT: 'Ein Eigentümerfreigabe ist ein Dokument, in dem der Eigentümer eines Gebäudes dem Fotografen bestätigt, dass die Bilder veröffentlicht gemäß den Lizenzbedingungen vertrieben werden dürfen.',

  variousStockGAI: 'Ein rechtlich konformes KI-Modell, das mit Millionen von Stockfotos trainiert wurde und für Unternehmensanforderungen optimiert ist, um Ihnen mit Haftpflichtversicherung Sicherheit zu geben.',
  extendedLicense: 'Erweiterte Lizenz',
  sizes: 'Größen',

  downloadMail1st: 'Unser Bildvergrößerungsdienstleister wird Ihnen per E-Mail an',
  downloadMail2nd:
    'die Anweisungen zum Herunterladen der endgültigen vergrößerten Datei innerhalb des nächsten Arbeitstages mitteilen',

  aiGenarated: 'KI-generiertes Bild',

  variousOpenJourney: 'KI-Modell generiert Kunstwerke, die dem unverwechselbaren Stil von Midjourney ähneln.',
  variousTurboVision: 'Hohe Geschwindigkeit bei der Generierung in "normaler" XL-Auflösung, Verbesserung der Gesamtqualität.',
  variousRealVisXL: 'Das Modell zielt darauf ab, fotorealistische Ergebnisse mit Präzision und Genauigkeit zu erzielen.',
  variousPortraitPlus: 'Modell mit einheitlicher Porträtkomposition, ideal für ein Seitenverhältnis von 1:1.',
  enterVirifiedCode: 'Geben Sie den Bestätigungscode ein',
  verify: 'Überprüfen',
  didNotGetCode: 'Keinen Code erhalten?',
  clickToResend: 'Klicken Sie, um erneut zu senden',
  otpCannotEmpty: 'OTP darf nicht leer sein.',
  sentCodeToMail: 'Wir haben einen Code an Ihre registrierte E-Mail gesendet.',
  invalidCode: 'Ungültiger Code.',
  expiredOTP: 'OTP ist abgelaufen, bitte erneut senden.',
  sendEmail: 'Die E-Mail wurde erfolgreich gesendet, bitte überprüfen Sie Ihren Posteingang, um fortzufahren.',
  remainingTime: 'Verbleibende Zeit: ',
  tooManyAttempts: 'Zu viele Versuche. Bitte versuchen Sie es später erneut.',
};

export default de;
