const pl = {
  // Header
  photos: 'Zdjęcia',
  Photos: 'Zdjęcia',
  vectors: 'Wektory',
  Vectors: 'Wektory',
  footageupper: 'Filmy',
  footage: 'Filmy',
  video: 'Video',
  audio: 'Audio',
  signin: 'Zapisz się',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Jezyk%20Polski.png',
  language: 'Język',
  // Footer
  contact: 'Kontakt',
  contactUs: 'Skontaktuj się z nami',
  privacy: 'Polityka prywatnośc',
  terms: 'Warunki Użytkowania',
  cookie: 'POLITYKA COOKIE',
  corporateAgreement: 'License Agreement',
  // ForgotPassword
  forgotPwdTitle2: 'Zapomniałeś hasła',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: 'Wpisz aktualny adres e-mail.',
  emailCannotEmpty: 'E-mail nie może być pusty',
  returnToLogin: 'POWRÓT DO LOGOWANIA',
  email: 'Email',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // Login
  username: 'Nazwa użytkownika',
  pw: 'Haslo',
  rememberMe: 'Pamiętaj mnie',
  login: 'Zaloguj',
  forgotPassword: 'Zapomniałeś hasła',
  loginAsGuest: 'Gość',
  // ResetPassword
  resetPwdDesc: 'Wpisz nowe hasło, a my zalogujemy Cię od razu.',
  newPw: 'Nowe haslo',
  confirmPwd: 'Potwierdź hasło',
  resetPwdButton: 'Zresetuj hasło',
  resetPwdTitle: 'Zresetuj swoje hasło',
  enterYourNewPw: 'Podaj nowe hasło.',
  pwMiniMumChars: 'Minimum 8 znaków',
  pwMaxChar20: 'Maksymalne dozwolone jest 20 znaków',
  pwLowerCase: 'Przynajmniej jedna mała liter',
  pwUpperCase: 'Przynajmniej jedna duża litera',
  pwNumeral: 'Przynajmniej jedna cyfra',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: 'Potwierdź hasło nie jest zgodne z Hasło',
  resetPwdInvalidLink: 'Ten link jest nieprawidłowy lub wygasł.',
  pwHasBeenReset: 'Hasło zostało zresetowane, zaloguj się, aby kontynuować.',
  aiLicenseHeader: 'Ten plik został wygenerowany przez AI (Sztuczną Inteligencję).',
  aiLicenseContent: 'Treści wygenerowane przez sztuczną inteligencję nie podlegają standardowemu procesowi przeglądu zgodności 123RF. Twórca jest odpowiedzialny za zapewnienie, że materiały generowane przez AI nie naruszają praw własności intelektualnej ani innych praw osób trzecich. Tworzenie spamu, a także wszelkich treści fałszywych, wprowadzających w błąd, szkodliwych, ukazujących przemoc, pornograficznych lub nielegalnych, jest surowo zabronione przez nasze zasady.',
  // Admin index
  companyName: 'Nazwa firmy',
  phone: 'Telefon',
  fax: 'Faks',
  address: 'Adres',
  idd: 'IDD',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: 'Suma pozycji',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: 'Stare hasło',
  confirmNewPassword: 'Potwierdz nowe haslo',
  returnToHome: 'Powrót do strony startowej',
  // Cookie Policy
  langCookie01:
    'Gdy odwiedzasz %host%, umieszczamy mały plik tekstowy o nazwie cookie na dysku twardym komputera.',
  langCookie02:
    'Ten plik pozwala nam śledzić Cię, dzięki czemu jesteśmy w stanie zapewnić Ci podstawowe funkcje, takie jak koszyk. Są one również używane w celu zwiększenia wygody korzystania z Internetu oraz zapewnienia funkcjonalności takiej, jak "likebox".',
  langCookie03:
    'Ponadto pozwala nam analizować Twoją interakcję z naszą stroną internetową (w tym odwiedzane strony, wyszukiwane hasła i oglądane zdjęcia). Jest to niezależne od tego, czy zdecydujesz się dokonać zakupu, czy nie.',
  langCookie04:
    'Pliki cookie, na które wyrażasz zgodę, są również wykorzystywane do profilowania Cię do listy odbiorców w celu dostarczania ukierunkowanych reklam, dostosowanych do Twoich zainteresowań.',
  langCookie05:
    'Większość przeglądarek umożliwia odrzucanie plików cookie; ale jeśli to zrobisz, niektóre funkcje mogą być niedostępne, a niektóre strony mogą być wyświetlane niepoprawnie.',

  downloadModalTitle: 'Pobranie zdjęcia',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf:
    'Pliki TIFF, media filmowe i media audio nie są obsługiwane. Następujące media:',
  messageFileNotSupported2ndHalf: 'zostały usunięte z dużego pobrania.',
  licenseOnBehalf: 'Licencja na rzecz',
  customizedPortal: 'Customized portal',
  normalDownload: 'Zwykłe pobranie',
  myCredit: 'Moje kredyty',
  lowCreditsReminder: 'Mała ilość kredytów',
  downloadModalId: 'Numer zdjęcia:',
  downloadModalPrice: 'Kredyt:',
  clearAll: 'Wyczyść wszystko',
  myActivities: 'Moja aktywność',
  info: 'Info',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: 'pobierz treści za pomocą',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: 'Wpisz tutaj swoje zapytanie',
  toSearchAIKeyword: 'Wprowadź słowa kluczowe, tytuł artykułu lub tytuł bloga',
  allImages: 'Wszystkie Obrazy',
  freeImages: 'Free Images',
  categoryImage: 'Category',
  recentViewed: 'Ostatnio Oglądane',
  latestIMG: 'Latest Images',
  // Setting
  adminControlPanel: 'Panel kontrolny Corp+ Admin',
  downloadHistory: 'Historia pobierania',
  hruc: 'HRUC',
  changePassword: 'Zmień hasło',
  logOut: 'Wyloguj',
  hello: 'Witaj,',
  viewAccount: 'View Account',
  myAccount: 'Moje konto',
  myCollections: 'Moje kolekcje',
  personalCredits: 'Kredyty indywidualne',
  groupTotal: 'Grupa ogółem',
  toTalUnpaidLicense: 'Łącznie niezapłacona licencja',
  highRUnwatermarkComp: 'Prewki hi-res bez znaku wodnego',
  monthSelection: 'Month selection',

  // Menu
  categories: 'Kategorie',
  myLikebox: 'Mój lightbox',
  settings: 'Ustawienia',
  notification: 'Wiadomości',
  clear: 'Wyczyść',
  likebox: 'Lightbox',
  credits: 'Kredyty',
  // Credit summary at header
  downloadAndUsege: 'Pobieranie i wykorzystanie',
  totalDlCredit: 'Dostępne kredyty',
  allocatedDlCredit: 'Przydzielone kredyty',
  personalAvailableCre: 'Osobiste kredyty',
  groupAvailableCre: 'Grupowe kredyty',
  availableDlSlot: 'Dostępne sloty',
  uNPLD: 'Użyj teraz, zapłać później.',
  hiResHRUC: 'Prewki hi-res bez znaku wodnego',
  monthlyAllocation: 'Miesięczne przydzielenie',
  remainingQuota: 'Pozostała ilość',
  remainingHRUCQ: 'Pozostała ilość prewek hi-res bez znaku wodnego',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: 'Grupy',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp: 'Daj nam znać, jak możemy Ci pomóc.',
  liveChat: 'Live chat',
  whatsApp: 'WhatsApp',
  hiThere: 'Cześć,',
  // email response
  emailIsNotAvailable: 'E-mail nie jest dostępny.',
  anErrorHaSoccured: 'Wystąpił błąd. Spróbuj ponownie.',
  emailHasBeenSent:
    'E-mail został wysłany pomyślnie, sprawdź swoją skrzynkę pocztową, aby kontynuować. Wysłaliśmy e-mail do __email z instrukcjami resetowania. Jeśli e-mail nie pojawi się w skrzynce odbiorczej w ciągu 5 minut, należy sprawdzić folder Spam.',
  tooManyAttempts: 'Zbyt wiele prób, spróbuj ponownie za kilka minut.',
  // INVALIDPASSWORD
  inValidPassword: 'Invalid password! Check if your caps lock is on.',
  userIsBlocked: 'Zbyt wiele nieudanych prób, zostałeś tymczasowo zablokowany.',
  blockWarrings: 'Po 10 próbach zostaniesz tymczasowo zablokowany.',
  // Update Password
  resetPwdUpdated: 'Twoje hasło zostało zaktualizowane',
  updatePwDfailed: 'Nie udało się zaktualizować hasła.',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'minuty',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: 'Umowa licencyjna',
  licenseAgreementCaption:
    'PLEASE READ ALL THE TERMS PROVIDED IN THIS CORPORATE+ CONTENT LICENSING PLATFORM. YOUR ACCEPTANCE OF THESE TERMS IS AN ABSOLUTE CONDITION OF YOUR USE OF THE CORPORATE+ CONTENT LICENSING PLATFORM AND THE CONTENT, AND FORMS A LEGAL AGREEMENT. BY ACCESSING ANY SERVICE OR PART THEREOF UNDER THE CORPORATE+ CONTENT LICENSING PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF THESE LEGAL DOCUMENTS. 123RF RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME WITHOUT PRIOR WRITTEN NOTICE, AND YOU AGREE TO BE BOUND BY SUCH CHANGES.',
  corpplusAgreement: 'Corporate+ Agreement',
  // login
  usernameCannotEmpty: 'Nazwa użytkownika nie może być pusta',
  passWordCannotEmpty: 'Hasło nie może być puste',
  oldPasswordCannotEmpty: 'Stare hasło nie może być puste',
  confirmPasswordCannotBeEmpty: 'Potwierdź nowe hasło nie może być puste',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: 'Kan geen e-mails meer versturen, controleer uw mailbox.',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: 'Czy używasz starszej wersji przeglądarki?',
  upgradeBrowser: 'Zaktualizuj ją do nowszej wersji, co zapewni jej płynniejsze działanie.',
  clickToDownload: 'Kliknij, żeby pobrać dowolną z poniższych:',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: 'Zobacz wszystko',
  // Filter bar
  aiGenerate: 'Zdjęcie wygenerowane przez AI',
  standardModels: 'Modeli standardowe',
  fineTunedModels: 'Dopracowane modele',
  aiContent: 'Uwzględnij treści generowane przez AI',
  filter: 'Filtry',
  safeSearch: 'Bezpieczne wyszukiwanie',
  aiAssist: 'Wsparcie AI',
  freshness: 'Popularność',
  gender: 'Płeć',
  sortBy: 'Sortuj według',
  mediaType: 'Typ nosnika',
  orientation: 'Orientacja',
  modelPreferences: 'Typ modela',
  people: 'Ludzie',
  more: 'Więcej',
  numOfPeople: 'Liczba osób',
  age: 'Wiek',
  ethnicity: 'Pochodzenie etniczne',
  collection: 'Kolekcje',
  uploaded: 'Uploaded',
  style: 'Styl',
  cutOut: 'Jednolite/przezroczyste tło',
  doNotIncludeWords: 'Bez użycia tych słów',
  doNotIncludeWords_ex: 'np. ser',
  color: 'Kolor',
  max1Color: 'Maksimum 1 kolor',
  license: 'Rodzaj licencji',
  fps: 'FPS',
  resolution: 'Rozdzielczosc',
  clipDuration: 'Czas trwania (minuty)',
  loop: 'Zapętlone',
  category: 'Kategorie',
  genre: 'Gatunek muzyczny',
  tempo: 'Tempo (bpm)',
  // Filter button text
  Relevance: 'Losowo',
  New: 'Nowy',
  Georank: 'pozycji geograficznej',
  DownloadHistory: 'Historia pobierania',
  Popularity: 'Popularność',
  Photography: 'Fotografie',
  VectorIllustration: 'Wektory',
  Horizontal: 'pozioma',
  Landscape: 'Pozioma',
  Portrait: 'Pionowa',
  Square: 'Kwadrat',
  Panorama: 'Panorama',
  numPpl: 'Liczba osób',
  WithoutPeople: 'Bez ludzi',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: 'Wyłącz',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: 'Ostatni tydzień',
  LastMonth: 'Last month',
  PastThreeMonths: 'Ostatni 3 miesięcy',
  PastTwodays: 'Ostatnie 2 dni',
  Male: 'Mężczyzna',
  Female: 'Kobieta',
  Standard: 'Standardowy',
  extended: 'Rozszerzona',
  Editorial: 'Publikacyjne',
  Commercial: 'Commercial',
  Black: 'Black',
  Caucasian: 'Kaukaski',
  AfricanAmerican: 'Afroamerykanski',
  Asian: 'Azjata',
  EastAsian: 'East Asian',
  Hispanic: 'Latynoski',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: 'Dzieci',
  Teenagers: 'Nastolatki',
  Adults: 'Dorosłych',
  Seniors: 'Seniorzy',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: 'Tak',
  Nonloopable: 'Nie',
  Music: 'Muzyka',
  SoundEffects: 'Efekty dzwiekowe',
  LogoAndIdents: 'Loga i identyfikatory',
  Veryslow: 'Bardzo wolne',
  VeryslowSubText: '(0-79)',
  Slow: 'Wolne',
  SlowSubText: '(80-119)',
  Medium: 'Średnie',
  MediumSubText: '(120-139)',
  Fast: 'Szybkie',
  FastSubText: '(140-159)',
  Veryfast: 'Bardzo szybkie',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: 'Popularnych',
  MostDownload: 'Najczęściej pobierane',
  Credits: 'Créditos',
  Freshness: 'Czas trwania',
  pickAGenre: 'Wybierz gatunek',
  // Paginations
  Of: 'z',
  Page: 'Strona',
  // home
  recommended: 'Polecamy',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: 'Popularne słowa kluczowe',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: 'Dźwięki z gier',
  cartoonSounds: 'Dźwięki z kreskówek',
  soundPacks: 'Sound Packs',
  interfaceSounds: 'Dźwięki interfejsu',
  transitionsAndMovementsSounds: 'Przejścia i ruch',
  natureSounds: 'Dźwięki natury',
  domesticSounds: 'Dźwięki domowe',
  urbanSounds: 'Dżwięki miejskie',
  industrialSounds: 'Dźwięki przemysłowe',
  futuristicSounds: 'Dźwięki futurystyczne',
  humanSounds: 'Dźwięki ludzkie',
  othersSounds: 'Inne',
  ambientSounds: 'Ambient',
  childrenSounds: 'Dziecęce',
  cinematicSounds: 'Filmowe',
  classicalSounds: 'Klasyka',
  corporateSounds: 'Korporacyjne',
  electronicaSounds: 'Elektronika',
  soulSounds: 'Soul & R&B',
  jazzSounds: 'Jazz',
  popSounds: 'Pop',
  worldBeatSounds: 'Muzyka świata',

  countrySounds: 'Country & Western',
  drumSounds: 'Drum & Bass',

  folkSounds: 'Folk & Akustyczne',
  funkSounds: 'Funk & Groove',

  hiphopSounds: 'Hip Hop',
  holidaySounds: 'Świąteczne i okolicznościowe',

  rockSounds: 'Rock',

  individualSounds: 'Individual',
  introSounds: 'Intro & Outro',
  ascSounds: 'Rosnąca & Malejąca',
  businessSounds: 'Business & Media',
  orchestralSounds: 'Orkiestrowa',
  festiveSounds: 'Uroczysta',
  percussiveSounds: 'Perkusyjna',
  miscellaneousSounds: 'Rozmaita',
  packsSounds: 'Packs',
  percussionSounds: 'Dźwięki perkusyjne',
  sampleLoopSounds: 'Przykładowa pętla',

  // Search images
  searchImage: 'Zdjęcia i ilustracje',
  StockPhotosAndImages: 'Zdjęcia stockowe',
  StockVectorClipartAndIllustration: 'Stockowe ilustracje oraz ilustarcje wektorowe',
  matches: 'dopasowanie',
  editorial: 'Publikacyjne',
  downloaded: 'To zdjęcie było już pobierane',
  to: 'to',
  relatedSearches: 'Powiązane wyszukiwania',
  similarTo: 'Podobny do',
  searchByImage: 'Wyszukiwanie poprzez zdjęcie',
  options: 'Opcje',
  searchByImageMsg:
    'Przeszukaj 123RF za pomocą zdjęcia zamiast tekstu. Przeciągnij zdjęcie do pola wyszukiwania.',
  mustBeJPGorPNG: 'Zdjęcie musi być w formacie JPG / PNG',
  sizeLessThan5MB: 'Wielkość zdjęcia musi być mniejsza niż 5 MB',
  sizeLessThan4MB: 'Wielkość zdjęcia musi być mniejsza niż 4 MB',
  dragOrDropFile: 'Przeciągnij i upuść plik lub',
  browse: 'Przeglądaj',
  uploadedImage: 'Przesłany obraz',
  similarStockPhotos: 'Podobne zdjęcia',
  // Search footage
  searchFootage: 'Filmy',
  StockFootage: 'Filmy stockowe',
  businessFootage: 'Hacks to make the most of your business videos',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  footageProperties: 'Właściwości materiału filmowego',
  audioProperties: 'Właściwości audio',
  modalDownloadTitle: 'Opcje pobierania plików',
  credit: 'Kredyty',
  saveAs: 'Zapisz jako',
  ownLicense: 'Normalne pobieranie (własna licencja)',
  downloadAs: 'Pobierz jako',
  insufficientCredits: 'Niewystarczająca ilość kredytów',
  custom: 'Niestandardowa',
  personalAndCom: 'Użytek osobisty i reklamowy, prezentacje, media społecznościowe, internet.',
  licenseType: 'Typ licencji',
  requiredForResale:
    'Wymagana do odsprzedaży lub dystrybucji w przedstawieniach teatralnych, płatnym dostępie, strumieniowaniu wideo, grach i aplikacjach.',
  footageDetail: 'Szczegóły filmu',
  stockAudioKeywords: 'Słowa kluczowe',
  stockFootageKeywords: 'Słowa kluczowe materiałów filmowych',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: 'Stereo',
  fileSize: 'Rozmiary plików',
  footageId: 'ID filmu',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  fileName: 'Nazwa pliku',
  // Search audio
  searchAudio: 'Audio (muzyka i efekty dźwiękowe)',
  StockAudio: 'Stock audio',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: 'Kopiuj link',
  preview: 'Podgląd',

  bestSellingFootage: 'Przeglądaj najlepiej sprzedające się filmy royalty',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: 'Podobne zdjęcia',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: 'Ostatnio oglądane zdjęcia będą widoczne tutaj',
  rVVectors: 'Ostatnio oglądane wektory będą widoczne tutaj',
  rVFootage: 'Ostatnio oglądane filmy będą widoczne tutaj',
  rVAudio: 'Ostatnio oglądane audio będą widoczne tutaj',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: 'Twoje wyszukiwanie z tymi filtrami nie przyniosło żadnych wyników.',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle: 'Spróbuj usunąć niektóre filtry lub wypróbuj inne słowo kluczowe.',
  emptyReverseSearchTitle: 'Brak wyników!',
  emptyReverseSearchSubTitle1:
    'Dodane zdjęcie zawiera błędy, upewnij się, że zdjęcie spełnia następujące kryteria:',
  emptyReverseSearchSubTitle2: 'Sprawdź swoje wyszukiwanie za pomocą słów kluczowych',
  emptyReverseSearchSubTitle3: 'Zdjęcie musi być w formacie JPG / PNG',
  emptyReverseSearchSubTitle4: 'Rozmiar zdjęcia musi być mniejszy niż 5 MB',
  emptySearchResultClearFilter: 'Wyczyść wszystkie filtry',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: 'Wpisz słowo kluczowe',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // collection search result page
  searchCollection: 'Przeszukaj kolekcje',
  personal: 'Osobiste',
  groups: 'Grupy',
  createNew: 'Utwórz nowy',
  createNewCollection: 'Utwórz nową kolekcję',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  titleItem: 'Tytuł',
  selectGroup: 'Wybierz grupę',
  description: 'Opis',
  charactersRemaining: 'Pozostało 180 znaków',
  cancel: 'Anuluj',
  create: 'Utwórz',
  or: 'LUB',
  defaultCollection: 'Kolekcja domyślna',
  addToMyCollections: 'Dodaj do Likeboxu',
  addedToCollection: 'Dodano do kolekcji',
  removedFromCollection: 'Usunięto z kolekcji',
  collectionModalPlaceholder: 'np. natura',
  groupNameShowsHere: 'Nazwa grupy pojawi się tutaj',
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: 'Udostępnione mi',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: 'Właściciel',
  images: 'Zdjęcia',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: 'Osobiste',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: 'Udostępnij',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: 'Nie masz żadnej kolekcji.',
  btnUnDo: 'Cofnij',
  // my collection
  dateAdded: 'Daty dodania',
  collectionName: 'NAZWA',
  selectAll: 'Zaznacz wszystko',
  delete: 'Usuń',
  seeAll: 'Zobacz wszystko',
  deleteCollection: 'Usuń kolekcję',
  deleteWarning:
    'Wykonanie tej czynności spowoduje trwałe usunięcie Twoich kolekcji wraz z ich zawartością. Pamiętaj, że tej czynności nie można cofnąć',
  createCollection: ' został utworzony',
  shareCollection: 'Udostępnij',
  shareWithTeam: 'Share in team',
  selectUsers: 'Wybierz użytkowników',
  searchUserName: 'wyszukiwanie nazwy użytkownika',
  copy: 'Kopiuj',
  titleCopyModal: 'Kopiuj wybrane elementy do',
  collectionUppercase: 'KOLEKCJA',
  groupCollection: 'GRUPA',
  moveImage: 'Przenieś wybrane elementy do',
  defaultSelect: 'Nazwa kolekcji',
  moveLikebox: 'Move',
  messsageDeleteLikebox:
    'Wykonanie tej czynności spowoduje trwałe usunięcie Twoich kolekcji wraz z ich zawartością. Pamiętaj, że tej czynności nie można cofnąć.',
  areYouSure: 'Jesteś pewien?',
  editCollection: 'Edytuj',
  collectionNameUpper: 'NAZWA',
  deleteSuccessful: 'has been successfully deleted',
  edit: 'Edytuj',
  messageRemoveImage: 'Czy na pewno chcesz usunąć wybrany element (elementy)?',
  titleRemove: 'Usuń wybrane elementy',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: 'Zapisz',
  shareLikebox: 'Udostępnione mi',
  remove: 'Usuń',
  moveTo: 'Przenieś do',
  createNewPlaceholder: 'np. natura',
  shareViaEmail: 'Udostępnij przez e-mail',
  sharePublicly: 'Share publicly',
  receipnetEmail: 'Adres e-mail odbiorcy',
  message: 'Wiadomość',
  insertReceipmentEmail: 'Wpisz e-mail odbiorcy',
  messageText: 'Treść wiadomości.',
  exportCollection: 'Eksportuj',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: 'Dodaj nową kolekcję',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: 'FORMAT',
  lowCredit: 'Mała ilość kredytów',
  downloadSuccess: 'Dziękujemy za pobranie! Pobieranie rozpocznie się za chwilę.',
  downloadFailed: 'Błąd pobierania: Wystąpił błąd. Spróbuj ponownie.',
  downloadedLabel: 'Pobrane',
  any: 'Jakikolwiek',
  resetFilter: 'Wyczyść filtry',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: 'Śledź',
  name: 'Nazwa',
  location: 'Lokalizacja',
  since: 'Od',

  // Moje konto
  updated: 'Update',
  userName: 'NAZWA UŻYTKOWNIKA',
  firstName: 'Imię',
  lastName: 'Nazwisko',
  myProfile: 'My Profile',
  changeProfiledetail: 'Tutaj możesz zmienić swoje dane profilowe',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: 'Mój plan',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: 'Użyj teraz, zapłać później',
  bodyUNPL:
    'Use Now, Pay Later (UNPL) to rodzaj krótkoterminowego finansowania, które pozwala konsumentom dokonywać zakupów i płacić za nie w późniejszym terminie, często bez odsetek. Określane również jako "pożyczki ratalne w punktach sprzedaży.',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',

  // audio player
  categoryGenre: 'Kategorie / Gatunek muzyczny',
  duration: 'Długość',
  seeMore: 'Pokaż więcej',
  seeLess: 'Pokaż mniej',
  audioID: 'Numer audio',
  // category
  landscape: 'Krajobraz',
  Babies: 'Niemowlęta',
  business: 'Biznes',
  food: 'Jedzenie',
  animals: 'Zwierzęta',
  technology: 'Technologia',
  healthcare: 'Opieka zdrowotna',
  nature: 'Natura',
  romance: 'Romans',
  // category vector
  graphic: 'Grafika',
  advertising: 'Reklama',
  flower: 'Kwiat',
  abstract: 'Abstrakcyjny',
  Illustration: 'Ilustracja',
  pop: 'Pop',
  bird: 'Ptak',
  pixel: 'Pixel',
  threeD: '3D',

  // footage category
  education: 'Edukacja',
  communication: 'Komunikacja',
  beauty: 'Uroda',
  concept: 'Concept',
  fitness: 'Fitnes',
  lifestyle: 'Lifestyle',
  celebration: 'Celebration',
  family: 'Rodzina',
  fashion: 'Moda',

  // Download-history modal
  reDownload: 'Ponowne pobranie',
  chooseCustomTagOrCreate: 'Wybierz niestandardowy tag lub utwórz nowy',
  downloadDetails: 'Szczegóły pobrania',
  image: 'Image',
  downloadType: 'Rodzaj pobrania',
  groupName: 'Nazwa grupy',
  size: 'Rozmiar',
  dateDownloaded: 'Data pobrania',
  reLicense: 'Re-licencjonowanie',
  customFields: 'Pola niestandardowe',
  saveAndClose: 'Zapisz i zamknij',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: 'data',
  export: 'Eksportuj',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: 'Darmowe',
  searchById: 'Szukaj po numerze',
  pleaseSelect: 'Wybierz',
  totalOutstanding: 'Total Outstanding',
  viewInvoices: 'Zobacz fakturę',
  // Image detail page - select download size sidebar
  standardSizes: 'Rozmiary standardowe',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: 'Przytnij',
  // empty data
  opsNothingToSeeHere: 'Nic tu nie ma.',
  // search image
  authentic: 'Autentyczny',
  // search audio
  mostDownloaded: 'Najczesciej pobierane',
  download: 'Pobierz',
  // audio category
  soulAndRB: 'Soul & R&B',
  corporate: 'Korporacyjne',
  electronica: 'Elektronika',
  classical: 'Klasyczne',
  funkAndGrooveMusic: 'Funk & Groove',
  worldBeat: 'Muzyka świata',
  cinematic: 'Kinowy',
  jazz: 'Jazz',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // search page
  nextPage: 'Następna strona',
  reverseImageSearch: 'Reverse Image Search',

  group: 'Grupa',
  needHelp: 'Potrzebujesz pomocy?',
  copied: 'Skopiowane!',
  copyPortfolioLinkSuccess: 'Link portfolio współpracownika skopiowany do schowka.',

  // my activities
  myActivity: 'Moja aktywność',
  recentLike: 'Moje ostatnie polubienia',
  recentView: 'Ostatnio oglądane',
  myActivityViewDesc: ' Historia ostatnio oglądanych będzie przechowywana tylko do 3 miesięcy',
  myActivityLikeDesc: 'Tylko ostatnie 3 miesiące',

  // dashboard
  profile: 'Profil',

  // Pricing
  pricingCreditText: 'Kredyt',

  // Dashboard - Credit Usage
  user: 'User',
  creditUsage: 'Credit usage',
  searchPlaceholder: 'Search name, username, role, group',
  all: 'Wszystko',
  active: 'Active',
  deleted: 'Deleted',
  reset: 'Reset',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: 'Wyszukiwanie oparte na sztucznej inteligencji',
  aiPoweredSearchOn: 'Wyszukiwanie oparte na AI jest włączone',
  aiSearchIntroduction:
    'Wypróbuj naszą szybszą i wydajniejszą wyszukiwarkę. Nasza wyszukiwarka AI zajmie się resztą!',
  ok: 'OK',
  // Filter bar ai
  bstract: 'Abstrakcyjny',
  macroCloseUp: 'Makro/zbliżenie',
  object: 'Obiekt',
  watercolor: 'Akwarela',
  flat: 'Płaski',
  cartoon: 'Kreskówka',
  geometric: 'Geometryczny',
  gradient: 'Gradient',
  isometric: 'Izometryczny',
  '3d': '3d',
  handDrawn: 'Ręcznie rysowane',
  natural: 'Naturalny',
  warm: 'Ciepły',
  dramatic: 'Dynamiczny',
  vividAndBold: 'Żywy i wyrazisty',
  blackAndWhite: 'Czarny i biały',
  // introduce Free image research
  imageResearchContentTitle:
    'Przedstawiamy bezpłatną usługę wyszukiwania zdjęć 123RF: Doskonałe zdjęcia, zero kłopotów',
  imageResearchContentDesc:
    'Rewolucjonizujemy wyszukiwanie zdjęć dla klientów korporacyjnych, ułatwiając znalezienie idealnego zdjęcia i wprowadzenie wizji w życie.',
  submitRequest: 'Prześlij zapytanie',
  imageResearchContentInfoTitle1: 'Proste wyszukiwanie zdjęć',
  imageResearchContentInfoDesc1:
    'Rozpoczęcie pracy jest proste. Wystarczy podać nam szczegółowy opis pożądanego obrazu, w tym styl, nastrój, kolory i konkretne elementy. Im więcej szczegółów podasz, tym lepiej dopasujemy się do Twoich potrzeb.',
  imageResearchContentInfoTitle2: 'Jak to działa?',
  imageResearchContentInfoTitle3: 'Szybka i niezawodna usługa',
  imageResearchContentInfoTitle4: 'Pozwól nam wykonać pracę za Ciebie',
  imageResearchContentInfoDesc4:
    'Znajdziemy dla Ciebie idealne zdjęcia, abyś mógł skupić się na swoim projekcie. Zaufaj nam, a urzeczywistnimy Twoją wizję dzięki wysokiej jakości, starannie dobranym zdjęciom.',
  requestForm: 'Formularz zapytania',
  whatDoYouWantThisImageFor: 'Do czego chcesz wykorzystać to zdjęcie?',
  websiteBanner: 'Baner na stronie internetowej',
  socialMedia: 'Media społecznościowe',
  blogPost: 'Wpis na blogu',
  advertisement: 'Reklama',
  print: 'Druk',
  others: 'Inne',
  othersOutputPlaceholder: 'Wprowadź inne rodzaje materiałów wyjściowych',
  whatIsPrimaryMessage: 'Jakie jest główne przesłanie lub temat, który zdjęcie ma przekazać?',
  whatIsPrimaryMessagePlaceholder: 'Np. kobieta biegnąca z futrzastym psem w parku.',
  areThereSubjectYouWantInThePicture:
    'Czy istnieją określone tematy lub elementy, które mają znaleźć się na zdjęciu?',
  egPeopleNatureTechnologyAbstract: 'Np. ludzie, natura, technologia, abstrakcyjne koncepcje.',
  areThereImageOrStyleReference:
    'Czy są jakieś zdjęcia lub style, które widziałeś wcześniej, a które chciałbyś, abyśmy wykorzystali jako inspirację lub odniesienie?',
  ifPeopleIncludeWhatTheirDemographic:
    'Jeśli ludzie mają być uwzględnieni, jakie powinny być ich cechy demograficzne?',
  egAgeGenderEthnicityOccupation: 'Np. wiek, płeć, pochodzenie etniczne, zawód.',
  areThereAnyThingToAvoid:
    'Czy są jakieś elementy, tematy lub motywy, których chcesz uniknąć na zdjęciu?',
  whoisYourTargetAudience: 'Kto jest Twoją grupą docelową?',
  whoisYourTargetAudiencePlaceholder: 'Np. młodzi dorośli, którzy uwielbiają czytać',
  typeOfImage: 'Rodzaj zdjęcia',
  whatMoodShouldImageEvoke: 'Jaki nastrój lub emocje powinno wywoływać zdjęcie?',
  happy: 'Radość',
  sad: 'Smutek',
  calm: 'Spokój',
  excited: 'Podekscytowanie',
  angry: 'Złość',
  enterCustomMood: 'Wprowadź niestandardowy nastrój',
  areThereAnyOrientationForImage: 'Czy wymagane są jakieś szczególne preferencje dla zdjęcia?',
  enterCustomOrientation: 'Wprowadź niestandardową preferencję',
  isThereLocationYouWantImageToBeIn:
    'Czy istnieje określona lokalizacja lub ustawienie dla zdjęcia?',
  no: 'Nie',
  yes: 'Tak',
  shareWithUsAReference: 'Udostępnij nam referencję (tylko JPG lub PDF. Maksymalnie 4 MB)',
  replace: 'Zastąp',
  areThereAnySpecificRequirements: 'Czy są jakieś inne szczególne wymagania lub preferencje?',
  enterOtherComments: 'Wprowadź inne komentarze',
  submit: 'Prześlij',
  fieldCannotEmpty: 'To pole nie może być puste',
  selectAtLeastOne: 'Wybierz co najmniej jedną opcję',
  fileMustBeJpgOrPdf: 'Plik musi być w formacie JPG lub PDF',
  fileSizeLessThan4MB: 'Rozmiar pliku musi być mniejszy niż 4 MB',
  maximumFileNameAllow255: 'Maksymalna dozwolona liczba znaków wynosi 255',
  updateSuccessfully: 'Otrzymaliśmy Twoją prośbę.',
  freeImageResearch: 'Bezpłatne wyszukiwanie zdjęć',
  newService: 'Nowa usługa!',
  freeImageResearchIntroduction:
    'Wypróbuj naszą usługę wyszukiwania zdjęć i przekonaj się, dlaczego nasze zdjęcia idealnie pasują do Twoich wymagań.',
  later: 'Później',
  letTryIt: 'Spróbujmy!',
  imageResearchContentInfoDesc2: (
    <>
      Nasz zespół łączy
      {' '}
      <strong>technologię AI</strong>
      {' '}
      i doświadczenie, aby znaleźć idealne zdjęcia
      dla Ciebie, a zaawansowane algorytmy zawężają opcje z naszej ogromnej biblioteki na podstawie
      Twoich danych wejściowych. Nasz
      {' '}
      <strong>zespół oddanych i doświadczonych researcherów</strong>
      {' '}
      dopracowuje wyniki tak, aby idealnie spełniały kryteria użytkownika
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      Dostarczamy wyniki w ciągu
      {' '}
      <strong>2 dni roboczych</strong>
      , zapewniając kompleksowe
      wyjaśnienie wyboru zdjęć. Skoncentruj się na swoim biznesie, a my zajmiemy się resztą
    </>
  ),
  viewPdfOfSampleOutput: 'Wyświetl plik PDF z przykładowymi danymi wyjściowymi',
  characterMaxChar255: 'Maksymalna dozwolona liczba znaków wynosi 255',

  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: 'jest wymagany',

  relatedSearch: 'Related searches',
  showMore: 'Pokaż więcej',
  showLess: 'Pokaż mniej',

  requiredWithVariable: '{{var}} jest wymagany',
  mood: 'Nastrój',
  person: 'osoba',
  doNotIncludeWords_ex2: 'Np. zielony kolor, proste włosy',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: 'Generator zdjęć AI',
  imageGenerator: 'Generator Obrazów',
  aiImageGeneratorDesc: 'Kreatywność z prędkością wyobraźni.',
  generate: 'Generuj',
  exploreVisualsCreatedWithThePowerOfAI:
    'Poznaj wizualizacje stworzone dzięki mocy sztucznej inteligencji',
  turnYourImaginationIntoRealityIn3SimpleSteps:
    'Od wyobraźni do rzeczywistości w 3 prostych krokach',
  startWithGoodTextPrompt: (
    <>
      Zacznij od
      {' '}
      <span>
        dobrej
        <br />
        podpowiedzi tekstowej
      </span>
    </>
  ),
  startWithGoodTextPromptDesc:
    'Dobra wskazówka gwarantuje świetne rezultaty. Aby zapewnić najlepsze rezultaty, należy uwzględnić perspektywę, temat, akcję, słowa kluczowe, oświetlenie i styl.',
  reviewYourAIGeneratedImages: (
    <>
      Przejrzyj zdjęcia wygenerowane
      {' '}
      <br />
      <span>przez sztuczną inteligencję</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    'Nasz generator AI wygeneruje i wyświetli 3 zdjęcia podglądu, które najbardziej pasują do podpowiedzi.',
  downloadAndLicenseVisuals: (
    <>
      Pobieranie i
      {' '}
      <br />
      <span>licencjonowanie wizualizacji</span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    'Zdjęcia te można teraz pobierać i licencjonować do użytku osobistego i komercyjnego z ochroną prawną w wysokości 25 000 USD.',
  needSomeIdeas: 'Potrzebujesz pomysłów? Zacznij od tych próbek i odśwież swoje pomysły!',
  tryPrompt: 'Wypróbuj podpowiedź',
  creativityAtTheSpeedOfYourImagination: 'Kreatywność w tempie wyobraźni',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    'Wygeneruj w kilka sekund własne zdjęcia AI na podstawie podpowiedzi tekstowych z Corporate+!',
  tryNow: 'Wypróbuj teraz',

  /// Random prompt
  prompt1: 'Przedstaw pewnego siebie, ale przystępnego 28-letniego koreańskiego piosenkarza o urzekającej scenicznej obecności. Zaprezentuj jego dynamiczne umiejętności wokalne i magnetyczny urok poprzez ekspresyjny portret podkreślający jego uderzające rysy. Oddanie szczegółów jego gęstych, czarnych włosów i intensywnych, emocjonalnych oczu, przekazując głębię jego artystycznej pasji. Ubierz go w stylowy, współczesny strój odzwierciedlający jego muzyczną osobowość, być może z nutką zadziorności lub wyrafinowania. Umieść go w otoczeniu sugerującym występ na żywo lub studio nagrań, uchwyć energię i ekscytację jego rzemiosła. Użyj dramatycznego oświetlenia i kadrowania, aby stworzyć kinową, ponadnaturalną jakość, podkreślając jego status wschodzącej gwiazdy. Wlej kompozycję w poczucie ruchu i dynamiki, jakby był w trakcie urzekającego występu wokalnego. Dąż do stworzenia obrazu, który natychmiast przyciąga uwagę widza i prezentuje niezaprzeczalną sceniczną obecność i talent wokalny piosenkarza.',
  prompt2: 'Fotorealistyczny obraz majestatycznego lwa wylegującego się na skale z rozległą afrykańską sawanną w tle. Użyj aparatu z obiektywem 400 mm przy ustawieniu przysłony F 2.8, aby rozmyć tło i ostro skupić się na lwie. Oświetlenie powinno być naturalne i ciepłe, uchwycić złote odcienie zachodu słońca. Podkreśl fotorealizm, aby stworzyć żywy, szczegółowy obraz, który przekazuje piękno i wspaniałość dzikiej przyrody.',
  prompt3: 'Praca w technice mieszanej przedstawiająca futurystycznego samuraja w pozie akcji, władającego świecącym mieczem katana, na tle cyberpunkowego miasta z neonowymi światłami i hologramami. Włącz różnorodne tekstury i materiały, w tym elementy metaliczne i cyfrowe, aby stworzyć wizualnie bogatą i dynamiczną scenę.',
  prompt4: 'Wesoła cyfrowa ilustracja młodej księżniczki w płynącej sukni, bawiącej się magicznymi stworzeniami w zaczarowanym lesie. Otaczaj ją kolorowymi kwiatami, motylami i iskrzącym pyłem wróżek, na tle żywego i kapryśnego tła.',
  prompt5: 'Szczegółowy rysunek ołówkiem starożytnego wojownika o zniszczonej twarzy i zdecydowanym wyrazie, trzymającego miecz. Użyj skomplikowanych cieni i faktur, aby podkreślić zbroję wojownika i blizny wojenne, tworząc ponadczasowy i potężny obraz.',
  prompt6: 'Surrealistyczny kolaż mieszanych mediów przedstawiający podróżnika w czasie przemieszczającego się przez różne epoki historyczne, z elementami takimi jak stare mapy, starożytne artefakty i futurystyczne gadżety.  Użyj kombinacji starych wycinków z gazet, fotografii i grafiki cyfrowej, aby stworzyć humorystyczną i  prowokującą do myślenia pracę.',
  prompt7: 'Szczegółowa ilustracja cyfrowa dystopijnego miasta, z wieżowcami, latającymi samochodami i ciemnymi, złowieszczymi chmurami. Skup się na stworzeniu poczucia głębi i skali, ze skomplikowanymi detalami w architekturze i dramatycznym oświetleniem, aby podkreślić nastrój.',
  prompt8: 'Portret mądrego starca trzymającego magiczną laskę, uchwycony w dramatycznym oświetleniu typu chiaroscuro, aby podkreślić rysy twarzy starca i świecące runy laski. Użyj bogatych tekstur i cieni, aby stworzyć poczucie tajemnicy i mądrości.',
  prompt9: 'Żywa ilustracja fantasy przedstawiająca jeźdźca smoka szybującego po niebie, z jeźdźcem ubranym w zbroję i rozpostartymi skrzydłami smoka. Otacza ich chmury i odległe góry, dodając poczucia przygody i majestatu.',
  prompt10: 'Niepozowana czarno-biała fotografia uliczna tętniącego życiem targowiska w azjatyckim mieście, z handlarzami sprzedającymi kolorowe owoce i warzywa. Użyj małej głębi ostrości, aby skupić się na wyrazie twarzy i interakcjach handlarzy, uchwycić żywą atmosferę.',
  prompt11: 'Akwarelowy obraz o fantazyjnym charakterze przedstawiający jednorożca z tęczową grzywą, stojącego w magicznym lesie otoczonym świecącymi grzybami i błyszczącymi świetlikami. Użyj miękkich, pastelowych kolorów i płynnych pociągnięć pędzla, aby stworzyć senny, urzekający obraz.',
  prompt12: 'Trójwymiarowa wizualizacja nowoczesnej kuchni o eleganckim, minimalistycznym designie, ze stalowymi urządzeniami i dużą wyspą pośrodku. Użyj fotorealistycznych tekstur i oświetlenia, aby podkreślić czyste linie i współczesny styl, skupiając się na odbiciach i materiałach.',
  prompt13: 'Fantastyczny obraz krajobrazu przedstawiający unoszącą się wyspę na niebie, z wodospadami spływającymi po krawędziach i bujną roślinnością pokrywającą powierzchnię. Otaczaj wyspę puszystymi chmurami i żywym niebieskim niebem, tworząc poczucie zdumienia i magii.',
  prompt14: 'Szczegółowy portret wojownika wikinga z warkoczami i dzikim wyrazem twarzy, trzymającego topór bojowy. Tło powinno przedstawiać burzliwe morze i strome klify, z dramatycznym oświetleniem podkreślającym rysy twarzy i zbroję wojownika.',
  prompt15: 'Surrealistyczny kolaż technik mieszanych przedstawiający steampunkowego wynalazcę w zatłoczonym warsztacie wypełnionym kołami zębatymi, mechanizmami zegarowymi i napędzanymi parą urządzeniami. Użyj starych fotografii, starych planów i metalicznych tekstur, aby stworzyć wizualnie bogatą i wyobraźniową scenę.',
  prompt16: 'Cyfrowa ilustracja futurystycznego nocnego miasta, z wieżowcami, neonowymi znakami i latającymi pojazdami. Użyj żywych kolorów i dynamicznego oświetlenia, aby stworzyć tętniącą życiem i wciągającą atmosferę, podkreślając postęp technologiczny i tętniącą energię miasta.',
  prompt17: 'Fotorealistyczny render 3D przytulnego kącika do czytania z wygodnym fotelem, małym stolikiem z lampą i półkami wypełnionymi książkami. Sceneria powinna mieć ciepłe, przytulne oświetlenie i miękkie tekstury, oddając spokojny i relaksujący nastrój przestrzeni.',
  prompt18: 'Przedstaw przezroczystą, przypominającą szkło damską torebkę z wbudowanym, w pełni funkcjonalnym akwarium. Starannie oddanie delikatnie pływających rybek wewnątrz torebki, tworząc hipnotyzujący i niekonwencjonalny dodatek. Upewnij się, że akwarium jest idealnie zintegrowane ze strukturą torebki, z płynnymi przejściami między dwoma elementami. Umieść torebkę na widocznym miejscu w luksusowym domu towarowym, otoczonym eleganckimi, nowoczesnymi półkami, oświetleniem i innymi luksusowymi wystawami detalicznymi. Wykorzystaj dramatyczne, kierunkowe oświetlenie, aby stworzyć wrażenie dramatyzmu i przyciągnąć uwagę widza, kierując jego wzrok bezpośrednio na wodną torebkę. Uchwyć torebkę z kąta, który pokazuje jej przezroczystą, prawie eteryczną jakość, pozwalając na wyraźne widzenie ryb i tworząc wrażenie lekkości lub płynności.',
  prompt19: 'Uchwyć przytulną, zapraszającą scenę z pluszowymi fotelami w stonowanej palecie brązowych i oliwkowo-zielonych odcieni. Zaprezentuj fotele w ciepłym, intymnym otoczeniu, otoczone detalami, które wywołują uczucie relaksu i komfortu, takimi jak miękkie tekstylia, bogate drewniane akcenty i być może widok kominka lub okna z widokiem na spokojny, naturalny krajobraz. Podkreśl zmysłową, teksturalną jakość tapicerki i ogólne wrażenie przytulności i schronienia.',
  prompt20: 'Uchwyć żywe, realistyczne przedstawienie kuropatwy górskiej siedzącej na porośniętej mchem gałęzi, na tle bujnego, szmaragdowozielonego tła, które uzupełnia oszałamiające upierzenie ptaka. Oddanie skomplikowanych piór kuropatwy z niezwykłą szczegółowością, używając subtelnego gradientu światła i cienia, aby podkreślić ich tekstury. Podkreśl bogaty, ciemnozielony czubek kuropatwy, ciepły, ziemiscie brązowy dziób i jasne, dociekliwe czarne oczy, tworząc urzekający portret naturalnego piękna ptaka. Przekaż całej scenie ciepłą, spokojną atmosferę, zapraszając widza do wejścia w spokojne, naturalne otoczenie.',
  prompt21: 'Zaprojektuj wizualnie atrakcyjny obraz przedstawiający miskę kremowego, mrożonego jogurtu, udekorowaną żywą mieszanką świeżych owoców i chrupiących orzechów. Uchwyć orzeźwiające i odżywcze walory tego zdrowego deseru, pokazując kontrast między gładkim, jasnym jogurtem a żywymi kolorami dodatków owocowych. Ułóż składniki w artystyczny, apetyczny sposób, pozwalając widzowi docenić walory wizualne i oczekiwać orzeźwiającego, smakowitego doświadczenia. Zadbaj o to, aby ogólny obraz emanował poczuciem zdrowotności i przyjemności, zachęcając widza do delektowania się pysznymi i pożywnymi walorami tego pysznego przysmaku.',
  prompt22: 'Zanurz się w wizualnej uczcie, eksplorując surrealistyczny ogród kwiatowy pełen żywych kolorów i skomplikowanych szczegółów. Uchwyć urzekającą esencję kwiatów o zwijających się, spiralnych płatkach, pstrym listowiu i innych hipnotyzujących odcieniach – każdy z nich jest dziełem sztuki samym w sobie. Wykorzystaj płynność akwareli i ekspresyjność tuszu, stosując technikę „mokre na mokre”, aby stworzyć wrażenie ruchu i głębi. Przekaż surrealistyczną, oniryczną jakość tej kwiatowej krainy czarów poprzez grę bogatych, nasyconych kolorów i delikatne, skomplikowane odwzorowanie każdego płatka i liścia.',
  prompt23: 'Stwórz urzekającą ilustrację nocnej sceny przedstawiającą starożytną latarnię morską stojącą wysoko na skalistej wyspie. Latarnia, ozdobiona wieloma oknami, emituje ciepłą, przyjazną poświatę, podkreślając jej skomplikowaną architekturę i bogatą historię. Skalista wyspa jest pokryta bujną, zieloną roślinnością, której odbicie tańczy na spokojnych, księżycowych wodach, które odzwierciedlają srebrzystą poświatę pełni księżyca. Niebiańskie koło zdobi niebo, rzucając eteryczną poświatę na chmury i morze, podczas gdy puszyste chmury częściowo zasłaniają jego blask, dodając scenie tajemniczości. Migoczące gwiazdy zdobią atramentową otchłań, tworząc niebiański gobelin, który harmonizuje z blaskiem księżyca. To urzekające dzieło sztuki uchwyciło spokojną i ukojącą noc, w której oświetlona latarnia morska stoi nieugięcie pośród niebiańskiego piękna, służąc jako strażnik i tworząc kinowe, oniryczne doświadczenie dla widza.',
  /// Tool list
  // ratio
  ratio: 'Współczynnik',
  square1_1: 'Kwadrat (1:1)',
  square: 'Kwadrat',
  portrait2_3: 'Portret (2:3)',
  portrait: 'Portret',
  landscape3_2: 'Krajobraz (3:2)',
  wide16_9: 'Szeroki (16:9)',
  wide: 'Szeroki',
  // style
  none: 'Brak',
  enhance: 'Wzmocnienie',
  anime: 'Anime',
  photographic: 'Fotografia',
  digitalArt: 'Sztuka cyfrowa',
  comicBook: 'Komiks',
  fantasyArt: 'Sztuka fantasy',
  analogFilm: 'Film analogowy',
  neonPunk: 'Neon punk',
  lowPoly: 'Low poly',
  origami: 'Origami',
  lineArt: 'Grafika liniowa',
  tileTexture: 'Tekstura płytki',
  threeDModel: 'Model 3D',
  pixelArt: 'Grafika pikselowa',
  craftClay: 'Glina rzemieślnicza',
  // color
  coolTone: 'Chłodny ton',
  mutedColors: 'Stonowane kolory',
  pastelColors: 'Pastelowe kolory',
  vibrantColors: 'Żywe kolory',
  warmTone: 'Ciepły odcień',
  // lighting
  lighting: 'Oświetlenie',
  backlight: 'Podświetlenie',
  crepuscularRays: 'Promienie zmierzchowe',
  dimlyLit: 'Przyćmione oświetlenie',
  goldenHour: 'Złota godzina',
  lowLight: 'Słabe światło',
  rimLighting: 'Oświetlenie krawędziowe',
  studio: 'Studio',
  sunlight: 'Światło słoneczne',
  volumetric: 'Wolumetryczne',
  // composition
  composition: 'Kompozycja',
  blurryBackground: 'Rozmyte tło',
  closeUp: 'Zbliżenie',
  macrophotograph: 'Makrofotografia',
  narrowDepth: 'Wąski zakres',
  shotFromAbove: 'Ujęcie z góry',
  shotFromBelow: 'Ujęcie od dołu',
  wideAngle: 'Szeroki kąt',

  /// FQAs
  allAIImageQuestionsAnswered:
    'Odpowiedzi na wszystkie pytania dotyczące zdjęć generowanych przez sztuczną inteligencję.',
  TTI_Q1: 'Czym są zdjęcia generowane przez AI?',
  TTI_A1:
    'Zdjęcia generowane przez AI to zdjęcia tworzone przy użyciu algorytmów sztucznej inteligencji (AI). Algorytmy te są zaprojektowane tak, aby symulować ludzką kreatywność i mogą generować zdjęcia podobne do tych tworzonych przez ludzkich artystów, ale z unikalnymi i często surrealistycznymi lub abstrakcyjnymi elementami.',
  TTI_Q2: 'Czy istnieje limit ilości zdjęć, które mogę wygenerować dziennie?',
  TTI_A2:
    'Tak, istnieje limit ilości zdjęć, które można wygenerować dziennie. Limit ten zapewnia wszystkim użytkownikom sprawiedliwy dostęp i komfort korzystania z usługi generowania zdjęć AI.',
  TTI_Q3: 'Do you support text prompts in languages other than English?',
  TTI_A3: 'We support only the English language at the moment.',
  TTI_Q4: 'Jaka jest różnica między generowaniem a licencjonowaniem zdjęcia?',
  TTI_A4:
    'Wygenerowanie zdjęcia powoduje utworzenie zestawu zdjęć AI do wyświetlenia. Licencjonując je w Corporate+, uzyskujesz oficjalny dostęp do pobrania wersji zdjęcia AI w wysokiej rozdzielczości, która jest dostarczana z własną licencją na użytkowanie.',
  TTI_Q5:
    'Wygenerowane przeze mnie zdjęcie zawiera rozpoznawalny znak towarowy, punkt orientacyjny lub osobę publiczną. Czy mogę go nadal używać?',
  TTI_A5:
    'Jeśli zdjęcie zawiera rozpoznawalną osobę lub przedmiot lub skupia się głównie na charakterystycznym punkcie orientacyjnym, konieczne będzie uzyskanie zgody bezpośrednio od strony przedstawionej na zdjęciu, zwłaszcza jeśli zamierzasz wykorzystać zdjęcie w celach komercyjnych.',
  TTI_Q6: 'Kim są osoby pojawiające się na wygenerowanych zdjęciach?',
  TTI_A6:
    'Osoby pojawiające się na zdjęciach generowanych przez AI nie są prawdziwymi ludźmi, a raczej cyfrowymi kreacjami.',
  TTI_Q7:
    'Co stanie się z wygenerowaną przeze mnie treścią? Czy zostanie ona udostępniona innym użytkownikom?',
  TTI_A7:
    'Wygenerowana przez użytkownika treść może być również udostępniana innym użytkownikom. Więcej informacji można znaleźć w naszej licencji.',
  TTI_Q8: 'Dlaczego ładowanie trwa długo, gdy próbuję wygenerować zdjęcie?',
  TTI_A8:
    'Wygenerowanie zdjęcia zajmie kilka sekund. Czas ten zależy od złożoności podpowiedzi, dostępnego sprzętu i zasobów obliczeniowych, a także ilości żądań, które sztuczna inteligencja musi obsłużyć jednocześnie.',
  TTI_Q9: 'Jak mogę licencjonować wygenerowane przeze mnie zdjęcia AI?',
  TTI_A9:
    'Utworzone zdjęcia AI będzie można licencjonować, pobierając je ze swojego konta Corporate+. Wraz z każdym pobraniem otrzymasz dostępną wersję wygenerowanego zdjęcia w wysokiej rozdzielczości oraz gwarancję prawną w wysokości 25 000 USD.',
  TTI_Q10: 'Czy mogę przeglądać poprzednie generacje?',
  TTI_A10:
    'Tak, możesz to zrobić, dodając do zakładek i odświeżając adres URL poprzednich generacji. Możesz również udostępniać wyniki generacji swoim znajomym.',
  TTI_Q11: 'Jak wygenerować wysokiej jakości zdjęcia za pomocą AI?',
  TTI_A11: `<ol>
              <li>Zacznij od jasnego celu: "Wysokiej jakości zdjęcie górskiego krajobrazu na bloga podróżniczego".</li>
              <li>Określ pożądany styl i nastrój: "Zdjęcie powinno być jasne i żywe z naturalnym, organicznym charakterem."</li>
              <li>Opisz temat: określ temat zdjęcia, w tym szczegóły, takie jak sceneria, oświetlenie i inne ważne cechy lub elementy. Na przykład: "Przedstaw ośnieżony szczyt górski z krętą rzeką na pierwszym planie i czystym błękitnym niebem w tle".</li>
              <li>Podaj przykłady: dołącz przykłady innych zdjęć, które mają podobny styl lub nastrój do tego, którego szukasz. Może to pomóc w kierowaniu algorytmem sztucznej inteligencji i upewnić się, że wynik spełnia Twoje oczekiwania.</li>
              <li>Użyj odpowiednich słów kluczowych: "górski krajobraz", "ośnieżony szczyt" lub "jasny i żywy".</li>
              <li>Unikaj dwuznaczności: Przekazując instrukcje, bądź jak najbardziej jasny i konkretny oraz unikaj używania niejasnego lub niejednoznacznego języka, który mógłby prowadzić do nieoczekiwanych rezultatów.</li>
            </ol>`,

  /// Generating page
  bringingYourImagesToLife: 'Ożyw swoje zdjęcia...',
  cplusAiGenerator: 'Generator AI',
  generatedImages: 'Wygenerowane zdjęcia',
  promptSettings: 'Ustawienia podpowiedzi',
  pastGeneration: 'Poprzednie generacje',
  generateVariation: 'Generowanie wariantów',
  iLikeThis: 'Podoba mi się to',
  iDislikeThis: 'Nie podoba mi się to',
  shareOptions: 'Udostępnianie opcji',
  variations: 'Warianty',
  copiedSharedUrl: 'Skopiowano udostępniony adres URL do schowka',

  galleryPrompt1: 'Eteryczna skromna syrena, pod wodą, korona z muszli otoczona rybami, portret',
  galleryPrompt2: 'obfita niedzielna pieczeń na stole pełnym jedzenia i świec',
  galleryPrompt3: 'Uroczy, uśmiechnięty puszysty lis w lesie, bardzo szczegółowy, powiększony z innymi zwierzętami leśnymi',
  galleryPrompt4: 'wysoka jakość, 8K Ultra HD, kształty instrumentów muzycznych wewnątrz dyni wykonanej z kryształu, wysoka szczegółowość, magiczna lampa z dyni, jesienna atmosfera, spokój',
  galleryPrompt5: 'Szkocja - panoramiczny, filmowy krajobraz, jasny, piękny dzień',
  galleryPrompt6: '3d mała dziewczynka, 4k, 8k, oktanowy render fotorealistyczny, hdr, fotografia, wysoka rozdzielczość, symetryczna twarz, oświetlenie wolumetryczne, zakurzona mgiełka, zdjęcie, oktanowy render, 24mm, 4k, 24mm, DSLR, wysoka jakość, 60 fps, ultra realistyczny',
  galleryPrompt7: 'brązowoskóry mężczyzna, bohater uderzający planetę Jowisz w kosmosie, enface',
  galleryPrompt8: 'ciasteczko w ciepłej kawie, w świątecznej atmosferze',
  galleryPrompt9: 'Artystyczna butelka wody na białym minimalistycznym tle z liśćmi Montserra',
  galleryPrompt10: 'Emanujący diamentowy smok fantasy',
  galleryPrompt11: 'planety w kroplach rosy, planetarne krople rosy, o wschodzie słońca, zbliżenie, bardzo szczegółowe, ostrość, zdjęcie studyjne, skomplikowane szczegóły, bardzo szczegółowe',
  galleryPrompt12: 'sroka siedząca na skrzyni ze złota, w stylu realistycznej grafiki fantasy, kamień szlachetny, mroczne bajki, tajemniczy',
  galleryPrompt13: 'góra śniegu i ślad lodu na szczycie góry idącej w drugą stronę, ultra hd, realistyczne, żywe kolory, bardzo szczegółowe, rysunek UHD, pióro i atrament, idealna kompozycja, piękny szczegółowy skomplikowany szalenie szczegółowy render oktanowy trendy na artstation, fotografia artystyczna 8k, fotorealistyczna grafika koncepcyjna, miękkie naturalne wolumetryczne kinowe doskonałe światło',
  galleryPrompt14: 'Era cywilizacji międzygwiezdnej, stojąca wysoko z widokiem na tajemniczą cyfrową przestrzeń super sci-fi obcych budynków miejskich, Wykwintny piękny zewnętrzny super wieżowiec, ulica, podniebny most, podniebna architektura super sci-fi, maszyna wojenna, high-tech, niezliczona ilość elektronicznych świateł ekranowych, Super Sci-Fi Visual Feast Space, Sci-Fi, Mystery, sci-fi, surrealistyczne, super wyraźne zdjęcia, piksele HD, ultra-HD, 5D, rozdzielczość 8K, piksele doskonałe, doskonałe ultra-drobne szczegóły, ostrość, zdjęcie studyjne, skomplikowane szczegóły, bardzo szczegółowe',
  galleryPrompt15: 'Piękna plaża z oceanem w tle w Florida Keys w nocy, neonowe kolory, czarne tło, spokojne fale, naturalny odcień, zachód słońca, spokojny, relaksujący zoom w falach, plaża, wyprostowany horyzont, różowy, niebieski, pomarańczowy, fioletowy',
  galleryPrompt16: 'najlepsza jakość, podwójna ekspozycja, sztuka pocztowa, sztuka na popękanym papierze, z bliska uroczy kotek bawiący się kłębkiem wełny, szczegółowe przytulne wnętrze pokoju, błyszczące, światło słoneczne, promienie słońca, dynamiczna poza, ilustracja do książki, 2d, płaskie, urocze, urocze, vintage, bajka, patchwork, witraż, szczegółowa ilustracja do książki, kinowe, bardzo szczegółowe, drobne szczegóły, piękne szczegóły, mistyczne, żywe kolory, złożone tło',
  galleryPrompt17:
    'piramida na wodzie z odbiciami ognia, góry, mroczny surrealizm, nastrojowe portrety, ostrość',
  galleryPrompt18: 'czerwona panda w lesie, filmowe',
  galleryPrompt19: 'morze, księżyc, ryby, rozgwieżdżone niebo, łódź, kolorowa ilustracja',
  galleryPrompt20:
    'Dynamiczne miejskie refleksy tańczą na tle nowoczesnych budynków i połyskującej wody w minimalistycznym abstrakcyjnym stylu, uchwycając żywą wieczorną energię, geometryczną abstrakcję, współczesny styl pop-art z neonowym oświetleniem i akrylową techniką malowania.',
  galleryPrompt21:
    'Widok z tyłu sylwetki kobiety ze świecącym mieczem, okrągły portal w arktyce prowadzący do gór lodowych, filmowe ujęcie średnie, monumentalna postać',

  /// error page
  copyrightDetected: 'Wykryte chronione prawem autorskim terminy/osoby lub znaki towarowe',
  copyrightDetectedDesc:
    'Jesteśmy zobowiązani do bycia odpowiedzialnym dostawcą sztucznej inteligencji i nie będziemy generować wyników dla chronionych prawem autorskim znaków towarowych, terminów, marek lub osobowości zawartych w podpowiedziach, aby zapobiec potencjalnym naruszeniom. Nasze Warunki świadczenia usług stanowią, że NIE wolno korzystać z usług w sposób, który narusza, sprzeniewierza lub narusza jakiekolwiek prawa własności intelektualnej. Prosimy o zmianę podpowiedzi i ponowną próbę.',
  errorMesTTI: 'Ups! Przepraszamy, ale coś poszło nie tak.',
  errorMesTTIDesc: 'Problem został zarejestrowany w celu wyjaśnienia. Spróbuj ponownie później.',
  tryAgain: 'Spróbuj ponownie',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',
  // download feature
  downloadQuotaFinished:
    'You have reached the maximum quota available. Please contact your system administrator.',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: 'Znajdź zachwycające zdjęcia. Wyszukiwanie według słów kluczowych lub frazy.',
  searchBarPlaceholderAllImageTablet: 'Znajdź piękne zdjęcia.',
  searchBarPlaceholderAllImageMobile: 'Znajdź zdjęcia.',
  searchBarPlaceholderPhotoDesktop: 'Znajdź zachwycające zdjęcia. Wyszukiwanie według słów kluczowych lub frazy.',
  searchBarPlaceholderPhotoTablet: 'Znajdź piękne zdjęcia.',
  searchBarPlaceholderPhotoMobile: 'Znajdź zdjęcia.',
  searchBarPlaceholderVectorDesktop: 'Znajdź zachwycające wektory. Wyszukiwanie według słów kluczowych lub frazy.',
  searchBarPlaceholderVectorTablet: 'Znajdź piękne wektory.',
  searchBarPlaceholderVectorMobile: 'Znajdź wektory.',
  searchBarPlaceholderFootageDesktop: 'Znajdź zachwycające film. Wyszukiwanie według słów kluczowych lub frazy.',
  searchBarPlaceholderFootageTablet: 'Znajdź piękne film.',
  searchBarPlaceholderFootageMobile: 'Znajdź film.',
  searchBarPlaceholderAudioDesktop:
    'Znajdź zachwycające pliki audio i muzyczne. Wyszukiwanie według słów kluczowych lub frazy.',
  searchBarPlaceholderAudioTablet: 'Znajdź piękne pliki audio i muzyczne.',
  searchBarPlaceholderAudioMobile: 'Znajdź pliki audio i muzyczne.',
  searchBarPlaceholderFreeDesktop: 'Znajdź zachwycające darmowe zdjęcia. Wyszukiwanie według słów kluczowych lub frazy.',
  searchBarPlaceholderFreeTablet: 'Znajdź piękne darmowe zdjęcia.',
  searchBarPlaceholderFreeMobile: 'Znajdź darmowe zdjęcia.',
  // editorial warning
  forEditorialUseOnly: 'Tylko do użytku redakcyjnego',
  forEditorialUseOnlyDesc:
    'użycie tego zdjęcia bez zezwolenia do celów reklamowych lub promocyjnych jest zabronione.',

  // download comp
  downloadHiResComp: 'POBIERZ PREWKĘ HI-RES',
  downloadComp: 'POBIERZ PREWKĘ',

  generateTerm: ({ callback }) => (
    <>
      By clicking “Generate”, you hereby agree that the generated Output is solely governed by the
      {' '}
      <span aria-hidden onClick={callback}>
        AI Generator Terms of Service.
      </span>
      {' '}
    </>
  ),

  textToImage: 'Tekst do zdjęcia',
  descTourTTI: 'Puść wodze fantazji i twórz zdjęcia z tekstem!',
  colorAndTone: 'Kolor i tonacja',
  titleSuccessDownload: 'Cieszymy się, że znalazłeś to, czego potrzebowałeś.',
  hangInThere: 'Poczekaj! To nie potrwa długo.',
  adultContentTitle: 'Wykryte potencjalne wyrażenia dotyczące treści dla dorosłych',
  adultContentDesc:
    'Zobowiązujemy się do odpowiedzialnego użycia sztucznej inteligencji i dlatego nie generujemy wyników dla niektórych fraz, pojęć i / lub pomysłów. Prosimy o zmianę opisu podanego do wygenerowania zdjęcia i spróbowanie ponownie.',
  reachedTheLimit: 'O nie! Osiągnąłeś limit generowania zdjęć AI na dziś.',
  backTomorrow: 'Wróć jutro po więcej generacji!',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder: 'Na początek opisz swoją wizję tutaj, a my wprowadzimy ją w życie!',

  downloadAgain: 'Download again',
  btnClose: 'Zamknij',

  fluxPro: 'Flux oferuje zaawansowane, wysokiej jakości i zróżnicowane generowanie obrazów.',
  flux: 'Nasz najszybszy model, zaprojektowany specjalnie do lokalnego rozwoju.',
  ideogram: 'Przekształcanie opisu tekstowego w piękne obrazy w kilka sekund.',
  realStockPhoto: 'Twórz przyciągające uwagę zdjęcia stockowe z pomocą naszego zaawansowanego modelu AI.',
  realCinematic: 'Model specjalizujący się w produkcji kinowych wyników, doskonały przy szerszych proporcjach obrazu.',
  realFood: 'Zwiększ rozpoznawalność swojej marki kulinarnej za pomocą modelu wizualizacji jedzenia.',
  realMacro: 'Świetny model do generowania szczegółowych obrazów makro.',
  realBokeh: 'Fotorealistyczny model stylu, idealny do tworzenia oszałamiających obrazów z efektem bokeh.',
  variousTitle: 'Odkryj nasze różne modele',
  variousSDXL: 'Ożywiaj niesamowite, fotorealistyczne postacie i sceny.',
  amazonNova: 'Nowoczesny model generowania obrazów.',
  variousEimisAnime:
    'Generatywny model sztucznej inteligencji przeznaczony do tworzenia zdjęć w stylu anime.',
  variousDynaVisionXL: 'Generowanie stylizowanych postaci z kreskówek 3D.',
  variousJuggernautXL: 'Skoncentrowane wokół elementów architektonicznych i krajobrazów/tła.',
  variousDreamShaperXL:
    'Skalowanie w wysokiej rozdzielczości, ulepszone generowanie postaci i smoków, zmniejszone rozmycie krawędzi.',
  variousRealismEngineSDXL:
    'Model ten charakteryzuje się bardzo szczegółowymi, realistycznymi zdjęciami.',
  variousDALLE:
    'DALL-E 3, wszechstronny model, wyróżnia się precyzyjnym przekształcaniem koncepcji w obraz.',
  aiEngine: 'Mechanizm AI',
  'DALL-E': 'DALL-E 3',

  langText: 'Tekst',
  langElements: 'Elementy',
  langAuto: 'Auto',
  langBgRemoval: 'Usuwanie tła',
  resetAll: 'Zresetować Wszystk',
  facebookImage: 'Facebook zdjęcie',
  facebookLink: 'Facebook link',
  facebookCover: 'Facebook cover',
  facebookStory: 'Facebook story',
  instagramPost: 'Instagram post',
  instagramStory: 'Instagram story',
  twitterPost: 'Twitter post',
  twitterHeader: 'Twitter header',
  pinterestPost: 'Pinterest post',
  emailHeader: 'Email header',
  presentation: 'Prezentacja',
  presentationWide: 'Szeroka prezentacja',
  ebookCover: 'E-book okładka',
  linkedinPost: 'LinkedIn post',
  brochureCover: 'Broszura okładka (A4)',
  postcard: 'Pocztówka',
  photoPrint: 'Wydruk zdjęcia',
  youtubeProfileImage: 'Zdjęcie profilowe na YouTube',
  youtubeChannelCoverPhoto: 'Zdjęcie na cover na YouTube',
  langApplyBgRemoval: 'Zastosuj usunięcie tła',
  autoBnW: 'Auto cz/b',
  autoAdjust: 'Automatyczne dopasowanie',
  autoPop: 'Auto Pop',
  original: 'Oryginał',
  addHeader: 'Dodaj nagłówek',
  addTitle: 'Dodaj tytuł',
  addBody: 'Dodaj treść',
  addAssistive: 'Dodaj tekst pomocniczy',
  langCompare: 'Porównaj',
  langEnableEditor: 'Edytuj zdjęcie',
  langSimilarStock: 'Podobne zdjęcia stockowe',
  langTextUndo: 'Cofnij',
  langTextRedo: 'Popraw',
  langTextFont: 'Czcionka',
  langTextFontSize: 'Rozmiar czcionki',
  langTextColor: 'Kolor tekstu',
  langTextHighlight: 'Wyróżnienie',
  langTextAlignment: 'Wyrównanie',
  langTextBold: 'Pogrubienie',
  langTextItalic: 'Kursywa',
  langTextUnderline: 'Podkreślenie',
  langTextLineSpacing: 'Odstępy między wierszami',
  langTextLetterSpacing: 'Odstępy między literami',
  langBringForward: 'Przesuń do przodu',
  langBringBackward: 'Przesuń do tyłu',
  langDuplicate: 'Duplikacja',
  langOpacity: 'Nieprzezroczystość',
  langDelete: 'Usuń',
  imageProperties: 'Właściwości zdjęcia',
  noEditCompareMsg: 'Nie wprowadzono żadnych zmian do porównania.',
  stockPhoto: 'Zdjęcie Seryjne',
  basicShape: 'Podstawowe kształty',
  chitChat: 'Czat',
  shapes: 'Kształty',
  elementChitChat: 'Element Chat',
  organicAbstractShapes: 'Organiczne, abstrakcyjne kształty',
  elementShapes: 'Kształty elementów',
  langApplyingEdit: 'Wprowadzanie zmian...',
  langTextAllFonts: 'Wszystkie czcionki',
  langSearchFontType: 'Wyszukaj typ czcionki',
  editorCompareImageBeforeTitle: 'Przed',
  editorCompareImageAfterTitle: 'Po',
  langSizePx:
    'Piksel jest podstawową jednostką zdjęcia cyfrowego. Rozmiar zdjęcia, mierzony w pikselach, określa poziom szczegółowości i rozmiar pobierania.',
  langSizeDpi:
    'Liczba punktów na cal oznacza rozdzielczość druku. Wyższa rozdzielczość oznacza więcej szczegółów. W przypadku drukowania zalecana rozdzielczość dla wszystkich zdjęć wynosi 300 dpi',
  cautionTTI:
    'Uwaga: Wykryliśmy użycie w podpowiedzi zwrotów związanych ze znakami towarowymi, prawami autorskimi, słynnymi punktami orientacyjnymi i/lub osobami.  Pamiętaj, że ponosisz wyłączną odpowiedzialność za korzystanie z Treści, w tym za to, że nie narusza ona żadnych obowiązujących przepisów ani praw.',

  addImageReference: 'Dodaj odnośnik do zdjęcia (opcjonalnie)',
  uploadImageOnly: 'Prześlij tylko JPG lub PNG. Maksymalnie 4MB.',
  uploadJPGImageOnly: 'Prześlij tylko JPG. Maksymalnie 4MB.',
  dragAndDrop: 'Przeciągnij i upuść plik lub',
  errorFileSize: 'Ten plik jest za duży. Dozwolone jest maksymalnie 4MB.',
  adjustScale: 'Dostosuj tę skalę, aby wygenerować bardzo podobne lub różne wyniki od przesłanego zdjęcia. Zalecany zakres to od 7 do 15.',
  similar: 'Podobne',
  different: 'Różne',
  uploadNew: 'Załaduj nowy',

  imageGuideGeneration: 'Twoje zdjęcie będzie kierowało Twoją podpowiedzią podczas generowania.',
  notAvailableForEngine: 'Niedostępne dla wybranego silnika AI.',

  imagePromptDescribe: 'Na początek podpowiedz, jaki obraz ma wygenerować nasza sztuczna inteligencja, opisując to, co masz w głowie.',
  fileTypeNotSp: 'Typ pliku nie jest obsługiwany.',
  theFileSizeTooSmall: 'Rozmiar pliku jest zbyt mały. Dozwolone minimum 512 pikseli.',

  relatedSearchPhoto: 'Powiązane wyszukiwanie zdjęć stockowych',
  STOCK_PHOTO_PATH: 'zdjecia-seryjne',
  STOCK_FOOTAGE_PATH: 'stock-wideo',
  STOCK_AUDIO_PATH: 'stock-audio',
  CLIPART_VECTOR_PATH: 'cliparty-wektory',
  ALL_IMAGES: 'Wszystkie Obrazy',
  PHOTO: 'Photo',
  PHOTOS: 'Zdjęcia',
  VECTORS: 'Wektory',
  FOOTAGE: 'Wideo',
  AUDIO: 'Audio',
  LANG_MORETHANMILLION: 'ZDJĘCIA STOCKOWE',
  LANG_SVG_OR_EPS: 'SVG lub EPS',
  LANG_OR: 'lub',
  STANDARD_LICENSE: 'Licencja standardowa',
  EXTENDED_LICENSE: 'Licencja rozszerzona',
  CREDIT_PACK: 'Kredyty',
  DOWNLOAD_PACK: 'Pakietu zdjęć',
  SUBSCRIPTION_PLAN: 'Subskrypcja',
  LANG_DOWNLOAD: 'Pobierz',
  STOCKPHOTO: 'Zdjęcie Seryjne',
  STOCK_PHOTO: 'Zdjęcie stockowe',
  STOCK_VECTOR: 'Wektor stockowy',
  PREMIUM_STOCKPHOTO: 'PREMIUM Stock Photo',
  PLUS_STOCK_PHOTO: 'PLUS Stock Photo',
  PLUS_STOCK_VECTOR: 'PLUS Stock Vector',
  MOBILE_STOCKPHOTO: 'Mobile Stock Photo',
  STOCKFOOTAGE: 'Wideo',
  IMAGEID: 'ID zdjęcia',
  FONTID: 'ID',
  MEDIATYPE: 'Rodzaj mediów',
  COPYRIGHT_TEXT: 'Prawo autorskie',
  KEYWORDS: 'Słowa Kluczowe',
  LANG_CANCEL: 'Anuluj',
  LANG_PURCHASE: 'Zakupy',
  LANG_STOCKVECTORSANDILLUS: 'Stockowe ilustracje oraz ilustarcje wektorowe',
  LANG_STOCKPHOTOSANDIMAGES: 'zdjęcia stockowe',
  LANG_MATCHES: 'dopasowania',
  LANG_VECTORRESULTS: '{number} {keyword} znalezionych stockowych wektorów royalty-free',
  LANG_PHOTORESULTS: '{number} {keyword} znalezionych stockowych zdjęć royalty-free',
  LANG_SIMILARIMGS: 'Podobne zdjęcia Royalty-Free',
  LANG_COLLECTIONS: 'Kolekcje',
  LANG_ALL: 'Wszystkie',
  LANG_STD: 'Standardowa',
  LANG_EXTD: 'Rozszerzona',
  LANG_COMMERCIAL: 'Commercial',
  LANG_SUBSCRIPTION: 'Abonament',
  LANG_EDITORIAL: 'Redakcyjna',
  LANG_UPLOADED: 'Dodane',
  LANG_FRESHNESS: 'Czas trwania',
  LANG_ANYTIME: 'Kiedykolwiek',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: '< 2 dni',
  LANG_PASTWEEK: 'ostatni tydzień',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/miesiąc",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/rok",
  LANG_PASTMONTH: 'ostatni miesiąc',
  LANG_PAST3MONTHS: '3 miesiące',
  LANG_NUMOFPPL: 'Liczba osób',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: 'Nie otrzymałeś e-maila weryfikacyjnego?',
  LANG_WITHOUTPEOPLE: 'Bez ludzi',
  LANG_PERSON: 'osoba',
  LANG_PEOPLE: 'Ludzie',
  LANG_INFANTS: 'Niemowlęta',
  LANG_TODDLERS: 'Małe dzieci',
  LANG_TEENS: 'Nastolatki',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: 'kaukaskie',
  LANG_BLACK: 'afrykańskie',
  LANG_HISPANIC: 'latynoskie',
  LANG_EAST_ASIAN: 'wschodnioazjatyckie',
  LANG_SOUTHEAST_ASIAN: 'południowowschodnie',
  LANG_INDIAN: 'hinduskie',
  LANG_MIDDLE_EASTERN: 'środkowowschodnie',
  LANG_AGE: 'Wiek',
  LANG_ALL_SHAPE: 'Zobacz wszystko',
  LANG_ETHNICITY: 'Pochodzenie etniczne',
  LANG_PEOPLEORMORE: '4 osoby lub więcej',
  LANG_COLOR: 'Kolor',
  LANG_OTHEROPTION: 'Inne opcje',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: 'Jednolite/przezroczyste tło',
  LANG_FILTER_BY: 'Filtrowanie według',
  LANG_FILTER: 'Filtry',
  LANG_PHOTOGRAPHY: 'zdjęcia',
  LANG_VECTORILLUST: 'wektory',
  LANG_SORTBY: 'Sortuj według',
  LANG_MORE: 'Więcej',
  LANG_RELEVANCE: 'trafności',
  LANG_RESEND: 'Wyślij ponownie',
  LANG_RESEND_VERIFICATION: 'Wyślij ponownie e-mail weryfikacyjny',
  LANG_NEWEST: 'od najnowszych',
  LANG_GEORANK: 'pozycji geograficznej',
  LANG_ORIENTATION: 'Orientacja',
  LANG_HORIZONTAL: 'pozioma',
  LANG_PORTRAIT: 'portret',
  LANG_SQUARE: 'kwadrat',
  LANG_PANORAMA: 'Panorama',
  LANG_SELECTIVE_FOCUS: 'Selektywna ostrość',
  LANG_PATTERN: 'Wzór',
  LANG_VIBRANCE: 'Jaskrawość',
  LANG_VERIFICATION_DESCRIPTION: 'Przed pobraniem treści z serwisu 123RF należy zweryfikować swoje konto. Prosimy o sprawdzenie wiadomości e-mail lub kliknięcie poniższego łącza, jeśli chcesz ją ponownie wysłać.',
  LANG_VERIFICATION_TITLE: 'Zweryfikuj swój e-mail.',
  VERIFICATIONRESETUNABLE: 'Nie udało się wysłać Twojej wiadomości e-mail. Skontaktuj się z naszym działem ',
  LANG_CHOOSEUPTO3COLORS: 'Maksymalnie 3 kolory',
  LANG_ISOLATED: 'Odizolowane',
  LANG_STYLE: 'Styl',
  LANG_SAFESEARCH: 'Filtr SafeSearch',
  LANG_ONLY_AUTHENTIC: 'Wyłącznie naturalne',
  LANG_EXCLUDE_PLACEHOLDER: 'np. ser',
  LANG_EXCLUDEWORDS: 'Nie wyszukuj tych słów',
  LANG_EXCLUDE: 'Wyłącz',
  LANG_INCLUDE_AICONTENT: 'Uwzględnij treści generowane przez AI',
  VISUAL_SEARCH: 'Wyszukiwanie wizualne',
  VISUAL_SEARCH_TOOLTIP_TITLE: 'Trzy proste sposoby na dostosowanie wyszukiwania!',
  VISUAL_SEARCH_TOOLTIP_INFO_1: '1. Kliknij kropki na zdjęciu, aby zidentyfikować szukany element.',
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2. Przeciągnij pole funkcji przycinania, aby powiększyć lub zmniejszyć obszar wyszukiwania.',
  VISUAL_SEARCH_TOOLTIP_INFO_3: "3. Kliknij 'X', aby powrócić do wyszukiwania",
  VISUAL_SEARCH_NO_RESULTS: 'Brak wyników.',
  LANG_RIS_TITLE: 'Wyszukiwanie za pomocą zdjęcia',
  LANG_RIS_NOTFOUND: 'Brak wyników!',
  LANG_RIS_ERROR_HEADER: 'Przesłane zdjęcie zawiera błędy, upewnij się, że zdjęcie spełnia następujące kryteria:',
  LANG_RIS_REFINE_SRCH_MSG: 'Sprawdź zawężenie wyszukiwania za pomocą słowa kluczowego',
  LANG_RIS_UPLOADED_IMAGE: 'Przesłane zdjęcie',
  LANG_TELL_ME_MORE: 'Dowiedz się więcej',
  LANG_ENTERKEYWORD: 'Wpisz słowo kluczowe',
  LANG_SIZE: 'Rozmiar',
  LANG_STANDARD_SIZE: 'Rozmiary standardowe',
  LANG_EXTENDED_SIZE: 'ROZSZERZONE ROZMIARY',
  LANG_SIZE_PX: 'Piksel jest podstawową jednostką zdjęcia cyfrowego. Rozmiar zdjęcia, mierzony w pikselach, określa poziom szczegółowości i rozmiar pobierania.',
  LANG_SIZE_DPI: 'Liczba punktów na cal oznacza rozdzielczość druku. Wyższa rozdzielczość oznacza więcej szczegółów. W przypadku drukowania zalecana rozdzielczość dla wszystkich zdjęć wynosi 300 dpi',
  LANG_SOCMED_SIZE: 'Rozmiary do mediów społecznościowych',
  LANG_COMPARE: 'Porównaj',
  LANG_AUTO_ENHANCE: 'Automatyczne wzmocnienie',
  LANG_AUTO: 'Auto',
  LANG_BG_REMOVAL: 'Usuwanie tła',
  LANG_TEXT: 'Tekst',
  LANG_ELEMENTS: 'Elementy',
  LANG_IMAGE_INFO: 'Informacje o zdjęciu',
  LANG_FOLLOW: 'Obserwuj',
  LANG_FOLLOWED: 'Obserwowane',
  LANG_FOLLOWING: 'Kolejny',
  FACEBOOK_IMAGE: 'Facebook zdjęcie',
  FACEBOOK_LINK: 'Facebook link',
  FACEBOOK_COVER: 'Facebook cover',
  FACEBOOK_STORY: 'Facebook story',
  INSTAGRAM_POST: 'Instagram post',
  INSTAGRAM_STORY: 'Instagram story',
  TWITTER_POST: 'Twitter post',
  TWITTER_HEADER: 'Twitter header',
  PINTEREST_POST: 'Pinterest post',
  EMAIL_HEADER: 'Email header',
  PRESENTATION: 'Prezentacja',
  PRESENTATION_WIDE: 'Szeroka prezentacja',
  EBOOK_COVER: 'E-book okładka',
  LINKEDIN_POST: 'LinkedIn post',
  BROCHURE_COVER: 'Broszura okładka (A4)',
  POSTCARD: 'Pocztówka',
  PHOTO_PRINT: 'Wydruk zdjęcia',
  YOUTUBE_PROFILE_IMAGE: 'Zdjęcie profilowe na YouTube',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'Zdjęcie na cover na YouTube',
  AUTO_COLOR: 'Kolor automatyczny',
  BALANCE: 'Balans',
  AUTO_LEVEL: 'Automatyczny poziom',
  AUTO_TONE: 'Auto-tone',
  ENRICH: 'Wzbogacanie',
  RESET: 'Resetowanie',
  RESET_ALL: 'Zresetować Wszystk',
  FILE_DOWNLOAD_OPTIONS: 'Opcje pobierania plików',
  FILE_DOWNLOAD_INITIATED: 'Rozpoczęcie pobierania',
  IMAGE_PROPERTIES: 'Właściwości zdjęcia',
  DOWNLOAD_DETAILS: 'Pobierz właściwości',
  FILE_SIZE: 'Rozmiar pliku',
  LICENSE: 'Licencja',
  FILEFORMAT: 'Typ pliku',
  CHANGE: 'Zmień',
  PRICING: 'Cennik',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: 'Zapisz jako',
  IMAGE_USAGE: 'Wykorzystanie zdjęcia',
  PRINT: 'Na druk',
  ELECTRONIC: 'Elektroniczna',
  COMPREHENSIVE: 'Pełna',
  DOWNLOAD_CREDITS: '<b>{quota}</b> kredytów z Twojego Pakietu kredytów',
  BASIC_SHAPE: 'Podstawowe kształty',
  CHIT_CHAT: 'Czat',
  SHAPES: 'Kształty',
  ELEMENT_CHIT_CHAT: 'Element Chat',
  ORGANIC_ABSTRACT_SHAPES: 'Organiczne, abstrakcyjne kształty',
  ELEMENT_SHAPES: 'Kształty elementów',
  BUY_SINGLE_IMAGE: 'Kup pojedyncze zdjęcie <br/><b>{quota} kredytów</b>',
  BUY_SINGLE_IMAGE_CREDIT: 'Kup kredyty <br/><b>{quota} kredytów</b>',
  BUY_CREDIT_PACK: 'Kup pakiet kredytów i <br/><b>zaoszczędź więcej</b>',
  BUY_CREDIT_PACK_POINT_1: 'Pakiet {quota} kredytów za <b>{price}</b>',
  BUY_CREDIT_PACK_POINT_2: 'Bez miesięcznych zobowiązań.',
  BUY_CREDIT_PACK_POINT_3: '<b>12-sto</b> miesięczna ważność.',
  BUY_SINGLE_IMAGE_PSUB: 'Kup pojedyncze zdjęcie <br/><b>{quota} pobranie</b>',
  BUY_DOWNLOAD_PACK: 'Kup pakiet pobrań i <br/><b>zaoszczędź</b>',
  BUY_DOWNLOAD_PACK_POINT_1: 'Pakiet pobrań {quota} zdjęć za <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: 'Bez miesięcznych zobowiązań.',
  BUY_DOWNLOAD_PACK_POINT_3: '<b>12-sto</b> miesięczna ważność.',
  LOWEST_PRICE: '<b>Najniższą cenę</b> <br/>gwarantuje subskrypcja',
  LOWEST_PRICE_POINT_1: 'Wypróbuj 1 miesiąc za <b>{price}</b>',
  LOWEST_PRICE_POINT_2: 'Pobierz 10 zdjęć lub wektorów.',
  LOWEST_PRICE_POINT_3: 'Bez dziennego limitu, z możliwością przeniesienia niewykorzystanych pobrań na następny miesiąc.',
  PER_IMAGE_SIZE: 'za zdjęcie w rozmiarze <b>{size}</b>',
  PER_IMAGE_ANY: 'za zdjęcie <b>w dowolnym rozmiarze</b>',
  PER_IMAGE: 'za zdjęcie',
  VIEW_PRICING: 'Więcej planów i cen',
  DOWNLOAD_BEGIN: 'Pobieranie rozpocznie się za chwilę.',
  DOWNLOAD_NOT_STARTING: 'Jeśli pobieranie się nie rozpoczęło?',
  SELECT_MIRROR: '[Wybierz serwer]',
  SHOW_MORE: 'Pokaż więcej',
  SHOW_LESS: 'Pokaż mniej',
  LICENSE_TYPE: 'Rodzaj licencji',
  LICENSE_SUMMARY: 'Streszczenie licencji',
  BACK: 'Wróć',
  LANG_VECTOR: 'Wektor',
  PREMIUM_VECTOR: 'PREMIUM Stock Vector',
  SCALE_ANY_SIZE: 'Zmniejsz do jakiegokowiek rozmiaru',
  LANDINGPAGE_VECTOR_HEADLINE: 'Wektory - ilustracje, cliparty i grafiki Royalty Free',
  LANDINGPAGE_PHOTO_HEADLINE: 'Fotografie - zdjęcia Royalty Free',
  CATEGORIES: 'Kategorie',
  LANDINGPAGE_PRICING_1: 'Kredytów',
  LANDINGPAGE_PRICING_2: 'Wykup Subskrypcję',
  LANDINGPAGE_AS_LOW_AS: 'Za jedyne',
  EMPTY_SEARCH_TITLE: 'Twoje wyszukiwanie nie przyniosło żadnych wyników.',
  LANG_SMALL: 'Mały',
  LANG_MEDIUM: 'Średni',
  LANG_LARGE: 'Duży',
  LANG_EXTRA_LARGE: 'Bardzo duży',
  LANG_LOW_CREDITS: 'Wygląda na to, że kończą Ci się kredyty!',
  LANG_TOPUP: 'Top up',
  LANG_TOPUP_NOW: 'Doładuj teraz',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description} Zdjęcia royalty free, obrazki, obrazy oraz fotografia seryjna. Image {mediaId}',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description} SVG, Cliparty, ilustracje stockowe oraz ilustracje wektorowe Royalty Free. Image {mediaId}',
  SEO_DETAILS_METADESC_STOCKPHOTO: 'Zdjęcie {description} obraz seryjny, obrazy i fotografie seryjne. Image {mediaId}',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: 'Zbiór {description} grafik oraz ilustracji wektorowych. Image {mediaId}',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} Darmowe Zdjęcie {mediaId}.',
  SEO_DETAILS_METADESC_FREE_PHOTO: 'Darmowe zdjęcie {description}. Darmowe zdjęcie {mediaId}.',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} Darmowe wektory i grafiki {mediaId}.',
  SEO_DETAILS_METADESC_FREE_VECTOR: 'Darmowy wektor {description}. Darmowa grafika {mediaId}.',
  SEO_DETAILS_OGDESCRIPTION: '123RF - miliony inspirujących zdjęć stockowych, wektorów, filmów i muzyki do Twoich projektów.',
  LIVECHAT_LOOKINGHELP: 'Potrzebujesz pomocy?',
  LIVECHAT_CHATWITHUS: 'CZATUJ Z NAMI!',
  FREE_TRIAL: '<b>Get 10 Free Images</b> <br/>with Subscription Plan',
  LANG_RELATEDSEARCH: 'Hasła pokrewne',
  LANG_RELATED_STOCKPHOTO_SEARCH: 'Powiązane wyszukiwanie zdjęć stockowych',
  LANG_PROPERATTRIBUTE: 'Oto nasza atrybucja:',
  LANG_ATTRIBUTEDESC: 'Prosimy o podanie inforacji o autorze tego zdjęcia poprzez umieszczenie poniższej linii kredytowej w dowolnym miejscu produktu, strony internetowej lub materiałów reklamowych.',
  LANG_ATTRIBUTE_TITLE: 'Podanie informacji o autorstwie jest opcjonalne.',
  LANG_ATTRIBUTE_CREDIT: 'Jeśli chcesz podać informację o autorze, skorzystaj z poniższego linku:',
  LANG_ATTRIBUTE_COPYLINK: 'Kopiuj link',
  LANG_ATTRIBUTE_COPYTEXT: 'Kopiuj Tekst',
  LANG_LICENSE_AGREEMENT: '<a href={url}><b>Tutaj</b></a> znajduje się umowa licencyjna dla tego pobrania',
  LANG_NEXTPAGE: 'Następna Strona',
  LANG_IMAGE_NO_AVAILABLE: 'Zdjęcie nr {mediaId} nie jest już dostępne.',
  LANG_PHOTOGRAPHY_METAIMG_DESC: 'Szukaj i wybieraj spośród milionów zdjęć stockowych HD i zdjęć royalty free',
  LANG_VECTOR_METAIMG_DESC: 'Szukaj i wybieraj spośród milionów zdjęć stockowych HD i zdjęć royalty free',
  LANG_ANOTHERMETAKWDEFAULT: 'royalty free, zdjęcia stockowe, fotografia stockowa, zdjęcia, fotografie',
  LANG_ANOTHERMETADESCDEFAULT: 'Szukaj i wybieraj spośród milionów zdjęć stockowych HD, zdjęć royalty free, clipartów, wektorów i ilustracji',
  EMPTY_FILTERED_RESULT_TITLE: 'Twoje wyszukiwanie z tymi filtrami nie przyniosło żadnych wyników.',
  EMPTY_FILTERED_RESULT_SUBTITLE: 'Spróbuj usunąć niektóre filtry lub wypróbuj inne słowo kluczowe.',
  CLEAR_ALL_FILTER: 'Wyczyść wszystkie filtry',
  PROMOBAR_FREE10_TEXT: 'Get <b>10 FREE Images</b> when you get started on our 1 Month-Free Trial.',
  PROMOBAR_FREE10_BTNLABEL: 'START NOW',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: 'Ogromne obniżki na wszystkie subskrypcje.',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: 'Zobacz ofertę',
  LANG_MODEL_RELEASED: 'Model zrzekł się praw autorskich',
  LANG_PROPERTY_RELEASED: 'Właściciel zrzekł się praw autorskich',
  LANG_MODEL_RELEASE: 'z Model Release',
  LANG_PROPERTY_RELEASE: 'z Property Release',
  LANG_YES: 'Tak',
  LANG_NO: 'Nie',
  LANG_MREXPLAIN: 'Zgoda modela (model release) to dokument prawny podpisany przez modela(i), udzielający zgody na publikację / rozprowadzanie / używanie fotografii z jego czy jej wizerunkiem w takiej czy innej formie.',
  LANG_PREXPLAIN: 'Umowa określająca prawo i sposób wykorzystania zdjęć nieruchomości (property release) to dokument prawny podpisany przez właściciela obiektu, przedmiotu czy miejsca wykonania zdjęcia, udzielający zgody na publikację / rozprowadzanie / używanie fotografii w takiej czy innej formie.',
  LANG_DOWNLOADS: 'Pobrane obrazy',
  LANG_OLDFILENAME: 'Stara nazwa pliku',
  LANG_DOWNLOAD_PREVIEW: 'Pobierz prewkę',
  LANG_SHARE: 'Udostęnij',
  LANG_RESTRICTIONS: 'Restrykcje',
  LANG_READ_MORE: 'Czytaj dalej',
  LANG_EDITORIAL_USE: 'Tylko do użytku redakcyjnego',
  LANG_EDITORIALDESCP: 'Tylko do użycia wydawniczego: To zdjęcie może być używane tylko do celów redakcyjnych. Wykorzystanie tego zdjęcia w reklamie, do celów handlowych lub promocyjnych jest zakazane, chyba że licencjobiorca uzyska odpowiednią zgodę. 123RF.com nie świadczy żadnych usług dotyczących takich zezwoleń.',
  LANG_LOGO_OWNERSHIP: 'Prawa własności do logo',
  LANG_PHOTO_SHOT_SMARTPHONE: 'To zdjęcie zostało zrobione smartfonem.',
  LANG_VECTORNOLOGO: 'Jeśli chciałbyś użyć grafiki jako logo swojej firmy, możemy <b>wykupić prawa</b> w Twoim imieniu. By dowiedzieć się więcej, skontaktuj się z nami pod numerem telefonu <b>{phonenum}</b>',
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> pobranie z Twojej Subskrypcji',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> pobranie z Twojego Pakietu zdjęć',
  LANG_LOGO: 'Logo',
  USING_OLDER_BROWSER: 'Czy używasz starszej wersji przeglądarki?',
  UPGRADE_NEWER_BROWSER: 'Zaktualizuj ją do nowszej wersji, co zapewni jej płynniejsze działanie.',
  CLICK_TO_DOWNLOAD: 'Kliknij, żeby pobrać dowolną z poniższych:',
  LANG_VECTOR_EDITABLE: 'Pliki do edycji w formacie SVG i EPS',
  PROMOBAR_GOOD15_TEXT1: 'Płać mniej, pobieraj jeszcze więcej. ',
  PROMOBAR_GOOD15_TEXT2: '15% zniżki na abonamenty z kodem promocyjnym:',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: 'Płać mniej, pobieraj jeszcze więcej. ',
  PROMOBAR_SUMMER25_TEXT2: '25% zniżki na WSZYSTKO z <b>kodem promocyjnym</b>:',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: '30% zniżki na WSZYSTKO z <b>kodem promocyjnym</b>:',
  NO_EDIT_COMPARE_MSG: 'Nie wprowadzono żadnych zmian do porównania.',
  SWITCH_TO_VECTOR_MSG: 'Edytor zdjęć nie działa w przypadku wektorów. Jeśli przełączysz się na format wektorowy, utracisz wszystkie zmiany.',
  SWITCH_TO_XL_MSG: 'Edytor zdjęć nie działa w przypadku zdjęć w rozmiarze XL. Jeśli przełączysz się na format XL, utracisz wszystkie zmiany.',
  UNAVAILABLE_FOR_THIS_SIZE: 'Niedostępne dla rozmiaru {size}.',
  SWITCH: 'PRZEŁĄCZ',
  SWITCH_LICENSE_EXT_MSG: 'Czy chcesz najpierw pobrać zdjęcie w formacie {type}? Jeśli zmienisz na licencję rozszerzoną, utracisz wszystkie zmiany.',
  LANG_SESSION_EXPIRED: 'Twoja sesja wygasła. <a href={url}>Zaloguj się tutaj</a>',
  LANG_TEXT_COLOR: 'Kolor tekstu',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: 'Wyróżnienie',
  LANG_TEXT_ALIGNMENT: 'Wyrównanie',
  LANG_TEXT_BOLD: 'Pogrubienie',
  LANG_TEXT_ITALIC: 'Kursywa',
  LANG_TEXT_UNDERLINE: 'Podkreślenie',
  LANG_TEXT_BULLET: 'Bullet',
  LANG_TEXT_LINE_SPACING: 'Odstępy między wierszami',
  LANG_TEXT_LETTER_SPACING: 'Odstępy między literami',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: 'Przesuń do przodu',
  LANG_BRING_BACKWARD: 'Przesuń do tyłu',
  LANG_DUPLICATE: 'Duplikacja',
  LANG_OPACITY: 'Nieprzezroczystość',
  LANG_DELETE: 'Usuń',
  LANG_TEXT_ADD: 'Dodaj tekst',
  LANG_TEXT_EDIT: 'Edytuj tekst',
  LANG_TEXT_FONT_TYPE: 'Typ czcionki',
  LANG_TEXT_FONT_SIZE: 'Rozmiar czcionki',
  LANG_TEXT_ALL_FONTS: 'Wszystkie czcionki',
  LANG_TEXT_UNDO: 'Cofnij',
  LANG_TEXT_REDO: 'Popraw',
  LANG_TEXT_FONT: 'Czcionka',
  LANG_SEARCH_FONT_TYPE: 'Wyszukaj typ czcionki',
  LANG_SEARCH_SHAPE_TYPE: 'Wyszukiwanie elementów',
  LANG_DONE: 'Gotowe',
  ADD_HEADER: 'Dodaj nagłówek',
  ADD_TITLE: 'Dodaj tytuł',
  ADD_BODY: 'Dodaj treść',
  ADD_ASSISTIVE: 'Dodaj tekst pomocniczy',
  STOCK_PHOTO_BANNER_TITLE: 'Miliony zdjęć royalty free, dzięki którym stworzysz wszystko!',
  STOCK_PHOTO_BANNER_DESC: 'Znajdź wszystkie oszałamiające zdjęcia, których potrzebujesz, aby stworzyć przyciągające wzrok wizualizacje, które ożywią Twoje pomysły.',
  STOCK_PHOTO_SUBSCRIBE_TITLE: 'Wizualizuj swoje historie za pomocą właściwych zdjęć',
  STOCK_PHOTO_SUBSCRIBE_DESC: 'Uzyskaj natychmiastowy dostęp do ponad 180 milionów zdjęć, aby wzmocnić swój styl opowiadania historii. Nasze materiały wizualne i Twoja historia, to idealne połączenie, aby zmaksymalizować Twój twórczy potencjał!',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: 'Pakiet zdjęć',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: 'Już za',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: 'Subskrypcja',
  STOCK_PHOTO_CARD_IMAGE: 'zdjęcie',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: 'Subskrybuj teraz',
  STOCK_PHOTO_CARD_BUY_NOW: 'Kup teraz',
  STOCK_PHOTO_POPULAR_ROYALTY: 'Popularne kategorie zdjęć royalty free',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: 'Odkryj przystępne plany i ceny za zdjęcia',
  STOCK_PHOTO_DISCOVER_NOW: 'Sprawdź',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: 'Krajobraz',
  STOCK_PHOTO_BABIES: 'Niemowlęta',
  STOCK_PHOTO_BUSINESS: 'Biznes',
  STOCK_PHOTO_FOOD: 'Jedzenie',
  STOCK_PHOTO_ANIMALS: 'Zwierzęta',
  STOCK_PHOTO_TECHNOLOGY: 'Technologia',
  STOCK_PHOTO_HEALTHCARE: 'Opieka zdrowotna',
  STOCK_PHOTO_NATURE: 'Natura',
  STOCK_PHOTO_ROMANCE: 'Romans',
  CLIPART_VECTOR_EXPLORE_MILLIONS: 'Twórz z milionami wektorów royalty free na wyciągnięcie ręki.',
  CLIPART_VECTOR_NEED_AN_EYE: 'Znajdź fajne ilustracje wektorowe, infografiki, kliparty, ikony i wiele innych, które pomogą przekształcić Twoje niesamowite pomysły w rzeczywistość.',
  CLIPART_VECTOR_FIND_VECTOR: 'Znajdź odpowiednie wektory do urozmaicenia Twoich projektów.',
  CLIPART_VECTOR_FIND_VECTOR_DESC: 'Czy to fajne infografiki, ilustracje, kreskówki, cliparty, tła, ikony lub cokolwiek, czego potrzebujesz, aby stworzyć oszałamiające wizualizacje; mamy je tutaj. Wektoryzuj swoje projekty do życia już teraz!',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: 'Popularne kategorie wektorów royalty free',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: 'Odkryj przystępne plany i ceny wektorów',
  CLIPART_VECTOR_DISCOVER_NOW: 'Sprawdź',
  SPEND_AND_WIN_SUBTITLE: 'Każde wydane {currency} = 1 los',
  SPEND_AND_WIN_IMGALT: "Wydawaj i wygraj iPhone'a 13 Pro",
  CLIPART_VECTOR_GRAPHIC: 'Grafika',
  CLIPART_VECTOR_ADVERTISING: 'Reklama',
  CLIPART_VECTOR_FLOWER: 'Kwiat',
  CLIPART_VECTOR_ABSTRACT: 'Abstrakcja',
  CLIPART_VECTOR_POP: 'Pop',
  CLIPART_VECTOR_BIRD: 'Ptak',
  CLIPART_VECTOR_ILLUSTRATION: 'Ilustracja',
  CLIPART_VECTOR_PIXEL: 'Pixel',
  CLIPART_VECTOR_3D: '3D',
  LANG_VECTOR_SVG_EPS: 'Wektor, SVG i EPS',
  FREE_UPGRADE: 'Darmowa aktualizacja',
  FREE_UPGRADE_MESSAGE: 'DARMOWA aktualizacja XL dla subskrypcji lub pakietu zdjęć. Kup teraz.',
  LANG_FREE_IMAGE: '123RF Darmowe',
  LANG_IMAGE_FREE: 'To zdjęcie stockowe jest dostępne bezpłatnie do użytku komercyjnego i osobistego. Wymagane jest wskazanie autorstwa. ',
  LANG_HOW_TO_ATTRIBUTE: 'Jak wskazać autorstwo?',
  LANG_FREE_DOWNLOAD: 'Darmowe pobieranie',
  LANG_FREE_DOWNLOAD_BUTTON: 'FREE Pobieranie',
  LANG_GO_PLUS_BUTTON: 'Przejdź do PLUS',
  LANG_ATTRIBUTION_IS_KEY: 'Wskazanie autorstwa jest ważnym elementem dla twórców treści, którzy udostępniają swoje prace publicznie. Nasi twórcy doceniają fakt, że ich dzieła są wykorzystywane, udostępniane i uznawane przez miliony użytkowników, takich jak Ty.',
  LANG_GET_ATTRIBUTION_LINK: 'Skopiuj ten link i umieść przy publikowanym zdjęciu.',
  LANG_CREDIT_OWNER: 'Prosimy o wskazanie autora:',
  LANG_USE_ATTRIBUTION: 'Użyj poniższego linku.',
  LANG_ATTRIBUTION_LOCATION: 'Jeśli nie jest to możliwe, możesz umieścić podpis w stopce strony internetowej, bloga lub newslettera, lub w sekcji credits na stronie internetowej, gdzie można podać informacje o właścicielu {media_type}.',
  LANG_GOT_A_QUESTION: "Czy masz jakieś pytania? <a href={url} target='_blank'><u>Skontaktuj się z nami</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: 'Prosimy o wskazanie właściciela tego darmowego zdjęcia.',
  LANG_ATTR_FREE_DOWNLOADING: 'Momencik! To nie potrwa długo.',
  LANG_PLUS_CONTENT_1: 'Nieograniczona ilość pobrań',
  LANG_PLUS_CONTENT_2: 'Dostęp do ponad 100 milionów zdjęć i wektorów',
  LANG_PLUS_CONTENT_3: 'Wyjątkowe ilustracje 3D tylko w 123RF!',
  LANG_PLUS_CONTENT_4: 'Nieograniczone pobieranie plików',
  LANG_PLUS_CONTENT_5: 'Nieograniczona wolność twórcza',
  FREE_VECTOR: 'FREE Stock Vector',
  FREE_STOCKPHOTO: 'FREE Stock Photo',
  FREE_PUBLIC_DOMAIN_CC0: 'Domena publiczna CC0',
  BUY_5_DOWNLOAD_PACK: "<b>Oferta limitowana!</b><br/>3 + <b><span style='color:red'>2 GRATIS</span></b> = 5 zdjęć lub wektorów",
  BUY_5_DOWNLOAD_PACK_POINT_2: 'Pobierz w dowolnym czasie (w ciągu roku)',
  BUY_5_GET_2_FREE: '2 zdjęcia GRATIS!',
  LANG_ASK_DOWNLOAD_PLUS: 'Chcesz pobrać treści PLUS?',
  LANG_SIGN_UP_PLUS: 'Zarejestruj się w 123RF PLUS już od ',
  LANG_FOR_ONLY: "<span class='text'>za tylko</span> <span class='price'>{price}</span>/miesiąc",
  LANG_FOR_ONLY_2: "<span class='text'>za tylko</span> <span class='price'>{price}</span>",
  LANG_BENEFIT_PLUS: 'Skorzystaj z PLUS już od ',
  LANG_RELEASES: 'Własności',
  LANG_UNAVAILABLE: 'Niedostępne',
  LANG_ATTR_FREE_IMG_TITLE2: 'Cieszymy się, że znalazłeś to, czego potrzebowałeś. 🎉',
  LANG_PLEASE_NOTE: 'Uwaga: do tego zdjęcia nie dołączono żadnych zgód modela ani własności. Wykorzystanie komercyjne na własne ryzyko. ',
  LANG_PLEASE_ADVISED: 'Proszę o poinformowanie, że obrazy zawierające rozpoznawalne osoby nie są dostarczane z żadnymi wydawnictwem. Aby użyć tych zdjęć w handlu, należy uzyskać zgodę od odpowiednich stron. Jeśli obraz nie zawiera rozpoznawalnych osób, nie powinno być problemów z prawami autorskimi podczas korzystania z niego na rynku.',
  LANG_REACHED_LIMIT: 'Ops! Osiągnąłeś swój dzienny limit pobierania.',
  LANG_COME_TOMORROW: 'Cieszymy się, że podobają Ci się nasze darmowe zdjęcia! Wróć jutro, żeby odświeżyć swój limit pobierania. Dziękujemy 😊',
  LANG_DOWNLOAD_ERROR: 'Twoje zdjęcie nie mogło zostać pobrane z powodu błędu.',
  LANG_REFRESH_AND_TRY_AGAIN: "Odśwież przeglądarkę i spróbuj ponownie. <a href={url} target='_blank'>Daj nam znać </a> jeśli problem będzie się powtarzał.",
  LANG_REACHED_LIMIT_PLUS: 'Osiągnięto codzienne limit pobierania',
  LANG_COME_TOMORROW_PLUS: 'Wow! Osiągnąłeś swój limit pobierania na dziś. Wróć jutro, aby pobrać więcej. Dziękuję za wsparcie!',
  SEE_YOU_TOMORROW: 'Do zobaczenia jutro!',
  ORIGINAL: 'Oryginał',
  'AUTO_B&W': 'Auto cz/b',
  AUTO_ADJUST: 'Automatyczne dopasowanie',
  AUTO_POP: 'Auto Pop',
  NONE: 'Nic',
  LANG_CROP: 'Przytnij',
  LANG_ENABLE_EDITOR: 'Edytuj zdjęcie',
  LANG_DISABLE_EDITOR: 'Zamknij',
  LANG_IMAGE_FREE_CC0: "To jest obraz CC0. Przypisanie nie jest wymagane. Możesz go używać zgodnie z <a class='linkCC0' href={url} target='_blank'>warunkami i warunkami licencyjnymi CC0</a>.",
  LANG_PREVIEW: 'Podgląd',
  LANG_ATTRIBUTION_CC0_TITLE: 'Zdjęcie CCO: wskazanie autorstwa nie jest wymagane',
  LANG_ATTRIBUTION_TITLE: 'Wymagane podawanie autorstwa',
  LANG_ATTRIBUTION_CONTENT: 'Link atrybucyjny zostanie podany po pobraniu pliku.',
  LANG_ATTRIBUTION_CC0_CONTENT: 'Wskazanie autorstwa nie jest wymagane, ponieważ jest to zdjęcie CCO.',
  LANG_SUBCRIBE_PREMIUM: 'Osiągnąłeś dzienny limit pobrań! Aby pobrać więcej zdjęć, zasubskrybuj 123RF PREMIUM.',
  LANG_REMOVE_ATTRIBUTION: 'Usunąć wskazanie autorstwa?',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "Subskrybuj <img src={image} alt='123RF Plus Logo'>, aby uzyskać nieograniczony dostęp do 100 milionów zdjęć i ilustracji bez konieczności wskazywania ich autora.",
  LANG_CREATOR_ATTRIBUTION: 'Prosimy o podanie danych twórcy za pomocą poniższego linku.',
  LANG_GET_ATTRIBUTION_LINK_2: 'Skopiuj ten link i umieść go niedaleko miejsca wykorzystania treści, w stopce strony lub w określonej sekcji.',
  LANG_APPLY_BG_REMOVAL: 'Zastosuj usunięcie tła',
  LANG_APPLYING_EDIT: 'Wprowadzanie zmian...',
  LANG_APPLYING_EDIT_ERROR: 'Coś poszło nie tak.',
  LANG_TRY_AGAIN: 'Spróbować jeszcze raz?',
  LANG_SEARCHCOLLECTION_PREMIUM: 'Pełna kolekcja',
  LANG_SEARCHCOLLECTION_PLUS: 'Kolekcja zasadnicza',
  LANG_SEARCHCOLLECTION_FREE: 'Kolekcja podstawowa',
  LANG_SIMILARIMAGESPAGE_TITLE: 'Subskrypcja na Obrazy seryjne I fotografie',
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: 'Obrazy podobne',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: 'Ups! Nic tu nie widzieć.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: 'Zdjęcie niedostępne',
  LANG_PER_IMAGE: '/zdjęcie',
  LANG_PLANS_AND_PRICING: 'Oferta i cennik',
  LANG_CHOOSE_BEST_PLAN: 'Wybierz najlepszy plan, pasujący do Twoich potrzeb.',
  LANG_WANT_MORE_SAVING_1: 'Chcesz więcej oszczędności?',
  LANG_WANT_MORE_SAVING_2: 'Zobacz więcej planów od {price}/zdjęcie',
  LANG_BEST_VALUE: 'Najlepsza cena, zaoszczędź {percentage}%',
  LANG_YEARLY_PLAN: 'Plan roczny',
  LANG_WITH_MONTHLY_PAYMENTS: 'Z płatnościami miesięcznymi',
  LANG_GRAB_YEARLY_PLAN: 'Skorzystaj z planu rocznego i zaoszczędź {percentage}%.',
  LANG_PRICE_PER_MONTH: '{price} miesiąc',
  LANG_PRICE_PER_YEAR: '{price} rocznie',
  LANG_GET_X_EVERY_MONTH: 'Pobierz {quota} zdjęć lub wektorów co miesiąc',
  LANG_X_EVERY_MONTH: '{quota} zdjęć lub wektorów co miesiąc',
  LANG_SINGLE_IMAGE_PURCHASE: 'Zakup pojedynczego zdjęcia',
  LANG_GET_ONLY_WHAT_YOU_NEED: 'Kup tylko to, czego potrzebujesz na ten moment.',
  LANG_TOTAL_PRICE: 'Łącznie {price}',
  LANG_DOWNLOAD_IMMEDIATELY: 'Zdjęcie gotowe do pobrania natychmiast po dokonaniu zakupu.',
  LANG_NEXT: 'Następny',
  LANG_TITLE: 'Subskrypcja na Obrazy seryjne I fotografie',
  SIMILAR_STOCK_PHOTOS: 'Obrazy podobne',
  EMPTY_SIMILAR_SEARCH_RESULTS: 'Ups! Nic tu nie widzieć.',
  UNAVAILABLE_IMAGE: 'Zdjęcie niedostępne',
  LANG_DOWNLOAD_PACK: 'Pakiet zdjęć',
  LANG_GET_X_ANY_TIME: '{quota} zdjęć lub wektorów w dowolnym momencie',
  LANG_X_CREDITS: '{total} kredytów',
  LANG_PER_CREDIT: '/Kredytów',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '{photoCount} zdjęcia{videoCount, plural, =0 {} other { lub # wideo}} w dowolnym momencie',
  INCLUDES_STANDARD_LICENSE: 'Licencja standardowa',
  LANG_MEDIUM_SIZE: 'Średni',
  LANG_LARGE_SIZE: 'Duży',
  LANG_EXTRA_LARGE_SIZE: 'Bardzo duży',
  LANG_STANDARD_SIZES: 'Rozmiary standardowe',
  LANG_ROYALTY_FREE: 'Royalty-Free',
  LANG_OPTIONS: 'Opcje',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'Skorzystaj z PLUS już od ',
  LANG_RELATED_FONT_SEARCH: 'Powiązane wyszukiwania',
  AI_GENERATED_PHOTO: 'Zdjęcie wygenerowane przez AI',
  FONT_PRICING_CREDIT: 'kredytów',
  '3_FONTS_3_IMGS': '3 czcionki, 3 zdjęć <b>lub</b> 1 film',
  '9_FONTS_9_IMG_3_VIDEOS': '9 czcionek, 9 zdjęć <b>lub</b> 3 filmy',
  PURCHASE_AND_DOWNLOAD: 'Kup i pobierz',
  FONT_PATH: 'fonts',
  FONT_ZIP: 'Czcionka (OTF, TTF)',
  FONT_PACKS_LICENSE: 'Licencja na pakiety 123RF',
  FONT_META_DESC: 'Pobierz czcionkę {product_title}, krój pisma lub typografię, aby stworzyć oszałamiające projekty do druku i stron internetowych.',
  FONT_META_TITLE: 'Czcionka - {product_title} | 123rf',
  FONT_SIMILAR: 'Podobne czcionki',
  LANG_PLANS: 'PLANY',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: 'Spróbuj stworzyć różne warianty',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: 'Generowanie nowych zdjęć na podstawie oryginalnego zdjęcia. Ponadto jest to nowy sposób nagradzania dostawców 123RF za każdym razem, gdy wygenerowane przez sztuczną inteligencję zdjęcie jest licencjonowane!',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: 'Dziękujemy w imieniu naszej społeczności. Opłaty licencyjne będą dzielone z kontrybutorem 123RF.',
  LANG_CONTRIBUTORS_AI_VARIATION: 'Twórcy oryginalnego zdjęcia 123RF otrzymują prowizję za każdą licencjonowaną wersję AI.',
  LANG_AI_VARIATION_LIMIT: 'O nie! Osiągnąłeś limit generowania zdjęć AI na dziś.',
  LANG_GENERATE_COMEBACK: 'Wróć jutro po więcej!',
  LANG_LICENSE_AND_DOWNLOAD: 'Licencja i pobieranie',
  LANG_CONFIRM_OK: 'Dobrze',
  LANG_AI_SWITCH_CONTENT: 'Edytor zdjęć jest niedostępny dla modyfikacji AI. Wszystkie zmiany zostaną utracone po przełączeniu na wariant AI',
  LANG_GENERATE_VARIATIONS: 'Generowanie wariantów',
  LANG_GENERATE_WITH_AI: 'Generowanie zdjęć za pomocą sztucznej inteligencji',
  LANG_NEW: 'Nowość',
  LANG_AI_GENERATOR_LICENSE: 'Warunki korzystania z usługi 123RF AI Generator (“Warunki”)',
  LANG_I_UNDERSTAND: 'Rozumiem',
  LANG_WHAT_IS_THIS: 'Co to jest?',
  LANG_AUTHENTIC: 'Autentyczny',
  LANG_ABSTRACT: 'Abstrakcyjny',
  LANG_MACRO_CLOSEUP: 'Makro/zbliżenie',
  LANG_OBJECT: 'Obiekt',
  LANG_WATERCOLOR: 'Akwarela',
  LANG_FLAT: 'Płaski',
  LANG_CARTOON: 'Kreskówka',
  LANG_GEOMETRIC: 'Geometryczny',
  LANG_GRADIENT: 'Gradient',
  LANG_ISOMETRIC: 'Izometryczny',
  LANG_3D: '3d',
  LANG_HAND_DRAWN: 'Ręcznie rysowane',
  LANG_NATURAL: 'Naturalny',
  LANG_WARM: 'Ciepły',
  LANG_DRAMATIC: 'Dynamiczny',
  LANG_VIVID_BOLD: 'Żywy i wyrazisty',
  LANG_BLACK_WHITE: 'Czarno-biały',
  LANG_ASIAN: 'Azjata',
  LANG_AFRICAN_AMERICAN: 'Afroamerykanin',
  LANG_STANDARD: 'Standardowa',
  LANG_EXTENDED: 'Rozszerzona',
  LANG_STANDARD_CONTENT_1: 'Nieograniczone wyświetlenia w serwisach społecznościowych, w Internecie, w wiadomościach e-mail i na urządzeniach mobilnych.',
  LANG_STANDARD_CONTENT_2: 'Do 500 000 nakładu.',
  LANG_STANDARD_CONTENT_3: 'Nie do użytku na towarach przeznaczonych do sprzedaży.',
  LANG_EXTENDED_CONTENT_1: 'Nieograniczone wyświetlenia w serwisach społecznościowych, w Internecie, w wiadomościach e-mail i na urządzeniach mobilnych.',
  LANG_EXTENDED_CONTENT_2: 'Nieograniczony nakład.',
  LANG_EXTENDED_CONTENT_3: 'Do wykorzystania na towarach przeznaczonych do sprzedaży.',
  LANG_ORIGINAL_IMAGE: 'Oryginalne zdjęcie',
  LANG_IMPORTANT_NOTE: 'Ważna informacja',
  LANG_AI_ORIGINAL_ALTERED: 'Wariacje AI to warianty oryginalnego zdjęcia, które zostały zmienione przy użyciu technologii AI. Jest to podobne do zmiany wybranego zdjęcia przez edytor AI.',
  LANG_GENERATE_VARIATIONS_NOTE: 'Ponieważ jesteśmy obecnie na etapie beta wariacji zdjęć opartych na sztucznej inteligencji, wygenerowane zdjęcia mogą przynieść nieoczekiwane rezultaty. Dokładamy wszelkich starań, aby takie wyniki i wariacje były zgodne z etycznymi i odpowiedzialnymi wytycznymi dotyczącymi sztucznej inteligencji.',
  LANG_LICENSE_AI_EDITED: 'Przy licencjonowaniu jednej z wersji edytowanych przez AI, właściciel praw autorskich do oryginalnego zdjęcia otrzymuje opłatę licencyjną.',
  LANG_MOOD: 'Nastrój',
  LANG_AI_EXCLUDE_PLACEHOLDER: 'Np. zielony kolor, proste włosy',
  LANG_SIMILAR_STOCK: 'Podobne zdjęcia stockowe',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: 'z Model Release',
  WITH_PROPERTY_RELEASE: 'z Property Release',
  MODEL_RELEASE_CONTENT: 'Zgoda modela (model release) to dokument prawny podpisany przez modela(i), udzielający zgody na publikację / rozprowadzanie / używanie fotografii z jego czy jej wizerunkiem w takiej czy innej formie.',
  PROPERTY_RELEASE_CONTENT: 'Umowa określająca prawo i sposób wykorzystania zdjęć nieruchomości (property release) to dokument prawny podpisany przez właściciela obiektu, przedmiotu czy miejsca wykonania zdjęcia, udzielający zgody na publikację / rozprowadzanie / używanie fotografii w takiej czy innej formie.',

  variousStockGAI: 'Zgodny z prawem model sztucznej inteligencji przeszkolony z milionów zdjęć stockowych i zoptymalizowany pod kątem potrzeb przedsiębiorstw zapewnia spokój ducha dzięki ochronie przed odpowiedzialnością.',
  extendedLicense: 'Licencja Rozszerzona',
  sizes: 'Rozmiary',

  downloadMail1st: 'Nasz dostawca usługi powiększenia obrazu prześle na adres',
  downloadMail2nd: 'instrukcje dotyczące pobrania ostatecznego powiększonego pliku w ciągu następnego dnia roboczego',

  aiGenarated: 'Zdjęcie wygenerowane przez AI',

  variousOpenJourney: 'AI Model generuje grafikę przypominającą charakterystyczny styl Midjourney.',
  variousTurboVision: 'Szybkie generowanie w "normalnych" rozdzielczościach XL, poprawiające ogólną jakość.',
  variousRealVisXL: 'Model ma na celu osiągnięcie fotorealistycznych rezultatów z precyzją i dokładnością.',
  variousPortraitPlus: 'Model o jednolitej kompozycji portretowej, idealny do proporcji 1:1.',
  enterVirifiedCode: 'Wprowadź kod weryfikacyjny',
  verify: 'Zweryfikuj',
  didNotGetCode: 'Nie otrzymałeś kodu?',
  clickToResend: 'Kliknij, aby wysłać ponownie',
  otpCannotEmpty: 'OTP nie może być puste.',
  sentCodeToMail: 'Wysłaliśmy kod na zarejestrowany adres e-mail.',
  invalidCode: 'Nieprawidłowy kod.',
  expiredOTP: 'OTP wygasł, proszę wysłać ponownie.',
  sendEmail: 'E-mail został pomyślnie wysłany, proszę sprawdź swoją skrzynkę pocztową, aby kontynuować.',
  remainingTime: 'Pozostały czas: ',
};

export default pl;
